<template>
  <div>
    <v-autocomplete
      ref="autocompleteMain"
      :value="value"
      item-text="displayName"
      item-value="displayName"
      @input="updateValue($event)"
      :items="items"
      :loading="isLoading"
      :search-input.sync="searchAddress"
      :label="label"
      :placeholder="$t('AutocompleteAddressOsm.placeholder')"
      :prepend-icon="prependIcon"
      clearable
      :outlined="outlined"
      :filled="filled"
      :disabled="disabled"
      return-object
      :filter="filterNone"
      v-bind="searchParams"
      :title="$t('AutocompleteAddressOsm.title')"
      dense
    >
      <!--    <template slot="selection" slot-scope="{ item }">
      {{ item.displayName }}
    </template>

    <template slot="item" slot-scope="{ item }">
      {{ item.displayName }}
    </template>-->
      <!-- Dialog na Vytvorenie novej objednavky-->
    </v-autocomplete>
    <!--<v-dialog
      v-model="findNewPlaceDialog"
      transition="dialog-top-transition"
      max-width="600px"
      eager
    >
      <v-toolbar color="secondary" dark dense flat>
        <v-toolbar-title>Pridanie novej adresy</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="findNewPlaceDialog">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <input
                    ref="autocomplete"
                    type="text"
                    placeholder="Enter a location"
                    style="
                      width: 100%;
                      border: 0;
                      border-bottom: 1px solid #d2d2d2;
                      outline: 0;
                      font-size: 16px;
                      color: #212121;
                      padding: 7px 0;
                      background: transparent;
                      transition: border-color 0.2s;
                    "
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              dense
              text
              @click.stop="findNewPlaceDialog = false"
              >{{ $t("btnClose") }}</v-btn
            >
            <v-btn color="success" dense text @click.stop="addNewAddress()"
              >Pridať</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>-->
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "AutocompleteAddressOsm",
  props: {
    value: {
      type: Object,
      default: null,
    },

    label: {
      type: String,
      default: "Address",
    },
    prependIcon: {
      type: String,
      default: "",
    },

    filled: {
      type: Boolean,
    },
    outlined: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    searchParams: {
      type: Object,
      default: null,
    },
    addAddress: {
      type: Object,
      default: null,
    },
    addDefaultAddresses: {
      type: Array,
      default: null,
    },
    mapBounds: {
      type: Object,
      default: null,
    },
    setFocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      isLoading: false,
      model: null,

      searchAddress: null,
      doNotSearchAddedAddress: false,

      searchData: {
        format: "json",
        q: null,
        //country: null,
        //city: null,
        //taxiservicesId: null,
        //language: null,
        limit: 10,
        viewbox: null,
        bounded: 1,
      },

      debounce: null,

      //Pridanie adresy do DB od Google
      //    findNewPlaceDialog: false,
      //Flag na oznacenie, ze adresa bola priamo vyhladana cez google, aby sa duplicitne nevyhladavala v lokalnej DB
      /*    newPlaceFlag: false,
      autocomplete: null,
      newPlace: null,
      newAddress: {
        active: true,
        amenityName: null,
        amenityType: null,
        categoryId: null,
        categoryName: null,
        city: null,
        cityDistrict: null,
        country: "Slovensko",
        countryCode: "SK",
        county: null,
        displayName: null,
        formattedAddress: null,
        houseNumber: null,
        id: null,
        latitude: null,
        longitude: null,
        neighbourhood: null,
        ordering: 0,
        postcode: null,
        priority: 0,
        road: null,
        state: null,
        suburb: null,
      },
*/
      //Snackbar
      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },
    };
  },

  created() {},

  mounted() {
    //this.initMap();
    this.initSearch();
  },

  computed: {},

  watch: {
    searchAddress(value) {
      //Ak nie je ziadna hodnota, nevyhladavat.
      if (!value) {
        return;
      }
      //Ak je adresa z google, nevyhladavat
      //     if (this.newPlaceFlag == true) {
      //       this.newPlaceFlag = false; //po jednorazovom obmedzeni vyhladavania ak je priamo z google, tak opat povolit vyhladavanie
      //       return;
      //     }
      //Ak je hodnota kratšia ako 3 znaky, tak nevyhladavat
      if (value.length < 3) {
        return;
      }
      // this.clearEntries();
      //this.searchData.format = "json";
      this.searchData.q = value;
      /*this.searchData.countryCode = this.searchParams.countryCode;
      this.searchData.city = this.searchParams.city;*/
      //Nacitat z localstorage ID taxisluzby, lebo kazda taxisluzba si tvori vlasny zoznam adries/Places
      /* this.searchData.taxiserviceId = localStorage.getItem(
        "selectedTaxiserviceId"
      );*/
      //this.searchData.taxiserviceId = this.searchParams.taxiserviceId;
      //this.searchData.language = this.searchParams.language;
      //this.searchAddressApi();
      //Ak prisla adresa z mapy do pola v objekte "addAddress", tak ju nevyhladavat
      //console.log("this.doNotSearchAddedAddress", this.doNotSearchAddedAddress);
      if (this.doNotSearchAddedAddress == true) {
        this.doNotSearchAddedAddress = false;
      } else {
        // console.log("searchAddressApi");
        this.isLoading = true;
        this.fetchEntriesDebounced();
      }
    },
    /* findNewPlaceDialog: function (val) {
      this.$refs.autocomplete.focus();
    },*/
    addAddress: function () {
      //console.log("addAddress", this.addAddress);
      this.doNotSearchAddedAddress = true;
      //if (this.addAddress) {
      /*this.items = this.addAddress;*/
      var temporaryAddress = [];
      temporaryAddress.latitude = this.addAddress.latitude;
      temporaryAddress.longitude = this.addAddress.longitude;
      temporaryAddress.displayName = this.addAddress.displayName;
      //console.log("addAddress temporaryAddress", temporaryAddress);
      this.items.push(Object.assign({}, temporaryAddress));
      this.updateValue(Object.assign({}, temporaryAddress));
      //console.log("addAddress this.items.push", this.items);
      //}
    },
    addDefaultAddresses: function () {
      //console.log("addDefaultAddresses", this.addDefaultAddresses);
      //if (this.addDefaultAddresses) {
      /*this.items = this.addDefaultAddresses;*/
      var temporaryAddress = [];
      var defaultAddress = this.addDefaultAddresses;
      if (defaultAddress) {
        defaultAddress.forEach((element) => {
          temporaryAddress = [];
          temporaryAddress.latitude = element.latitude;
          temporaryAddress.longitude = element.longitude;
          temporaryAddress.displayName = element.address;
          //console.log("addDefaultAddresses temporaryAddress", temporaryAddress);
          //this.updateValue(Object.assign({}, temporaryAddress));
          this.items.push(Object.assign({}, temporaryAddress));
          //console.log("addDefaultAddresses this.items.push", this.items);
        });
      }
      //}
    },
    mapBounds: function () {
      //this.autocomplete.setBounds(this.mapBounds);
      //console.log("autocomplete - mapBounds", this.mapBounds);
      //covert this.mapBounds (google.maps.LatLngBounds) to OSM NOMINATIM viewbox string
      //https://nominatim.org/release-docs/develop/api/Search/
      //https://stackoverflow.com/questions/14492284/center-a-map-exactly-over-a-location-given-by-latitude-and-longitude
      var viewbox = "";
      var sw = this.mapBounds.getSouthWest();
      var ne = this.mapBounds.getNorthEast();
      viewbox = sw.lng() + "," + sw.lat() + "," + ne.lng() + "," + ne.lat();
      //console.log("autocomplete - viewbox", viewbox);
      this.searchData.viewbox = viewbox;
    },
    setFocus: function () {
      //console.log("setFocus", this.setFocus);
      if (this.setFocus == true) {
        setTimeout(() => {
          this.$refs.autocompleteMain.focus();
          //this.$refs.autocompleteMain.isMenuActive = true;
          this.$refs.autocompleteMain.activateMenu();
        }, 200);
      }
    },
  },

  methods: {
    clearSearch() {
      //console.log("test cleareable");
      var temporaryAddress = [];
      temporaryAddress.latitude = null;
      temporaryAddress.longitude = null;
      temporaryAddress.displayName = null;
      this.updateValue(Object.assign({}, temporaryAddress));
      this.items.push(Object.assign({}, temporaryAddress));
    },

    updateValue(value) {
      //console.log("updateValue: ", value);
      if (!value) {
        var temporaryAddress = [];
        temporaryAddress.latitude = null;
        temporaryAddress.longitude = null;
        temporaryAddress.displayName = "";
        value = Object.assign({}, temporaryAddress);
      }
      this.$emit("input", value);
    },

    filter(item, queryText, itemText) {
      return item.displayName
        .toLocaleLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(
          queryText
            .toLocaleLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        );
    },
    filterNone(item, queryText, itemText) {
      // Vráťte všetky výsledky z API bez akéhokoľvek filtrovania
      return true;
    },

    searchAddressApi() {
      this.isLoading = true;
      //console.log("search: ", this.searchData.query),
      axios
        /* .//get(process.env.VUE_APP_API_BASE_URL + `/dispatching/place/geo`, {*/
        .get(`https://nominatim.openstreetmap.org/search`, {
        //.get(`https://places.taxximo.com/search.php`, {
          params: this.searchData,
        })
        .then((res) => {
          //console.log("searchAddresssApi - response", res);
          if (res.data.length > 0) {
            var items = res.data;
            //zamenit display_name na displayName, lat (string) na latitude (number), lon na longitude
            items.forEach((element) => {
              element.displayName = element.display_name;
              //delete element.display_name;
              element.latitude = parseFloat(element.lat);
              //delete element.lat;
              element.longitude = parseFloat(element.lon);
              //delete element.lon;
            });
            console.log("searchAddresssApi - items", items);
            this.items = items;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },

    clearEntries() {
      this.items = [];
    },

    fetchEntriesDebounced() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchAddressApi();
      }, 800); /* 500ms throttle */
    },

    setFocusToAutocompleteField() {
      this.$refs.autocomplete.focus();
    },

    initSearch() {
      //const input = document.getElementById("pac-input");
      //  const input = /** @type {!HTMLInputElement} */ /* (this.$refs.autocomplete);*/

      /*   const options = {
        fields: [
          "formatted_address",
          "address_components",
          "geometry",
          "name",
          "icon",
        ],
        strictBounds: false,
        //types: ["establishment"],
        types: [],
      };*/
      /*  var bounds;
      //var boundsSw;
      //var boundsNe;
      if (!bounds || bounds == null) {
       boundsSw = new google.maps.LatLng(47.1, 17.1);
        boundsNe = new google.maps.LatLng(49.1, 19.1);
        //console.log("1", boundsNe);
        bounds = new google.maps.LatLngBounds(boundsSw, boundsNe);
        //console.log("bounds 2:", bounds);
        this.autocomplete = new google.maps.places.Autocomplete(input, options);
        var viewbox = sw.lng() + "," + sw.lat() + "," + ne.lng() + "," + ne.lat();
        //console.log("autocomplete - viewbox", viewbox);
        this.searchData.viewbox = viewbox;
      } else {
        bounds = this.mapBounds;*/
      var viewbox = "16.9,47.2,19.1,49.1";
      //console.log("autocomplete - viewbox", viewbox);
      this.searchData.viewbox = viewbox;
      /*}
      //console.log("bounds 3:", bounds);
      this.autocomplete.setBounds(bounds);
      //autocomplete.bindTo(bounds);
      this.autocomplete.addListener("place_changed", () => {
        const place = this.autocomplete.getPlace();
        this.newPlace = place;
        if (!place.geometry || !place.geometry.location) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          window.alert("No details available for input: '" + place.name + "'");
          return;
        }
        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          //console.log("place.geometry.viewport", place.geometry.viewport);
        } else {
          //console.log("place.geometry.location", place.geometry.location);
        }
        //console.log("place.geometry.location", place.geometry.location);
        //console.log("place.name", place.name);
        //console.log("place.formatted_address", place.formatted_address);
      });*/
    },
  },

  beforeDestroy() {
    clearInterval(this.debounce);
  },
};
</script>
