<template>
  <!--  :item-text="item => `${item.id} - ${item.firstName} ${item.lastName} (${item.internalName})`"  -->
  <!--  item-value="id"   -->
  <!--  @click:append="searchAccountApi()"   -->
  <!--  :item-text="(item) => item.id + ' - ' + item.name + ' | ' + item.status"  -->
  <v-autocomplete
    :value="value"
    item-value="accountId"
    ref="autocompleteAccount"
    @input="updateValue($event)"
    :items="items"
    :loading="isLoading"
    :search-input.sync="searchAccount"
    hide-selected
    :label="label"
    :placeholder="$t('AutocompleteAccountRemote.placeholder')"
    :prepend-icon="prependIcon"
    @click:prepend="searchAccountApi()"
    clearable
    :outlined="outlined"
    :filled="filled"
    :disabled="disabled"
    :single-line="singleLine"
    :filter="filter"
    v-bind="searchParams"
    :hide-details="hideDetails"
  >
    <template v-slot:selection="{ item }">
      {{ item.accountId }} - {{ item.name }}
      {{ item.status !== "ACTIVE" ? "(" + item.status + ")" : "" }}
    </template>

    <template v-slot:item="{ item }">
      {{ item.accountId }} - {{ item.name }}
      {{ item.status !== "ACTIVE" ? "(" + item.status + ")" : "" }}
      <template v-if="item.status !== 'ACTIVE'"> !!! </template>
    </template>
  </v-autocomplete>
</template>

<script>
import axios from "axios";
import { moduleDefaultSettings } from "../enums/ModuleEnum";
export default {
  props: {
    value: {
      type: Number,
      default: null,
    },

    label: {
      type: String,
      default: "Account",
    },
    prependIcon: {
      type: String,
      default: "mdi-domain",
    },

    filled: {
      type: Boolean,
    },
    outlined: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    hideDetails: {
      type: Boolean,
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    searchParams: {
      type: Object,
      //default: true,
    },
  },
  data() {
    return {
      items: [],
      /*   item: {
        active: "ACTIVE",
        email: "string",
        firstName: "string",
        id: 0,
        internalName: "string",
        lastName: "string",
        phone: "string",
        status: "ARCHIVED",
      },*/

      isLoading: false,
      model: null,
      module: moduleDefaultSettings,

      searchAccount: null,

      searchData: {},

      debounce: null,
    };
  },

  created() {
    //Nastavenie modulov
    var modules = this.$store.state.module;
   // console.log("autocomplete - modules pred", modules);
    if (modules !== null && modules !== undefined) {
      this.module = modules;
    }
    //console.log("autocomplete - module po", this.module);
  },

  mounted() {
    this.searchAccountApi();
  },

  computed: {},

  watch: {
    searchAccount(value) {
      console.log("searchAccount", value);
      //Ak nie je ziadna hodnota, nevyhladavat
      if (!value) {
        return;
      }
      //Vyhladat zo servera iba pri zadani prveho znaku, potom udaje filtrovat v autocomplete poli
      if (value.length > 1) {
        return;
      }
      // this.clearEntries();
      //this.searchData.all = this.searchParams.type;
      //this.searchAddressApi();
      this.isLoading = true;
      this.fetchEntriesDebounced();
    },
  },

  methods: {
    updateValue(value) {
      //console.log("updateValue: ", value);
      this.$emit("input", value);
    },

    filter(item, queryText, itemText) {
      return (
        item.accountId +
        " " +
        item.name +
        " " +
        item.status +
        " " +
        item.country
      )
        .toLocaleLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(
          queryText
            .toLocaleLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        );
    },

    searchAccountApi() {
      if (this.module.account == false) {
        this.items = [];
        return;
      }
      this.isLoading = true;
      //console.log("search: ", this.searchData.query),
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/account`, {
          params: this.searchData,
        })
        .then((res) => {
          // console.log(res);
          this.items = res.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },

    clearEntries() {
      this.items = [];
    },

    fetchEntriesDebounced() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchAccountApi();
      }, 800); /* 500ms throttle */
    },

    /*  hideExtraInput() {
      if (this.value.length > 0) {
        this.$refs.selectComponent.$el.querySelector(
          ".v-select__selections input"
        ).type = "hidden";
      }
    },*/

    /*  getItemText(items) {
      console.log("srč", `${items.firstName}`);
      return `${items.firstName} ${items.lastName}`;
    }, */
  },

  beforeDestroy() {
    clearInterval(this.debounce);
  },
};
</script>

<style>
/*.v-select.v-input--is-dirty input {
  display: none;
}

.v-select__selection {
  max-width: none;
}*/
/*.v-select__selections input {
  width: 0;
}*/
</style>

