<template>
  <v-container class="pa-1">
    <v-tabs v-model="tab" v-show="showPanel">
      <v-tabs-slider color="primary"></v-tabs-slider>

      <v-tab
        href="#tab-all"
        class="px-2"
        style="min-width: 48px !important"
        title="Všetky správy"
        @click="getAllMessages(), scrollToLastMessage('ALL')"
      >
        <v-icon>mdi-email-multiple-outline</v-icon>
        <!-- <span v-show="$vuetify.breakpoint.xlAndUp">Všetky</span>-->
      </v-tab>
      <v-tab
        href="#tab-order"
        class="px-2"
        style="min-width: 48px !important"
        @click="getOrderMessages()"
      >
        <v-icon left small>mdi-comment-text-multiple-outline</v-icon>
        <span v-show="$vuetify.breakpoint.mdAndUp">{{
          $t("ConsoleOrderMessage.tabOrder")
        }}</span>
      </v-tab>
      <v-tab
        href="#tab-dispdriver"
        class="px-2"
        style="min-width: 48px !important"
        @click="getDispDriverMessages()"
      >
        <v-icon left small>far fa-comments</v-icon>
        <span v-show="$vuetify.breakpoint.mdAndUp">{{
          $t("ConsoleOrderMessage.tabDriver")
        }}</span>
      </v-tab>
      <v-tab
        href="#tab-dispatching"
        class="px-2"
        style="min-width: 48px !important"
        @click="getDispatchingMessages()"
      >
        <v-icon left small>mdi-chat-outline</v-icon>
        <span v-show="$vuetify.breakpoint.mdAndUp">{{
          $t("ConsoleOrderMessage.tabDispatch")
        }}</span>
      </v-tab>
      <v-tab
        href="#tab-public"
        class="px-2"
        style="min-width: 48px !important"
        @click="getPublicMessages()"
        :title="$t('ConsoleOrderMessage.tabPublic_title')"
      >
        <v-icon left small>mdi-bullhorn-outline</v-icon>
        <!-- <span v-show="$vuetify.breakpoint.xlAndUp">Verejné</span> -->
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" v-show="showPanel">
      <v-tab-item value="tab-all">
        <div
          :style="
            'height:500px; max-width:' +
            ($vuetify.breakpoint.smAndDown ? '350' : '600') +
            'px'
          "
          class="overflow-y-auto"
          cols="5"
          ref="tabAll"
        >
          <v-list dense subheader>
            <template v-for="(item, index) in allMessages">
              <v-list-item :key="item.id" dense class="pa-0 ma-0">
                <!-- :content = how many minutes since item.createdAt, max 5 -->
                <v-badge
                  color="red"
                  dot
                  :value="
                    getMinutesFromNow(item.createdAt) >= 0 &&
                    getMinutesFromNow(item.createdAt) < 3
                      ? true
                      : false
                  "
                  offset-x="25"
                  offset-y="25"
                >
                  <v-list-item-avatar>
                    <v-icon :color="messageTypeColor[item.type]" dark>
                      {{
                        item.fromType == "DISPATCHER"
                          ? "mdi-face-man-outline"
                          : item.fromType == "DRIVER"
                          ? "mdi-account-tie-outline"
                          : item.fromType == "CLIENT"
                          ? "mdi-human-greeting-variant"
                          : item.fromType == "SYSTEM"
                          ? "mdi-robot-outline"
                          : "mdi-alien-outline"
                      }}</v-icon
                    >
                  </v-list-item-avatar>
                </v-badge>

                <v-list-item-content>
                  <!-- new Date(new Date(item.createdAt) -new Date().getTimezoneOffset() * 60000).toLocaleString('sk-SK') + -->
                  <v-list-item-title>{{
                    $t("messageType." + item.type) +
                    " | " +
                    moment(String(item.createdAt)).format(
                      "DD.MM.YYYY HH:mm:ss"
                    ) +
                    " | " +
                    (item.fromDispatcherId && item.fromType == "DISPATCHER"
                      ? item.fromDispatcherFirstName +
                        " " +
                        item.fromDispatcherLastName
                      : item.fromDriverId && item.fromType == "DRIVER"
                      ? item.fromDriverFirstName + " " + item.fromDriverLastName
                      : item.fromClientId && item.fromType == "CLIENT"
                      ? item.fromClientNickname
                      : item.type == "SYSTEM"
                      ? "ROBOT"
                      : "") +
                    (item.toOrderId ? " | " + item.toOrderId.substr(0, 8) : "")
                  }}</v-list-item-title>

                  <v-list-item-subtitle class="text-wrap">
                    <!--{{ new Date() }}-->{{
                      item.message
                    }}</v-list-item-subtitle
                  >
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    icon
                    class="px-7"
                    @click="
                      changeMessageTab(
                        item.type,
                        item.fromDriverId,
                        item.toDriverId,
                        item.toOrderId,
                        ''
                      )
                    "
                    v-show="item.type != 'SYSTEM'"
                  >
                    <v-icon :color="messageTypeColor[item.type]" dense>{{
                      item.type == "ORDER"
                        ? "mdi-comment-text-multiple-outline"
                        : item.type == "DISP_DRIVER"
                        ? "far fa-comments"
                        : item.type == "DISPATCHING"
                        ? "mdi-chat-outline"
                        : item.type == "PUBLIC"
                        ? "mdi-bullhorn-outline"
                        : item.type == "SYSTEM"
                        ? "mdi-email-multiple-outline"
                        : item.type == "DIRECT"
                        ? "comment-arrow-left-outline"
                        : "mdi-help-circle-outline"
                    }}</v-icon>
                    <v-icon :color="messageTypeColor[item.type]" dense>{{
                      item.type == "SYSTEM" ? "" : "mdi-chevron-right"
                    }}</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    class="px-7"
                    @click="showOrderDetailByIdFromMessage(item.toOrderId)"
                    v-show="item.type == 'SYSTEM' && item.toOrderId"
                  >
                    <v-icon> mdi-information-outline </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="index < allMessages.length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list>
        </div>
      </v-tab-item>

      <v-tab-item value="tab-order">
        <div
          id="tabOrder"
          class="overflow-y-auto"
          style="height: 400px; max-width: 600px"
          ref="tabOrder"
        >
          <v-list dense subheader>
            <template v-for="(item, index) in orderMessages">
              <v-list-item :key="item.id" dense class="pa-0 ma-0">
                <v-list-item-avatar>
                  <v-icon :color="messageTypeColor[item.type]" dark>
                    {{
                      item.fromType == "DISPATCHER"
                        ? "mdi-face-man-outline"
                        : item.fromType == "DRIVER"
                        ? "mdi-account-tie-outline"
                        : item.fromType == "CLIENT"
                        ? "mdi-human-greeting-variant"
                        : item.fromType == "SYSTEM"
                        ? "mdi-robot-outline"
                        : "mdi-alien-outline"
                    }}</v-icon
                  >
                </v-list-item-avatar>

                <v-list-item-content>
                  <!-- new Date(new Date(item.createdAt) -new Date().getTimezoneOffset() * 60000).toLocaleString('sk-SK') + -->
                  <v-list-item-title>{{
                    moment(String(item.createdAt)).format(
                      "DD.MM.YYYY HH:mm:ss"
                    ) +
                    " | " +
                    (item.fromDispatcherId && item.fromType == "DISPATCHER"
                      ? item.fromDispatcherFirstName +
                        " " +
                        item.fromDispatcherLastName
                      : item.fromDriverId && item.fromType == "DRIVER"
                      ? item.fromDriverFirstName + " " + item.fromDriverLastName
                      : item.fromClientId && item.fromType == "CLIENT"
                      ? item.fromClientNickname
                      : "") +
                    (item.toOrderId ? " | " + item.toOrderId.substr(0, 8) : "")
                  }}</v-list-item-title>

                  <v-list-item-subtitle class="text-wrap">
                    <!--{{ new Date() }}-->{{
                      item.message
                    }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="index < allMessages.length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list>
        </div>
        <v-form ref="formOrderMessage" onSubmit="return false;">
          <v-row align="center" dense>
            <v-col cols="12" md="9" class="pt-0 pb-4">
              <v-text-field
                v-model="orderIdField"
                ref="OrderIdMessage"
                label="Order Id"
                disabled
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="pt-0 pb-4">
              <v-icon @click="showOrderDetailByIdFromMessage(orderIdField)">
                mdi-information-outline
              </v-icon>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-chip
                dark
                label
                small
                class="px-1 mr-1"
                :color="messageTypeColor['ORDER']"
                @click="
                  orderMessageField = $t(
                    'ConsoleOrderMessage.orderMessageText.ok_text'
                  )
                "
                :title="$t('ConsoleOrderMessage.copyToTextField')"
              >
                {{ $t("ConsoleOrderMessage.orderMessageText.ok") }}
              </v-chip>
              <v-chip
                dark
                label
                small
                class="px-1 mr-1"
                :color="messageTypeColor['ORDER']"
                @click="
                  orderMessageField = $t(
                    'ConsoleOrderMessage.orderMessageText.good_text'
                  )
                "
                :title="$t('ConsoleOrderMessage.copyToTextField')"
              >
                {{ $t("ConsoleOrderMessage.orderMessageText.good") }}
              </v-chip>
              <v-chip
                dark
                label
                small
                class="px-1 mr-1"
                :color="messageTypeColor['ORDER']"
                @click="
                  orderMessageField = $t(
                    'ConsoleOrderMessage.orderMessageText.thankyou_text'
                  )
                "
                :title="$t('ConsoleOrderMessage.copyToTextField')"
              >
                {{ $t("ConsoleOrderMessage.orderMessageText.thankyou") }}
              </v-chip>
              <v-chip
                dark
                label
                small
                class="px-1 mr-1"
                :color="messageTypeColor['ORDER']"
                @click="
                  orderMessageField = $t(
                    'ConsoleOrderMessage.orderMessageText.calling_text'
                  )
                "
                :title="$t('ConsoleOrderMessage.copyToTextField')"
              >
                {{ $t("ConsoleOrderMessage.orderMessageText.calling") }}
              </v-chip>
              <v-chip
                dark
                label
                small
                class="px-1 mr-1"
                :color="messageTypeColor['ORDER']"
                @click="
                  orderMessageField = $t(
                    'ConsoleOrderMessage.orderMessageText.going_text'
                  )
                "
                :title="$t('ConsoleOrderMessage.copyToTextField')"
              >
                {{ $t("ConsoleOrderMessage.orderMessageText.going") }}
              </v-chip>
              <v-chip
                dark
                label
                small
                class="px-1 mr-1"
                :color="messageTypeColor['ORDER']"
                @click="
                  orderMessageField = $t(
                    'ConsoleOrderMessage.orderMessageText.cancelled_text'
                  )
                "
                :title="$t('ConsoleOrderMessage.copyToTextField')"
              >
                {{ $t("ConsoleOrderMessage.orderMessageText.cancelled") }}
              </v-chip>
              <v-chip
                dark
                label
                small
                class="px-1 mr-1"
                :color="messageTypeColor['ORDER']"
                @click="
                  orderMessageField = $t(
                    'ConsoleOrderMessage.orderMessageText.1min_text'
                  )
                "
                :title="$t('ConsoleOrderMessage.copyToTextField')"
              >
                {{ $t("ConsoleOrderMessage.orderMessageText.1min") }}
              </v-chip>
              <v-chip
                dark
                label
                small
                class="px-1 mr-1"
                :color="messageTypeColor['ORDER']"
                @click="
                  orderMessageField = $t(
                    'ConsoleOrderMessage.orderMessageText.2min_text'
                  )
                "
                :title="$t('ConsoleOrderMessage.copyToTextField')"
              >
                {{ $t("ConsoleOrderMessage.orderMessageText.2min") }}
              </v-chip>
              <v-chip
                dark
                label
                small
                class="px-1 mr-1"
                :color="messageTypeColor['ORDER']"
                @click="
                  orderMessageField = $t(
                    'ConsoleOrderMessage.orderMessageText.5min_text'
                  )
                "
                :title="$t('ConsoleOrderMessage.copyToTextField')"
              >
                {{ $t("ConsoleOrderMessage.orderMessageText.5min") }}
              </v-chip>
              <v-chip
                dark
                label
                small
                class="px-1 mr-1"
                :color="messageTypeColor['ORDER']"
                @click="getClientPhone(orderIdField)"
                :title="$t('ConsoleOrderMessage.copyToTextField')"
              >
                {{ $t("ConsoleOrderMessage.orderMessageText.clientPhone") }}
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <!--v-model="orderMessageField" -->
            <v-col cols="12" md="9" class="pt-0 pb-4">
              <!--Message to Driver and Client -->
              <v-text-field
                filled
                :label="$t('ConsoleOrderMessage.orderMessageField')"
                type="text"
                validate-on-blur
                :value="orderMessageField"
                @change="(v) => (orderMessageField = v)"
                dense
                clearable
                required
                hide-details
                single-line
                :rules="[(v) => !!v || $t('msgRequiredField')]"
                maxlength="140"
                @keydown.enter="sendOrderMessage()"
              ></v-text-field>
              <!-- append-icon="mdi-send-outline"
                @click:append="sendOrderMessage"
                @click:append="$refs.OrderIdMessage.$el.focus(); sendOrderMessage()" -->
            </v-col>
            <v-col cols="12" md="3" class="pt-0 pb-4">
              <v-btn color="info" medium @click="sendOrderMessage()">
                <v-icon dark> mdi-send-outline </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>

      <v-tab-item value="tab-dispdriver">
        <div
          id="tabDispDriver"
          class="overflow-y-auto"
          style="height: 400px; max-width: 600px"
          ref="tabDispDriver"
        >
          <v-list dense subheader>
            <template v-for="(item, index) in dispDriverMessages">
              <v-list-item :key="item.id" dense class="pa-0 ma-0">
                <v-list-item-avatar>
                  <v-icon :color="messageTypeColor[item.type]" dark>
                    {{
                      item.fromType == "DISPATCHER"
                        ? "mdi-face-man-outline"
                        : item.fromType == "DRIVER"
                        ? "mdi-account-tie-outline"
                        : item.fromType == "CLIENT"
                        ? "mdi-human-greeting-variant"
                        : item.fromType == "SYSTEM"
                        ? "mdi-robot-outline"
                        : "mdi-alien-outline"
                    }}</v-icon
                  >
                </v-list-item-avatar>

                <v-list-item-content>
                  <!--new Date(new Date(item.createdAt) -new Date().getTimezoneOffset() * 60000).toLocaleString('sk-SK') +-->
                  <v-list-item-title>{{
                    moment(String(item.createdAt)).format(
                      "DD.MM.YYYY HH:mm:ss"
                    ) +
                    " | " +
                    (item.fromDispatcherId && item.fromType == "DISPATCHER"
                      ? item.fromDispatcherFirstName +
                        " " +
                        item.fromDispatcherLastName
                      : item.fromDriverId && item.fromType == "DRIVER"
                      ? item.fromDriverFirstName + " " + item.fromDriverLastName
                      : item.fromClientId && item.fromType == "CLIENT"
                      ? item.fromClientNickname
                      : "") +
                    (item.toOrderId ? " | " + item.toOrderId.substr(0, 8) : "")
                  }}</v-list-item-title>

                  <v-list-item-subtitle class="text-wrap">
                    <!--{{ new Date() }}-->{{
                      item.message
                    }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="index < allMessages.length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list>
        </div>
        <div>
          <v-form ref="formDispDriverMessage" onSubmit="return false;">
            <v-row dense>
              <v-col cols="12">
                <autocomplete-driver-remote
                  v-model="dispDriverField"
                  dense
                  :singleLine="true"
                  :searchParams="driverSearchData"
                  :label="$t('ConsoleOrderMessage.btnAutocompleteDriverRemote')"
                  :placeholder="
                    $t(
                      'ConsoleOrderMessage.btnAutocompleteDriverRemote_placeholder'
                    )
                  "
                  :title="
                    $t('ConsoleOrderMessage.btnAutocompleteDriverRemote_title')
                  "
                >
                </autocomplete-driver-remote
              ></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-chip
                  dark
                  label
                  small
                  class="px-1 mr-1"
                  :color="messageTypeColor['DISP_DRIVER']"
                  @click="
                    dispDriverMessageField = $t(
                      'ConsoleOrderMessage.dispDriverMessageText.ok_text'
                    )
                  "
                  :title="$t('ConsoleOrderMessage.copyToTextField')"
                >
                  {{ $t("ConsoleOrderMessage.dispDriverMessageText.ok") }}
                </v-chip>
                <v-chip
                  dark
                  label
                  small
                  class="px-1 mr-1"
                  :color="messageTypeColor['DISP_DRIVER']"
                  @click="
                    dispDriverMessageField = $t(
                      'ConsoleOrderMessage.dispDriverMessageText.good_text'
                    )
                  "
                  :title="$t('ConsoleOrderMessage.copyToTextField')"
                >
                  {{ $t("ConsoleOrderMessage.dispDriverMessageText.good") }}
                </v-chip>
                <v-chip
                  dark
                  label
                  small
                  class="px-1 mr-1"
                  :color="messageTypeColor['DISP_DRIVER']"
                  @click="
                    dispDriverMessageField = $t(
                      'ConsoleOrderMessage.dispDriverMessageText.thankyou_text'
                    )
                  "
                  :title="$t('ConsoleOrderMessage.copyToTextField')"
                >
                  {{ $t("ConsoleOrderMessage.dispDriverMessageText.thankyou") }}
                </v-chip>
                <v-chip
                  dark
                  label
                  small
                  class="px-1 mr-1"
                  :color="messageTypeColor['DISP_DRIVER']"
                  @click="
                    dispDriverMessageField = $t(
                      'ConsoleOrderMessage.dispDriverMessageText.calling_text'
                    )
                  "
                  :title="$t('ConsoleOrderMessage.copyToTextField')"
                >
                  {{ $t("ConsoleOrderMessage.dispDriverMessageText.calling") }}
                </v-chip>
                <v-chip
                  dark
                  label
                  small
                  class="px-1 mr-1"
                  :color="messageTypeColor['DISP_DRIVER']"
                  @click="
                    dispDriverMessageField = $t(
                      'ConsoleOrderMessage.dispDriverMessageText.going_text'
                    )
                  "
                  :title="$t('ConsoleOrderMessage.copyToTextField')"
                >
                  {{ $t("ConsoleOrderMessage.dispDriverMessageText.going") }}
                </v-chip>
                <v-chip
                  dark
                  label
                  small
                  class="px-1 mr-1"
                  :color="messageTypeColor['DISP_DRIVER']"
                  @click="
                    dispDriverMessageField = $t(
                      'ConsoleOrderMessage.dispDriverMessageText.whereareyou_text'
                    )
                  "
                  :title="$t('ConsoleOrderMessage.copyToTextField')"
                >
                  {{
                    $t("ConsoleOrderMessage.dispDriverMessageText.whereareyou")
                  }}
                </v-chip>
                <v-chip
                  dark
                  label
                  small
                  class="px-1 mr-1"
                  :color="messageTypeColor['DISP_DRIVER']"
                  @click="
                    dispDriverMessageField = $t(
                      'ConsoleOrderMessage.dispDriverMessageText.cancelled_text'
                    )
                  "
                  :title="$t('ConsoleOrderMessage.copyToTextField')"
                >
                  {{
                    $t("ConsoleOrderMessage.dispDriverMessageText.cancelled")
                  }}
                </v-chip>
                <v-chip
                  dark
                  label
                  small
                  class="px-1 mr-1"
                  :color="messageTypeColor['DISP_DRIVER']"
                  @click="
                    dispDriverMessageField = $t(
                      'ConsoleOrderMessage.dispDriverMessageText.1min_text'
                    )
                  "
                  :title="$t('ConsoleOrderMessage.copyToTextField')"
                >
                  {{ $t("ConsoleOrderMessage.dispDriverMessageText.1min") }}
                </v-chip>
                <v-chip
                  dark
                  label
                  small
                  class="px-1 mr-1"
                  :color="messageTypeColor['DISP_DRIVER']"
                  @click="
                    dispDriverMessageField = $t(
                      'ConsoleOrderMessage.dispDriverMessageText.2min_text'
                    )
                  "
                  :title="$t('ConsoleOrderMessage.copyToTextField')"
                >
                  {{ $t("ConsoleOrderMessage.dispDriverMessageText.2min") }}
                </v-chip>
                <v-chip
                  dark
                  label
                  small
                  class="px-1 mr-1"
                  :color="messageTypeColor['DISP_DRIVER']"
                  @click="
                    dispDriverMessageField = $t(
                      'ConsoleOrderMessage.dispDriverMessageText.5min_text'
                    )
                  "
                  :title="$t('ConsoleOrderMessage.copyToTextField')"
                >
                  {{ $t("ConsoleOrderMessage.dispDriverMessageText.5min") }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="8" class="pt-0 pb-4">
                <!--Message to Driver -->
                <v-text-field
                  filled
                  :label="$t('ConsoleOrderMessage.dispDriverMessageField')"
                  :value="dispDriverMessageField"
                  @change="(v) => (dispDriverMessageField = v)"
                  dense
                  clearable
                  required
                  hide-details
                  single-line
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  maxlength="140"
                  @keydown.enter="sendDispDriverMessage()"
                ></v-text-field>
                <!-- append-icon="mdi-send-outline"
                  @click:append="sendDispDriverMessage()" -->
              </v-col>
              <v-col cols="12" md="4" class="pt-0 pb-4">
                <v-btn color="info" medium @click="sendDispDriverMessage()">
                  <v-icon dark>mdi-send-outline</v-icon>
                </v-btn>
                <v-btn
                  color="info"
                  medium
                  class="ml-1"
                  :title="$t('ConsoleOrderMessage.btnWorkShift')"
                  @click="showEditDriverWorkShiftDialog()"
                  ><v-icon dark>mdi-briefcase-clock-outline</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-tab-item>

      <v-tab-item value="tab-dispatching">
        <div
          id="tabDispatching"
          class="overflow-y-auto"
          style="height: 400px; max-width: 600px"
          ref="tabDispatching"
        >
          <v-list dense subheader>
            <template v-for="(item, index) in dispatchingMessages">
              <v-list-item :key="item.id" dense class="pa-0 ma-0">
                <v-list-item-avatar>
                  <v-icon :color="messageTypeColor[item.type]" dark>
                    {{
                      item.fromType == "DISPATCHER"
                        ? "mdi-face-man-outline"
                        : item.fromType == "DRIVER"
                        ? "mdi-account-tie-outline"
                        : item.fromType == "CLIENT"
                        ? "mdi-human-greeting-variant"
                        : item.fromType == "SYSTEM"
                        ? "mdi-robot-outline"
                        : "mdi-alien-outline"
                    }}</v-icon
                  >
                </v-list-item-avatar>

                <v-list-item-content>
                  <!--new Date(new Date(item.createdAt) -new Date().getTimezoneOffset() * 60000).toLocaleString('sk-SK') +-->
                  <v-list-item-title>{{
                    moment(String(item.createdAt)).format(
                      "DD.MM.YYYY HH:mm:ss"
                    ) +
                    " | " +
                    (item.fromDispatcherId && item.fromType == "DISPATCHER"
                      ? item.fromDispatcherFirstName +
                        " " +
                        item.fromDispatcherLastName
                      : item.fromDriverId && item.fromType == "DRIVER"
                      ? item.fromDriverFirstName + " " + item.fromDriverLastName
                      : item.fromClientId && item.fromType == "CLIENT"
                      ? item.fromClientNickname
                      : "") +
                    (item.toOrderId ? " | " + item.toOrderId.substr(0, 8) : "")
                  }}</v-list-item-title>

                  <v-list-item-subtitle class="text-wrap">
                    <!--{{ new Date() }}-->{{
                      item.message
                    }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="index < allMessages.length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list>
        </div>
        <v-form ref="formDispatchingMessage" onSubmit="return false;">
          <v-row no-gutters>
            <v-col cols="12">
              <v-chip
                dark
                label
                small
                class="px-1 mr-1"
                :color="messageTypeColor['DISPATCHING']"
                @click="
                  dispatchingMessageField = $t(
                    'ConsoleOrderMessage.dispDriverMessageText.ok_text'
                  )
                "
                :title="$t('ConsoleOrderMessage.copyToTextField')"
              >
                {{ $t("ConsoleOrderMessage.dispDriverMessageText.ok") }}
              </v-chip>
              <v-chip
                dark
                label
                small
                class="px-1 mr-1"
                :color="messageTypeColor['DISPATCHING']"
                @click="
                  dispatchingMessageField = $t(
                    'ConsoleOrderMessage.dispDriverMessageText.good_text'
                  )
                "
                :title="$t('ConsoleOrderMessage.copyToTextField')"
              >
                {{ $t("ConsoleOrderMessage.dispDriverMessageText.good") }}
              </v-chip>
              <v-chip
                dark
                label
                small
                class="px-1 mr-1"
                :color="messageTypeColor['DISPATCHING']"
                @click="
                  dispatchingMessageField = $t(
                    'ConsoleOrderMessage.dispDriverMessageText.thankyou_text'
                  )
                "
                :title="$t('ConsoleOrderMessage.copyToTextField')"
              >
                {{ $t("ConsoleOrderMessage.dispDriverMessageText.thankyou") }}
              </v-chip>
            </v-col>
          </v-row>
          <v-row align="center" dense>
            <v-col cols="12" md="9" class="pt-0 pb-4">
              <!--Message to Dispatchers -->
              <v-text-field
                filled
                :label="$t('ConsoleOrderMessage.dispatchingMessageField')"
                :value="dispatchingMessageField"
                @change="(v) => (dispatchingMessageField = v)"
                dense
                clearable
                required
                hide-details
                single-line
                :rules="[(v) => !!v || $t('msgRequiredField')]"
                maxlength="140"
                @keydown.enter="sendDispatchingMessage()"
              ></v-text-field>
              <!--append-icon="mdi-send-outline"
                @click:append="sendDispatchingMessage()"-->
            </v-col>
            <v-col cols="12" md="3" class="pt-0 pb-4">
              <v-btn
                color="info"
                medium
                @click="sendDispatchingMessage()"
                :disabled="!module.dispatchingChat"
              >
                <v-icon dark> mdi-send-outline </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>

      <v-tab-item value="tab-public">
        <div
          id="tabPublic"
          class="overflow-y-auto"
          style="height: 400px; max-width: 600px"
          ref="tabPublic"
        >
          <v-list dense subheader>
            <template v-for="(item, index) in publicMessages">
              <v-list-item :key="item.id" dense class="pa-0 ma-0">
                <v-list-item-avatar>
                  <v-icon :color="messageTypeColor[item.type]" dark>
                    {{
                      item.fromType == "DISPATCHER"
                        ? "mdi-face-man-outline"
                        : item.fromType == "DRIVER"
                        ? "mdi-account-tie-outline"
                        : item.fromType == "CLIENT"
                        ? "mdi-human-greeting-variant"
                        : item.fromType == "SYSTEM"
                        ? "mdi-robot-outline"
                        : "mdi-alien-outline"
                    }}</v-icon
                  >
                </v-list-item-avatar>

                <v-list-item-content>
                  <!--new Date(new Date(item.createdAt) -new Date().getTimezoneOffset() * 60000).toLocaleString('sk-SK') +-->
                  <v-list-item-title>{{
                    moment(String(item.createdAt)).format(
                      "DD.MM.YYYY HH:mm:ss"
                    ) +
                    " | " +
                    (item.fromDispatcherId && item.fromType == "DISPATCHER"
                      ? item.fromDispatcherFirstName +
                        " " +
                        item.fromDispatcherLastName
                      : item.fromDriverId && item.fromType == "DRIVER"
                      ? item.fromDriverFirstName + " " + item.fromDriverLastName
                      : item.fromClientId && item.fromType == "CLIENT"
                      ? item.fromClientNickname
                      : "") +
                    (item.toOrderId ? " | " + item.toOrderId.substr(0, 8) : "")
                  }}</v-list-item-title>

                  <v-list-item-subtitle class="text-wrap">
                    <!--{{ new Date() }}-->{{
                      item.message
                    }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="index < allMessages.length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list>
        </div>
        <v-form ref="formPublicMessage" onSubmit="return false;">
          <v-row align="center" dense>
            <v-col cols="12" md="9" class="pt-0 pb-4">
              <!--Public Message -->
              <v-text-field
                filled
                :label="$t('ConsoleOrderMessage.publicMessageField')"
                :value="publicMessageField"
                @change="(v) => (publicMessageField = v)"
                dense
                clearable
                required
                hide-details
                single-line
                :rules="[(v) => !!v || $t('msgRequiredField')]"
                maxlength="140"
                @keydown.enter="sendPublicMessage()"
              ></v-text-field>
              <!-- append-icon="mdi-send-outline"
                @click:append="sendPublicMessage()"-->
            </v-col>
            <v-col cols="12" md="3" class="pt-0 pb-4">
              <v-btn color="info" medium @click="sendPublicMessage()">
                <v-icon dark> mdi-send-outline </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
    </v-tabs-items>
    <!-- Dialog na schvaľovanie stavov pracovnej zmeny-->
    <v-dialog
      v-model="editDriverWorkShiftDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark dense flat>
        <v-toolbar-title>{{
          $t("ConsoleOrderMessage.editDriverWorkShiftDialog_title")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="editDriverWorkShiftForm">
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  <v-list dense>
                    <v-subheader v-show="!disableDriverWorkShiftBreak">{{
                      $t("ConsoleOrderMessage.workBreak")
                    }}</v-subheader>
                    <v-list-item
                      :disabled="disableDriverWorkShiftBreak"
                      v-show="!disableDriverWorkShiftBreak"
                      @click="
                        (editDriverWorkShiftDialog = false),
                          updateDriverWorkShift('breakShiftApprove')
                      "
                    >
                      <v-list-item-icon>
                        <v-icon color="success">mdi-coffee-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("ConsoleOrderMessage.workBreakApprove")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      :disabled="disableDriverWorkShiftBreak"
                      v-show="!disableDriverWorkShiftBreak"
                      @click="
                        (editDriverWorkShiftDialog = false),
                          updateDriverWorkShift('breakShiftReject')
                      "
                    >
                      <v-list-item-icon>
                        <v-icon color="error">mdi-coffee-off-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("ConsoleOrderMessage.workBreakReject")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-subheader v-show="!disableDriverWorkShiftEnd">{{
                      $t("ConsoleOrderMessage.workEndShift")
                    }}</v-subheader>
                    <v-list-item
                      :disabled="disableDriverWorkShiftEnd"
                      v-show="!disableDriverWorkShiftEnd"
                      @click="
                        (editDriverWorkShiftDialog = false),
                          updateDriverWorkShift('endShiftApprove')
                      "
                    >
                      <v-list-item-icon>
                        <v-icon color="success">mdi-home-import-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("ConsoleOrderMessage.workEndShiftApprove")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      :disabled="disableDriverWorkShiftEnd"
                      v-show="!disableDriverWorkShiftEnd"
                      @click="
                        (editDriverWorkShiftDialog = false),
                          updateDriverWorkShift('endShiftReject')
                      "
                    >
                      <v-list-item-icon>
                        <v-icon color="error">mdi-home-remove-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("ConsoleOrderMessage.workEndShiftReject")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              text
              @click.stop="editDriverWorkShiftDialog = false"
              >{{ $t("btnClose") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!--Snackbar PRAVY o supesnom odoslani spravy-->
    <v-snackbar
      v-model="snackbarMessage"
      :timeout="snackbarMessageObject.timeout"
      right
      dark
      multi-line
      width="600"
      :color="snackbarMessageObject.color"
    >
      {{ snackbarMessageObject.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue-grey lighten-4"
          text
          v-bind="attrs"
          @click="snackbarMessage = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- Standardny snackbar -->
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
    <!-- Snackbar v strede obrazovky, napr. o vela ignorovani objednavky-->
    <v-snackbar
      v-model="snackbarCentered.show"
      :timeout="snackbarCentered.timeout"
      centered
      value="true"
      :color="snackbarCentered.color"
      class="pb-10 mb-10"
    >
      {{ snackbarCentered.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="showOrderDetailByIdFromMessage(snackbarCentered.orderId)"
        >
          <v-icon>mdi-information</v-icon>
        </v-btn>
        <v-btn
          color="blue-grey lighten-4"
          text
          v-bind="attrs"
          @click="snackbarCentered.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import { messageTypeColor, messageFromToTypeColor } from "../enums/ConsoleEnum";
import { moduleDefaultSettings } from "../enums/ModuleEnum";
import AutocompleteDriverRemote from "../components/AutocompleteDriverRemote.vue";
import * as moment from "moment/moment";

export default {
  name: "OrderMessagesPanel",
  components: { AutocompleteDriverRemote },
  props: {
    selectedOrderIdMessage: {
      type: String,
      default: "",
    },
    passOperationalDataMessagesToMessagesList: {
      type: Array,
      default: [],
    },
    passMessageFromNotification: {
      type: Object,
      default() {
        return {
          messageType: "SYSTEM",
          fromDriverId: "",
          toDriverId: "",
          orderId: "",
          messageText: "",
        };
      },
    },
  },
  data() {
    return {
      moment: moment,
      messageTypeColor,
      messageFromToTypeColor,
      tab: null,
      showPanel: true,
      module: moduleDefaultSettings,
      refreshTimer: null,
      //couter pre snackbar ak je vela system sprav
      orderMessageCount: [],

      //Message snackbar
      snackbarMessage: false,
      snackbarMessageObject: {
        text: "",
        timeout: 3000,
        color: "orange darken-3",
      },

      lastSnackbarNotification: null,

      //Standardny snackbar
      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 3000,
      },

      //snackbar pre upozornenia ktore musi zavriet pouzivatel
      snackbarCentered: {
        show: false,
        message: "OK",
        color: "warning",
        timeout: 9000,
        orderId: null,
      },

      //premenne pre ALL messages
      allMessages: [],
      getAllMessagesData: {
        limit: 50,
      },
      //premenne pre PUBLIC messages
      publicMessageField: null,
      publicMessages: null,
      getPublicMessagesData: {
        limit: 10,
      },
      sendPublicData: {
        message: null,
      },
      //premenne pre DISPATCHING vs DRIVER messages
      //Zoznam vodicov
      dispDriverField: null,
      dispDriverMessageField: null,
      dispDriverMessages: null,
      getDispDriverMessagesData: {
        limit: 10,
      },
      driverSearchData: {
        query: null,
        type: null,
      },
      sendDispDriverData: {
        driverId: null,
        message: null,
      },
      //Schvalovanie prestavok a ukoncenia pracovnej zmeny
      editDriverWorkShiftDialog: false,
      disableDriverWorkShiftBreak: false,
      disableDriverWorkShiftEnd: false,
      //request data pre zobrazenie schvalovanych pracovnych zmien
      getWorklogRequestItemsData: {
        onlyActive: true,
      },

      //premenne pre DISPATCHING messages
      dispatchingMessageField: null,
      dispatchingMessages: null,
      getDispatchingMessagesData: {
        limit: 10,
      },
      sendDispatchingData: {
        message: null,
      },
      //premenne pre ORDER messages
      orderIdField: "",
      orderMessageField: null,
      orderMessages: null,
      getOrderMessagesData: {
        limit: 50,
      },
      sendOrderData: {
        orderId: null,
        message: null,
      },
    };
  },

  created() {
    // this.selectedOrderIdMessage = "";
    //Nastavenie modulov
    var modules = this.$store.state.module;
    if (modules !== null && modules !== undefined) {
      this.module = modules;
    }
  },

  mounted() {
    //pouzit aktualny datetime ako uvodny default cas pre notifikacie alebo rozdielovu aktualizaciu zoznamu sprav
    //aby nevyrollovalo notifikacie pre vsetky spravy alebo neduplikovalo zoznam vsetkych sprav
    this.lastSnackbarNotification = new Date();
    // spravy medzi dispecermi je mozne nacitat uz na zaciatku, nie su parametrizovane, preto chodi stale ten isty zoznam
    this.getDispatchingMessages();
    // public spravy je mozne nacitat uz na zaciatku, nie su parametrizovane, preto chodi stale ten isty zoznam
    this.getPublicMessages();
  },

  computed: {},

  watch: {
    dispDriverField: function (newValue, oldValue) {
      //console.log("dispDriverField", this.dispDriverField);
      this.getDispDriverMessages();
    },
    selectedOrderIdMessage: function (newValue, oldValue) {
      //console.log("OrderMessagesPanel.vue - Message newValue ", newValue);
      this.orderIdField = newValue;
      this.getOrderMessages();
      this.tab = "tab-order";
    },
    passOperationalDataMessagesToMessagesList: function () {
      this.getAllMessages();
    },
    passMessageFromNotification: function (newValue, oldValue) {
      //console.log(newValue);
      this.changeMessageTabAndSendMessage(newValue);
    },
  },

  methods: {
    getAllMessages() {
      //this.animateProgressBar("start"),
      //this.animateProgressBar("ok");
      //var checkSystemMessagesCounter = true;
      //Pri spusteni ked su prazdne spravy, nacitat predvoleny pocet.
      if (this.allMessages.length === 0) {
        //checkSystemMessagesCounter = false;
        //console.log("this.allMessages.length", this.allMessages.length);
        this.allMessages = this.passOperationalDataMessagesToMessagesList;

        //PRVY KRAT, NADEFINOVANIE NAJNOVSIEHO DATUMU voci ktoremu sa bude porovnavat a pridavat nove spravy do zoznamu, na zlepsenie performance
        var allMessagesTemp = this.passOperationalDataMessagesToMessagesList;
        //console.log("OrderMessagesPanel.vue - allMessagesTemp.length",allMessagesTemp.length);
        if (allMessagesTemp.length > 0) {
          //console.log("OrderMessagesPanel.vue - this.allMessages.length",this.allMessages.length);
          //Zisit cas najnovsej notifikacie u sprav, ktore sa prvy krat nacitaju
          //1. krat, ked este nemame historicke udaje, tak skontrolovat vsetky spravy a nastavit datumu poslednej notifikacie
          var maxNotificationDatetime = new Date("1999-01-01T01:01:01.49561Z");
          var messageDate;
          allMessagesTemp.forEach(function (item, index) {
            //console.log("createdAt: ", item.createdAt, "vs. notificationDatetime: ", notificationDatetime);
            //Vsetky novsie od poslednej notifikacie zobrazit
            messageDate = new Date(item.createdAt);
            //console.log("messageDate: ", messageDate);
            //zistit najnovsi datum spravy, ten bude referencny pri dalsom requeste. Podla toho sa zobrazuju snackbary a aktualizuju zoznamy.
            if (messageDate > maxNotificationDatetime) {
              maxNotificationDatetime = messageDate;
              //console.log( "OrderMessagesPanel.vue - maxNotificationDatetime: ", maxNotificationDatetime );
            }
          });
          //Nastavit novy cas poslednej notifikacie
          this.lastSnackbarNotification = maxNotificationDatetime;
        }
      }
      //var allMessagesTemp = this.allMessages;
      var allMessagesTemp = this.passOperationalDataMessagesToMessagesList;
      var newMessages = [];
      var messageDate = null;
      var notificationDatetime = this.lastSnackbarNotification;
      var maxNotificationDatetime = this.lastSnackbarNotification;
      var executeGetOrderMessages = false;
      var executeGetDispDriverMessages = false;
      var executeGetDispatchingMessages = false;
      var executeGetPublicMessages = false;
      //skotrolovat vsetky spravy a ak sa medzi nimi nachadza nejaka nova podla datumu poslednej notifikacie, tak zobrazit sankbar
      allMessagesTemp.forEach(function (item, index) {
        //console.log("createdAt: ", item.createdAt, "vs. notificationDatetime: ", notificationDatetime);
        //Vsetky novsie od poslednej notifikacie zobrazit
        messageDate = new Date(item.createdAt);
        //console.log("messageDate: ", messageDate);
        if (messageDate > notificationDatetime) {
          //console.log("mark 0");
          //odlozit si iba nove spravy, neskor ich pridame naraz do celkoveho zoznamu na vyrenderovanie
          newMessages.push(item);
          //console.log("Toast snackbar: ");
          //Zistit, aky typ spravy dosiel, a podla toho a neskor aktualizuju jednotlive zalozky
          if (item.type == "DISP_DRIVER") {
            executeGetDispDriverMessages = true;
          } else if (item.type == "ORDER") {
            executeGetOrderMessages = true;
          } else if (item.type == "DISPATCHING") {
            executeGetDispatchingMessages = true;
          } else if (item.type == "PUBLIC") {
            executeGetPublicMessages = true;
          }
          //zistit najnovsi datum spravy, ten bude referencny pri dalsom requeste. Podla toho sa zobrazuju snackbary a aktualizuju zoznamy.
          if (messageDate > maxNotificationDatetime) {
            maxNotificationDatetime = messageDate;
          }
        }
      });
      //Doplnenie sprav do celkoveho zoznamu, aby sa vyrenderovali.
      var i = 0;
      while (i < Object.keys(newMessages).length) {
        this.allMessages.unshift(newMessages[i]);
        i++;
      }
      //Priezbezne vymazavat spravy ak je ich viac ako 200, aby ostalo iba 150 sprav
      if (this.allMessages.length > 200) {
        this.allMessages.splice(150, this.allMessages.length - 150);
      }

      //Nastavit novy cas poslednej notifikacie
      this.lastSnackbarNotification = maxNotificationDatetime;
      //Ak prisla nova objednavka niektoreho typu, pre dany ty refreshnut data
      if (executeGetDispDriverMessages == true) {
        this.getDispDriverMessages();
      } else if (executeGetOrderMessages == true) {
        this.getOrderMessages();
      } else if (executeGetDispatchingMessages == true) {
        //console.log("mark 3");
        this.getDispatchingMessages();
      } else if (executeGetPublicMessages == true) {
        this.getPublicMessages();
      }

      //Count how many messages in this.allMessages with type=SYSTEM and fromType=SYSTEM and toOrderId!=null are per each order (toOrderId)
      var orderMessageCount = [];
      var orderMessageCountFound = false;
      var showSnackbarCenteredOrderId = null;
      var allMessagesTemp2 = this.allMessages;
      if (allMessagesTemp2) {
        //&& checkSystemMessagesCounter == true
        if (allMessagesTemp2.length > 0) {
          for (var i = 0; i < allMessagesTemp2.length; i++) {
            if (
              allMessagesTemp2[i].type == "SYSTEM" &&
              allMessagesTemp2[i].fromType == "SYSTEM" &&
              allMessagesTemp2[i].toOrderId != null
            ) {
              orderMessageCountFound = false;
              for (var j = 0; j < orderMessageCount.length; j++) {
                if (
                  orderMessageCount[j].toOrderId ==
                  allMessagesTemp2[i].toOrderId
                ) {
                  orderMessageCount[j].count++;
                  orderMessageCountFound = true;
                }
              }
              if (orderMessageCountFound == false) {
                orderMessageCount.push({
                  toOrderId: allMessagesTemp2[i].toOrderId,
                  count: 1,
                  notified: false,
                });
              }
            }
          }
          //compare orderMessageCount and this.orderMessageCount, if there is more count, then go through orderMessageCount, and if there is more count=4 and notified=false, then show snackabar with message "Order ID xxxxxx has more than 3 system messages"
          for (var i = 0; i < orderMessageCount.length; i++) {
            if (this.orderMessageCount.length > 0) {
              for (var j = 0; j < this.orderMessageCount.length; j++) {
                if (
                  orderMessageCount[i].toOrderId ==
                  this.orderMessageCount[j].toOrderId
                ) {
                  if (
                    orderMessageCount[i].count >
                      this.orderMessageCount[j].count &&
                    orderMessageCount[i].count > 3 &&
                    orderMessageCount[i].notified == false
                  ) {
                    //show snackbar with order ID
                    showSnackbarCenteredOrderId =
                      orderMessageCount[i].toOrderId;
                    //set notified=true
                    orderMessageCount[i].notified = true;
                    //console.log("show snackbar orderMessageCount");
                    //console.log("orderMessageCount[i].notified: ",orderMessageCount[i].toOrderId);
                    //console.log("orderMessageCount[i].count: ",orderMessageCount[i].count);
                  }
                }
              }
            }
          }
          this.orderMessageCount = orderMessageCount;
          if (showSnackbarCenteredOrderId) {
            //console.log("showSnackbarCenteredOrderId: ",showSnackbarCenteredOrderId);
            //show snackbar
            this.snackbarCentered.message =
              this.$t("ConsoleOrderMessage.msgOrderMessageCountSystem") +
              " ID: " +
              showSnackbarCenteredOrderId.substring(0, 8);
            this.snackbarCentered.color = "warning";
            this.snackbarCentered.timeout = 9000;
            this.snackbarCentered.orderId = showSnackbarCenteredOrderId;
            this.snackbarCentered.show = "true";
          }
        }
      }
    },
    getOrderMessages() {
      //this.animateProgressBar("start"),
      //Ak nie je ziadna hodnota ID objednavky, nevolat API
      if (!this.orderIdField) {
        return;
      }
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/message/order/` +
            this.orderIdField,
          {
            params: this.getOrderMessagesData,
          }
        )
        .then((response) => {
          //  this.animateProgressBar("ok");
          this.orderMessages = response.data;
          //console.log("getOrderMessages");
          this.scrollToLastMessage("ORDER");
        })
        .catch((e) => {
          // this.animateProgressBar("error"), this.errors.push(e);
        });
    },
    getDispDriverMessages() {
      //this.animateProgressBar("start"),
      if (this.dispDriverField > 0) {
        axios
          .get(
            process.env.VUE_APP_API_BASE_URL +
              `/dispatching/message/dispdriver/` +
              this.dispDriverField,
            {
              params: this.getDispDriverMessagesData,
            }
          )
          .then((response) => {
            //this.animateProgressBar("ok");
            this.dispDriverMessages = response.data;
            this.scrollToLastMessage("DISP_DRIVER");
          })
          .catch((e) => {
            // this.animateProgressBar("error"), this.errors.push(e);
          });
      }
    },

    getDispatchingMessages() {
      //this.animateProgressBar("start"),
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/message/dispatching`,
          {
            params: this.getDispatchingMessagesData,
          }
        )
        .then((response) => {
          //  this.animateProgressBar("ok");
          this.dispatchingMessages = response.data;
          this.scrollToLastMessage("DISPATCHING");
        })
        .catch((e) => {
          // this.animateProgressBar("error"), this.errors.push(e);
        });
    },

    getPublicMessages() {
      //this.animateProgressBar("start"),
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/message/public`, {
          params: this.getPublicMessagesData,
        })
        .then((response) => {
          //this.animateProgressBar("ok");
          this.publicMessages = response.data;
          this.scrollToLastMessage("PUBLIC");
        })
        .catch((e) => {
          //this.animateProgressBar("error"), this.errors.push(e);
          //show snackbar with error
          this.snackbar.show = "true";
          this.snackbar.message = this.$t(
            "ConsoleOrderMessage.getPublicMessagesError"
          ); //"Problém s načítaním public zpráv."
          this.snackbar.color = "warning";
        });
    },
    prepareOrderMessage() {
      this.sendOrderData.message = this.orderMessageField;
      this.sendOrderData.orderId = this.orderIdField;
    },

    showOrderDetailByIdFromMessage(orderId) {
      this.$emit("orderIdFromMessage", orderId);
      this.$emit("changeTabToDetail", true);
    },

    sendOrderMessage() {
      //this.animateProgressBar("start"),
      //console.log("this.orderIdField", this.orderIdField);
      //pri stlaceni Append Icon niekedy nerozozna, ze je text v poli, ako keby sa neaktualizovala premenna hned, ale treba ist do ineho pola. Pri Enter to funguje dobre
      //console.log("this.orderMessageField", this.orderMessageField);
      if (
        this.orderIdField &&
        this.orderIdField != null &&
        this.orderMessageField &&
        this.orderMessageField != null
      ) {
        this.sendOrderData.message = this.orderMessageField;
        this.sendOrderData.orderId = this.orderIdField;
        axios
          .post(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/message/order`,
            this.sendOrderData
          )
          .then((response) => {
            //this.animateProgressBar("ok"), (this.items = response.data);
            this.getOrderMessages();
            // Snackbar toast
            this.snackbarMessageObject.text =
              this.$t("ConsoleOrderMessage.sendMessageSuccess") +
              ": " +
              this.orderMessageField;
            this.snackbarMessage = true;
            //Vycistenie pola s textom
            this.orderMessageField = null;
          })
          .catch((e) => {
            //this.animateProgressBar("error"), this.errors.push(e);
            //  this.createClientDialog = true;
          });
      } else {
        this.snackbarMessageObject.text = this.$t(
          "ConsoleOrderMessage.sendOrderMessageError"
        );
        this.snackbarMessage = true;
      }
    },
    sendDispDriverMessage() {
      //this.animateProgressBar("start"),
      if (
        this.dispDriverField > 0 &&
        this.dispDriverMessageField &&
        this.dispDriverMessageField != null &&
        this.dispDriverMessageField != ""
      ) {
        this.sendDispDriverData.message = this.dispDriverMessageField;
        this.sendDispDriverData.driverId = this.dispDriverField;
        axios
          .post(
            process.env.VUE_APP_API_BASE_URL +
              `/dispatching/message/dispdriver`,
            this.sendDispDriverData
          )
          .then((response) => {
            //this.animateProgressBar("ok"), (this.items = response.data);
            this.getDispDriverMessages();
            // snackbar toast
            this.snackbarMessageObject.text =
              this.$t("ConsoleOrderMessage.sendMessageSuccess") +
              ": " +
              this.dispDriverMessageField;
            this.snackbarMessage = true;
            //Vycistenie pola s textom
            this.dispDriverMessageField = null;
          })
          .catch((e) => {
            //this.animateProgressBar("error"), this.errors.push(e);
            //  this.createClientDialog = true;
          });
      } else {
        this.snackbarMessageObject.text = this.$t(
          "ConsoleOrderMessage.sendDispDriverMessageError"
        );
        this.snackbarMessage = true;
      }
    },
    sendDispatchingMessage() {
      //this.animateProgressBar("start"),
      if (
        this.dispatchingMessageField &&
        this.dispatchingMessageField != null &&
        this.dispatchingMessageField != ""
      ) {
        this.sendDispatchingData.message = this.dispatchingMessageField;
        axios
          .post(
            process.env.VUE_APP_API_BASE_URL +
              `/dispatching/message/dispatching`,
            this.sendDispatchingData
          )
          .then((response) => {
            //this.animateProgressBar("ok"), (this.items = response.data);
            this.getDispatchingMessages();
            // Snackbar toast
            this.snackbarMessageObject.text =
              this.$t("ConsoleOrderMessage.sendMessageSuccess") +
              ": " +
              this.dispatchingMessageField;
            this.snackbarMessage = true;
            //Vycistenie pola s textom
            this.dispatchingMessageField = null;
          })
          .catch((e) => {
            //this.animateProgressBar("error"), this.errors.push(e);
            //  this.createClientDialog = true;
          });
      } else {
        this.snackbarMessageObject.text = this.$t(
          "ConsoleOrderMessage.sendDispatchingMessageError"
        );
        this.snackbarMessage = true;
      }
    },

    sendPublicMessage() {
      //this.animateProgressBar("start"),
      //show window, if is user sure to send public message. If yes, then send message, else "return"
      if (
        !confirm("Ste si istý, že chcete poslať správu na všetkých vodičov?")
      ) {
        return; // Ak používateľ klikne na tlačidlo "Zrušiť", vráti sa z funkcie
      }
      if (
        this.publicMessageField &&
        this.publicMessageField != null &&
        this.publicMessageField != ""
      ) {
        this.sendPublicData.message = this.publicMessageField;
        axios
          .post(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/message/public`,
            this.sendPublicData
          )
          .then((response) => {
            //this.animateProgressBar("ok"), (this.items = response.data);

            this.getPublicMessages();
            // Snackbar toast
            this.snackbarMessageObject.text =
              this.$t("ConsoleOrderMessage.sendMessageSuccess") +
              ": " +
              this.publicMessageField;
            this.snackbarMessage = true;
            //Vycistenie pola s textom
            this.publicMessageField = null;
          })
          .catch((e) => {
            //this.animateProgressBar("error"), this.errors.push(e);
            //  this.createClientDialog = true;
          });
      } else {
        this.snackbarMessageObject.text = this.$t(
          "ConsoleOrderMessage.sendPublicMessageError"
        );
        this.snackbarMessage = true;
      }
    },
    changeMessageTabAndSendMessage(messageData) {
      //console.log(messageData);
      var messageType = messageData.messageType;
      var fromDriverId = messageData.fromDriverId;
      var toDriverId = messageData.toDriverId;
      var orderId = messageData.orderId;
      var messageText = messageData.messageText;
      //console.log(messageData);
      //console.log(messageData.messageType);
      this.changeMessageTab(
        messageType,
        fromDriverId,
        toDriverId,
        orderId,
        messageText
      );
    },
    changeMessageTab(
      messageType,
      fromDriverId,
      toDriverId,
      orderId,
      messageText
    ) {
      var driverId;
      if (fromDriverId) {
        driverId = fromDriverId;
      } else if (toDriverId) {
        driverId = toDriverId;
      } else {
        driverId = "";
      }
      //Ak je zobrazenie spravy z notifikacie v tomto komponente.
      //Stratilo to vyznam po premiestneni notifikacii do "console.log"
      //this.$emit("changeTabToMessage", messageType);
      switch (messageType) {
        case "SYSTEM":
          this.tab = "tab-all";
          break;
        case "PUBLIC":
          this.tab = "tab-public";
          this.getPublicMessages();
          break;
        case "DISP_DRIVER":
          this.dispDriverField = driverId;
          this.dispDriverMessageField = messageText;
          this.tab = "tab-dispdriver";
          this.getDispDriverMessages();
          break;
        case "ORDER":
          this.orderIdField = orderId;
          this.orderMessageField = messageText;
          this.tab = "tab-order";
          this.getOrderMessages();
          break;
        case "DISPATCHING":
          this.tab = "tab-dispatching";
          this.getDispatchingMessages();
          break;
        case "DIRECT":
          this.tab = "tab-direct";
          break;
        default:
          this.tab = "tab-all";
      }
      //this.tab = tabName;
    },
    scrollToLastMessage(tabName) {
      ////console.log("x", this.tab);
      switch (tabName) {
        case "ALL":
          var element = this.$refs["tabAll"];
          if (element) {
            if (element.scrollHeight > 0) {
              element.scrollTop = 0;
            }
          }
          break;
        case "SYSTEM":
          var element = this.$refs["tabAll"];
          if (element) {
            if (element.scrollHeight > 0) {
              element.scrollTop = 0;
            }
          }
          break;
        case "PUBLIC":
          var element = this.$refs["tabPublic"];
          if (element) {
            if (element.scrollHeight > 0) {
              element.scrollTop = element.scrollHeight;
            }
          }
          break;
        case "DISP_DRIVER":
          var element = this.$refs["tabDispDriver"];
          if (element) {
            if (element.scrollHeight > 0) {
              element.scrollTop = element.scrollHeight;
            }
          }
          break;
        case "ORDER":
          var element = this.$refs["tabOrder"];
          if (element) {
            if (element.scrollHeight > 0) {
              element.scrollTop = element.scrollHeight;
            }
          }
          break;
        case "DISPATCHING":
          var element = this.$refs["tabDispatching"];
          if (element) {
            if (element.scrollHeight > 0) {
              element.scrollTop = element.scrollHeight;
            }
          }
          break;
        case "DIRECT":
          var element = this.$refs["tabDirect"];
          if (element) {
            if (element.scrollHeight > 0) {
              element.scrollTop = element.scrollHeight;
            }
          }
          break;
      }
    },

    showEditDriverWorkShiftDialog() {
      if (!this.module.worklog) {
        alert(this.$t("msgModuleWorklogNotEnabled"));
        return;
      }
      if (this.dispDriverField) {
        var worklogRequestList;
        axios
          .get(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/driver/worklog`,
            {
              params: this.getWorklogRequestItemsData,
            }
          )
          .then((response) => {
            worklogRequestList = response.data;
            //console.log("showEditDriverWorkShiftDialog - worklogRequestList: ",worklogRequestList);
            //check if this.dispDriverField is in worklogRequestList as driverId parameter
            var driverWorklogRequest = worklogRequestList.find(
              (item) =>
                item.driverId == this.dispDriverField && item.status == "NEW"
            );
            //console.log("showEditDriverWorkShiftDialog - driverWorklogRequest: ", driverWorklogRequest);
            //Ak zaznam existuje, tak ponuknut jeho spracovanie, zobrazit spravne tlacidla v dialogovom okne
            if (driverWorklogRequest) {
              if (driverWorklogRequest.type == "ON_BREAK") {
                this.disableDriverWorkShiftBreak = false;
                this.disableDriverWorkShiftEnd = true;
              } else if (driverWorklogRequest.type == "OFFLINE") {
                this.disableDriverWorkShiftBreak = true;
                this.disableDriverWorkShiftEnd = false;
              } else {
                this.disableDriverWorkShiftBreak = true;
                this.disableDriverWorkShiftEnd = true;
              }
              this.editDriverWorkShiftDialog = true;
            } else {
              alert(this.$t("ConsoleOrderMessage.msgWorkShiftDriverNoRequest"));
            }
          })
          .catch((e) => {
            //this.errors.push(e);
          });

        //this.editDriverWorkShiftDialog = true;
      } else {
        alert(this.$t("ConsoleOrderMessage.msgWorkShiftDriverNotSelected"));
      }
    },

    updateDriverWorkShift(action) {
      //console.log("driverModel: ", this.dispDriverField),
      //console.log("action: ", action);
      var shiftApiUrl;
      //this.animateProgressBar("start"),
      switch (action) {
        case "breakShiftApprove":
          shiftApiUrl = "/dispatching/driver/worklog/confirm-break-request/";
          break;
        case "breakShiftReject":
          shiftApiUrl = "/dispatching/driver/worklog/decline-break-request/";
          break;
        case "endShiftApprove":
          shiftApiUrl = "/dispatching/driver/worklog/confirm-offline-request/";
          break;
        case "endShiftReject":
          shiftApiUrl = "/dispatching/driver/worklog/decline-offline-request/";
          break;
        default:
          alert(this.$t("ConsoleOrderMessage.msgWorkShiftMissingData"));
      }
      if (this.dispDriverField) {
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL +
              shiftApiUrl +
              this.dispDriverField
          )
          .then((response) => {
            var responseData = response.data;
            //this.animateProgressBar("ok"), (this.items = response.data);
            console.log(response.data);
            //this.orderDetailData = response.data;
            //this.driverSearchData.query = null;
            //this.sendOrderToDriver.driverId = null;
            //this.dispDriverField = null;
            if (
              responseData.message == "NO_EXISTS_WORKLOG_REQUEST" ||
              responseData.status == "FAILED"
            ) {
              alert(this.$t("ConsoleOrderMessage.msgWorkShiftMissingRequest"));
              this.snackbar.show = "true";
              this.snackbar.message = this.$t(
                "ConsoleOrderMessage.msgWorkShiftUpdateError"
              );
              this.snackbar.color = "error";
            } else {
              this.snackbar.show = "true";
              this.snackbar.message = this.$t(
                "ConsoleOrderMessage.msgWorkShiftUpdateSuccess"
              );
              this.snackbar.color = "success";
            }
          })
          .catch((e) => {
            //this.animateProgressBar("error"), this.errors.push(e);
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgResponseError");
            this.snackbar.color = "error";
          });
      } else {
        alert(this.$t("ConsoleOrderMessage.msgWorkShiftDriverNotSelected"));
      }
    },

    getClientPhone(orderId) {
      console.log(orderId),
        axios
          .get(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/order/` + orderId
          )
          .then((response) => {
            var orderDetailData = response.data;
            var clientPhone = "";
            if (orderDetailData.client) {
              clientPhone = orderDetailData.client.phone;
              this.orderMessageField =
                this.$t(
                  "ConsoleOrderMessage.orderMessageText.clientPhone_text"
                ) + clientPhone;
            }
          })
          .catch((e) => {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t(
              "ConsoleOrderMessage.msgGetOrderDetailError"
            );
            this.snackbar.color = "error";
          });
    },
    getMinutesFromNow(date) {
      var now = new Date();
      var date = new Date(date);
      var diff = (date.getTime() - now.getTime()) / 1000;
      diff /= 60;
      return Math.abs(Math.round(diff));
    },
  },
};
</script>
