<template>
  <span>
    <v-btn
      color="info"
      small
      :title="$t('ConsoleOrderEdit.btnEditOrder_title')"
      @click.stop="openEditSelectorDialog()"
      :disabled="disabled"
    >
      <v-icon left dark> mdi-file-document-edit </v-icon>
      {{ $t("ConsoleOrderEdit.btnEditOrder") }}
    </v-btn>
    <v-dialog
      v-model="editSelectorDialog"
      transition="dialog-top-transition"
      :max-width="dialogWidth"
    >
      <v-card>
        <v-toolbar color="secondary" dark dense flat>
          <v-toolbar-title>{{
            $t("ConsoleOrderEdit.dialogTitle")
          }}</v-toolbar-title></v-toolbar
        >
        <v-card-title
          >{{ $t("ConsoleOrderEdit.id_short") }}:
          {{ orderIdParam.slice(0, 8) }}</v-card-title
        >
        <v-card-subtitle class="py-0"
          >{{ $t("ConsoleOrderEdit.status") }}:
          {{ $t("orderStatus." + orderStatusParam) }}
        </v-card-subtitle>
        <v-card-subtitle class="py-0"
          >{{ $t("ConsoleOrderEdit.createdAt") }}:
          {{ orderCreatedAtParam | formatDateLong }}
        </v-card-subtitle>

        <v-divider></v-divider>
        <v-form ref="editSelectorForm">
          <v-card-text class="py-0">
            <v-container class="py-0">
              <v-row dense>
                <v-col cols="12">
                  <v-list dense>
                    <v-subheader dense>{{
                      $t("ConsoleOrderEdit.list.subheaderTime")
                    }}</v-subheader>
                    <!-- updateToInstantDialog - Zmeniť na okamžitú jazdu -->
                    <v-list-item
                      :disabled="disableUpdateToInstantDialog"
                      v-show="!disableUpdateToInstantDialog"
                      @click="
                        (editSelectorDialog = false),
                          (updateToInstantDialog = true)
                      "
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-av-timer</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("ConsoleOrderEdit.list.updateToInstantDialog")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- updateRequestedTimeDialog - Upraviť čas vyzdvihnutia -->
                    <v-list-item
                      :disabled="disableUpdateRequestedTimeDialog"
                      v-show="!disableUpdateRequestedTimeDialog"
                      @click="
                        (editSelectorDialog = false),
                          (updateRequestedTimeDialog = true)
                      "
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-calendar-clock</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("ConsoleOrderEdit.list.updateRequestedTimeDialog")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- updateRequestedTimeDialog - Zmeniť na časovku -->
                    <v-list-item
                      :disabled="disableChangeToTimeUpdateRequestedTimeDialog"
                      v-show="!disableChangeToTimeUpdateRequestedTimeDialog"
                      @click="
                        (editSelectorDialog = false),
                          (updateRequestedTimeDialog = true)
                      "
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-calendar-clock</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t(
                            "ConsoleOrderEdit.list.updateRequestedTimeDialog_toTime"
                          )
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- updatePresendTimeDialog - Upraviť čas zasielania vopred -->
                    <v-list-item
                      :disabled="disableUpdatePresendTimeDialog"
                      v-show="!disableUpdatePresendTimeDialog"
                      @click="
                        (editSelectorDialog = false),
                          (updatePresendTimeDialog = true)
                      "
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-timer-sand</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("ConsoleOrderEdit.list.updatePresendTimeDialog")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-subheader>{{
                      $t("ConsoleOrderEdit.list.subheaderStatus")
                    }}</v-subheader>
                    <!-- confirmNewDialog - Potvrdiť alebo rušiť jazdu-->
                    <v-list-item
                      :disabled="disableConfirmNewDialog"
                      v-show="!disableConfirmNewDialog"
                      @click="
                        (editSelectorDialog = false), (confirmNewDialog = true)
                      "
                    >
                      <v-list-item-icon>
                        <v-icon color="success"
                          >mdi-check-circle-outline</v-icon
                        >
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("ConsoleOrderEdit.list.confirmNewDialog")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- abortOrderDialog - Zrušiť jazdu-->
                    <v-list-item
                      :disabled="disableAbortOrderDialog"
                      v-show="!disableAbortOrderDialog"
                      @click="
                        (editSelectorDialog = false), (abortOrderDialog = true)
                      "
                    >
                      <v-list-item-icon>
                        <v-icon color="error">mdi-close-circle-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("ConsoleOrderEdit.list.abortOrderDialog")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- updateSendingActivateDialog - Aktivovať na odosielanie -->
                    <v-list-item
                      :disabled="disableUpdateSendingActivateDialog"
                      v-show="!disableUpdateSendingActivateDialog"
                      @click="
                        (editSelectorDialog = false),
                          (updateSendingActivateDialog = true)
                      "
                    >
                      <v-list-item-icon>
                        <v-icon color="success">mdi-send</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text--primary font-weight-bold"
                          >{{
                            $t(
                              "ConsoleOrderEdit.list.updateSendingActivateDialog"
                            )
                          }}</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <!-- updateSendingDeactivateDialog - Deaktivovať odosielanie -->
                    <v-list-item
                      :disabled="disableUpdateSendingDeactivateDialog"
                      v-show="!disableUpdateSendingDeactivateDialog"
                      @click="
                        (editSelectorDialog = false),
                          (updateSendingDeactivateDialog = true)
                      "
                    >
                      <v-list-item-icon>
                        <v-icon color="warning">mdi-send-lock</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t(
                            "ConsoleOrderEdit.list.updateSendingDeactivateDialog"
                          )
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- clearDriverDialog - Odobrať jazdu -->
                    <v-list-item
                      :disabled="disableClearDriverDialog"
                      v-show="!disableClearDriverDialog"
                      @click="
                        (editSelectorDialog = false), (clearDriverDialog = true)
                      "
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-arrow-left-circle</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("ConsoleOrderEdit.list.clearDriverDialog")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-subheader>{{
                      $t("ConsoleOrderEdit.list.subheaderSettings")
                    }}</v-subheader>
                    <!-- updateNoteDialog - Upraviť poznámku -->
                    <v-list-item
                      :disabled="disableUpdateNoteDialog"
                      v-show="!disableUpdateNoteDialog"
                      @click="
                        (editSelectorDialog = false), (updateNoteDialog = true)
                      "
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-playlist-edit</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("ConsoleOrderEdit.list.updateNoteDialog")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- updatePaymentDialog - Upraviť spôsob platby alebo firemný účet -->
                    <v-list-item
                      :disabled="disableUpdatePaymentDialog"
                      v-show="!disableUpdatePaymentDialog"
                      @click="
                        (editSelectorDialog = false),
                          (updatePaymentDialog = true)
                      "
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-cash</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("ConsoleOrderEdit.list.updatePaymentDialog")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- updateFilterDialog - Upraviť filtre/požiadavky na jazdu -->
                    <v-list-item
                      :disabled="disableUpdateFilterDialog"
                      v-show="!disableUpdateFilterDialog"
                      @click="
                        (editSelectorDialog = false),
                          (updateFilterDialog = true)
                      "
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-filter-cog-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("ConsoleOrderEdit.list.updateFilterDialog")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- updateSettingsDialog - Upraviť nastavenia zobrazenia a zasielania objednávky -->
                    <v-list-item
                      :disabled="disableUpdateSettingsDialog"
                      v-show="!disableUpdateSettingsDialog"
                      @click="
                        (editSelectorDialog = false),
                          (updateSettingsDialog = true)
                      "
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-cellphone-cog</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("ConsoleOrderEdit.list.updateSettingsDialog")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- updatePriceDialog - Prepočítať cenu -->
                    <v-list-item
                      v-show="displayOnlyForAdmin"
                      @click="
                        (editSelectorDialog = false), (updatePriceDialog = true)
                      "
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-cash-multiple</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("ConsoleOrderEdit.list.updatePriceDialog")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              text
              @click.stop="editSelectorDialog = false"
              >{{ $t("btnClose") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Dialog na zrušenie objednavky -->
    <v-dialog v-model="abortOrderDialog" :max-width="dialogWidth">
      <v-card>
        <v-card-title style="word-break: normal">
          {{ $t("ConsoleOrderEdit.abortOrderDialog_title") }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                {{ $t("ConsoleOrderEdit.abortOrderDialog_text") }}
              </v-col>
            </v-row>
            <v-row
              ><v-col cols="12">
                <v-select
                  v-model="abortInfo.abortedReason"
                  :items="$t('orderAbortedReasonArray')"
                  :label="$t('ConsoleOrderEdit.abortedReason')"
                  filled
                  dense
                ></v-select
              ></v-col>
              <v-col cols="12">
                <v-text-field
                  :label="$t('ConsoleOrderEdit.abortNote')"
                  filled
                  dense
                  v-model="abortInfo.abortNote"
                  :rules="rulesNote"
                  counter
                  maxlength="80"
                ></v-text-field
              ></v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="(abortOrderDialog = false), callUpdateApi('abortOrder')"
          >
            {{ $t("ConsoleOrderEdit.abortOrderDialog_btnAbort") }}
          </v-btn>
          <v-btn color="error" text @click="abortOrderDialog = false">
            {{ $t("btnClose") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog na aktivovanie objednavky na odoslanie automatom-->
    <v-dialog v-model="updateSendingActivateDialog" :max-width="dialogWidth">
      <v-card>
        <v-card-title style="word-break: normal">
          {{ $t("ConsoleOrderEdit.updateSendingActivateDialog_title") }}
        </v-card-title>
        <v-card-text
          >{{ $t("ConsoleOrderEdit.updateSendingActivateDialog_text") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="
              (updateSendingActivateDialog = false),
                callUpdateApi('updateSendingActivate')
            "
            >{{
              $t("ConsoleOrderEdit.updateSendingActivateDialog_btnActivate")
            }}
          </v-btn>
          <v-btn
            color="error"
            text
            @click="updateSendingActivateDialog = false"
          >
            {{ $t("btnClose") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog na Deaktivovanie objednavky na odoslanie automatom-->
    <v-dialog v-model="updateSendingDeactivateDialog" :max-width="dialogWidth">
      <v-card>
        <v-card-title style="word-break: normal">
          {{ $t("ConsoleOrderEdit.updateSendingDeactivateDialog_title") }}
        </v-card-title>
        <v-card-text>
          {{ $t("ConsoleOrderEdit.updateSendingDeactivateDialog_text") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="
              (updateSendingDeactivateDialog = false),
                callUpdateApi('updateSendingDeactivate')
            "
            >{{
              $t("ConsoleOrderEdit.updateSendingDeactivateDialog_btnDeactivate")
            }}
          </v-btn>
          <v-btn
            color="error"
            text
            @click="updateSendingDeactivateDialog = false"
          >
            {{ $t("btnClose") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog na zmenu času objednavky na okamžité odosielanie objednavky -->
    <v-dialog v-model="updateToInstantDialog" :max-width="dialogWidth">
      <v-card>
        <v-card-title style="word-break: normal">
          {{ $t("ConsoleOrderEdit.updateToInstantDialog_title") }}
        </v-card-title>
        <v-card-text>
          {{ $t("ConsoleOrderEdit.updateToInstantDialog_text") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="
              (updateToInstantDialog = false), callUpdateApi('updateToInstant')
            "
            >{{ $t("ConsoleOrderEdit.updateToInstantDialog_btnToInstant") }}
          </v-btn>
          <v-btn color="error" text @click="updateToInstantDialog = false">
            {{ $t("btnClose") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog na zmenu času objednavky na časovku -->
    <v-dialog v-model="updateRequestedTimeDialog" :max-width="dialogWidth">
      <v-card>
        <v-card-title style="word-break: normal">
          {{ $t("ConsoleOrderEdit.updateRequestedTimeDialog_title") }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12"
                >{{ $t("ConsoleOrderEdit.updateRequestedTimeDialog_text") }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <my-datepicker
                  :label="$t('ConsoleOrderEdit.requestedTime')"
                  dense
                  v-model="orderDetailData.requestedTime"
                >
                </my-datepicker>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="callUpdateApi('updateRequestedTime')"
            >{{ $t("ConsoleOrderEdit.updateRequestedTimeDialog_btnToTime") }}
          </v-btn>
          <v-btn color="error" text @click="updateRequestedTimeDialog = false">
            {{ $t("btnClose") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog na zmenu času kedy sa má objednávka začať vopred odosielať -->
    <v-dialog v-model="updatePresendTimeDialog" :max-width="dialogWidth">
      <v-card>
        <v-card-title style="word-break: normal">
          {{ $t("ConsoleOrderEdit.updatePresendTimeDialog_title") }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="isFormValidPresendTime">
              <v-row>
                <v-col cols="12">
                  {{ $t("ConsoleOrderEdit.updatePresendTimeDialog_text") }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('ConsoleOrderEdit.presendTime')"
                    v-model="orderDetailData.presendTime"
                    :rules="rulesPresendTime"
                    type="number"
                    suffix="min"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- Chips with preset values, which on click se value to orderDetialData.presendTime -->
              <v-row>
                <v-col cols="12">
                  <v-chip
                    v-for="n in [
                      5, 8, 10, 12, 15, 20, 25, 30, 45, 50, 60, 90, 120, 180,
                      240,
                    ]"
                    :key="n"
                    class="ma-1"
                    @click="orderDetailData.presendTime = n"
                    >{{ n }}</v-chip
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="
              (updatePresendTimeDialog = false),
                callUpdateApi('updatePresendTime')
            "
            :disabled="!isFormValidPresendTime"
            >{{ $t("ConsoleOrderEdit.updatePresendTimeDialog_btnUpdate") }}
          </v-btn>
          <v-btn color="error" text @click="updatePresendTimeDialog = false">
            {{ $t("btnClose") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog na odobratie objednavky vodicovi a zmenu stavu na odosielanie objednavky -->
    <v-dialog v-model="clearDriverDialog" :max-width="dialogWidth">
      <v-card>
        <v-card-title style="word-break: normal">
          {{ $t("ConsoleOrderEdit.clearDriverDialog_title") }}
        </v-card-title>
        <v-card-text>
          {{ $t("ConsoleOrderEdit.clearDriverDialog_text") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="(clearDriverDialog = false), callUpdateApi('clearDriver')"
            >{{ $t("ConsoleOrderEdit.clearDriverDialog_btnClear") }}
          </v-btn>
          <v-btn color="error" text @click="clearDriverDialog = false">
            {{ $t("btnClose") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog na zmenu poznamky objednavky -->
    <v-dialog v-model="updateNoteDialog" :max-width="dialogWidth">
      <v-card>
        <v-form ref="updateNoteForm">
          <v-card-title style="word-break: normal">
            {{ $t("ConsoleOrderEdit.updateNoteDialog_title") }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  {{ $t("ConsoleOrderEdit.updateNoteDialog_text") }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('ConsoleOrderEdit.note')"
                    filled
                    dense
                    v-model="orderDetailData.note"
                    :rules="rulesNote"
                    counter
                    maxlength="80"
                  ></v-text-field
                ></v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="(updateNoteDialog = false), callUpdateApi('updateNote')"
              >{{ $t("ConsoleOrderEdit.updateNoteDialog_btnUpdate") }}
            </v-btn>
            <v-btn color="error" text @click="updateNoteDialog = false">
              {{ $t("btnClose") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Dialog na zmenu Sposobu platby alebo firemneho uctu objednavky -->
    <v-dialog v-model="updatePaymentDialog" :max-width="dialogWidth">
      <v-card>
        <v-form ref="updatePaymentForm">
          <v-card-title style="word-break: normal">
            {{ $t("ConsoleOrderEdit.updatePaymentDialog_title") }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  {{ $t("ConsoleOrderEdit.updatePaymentDialog_text") }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="orderDetailData.paymentType"
                    :items="paymentTypeItems"
                    :label="$t('ConsoleOrderEdit.paymentType')"
                    dense
                    prepend-icon="mdi-cash"
                    @change="setPaymentOption()"
                  ></v-select>
                </v-col> </v-row
              ><v-row>
                <v-col cols="12">
                  <autocomplete-account-remote
                    v-model="orderDetailData.accountId"
                    :label="$t('ConsoleOrderEdit.accountId')"
                    :disabled="isDisabledAccountId"
                    dense
                    :searchParams="accountSearchData"
                  >
                  </autocomplete-account-remote>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="
                (updatePaymentDialog = false), callUpdateApi('updatePayment')
              "
            >
              {{ $t("ConsoleOrderEdit.updatePaymentDialog_btnUpdate") }}
            </v-btn>
            <v-btn color="error" text @click="updatePaymentDialog = false">
              {{ $t("btnClose") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Dialog na zmenu filtrov objednavky -->
    <v-dialog v-model="updateFilterDialog" :max-width="dialogWidth">
      <v-card>
        <v-form ref="updateFilterForm">
          <v-card-title style="word-break: normal">
            {{ $t("ConsoleOrderEdit.updateFilterDialog_title") }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row dense>
                <!--<v-col cols="12" md="6">
                  <v-select
                    label="Airconditioning"
                    dense
                    v-model="orderDetailData.filter.airconditioning"
                    :items="$t('orderFilterSelect.airconditioning')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-select>
                </v-col> -->
                <v-col cols="12" md="6">
                  <v-select
                    :label="$t('ConsoleOrderEdit.animal')"
                    dense
                    v-model="orderDetailData.filter.animal"
                    :items="$t('orderFilterSelect.animal')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    :label="$t('ConsoleOrderEdit.luggage')"
                    dense
                    v-model="orderDetailData.filter.luggage"
                    :items="$t('orderFilterSelect.luggage')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-select>
                </v-col>
              </v-row>
              <v-row dense>
                <!--<v-col cols="12" md="6">
                  <v-select
                    label="Autonomous"
                    dense
                    v-model="orderDetailData.filter.autonomous"
                    :items="$t('orderFilterSelect.autonomous')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-select>
                </v-col> -->
                <v-col cols="12" md="6">
                  <v-select
                    :label="$t('ConsoleOrderEdit.paymentTerminal')"
                    dense
                    v-model="orderDetailData.filter.paymentTerminal"
                    :items="$t('orderFilterSelect.paymentTerminal')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    :label="$t('ConsoleOrderEdit.childrenChair')"
                    dense
                    v-model="orderDetailData.filter.childrenChair"
                    :items="$t('orderFilterSelect.childrenChair')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-select>
                </v-col>
              </v-row>
              <!--<v-row dense>
                <v-col cols="12" md="6">
                  <v-select
                    label="Driver Rating"
                    dense
                    v-model="orderDetailData.filter.driverRating"
                    :items="$t('orderFilterSelect.driverRating')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    label="Eco"
                    dense
                    v-model="orderDetailData.filter.eco"
                    :items="$t('orderFilterSelect.eco')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-select>
                </v-col>
              </v-row> -->
              <v-row dense>
                <v-col cols="12" md="6">
                  <v-select
                    :label="$t('ConsoleOrderEdit.maxPerson')"
                    dense
                    v-model="orderDetailData.filter.maxPerson"
                    :items="$t('orderFilterSelect.maxPerson')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-select>
                </v-col>
                <!--<v-col cols="12" md="6">
                  <v-select
                    label="Smoking"
                    dense
                    v-model="orderDetailData.filter.smoking"
                    :items="$t('orderFilterSelect.smoking')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-select>
                </v-col>
              </v-row>
              <v-row dense> -->
                <v-col cols="12" md="6">
                  <v-select
                    :label="$t('ConsoleOrderEdit.specialTransport')"
                    dense
                    v-model="orderDetailData.filter.specialTransport"
                    :items="$t('orderFilterSelect.specialTransport')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="
                (updateFilterDialog = false), callUpdateApi('updateFilter')
              "
              >{{ $t("ConsoleOrderEdit.updateFilterDialog_btnUpdate") }}
            </v-btn>
            <v-btn color="error" text @click="updateFilterDialog = false">
              {{ $t("btnClose") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Dialog na zmenu nastavení objednavky -->
    <v-dialog v-model="updateSettingsDialog" :max-width="dialogWidth">
      <v-card>
        <v-form ref="updateSettingsForm">
          <v-card-title style="word-break: normal">
            {{ $t("ConsoleOrderEdit.updateSettingsDialog_title") }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  <v-checkbox
                    :label="$t('ConsoleOrderEdit.showPhone')"
                    v-model="orderDetailData.settings.showPhone"
                    :title="$t('ConsoleOrderEdit.showPhone_title')"
                    dense
                    filled
                  ></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    :label="$t('ConsoleOrderEdit.showAllAddresses')"
                    v-model="orderDetailData.settings.showAllAddresses"
                    :title="$t('ConsoleOrderEdit.showAllAddresses_title')"
                    dense
                    filled
                  ></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    :label="$t('ConsoleOrderEdit.onlyManualSending')"
                    v-model="orderDetailData.settings.onlyManualSending"
                    :title="$t('ConsoleOrderEdit.onlyManualSending_title')"
                    dense
                    filled
                    :disabled="!editSettingsDialogOnlyManualSendingField"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('ConsoleOrderEdit.forcedPickupRadius')"
                    dense
                    v-model.number="orderDetailData.settings.forcedPickupRadius"
                    :title="$t('ConsoleOrderEdit.forcedPickupRadius_title')"
                    type="number"
                    suffix="m"
                    min="0"
                    max="1000"
                    :rules="rulesForcedPickupRadius"
                    :disabled="!editSettingsDialogForcedPickupRadiusField"
                    @keypress="filterOnlyInteger(event)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="callUpdateApi('updateSettings')"
              >{{ $t("ConsoleOrderEdit.updateSettingsDialog_btnUpdate") }}
            </v-btn>
            <v-btn color="error" text @click="updateSettingsDialog = false">
              {{ $t("btnClose") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Dialog na aktualizovanie ceny objednavky-->
    <v-dialog v-model="updatePriceDialog" :max-width="dialogWidth">
      <v-card>
        <v-card-title style="word-break: normal">
          {{ $t("ConsoleOrderEdit.updatePriceDialog_title") }}
        </v-card-title>
        <v-card-text>
          {{ $t("ConsoleOrderEdit.updatePriceDialog_text") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="(updatePriceDialog = false), callPriceUpdateApi()"
            >{{ $t("ConsoleOrderEdit.updatePriceDialog_btnUpdate") }}
          </v-btn>
          <v-btn color="error" text @click="updatePriceDialog = false">
            {{ $t("btnClose") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog na potvrdenie NOVEJ objednavky z appky zakaznika -->
    <v-dialog v-model="confirmNewDialog" :max-width="dialogWidth">
      <v-card>
        <v-card-title style="word-break: normal">
          {{ $t("ConsoleOrderEdit.confirmNewDialog_title") }}
        </v-card-title>
        <v-card-text>
          {{ $t("ConsoleOrderEdit.confirmNewDialog_text") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="
              (confirmNewDialog = false), callUpdateApi('confirmNewOrder')
            "
            >{{ $t("ConsoleOrderEdit.confirmNewDialog_btnConfirmNew") }}
          </v-btn>
          <v-btn
            color="orange darken-1"
            text
            @click="(confirmNewDialog = false), callUpdateApi('abortOrder')"
            >{{ $t("ConsoleOrderEdit.confirmNewDialog_btnAbortNew") }}
          </v-btn>
          <v-btn color="error" text @click="confirmNewDialog = false">
            {{ $t("btnClose") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar o úspešnosti volania na API -->
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </span>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";
import { Status, StatusColor } from "../enums/OrderEnum";
import AutocompleteAccountRemote from "../components/AutocompleteAccountRemote.vue";
const DEFAULT_DIALOG_WIDTH = 500;
export default {
  name: "OrderEdit",
  components: { AutocompleteAccountRemote },
  props: {
    directEdit: {
      type: String,
      default: "",
    },
    orderEditTrigger: {
      type: Number,
      default: 0,
    },
    orderIdParam: {
      type: String,
      default: "",
    },
    orderStatusParam: {
      type: String,
      default: "",
    },
    orderCreatedAtParam: {
      type: String,
      default: "2000-01-01T01:01:01.123456Z",
    },
    orderNoteParam: {
      type: String,
      default: "",
    },
    orderRequestedTimeParam: {
      type: String,
      default: "2000-01-01T01:01:01.123456Z",
    },
    orderPresendTimeParam: {
      type: Number,
      default: "10",
    },
    orderTypeParam: {
      type: String,
      default: "",
    },
    orderFilterParam: {
      type: Object,
      default: {},
    },
    orderSettingsParam: {
      type: Object,
      default: {},
    },
    filled: {
      type: Boolean,
    },
    outlined: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
    medium: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    label: {
      type: String,
      default: "",
    },
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH,
    },
  },
  data() {
    return {
      moment: moment,
      displayOnlyForAdmin: false,
      Status,
      StatusColor,
      //Snackbar
      snackbar: {
        show: false,
        message: "OK",
        color: "info",
        timeout: 3000,
      },

      items: [],
      posts: [],
      errors: [],

      dataForUpdate: {
        requestedTime: null,
        note: null,
        presendTime: null,
        paymentType: null,
        accountId: null,
        abortedReason: null,
        abortNote: null,
      },
      //dovody zrusenia objednavky
      abortInfo: {
        abortedReason: "OTHER",
        abortNote: "",
      },

      //Zobrazenie dialogovych okien
      editSelectorDialog: false,
      abortOrderDialog: false,
      updateSendingActivateDialog: false,
      updateSendingDeactivateDialog: false,
      updateToInstantDialog: false,
      updateRequestedTimeDialog: false,
      updatePresendTimeDialog: false,
      clearDriverDialog: false,
      updateNoteDialog: false,
      updatePaymentDialog: false,
      updateFilterDialog: false,
      updateSettingsDialog: false,
      updatePriceDialog: false,
      confirmNewDialog: false,

      //Validovanie formularov
      isFormValidPresendTime: true,

      //Blokovania poloziek menu na zobrazenie dialogovych okien
      disableAbortOrderDialog: false,
      disableUpdateSendingActivateDialog: false,
      disableUpdateSendingDeactivateDialog: false,
      disableUpdateToInstantDialog: false,
      disableUpdateRequestedTimeDialog: false,
      disableChangeToTimeUpdateRequestedTimeDialog: false,
      disableUpdatePresendTimeDialog: false,
      disableClearDriverDialog: false,
      disableUpdateNoteDialog: false,
      disableUpdatePaymentDialog: false,
      disableUpdateFilterDialog: false,
      disableUpdateSettingsDialog: false,
      editSettingsDialogForcedPickupRadiusField: true,
      editSettingsDialogOnlyManualSendingField: true,
      disableConfirmNewDialog: false,

      rulesNote: [
        (v) => v.length <= 75 || this.$t("ConsoleOrderEdit.rulesNote"),
      ], //"Max 80 characters"],
      rulesPresendTime: [
        //v => !!v || "This field is required",
        (v) => (v && v >= 0) || this.$t("ConsoleOrderEdit.rulesPresendTime1"), //"Time should be above 1 minute",
        (v) => (v && v <= 240) || this.$t("ConsoleOrderEdit.rulesPresendTime2"), // "Max should not be above 240 minutes (4 hours)",
      ],
      rulesForcedPickupRadius: [
        //(v) => !!v || "This field is required",
        (v) => v >= 0 || this.$t("ConsoleOrderEdit.rulesForcedPickupRadius1"), //Should be 0 or more
        (v) =>
          v <= 1000 || this.$t("ConsoleOrderEdit.rulesForcedPickupRadius2"), //Max 1000m
      ],

      //Spôsob platby a Firemna jazda
      isDisabledAccountId: true,
      paymentTypeItems: [],
      //Zoznam firiem pre autocomplete Accounts
      accountSearchData: {
        query: null,
        type: null,
      },
      //Defaultna hotnota kedy dopred sa maju posielat objednavky
      defaultPresendTime: 15,
      presendTimeChips: [],

      orderDetailData: {
        id: null,
        status: null,
        type: null,
        service: null,
        paymentType: null,
        accountId: null,
        source: null,
        createdAt: null,
        requestedTime: null,
        acceptedAt: null,
        waitingAt: null,
        progressAt: null,
        finishedAt: null,
        interruptedAt: null,
        archivedAt: null,
        acceptedBy: null,
        waitingBy: null,
        progressBy: null,
        finishedBy: null,
        interruptedBy: null,
        clientRating: null,
        driverRating: null,
        currency: null,
        displayPrice: null,
        estimatedPrice: null,
        estimatedDistance: null,
        estimatedTime: null,
        note: "",
        plannedAt: null,
        pendingBy: null,
        pendingAt: null,
        plannedBy: null,
        locFrom: null,
        locTo: null,
        interruptedFor: null,
        sentArriveNoti: null,
        finalDistance: null,
        finalTime: null,
        waitingTime: null,
        finalDistancePrice: null,
        finalTimePrice: null,
        minimumFare: null,
        startFare: null,
        finalDrivePrice: null,
        finalWaitingPrice: null,
        priceDiscount: null,
        priceExtra: null,
        fareDistance: null,
        fareTime: null,
        priceType: null,
        waitingLimit: null,
        waitingFare: null,
        finalLocFrom: null,
        finalLocTo: null,
        settledAt: null,
        chargedAt: null,
        finalPrice: null,
        companyType: null,
        customerType: null,
        countedDrivePrice: null,
        fixedDrivePrice: null,
        remainingTime: null,
        estimatedFees: null,
        estimatedPaymentFees: null,
        presendTime: null,
        waypointList: [
          {
            id: null,
            status: null,
            ordering: null,
            latitude: null,
            longitude: null,
            address: null,
            type: "BOARD",
            localityId: null,
          },
          {
            id: null,
            status: null,
            ordering: null,
            latitude: null,
            longitude: null,
            address: null,
            type: "DESTINATION",
            localityId: null,
          },
        ],
        filter: {
          animal: null,
          luggage: null,
          specialTransport: null,
          //autonomous: null,
          //airconditioning: null,
          //eco: null,
          //smoking: null,
          childrenChair: null,
          maxPerson: null,
          //driverRating: null,
          paymentTerminal: null,
        },
        vehicle: null,
        client: {
          id: null,
          email: null,
          nickname: null,
          phone: null,
          rating: null,
          rateCount: null,
          rateSum: null,
          driveCounter: null,
        },
        driver: null,
        settings: {
          forcedPickupRadius: null,
          onlyManualSending: null,
          showAllAddresses: null,
          showDriverPhoneButton: null,
          showPhone: null,
          showPrice: null,
        },
      },
    };
  },

  created() {
    //Zistenie zakladneho casu na posielanie casovej objednavy vopred z Cookies
    var defaultPresendTime = this.getCookie("defaultPresendTime")
      ? JSON.parse(this.getCookie("defaultPresendTime"))
      : 15;
    this.defaultPresendTime = defaultPresendTime;
  },

  mounted() {
    //Nacitanie role podla ktorej sa potom zobrazuju niektore extra polia
    this.getUserRole();
    this.paymentTypeItems = this.$t("paymentTypeArray");
    //ponechat v paymentTypeItems iba tie položky: CASH, CARD, POSTPAID
    //TODO - upraviť tak, aby tu boli iba tie položky, ktoré sú v konfigurácii
    this.paymentTypeItems = this.paymentTypeItems.filter((item) => {
      return (
        item.value == "CASH" || item.value == "CARD" || item.value == "POSTPAID"
      );
    });
  },

  computed: {},

  watch: {
    orderEditTrigger: function (val) {
      if (!this.orderIdParam) {
        this.toastSnackbarUnavailable();
      } else {
        if (this.directEdit != "") {
          //console.log("directEdit sa zmenil na true a nie je prazdny!!!",this.directEdit);
          this.setDisableButtons();
          switch (this.directEdit) {
            case "confirmNew":
              if (this.disableConfirmNewDialog == false) {
                this.confirmNewDialog = true;
              } else {
                this.toastSnackbarUnavailable();
              }
              break;
            case "abort":
              if (this.disableAbortOrderDialog == false) {
                this.abortOrderDialog = true;
              } else {
                this.toastSnackbarUnavailable();
              }
              break;
            case "sendingActivate":
              if (this.disableUpdateSendingActivateDialog == false) {
                this.updateSendingActivateDialog = true;
              } else {
                this.toastSnackbarUnavailable();
              }
              break;
            case "sendingDeactivate":
              if (this.disableUpdateSendingDeactivateDialog == false) {
                this.updateSendingDeactivateDialog = true;
              } else {
                this.toastSnackbarUnavailable();
              }
              break;
            case "note":
              if (this.disableUpdateNoteDialog == false) {
                this.updateNoteDialog = true;
              } else {
                this.toastSnackbarUnavailable();
              }
              break;
            case "presendTime":
              if (this.disableUpdatePresendTimeDialog == false) {
                this.updatePresendTimeDialog = true;
              } else {
                this.toastSnackbarUnavailable();
              }
              break;
            case "requestedTime":
              if (this.disableUpdateRequestedTimeDialog == false) {
                this.updateRequestedTimeDialog = true;
              } else {
                this.toastSnackbarUnavailable();
              }
              break;
            case "payment":
              if (this.disableUpdatePaymentDialog == false) {
                this.updatePaymentDialog = true;
              } else {
                this.toastSnackbarUnavailable();
              }
              break;
            case "filter":
              if (this.disableUpdateFilterDialog == false) {
                this.updateFilterDialog = true;
              } else {
                this.toastSnackbarUnavailable();
              }
              break;
            case "settings":
              if (this.disableUpdateSettingsDialog == false) {
                this.updateSettingsDialog = true;
              } else {
                this.toastSnackbarUnavailable();
              }
              break;
            default:
              this.openEditSelectorDialog();
              break;
          }
        } else {
          this.toastSnackbarUnavailable();
        }
      }
    },
    orderNoteParam: function (val) {
      //console.log("orderNoteParam sa zmenil!!!", val);
      if (val) {
        this.orderDetailData.note = val;
      } else {
        this.orderDetailData.note = "";
      }
    },
    orderRequestedTimeParam: function (val) {
      //console.log("orderRequestedTimeParam sa zmenil!!!", val);
      this.orderDetailData.requestedTime = val;
    },
    orderPresendTimeParam: function (val) {
      //console.log("orderPresendTimeParam sa zmenil!!!", val);
      this.orderDetailData.presendTime = val;
    },
    orderFilterParam: function (val) {
      //console.log("orderFilterParam sa zmenil!!!", val);
      this.orderDetailData.filter = val;
    },
    orderSettingsParam: function (val) {
      //console.log("orderSettingsParam sa zmenil!!!", val);
      this.orderDetailData.settings = val;
    },
  },

  methods: {
    getUserRole() {
      let enabledRoles = ["ADMIN", "SUPPORT"];
      let userRoles = this.$store.state.user.roleList;
      this.displayOnlyForAdmin =
        userRoles.some((a) => (enabledRoles.includes(a) ? true : false)) ===
        true;
    },
    openEditSelectorDialog() {
      if (!this.orderIdParam) {
        this.toastSnackbarUnavailable();
      } else {
        this.setDisableButtons();
        if (
          this.orderStatusParam == "TRANSFERED" ||
          this.orderStatusParam == "PREREGISTERED" ||
          this.orderStatusParam == "NEW" ||
          this.orderStatusParam == "PLANNED" ||
          this.orderStatusParam == "PENDING" ||
          this.orderStatusParam == "ACCEPTED" ||
          this.orderStatusParam == "WAITING" ||
          this.orderStatusParam == "IN_PROGRESS"
        ) {
          //Zobrazenie Dialogoveho okna s zoznamom moznosti na zmenu objednavky
          this.editSelectorDialog = true;
        } else if (
          this.orderStatusParam == "FINISHED" ||
          this.orderStatusParam == "CHARGED" ||
          this.orderStatusParam == "SETTLED" ||
          this.orderStatusParam == "ARCHIVED" ||
          this.orderStatusParam == "CANCELLED_PREREGISTERED" ||
          this.orderStatusParam == "CANCELLED_NEW" ||
          this.orderStatusParam == "CANCELLED_PLANNED" ||
          this.orderStatusParam == "CANCELLED_PENDING" ||
          this.orderStatusParam == "CANCELLED_ACCEPTED" ||
          this.orderStatusParam == "CANCELLED_WAITING" ||
          this.orderStatusParam == "CANCELLED_IN_PROGRESS" ||
          this.orderStatusParam == "CANCELLED_FINISHED" ||
          this.orderStatusParam == "CANCELLED_CHARGED" ||
          this.orderStatusParam == "CANCELLED_SETTLED"
        ) {
          //NEzobrazenie Dialogoveho okna s zoznamom moznosti na zmenu objednavky
          this.editSelectorDialog = false;
          this.toastSnackbarUnavailable();
        } else {
          //NEzobrazenie Dialogoveho okna s zoznamom moznosti na zmenu objednavky
          this.editSelectorDialog = false;
          this.toastSnackbarUnavailable();
        }
      }
    },
    setDisableButtons() {
      //Defaultne hodnoty pre ponuku s povolenymi vsetkymi polozkami
      this.disableAbortOrderDialog = false;
      this.disableUpdateSendingActivateDialog = false;
      this.disableUpdateSendingDeactivateDialog = false;
      this.disableUpdateToInstantDialog = false;
      this.disableUpdateRequestedTimeDialog = false;
      this.disableChangeToTimeUpdateRequestedTimeDialog = false;
      this.disableUpdatePresendTimeDialog = false;
      this.disableClearDriverDialog = false;
      this.disableUpdateNoteDialog = false;
      this.disableUpdatePaymentDialog = false;
      this.disableUpdateFilterDialog = false;
      this.disableUpdateSettingsDialog = false;
      this.editSettingsDialogForcedPickupRadiusField = true;
      this.editSettingsDialogOnlyManualSendingField = true;
      this.disableConfirmNewDialog = false;
      //Skryvanie tlacidiel na zmenu na zaklade TYPU objednavky
      if (this.orderTypeParam == "INSTANT") {
        this.disableUpdateToInstantDialog = true;
        this.disableUpdatePresendTimeDialog = true;
        this.disableUpdateRequestedTimeDialog = true;
      } else if (this.orderTypeParam == "TIME") {
        this.disableChangeToTimeUpdateRequestedTimeDialog = true;
      } else if (this.orderTypeParam == "STREET") {
        this.disableUpdateToInstantDialog = true;
        this.disableUpdateRequestedTimeDialog = true;
        this.disableChangeToTimeUpdateRequestedTimeDialog = true;
        this.disableUpdatePresendTimeDialog = true;
      } else if (this.orderTypeParam == "DIRECT") {
      } else if (this.orderTypeParam == "REPEATED") {
      } else if (this.orderTypeParam == "AIRPORT") {
      } else {
        this.disableAbortOrderDialog = false;
        this.disableUpdateSendingActivateDialog = false;
        this.disableUpdateSendingDeactivateDialog = false;
        this.disableUpdateToInstantDialog = false;
        this.disableUpdateRequestedTimeDialog = false;
        this.disableUpdatePresendTimeDialog = false;
        this.disableClearDriverDialog = false;
        this.disableUpdateNoteDialog = false;
        this.disableUpdatePaymentDialog = false;
        this.disableUpdateFilterDialog = false;
        this.disableUpdateSettingsDialog = false;
        this.disableConfirmNewDialog = false;
      }
      //Skryvanie tlacidiel na zmenu na zaklade STAVOV objednavky
      if (this.orderStatusParam == "PREREGISTERED") {
        //Zobrazenie Dialogoveho okna s zoznamom moznosti na zmenu objednavky
        this.disableUpdateSendingActivateDialog = true;
        this.disableUpdateSendingDeactivateDialog = true;
        this.disableUpdateToInstantDialog = true;
        this.disableUpdateRequestedTimeDialog = true;
        this.disableChangeToTimeUpdateRequestedTimeDialog = true;
        this.disableUpdatePresendTimeDialog = true;
        this.disableClearDriverDialog = true;
        this.disableUpdateNoteDialog = false;
        this.disableUpdatePaymentDialog = true;
        this.disableUpdateFilterDialog = true;
        this.disableUpdateSettingsDialog = true;
        this.editSettingsDialogForcedPickupRadiusField = true;
        this.editSettingsDialogOnlyManualSendingField = true;
        this.disableConfirmNewDialog = true;
      } else if (this.orderStatusParam == "TRANSFERED") {
        //Zobrazenie Dialogoveho okna s zoznamom moznosti na zmenu objednavky
        this.disableUpdateSendingActivateDialog = true;
        this.disableUpdateSendingDeactivateDialog = true;
        this.disableConfirmNewDialog = true;
      } else if (this.orderStatusParam == "NEW") {
        //Zobrazenie Dialogoveho okna s zoznamom moznosti na zmenu objednavky
        this.disableUpdateSendingActivateDialog = true;
        this.disableUpdateSendingDeactivateDialog = true;
        this.disableUpdateToInstantDialog = true;
        this.disableUpdateRequestedTimeDialog = true;
        this.disableChangeToTimeUpdateRequestedTimeDialog = true;
        this.disableUpdatePresendTimeDialog = true;
        this.disableClearDriverDialog = true;
        this.disableUpdateNoteDialog = false;
        this.disableUpdatePaymentDialog = true;
        this.disableUpdateFilterDialog = true;
        this.disableUpdateSettingsDialog = true;
        this.editSettingsDialogForcedPickupRadiusField = true;
        this.editSettingsDialogOnlyManualSendingField = true;
        this.disableConfirmNewDialog = false;
      } else if (this.orderStatusParam == "PLANNED") {
        //Zobrazenie Dialogoveho okna s zoznamom moznosti na zmenu objednavky
        this.disableUpdateSendingDeactivateDialog = true;
        this.disableClearDriverDialog = true;
        this.disableConfirmNewDialog = true;
      } else if (this.orderStatusParam == "PENDING") {
        //Zobrazenie Dialogoveho okna s zoznamom moznosti na zmenu objednavky
        this.disableUpdateSendingActivateDialog = true;
        this.disableClearDriverDialog = true;
        this.disableConfirmNewDialog = true;
      } else if (this.orderStatusParam == "ACCEPTED") {
        //Zobrazenie Dialogoveho okna s zoznamom moznosti na zmenu objednavky
        this.disableUpdateSendingActivateDialog = true;
        this.disableUpdateSendingDeactivateDialog = true;
        this.disableUpdateToInstantDialog = true;
        this.disableUpdateRequestedTimeDialog = true;
        this.disableChangeToTimeUpdateRequestedTimeDialog = true;
        this.disableUpdatePresendTimeDialog = true;
        this.disableClearDriverDialog = false;
        this.disableUpdateFilterDialog = true;
        //specialne pre polia nastaveni zasielania
        this.editSettingsDialogForcedPickupRadiusField = true;
        this.editSettingsDialogOnlyManualSendingField = false;
        this.disableConfirmNewDialog = true;
      } else if (this.orderStatusParam == "WAITING") {
        //Zobrazenie Dialogoveho okna s zoznamom moznosti na zmenu objednavky
        this.disableUpdateSendingActivateDialog = true;
        this.disableUpdateSendingDeactivateDialog = true;
        this.disableUpdateToInstantDialog = true;
        this.disableUpdateRequestedTimeDialog = true;
        this.disableChangeToTimeUpdateRequestedTimeDialog = true;
        this.disableUpdatePresendTimeDialog = true;
        this.disableClearDriverDialog = false;
        this.disableUpdateFilterDialog = true;
        //specialne pre polia nastaveni zasielania
        this.editSettingsDialogForcedPickupRadiusField = false;
        this.editSettingsDialogOnlyManualSendingField = false;
        this.disableConfirmNewDialog = true;
      } else if (this.orderStatusParam == "IN_PROGRESS") {
        //Zobrazenie Dialogoveho okna s zoznamom moznosti na zmenu objednavky
        this.disableUpdateSendingActivateDialog = true;
        this.disableUpdateSendingDeactivateDialog = true;
        this.disableUpdateToInstantDialog = true;
        this.disableUpdateRequestedTimeDialog = true;
        this.disableChangeToTimeUpdateRequestedTimeDialog = true;
        this.disableUpdatePresendTimeDialog = true;
        this.disableClearDriverDialog = true;
        this.disableUpdateNoteDialog = true;
        this.disableUpdateFilterDialog = true;
        this.disableUpdateSettingsDialog = true;
        this.editSettingsDialogForcedPickupRadiusField = false;
        this.editSettingsDialogOnlyManualSendingField = false;
        this.disableConfirmNewDialog = true;
      } else if (
        this.orderStatusParam == "FINISHED" ||
        this.orderStatusParam == "CHARGED" ||
        this.orderStatusParam == "SETTLED" ||
        this.orderStatusParam == "ARCHIVED" ||
        this.orderStatusParam == "CANCELLED_PREREGISTERED" ||
        this.orderStatusParam == "CANCELLED_NEW" ||
        this.orderStatusParam == "CANCELLED_PLANNED" ||
        this.orderStatusParam == "CANCELLED_PENDING" ||
        this.orderStatusParam == "CANCELLED_ACCEPTED" ||
        this.orderStatusParam == "CANCELLED_WAITING" ||
        this.orderStatusParam == "CANCELLED_IN_PROGRESS" ||
        this.orderStatusParam == "CANCELLED_FINISHED" ||
        this.orderStatusParam == "CANCELLED_CHARGED" ||
        this.orderStatusParam == "CANCELLED_SETTLED"
      ) {
        //NEzobrazenie Dialogoveho okna s zoznamom moznosti na zmenu objednavky
        this.disableAbortOrderDialog = true;
        this.disableUpdateSendingActivateDialog = true;
        this.disableUpdateSendingDeactivateDialog = true;
        this.disableUpdateToInstantDialog = true;
        this.disableUpdateRequestedTimeDialog = true;
        this.disableChangeToTimeUpdateRequestedTimeDialog = true;
        this.disableUpdatePresendTimeDialog = true;
        this.disableClearDriverDialog = true;
        this.disableUpdateNoteDialog = true;
        this.disableUpdatePaymentDialog = true;
        this.disableUpdateFilterDialog = true;
        this.disableUpdateSettingsDialog = true;
        this.editSettingsDialogForcedPickupRadiusField = false;
        this.editSettingsDialogOnlyManualSendingField = false;
        this.disableConfirmNewDialog = true;
      }
    },
    toastSnackbar(successType) {
      if (successType == true) {
        this.snackbar.message = this.$t("ConsoleOrderEdit.msgSuccess");
        this.snackbar.timeout = 2000;
        this.snackbar.color = "success";
      } else {
        this.snackbar.message = this.$t("ConsoleOrderEdit.msgError");
        this.snackbar.timeout = 4000;
        this.snackbar.color = "error";
      }
      this.snackbar.show = true;
    },
    toastSnackbarUnavailable() {
      this.snackbar.message = this.$t("ConsoleOrderEdit.msgUnavailable");
      this.snackbar.timeout = 4000;
      this.snackbar.color = "info";
      this.snackbar.show = true;
    },

    callUpdateApi(apiType) {
      var urlToApi = "";
      var updatePrice = false;
      //console.log(this.orderIdParam);
      //console.log(apiType);
      this.dataForUpdate = {};
      switch (apiType) {
        case "abortOrder":
          urlToApi = this.orderIdParam + `/abort`;
          /*this.dataForUpdate.paymentType = null;
          this.dataForUpdate.accountId = null;
          this.dataForUpdate.requestedTime = null;
          this.dataForUpdate.note = null;
          this.dataForUpdate.presendTime = null;*/
          this.dataForUpdate.abortedReason = this.abortInfo.abortedReason;
          this.dataForUpdate.abortNote = this.abortInfo.abortNote;
          break;
        case "confirmNewOrder":
          //TODO: este nie je naimplementovane api
          alert("Function not implemented yet");
          return;
          break;
        case "updateSendingActivate":
          urlToApi = this.orderIdParam + `/sendingActivate`;
          this.dataForUpdate = null;
          break;
        case "updateSendingDeactivate":
          urlToApi = this.orderIdParam + `/sendingDeactivate`;
          this.dataForUpdate = null;
          break;
        case "updateToInstant":
          urlToApi = this.orderIdParam + `/instant`;
          this.dataForUpdate = null;
          updatePrice = true;
          break;
        case "updateRequestedTime":
          urlToApi = this.orderIdParam + `/requestedTime`;
          //najprv vynulovat premennu, moze obsahova udaje z predoslych
          /*this.dataForUpdate.paymentType = "";
          this.dataForUpdate.accountId = null;
          this.dataForUpdate.presendTime = null;
          this.dataForUpdate.note = null;*/
          this.dataForUpdate.requestedTime = this.orderDetailData.requestedTime;
          /*this.dataForUpdate.abortedReason = null;
          this.dataForUpdate.abortNote = null;*/
          //doplniť default presendTime - nacitavat z globalnej premennej
          this.dataForUpdate.presendTime = this.defaultPresendTime; //Zatial natvrdo, kym bude chodit premenna
          //current datetime
          var currentDateTime = new Date();
          //check if requestedTime is in the past
          if (this.dataForUpdate.requestedTime < currentDateTime) {
            this.snackbar.message = this.$t(
              "ConsoleOrderEdit.msgRequestedTimePastError"
            );
            this.snackbar.timeout = 4000;
            this.snackbar.show = true;
            this.snackbar.color = "error";
            return;
          }
          //check if requestedTime is earlier than next 2 weeks
          var twoWeeksFromNow = new Date();
          twoWeeksFromNow.setDate(twoWeeksFromNow.getDate() + 14);
          if (this.dataForUpdate.requestedTime > twoWeeksFromNow) {
            this.snackbar.message = this.$t(
              "ConsoleOrderEdit.msgRequestedTimeFarFutureError"
            );
            this.snackbar.timeout = 4000;
            this.snackbar.show = true;
            this.snackbar.color = "error";
            return;
          }
          this.updateRequestedTimeDialog = false;
          updatePrice = true;
          break;
        case "updatePresendTime":
          urlToApi = this.orderIdParam + `/presendTime`;
          //najprv vynulovat premennu, moze obsahova udaje z predoslych
          /*this.dataForUpdate.paymentType = null;
          this.dataForUpdate.accountId = null;
          this.dataForUpdate.requestedTime = null;
          this.dataForUpdate.note = null;*/
          this.dataForUpdate.presendTime = this.orderDetailData.presendTime;
          /*this.dataForUpdate.abortedReason = null;
          this.dataForUpdate.abortNote = null;*/
          if (this.orderDetailData.presendTime == null) {
            this.snackbar.message = this.$t(
              "ConsoleOrderEdit.msgPresendTimeNullError"
            );
            this.snackbar.timeout = 4000;
            this.snackbar.show = true;
            this.snackbar.color = "error";
            return;
          }
          //check if presendTime is not less than 1 min
          if (this.orderDetailData.presendTime < 1) {
            this.snackbar.message = this.$t(
              "ConsoleOrderEdit.rulesPresendTime1"
            );
            this.snackbar.timeout = 4000;
            this.snackbar.show = true;
            this.snackbar.color = "error";
            return;
          }
          //not more than 240 min
          if (this.orderDetailData.presendTime > 240) {
            this.snackbar.message = this.$t(
              "ConsoleOrderEdit.rulesPresendTime2"
            );
            this.snackbar.timeout = 4000;
            this.snackbar.show = true;
            this.snackbar.color = "error";
            return;
          }
          break;
        case "clearDriver":
          urlToApi = this.orderIdParam + `/clearDriver`;
          this.dataForUpdate = null;
          break;
        case "updateNote":
          urlToApi = this.orderIdParam + `/note`;
          //najprv vynulovat premennu, moze obsahova udaje z predoslych
          /*this.dataForUpdate.paymentType = null;
          this.dataForUpdate.accountId = null;
          this.dataForUpdate.requestedTime = null;
          this.dataForUpdate.presendTime = null;*/
          this.dataForUpdate.note = this.orderDetailData.note;
          /*this.dataForUpdate.abortedReason = null;
          this.dataForUpdate.abortNote = null;*/
          //console.log("dataForUpdate: ", this.dataForUpdate);
          //console.log("orderDetailData.note: ", this.orderDetailData.note);
          break;
        case "updatePayment":
          urlToApi = this.orderIdParam + `/account`;
          this.dataForUpdate.paymentType = this.orderDetailData.paymentType;
          this.dataForUpdate.accountId = this.orderDetailData.accountId;
          /*this.dataForUpdate.requestedTime = null;
          this.dataForUpdate.presendTime = null;
          this.dataForUpdate.note = null;
          this.dataForUpdate.abortedReason = null;
          this.dataForUpdate.abortNote = null;*/
          //console.log("dataForUpdate: ", this.dataForUpdate);
          //console.log("orderDetailData.note: ", this.orderDetailData.note);
          updatePrice = true;
          break;
        case "updateFilter":
          urlToApi = this.orderIdParam + `/filter`;
          this.dataForUpdate = this.orderDetailData.filter;
          updatePrice = true;
          break;
        case "updateSettings":
          urlToApi = this.orderIdParam + `/settings`;
          this.dataForUpdate = this.orderDetailData.settings;
          /*urlToApi = `settings`;
          this.dataForUpdate = this.orderDetailData.settings;
          this.dataForUpdate.id = this.orderIdParam;*/
          //validate updateSettingsForm form
          if (this.$refs.updateSettingsForm.validate()) {
            this.updateSettingsDialog = false;
          } else {
            //console.log("updateSettingsForm is not valid");
            //snackbar
            this.snackbar.message = this.$t(
              "ConsoleOrderEdit.msgInvalidDataInForm"
            );
            this.snackbar.timeout = 4000;
            this.snackbar.show = true;
            this.snackbar.color = "error";
            return;
          }
          break;
        default:
          urlToApi = ``;
          break;
      }
      axios
        .put(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/order/` + urlToApi,
          this.dataForUpdate
        )
        .then((response) => {
          //console.log("Orderedit.vue Log " + apiType + " / " + this.orderIdParam + " / " + JSON.stringify(response));
          //this.animateProgressBar("ok"), (this.items = response.data);
          if (response.data.status == "OK") {
            //this.sendOrderData = response.data.data;
            // getClientByPhone();
            this.createClientDialog = false;
            this.toastSnackbar(true);
            if (updatePrice) {
              this.callPriceUpdateApi();
            }
          } else if (response.data.status == "FAILED") {
            //this.createClientDialog = true;
            this.toastSnackbar(false);
          } else {
            //this.createClientDialog = true;
            this.toastSnackbar(true);
            if (updatePrice) {
              this.callPriceUpdateApi();
            }
          }
          //delay to get updated data from api - aby sa stihla aktualizovat cena
          setTimeout(() => {
            this.$emit("showOrderDetailByIdFromEdit", this.orderIdParam);
          }, 1500);
        })
        .catch((e) => {
          //this.animateProgressBar("error"), this.errors.push(e);
          this.toastSnackbar(false);
          //console.error(e);
          //if array then stringtify response.data and log to console
          console.error(
            "Orderedit.vue Error " +
              apiType +
              " / " +
              this.orderIdParam +
              " / " +
              JSON.stringify(e)
          );
        });
      //console.log("OrderEmit.vue: ", this.orderIdParam);
    },
    //PUT api to request price change
    callPriceUpdateApi() {
      var urlToApi = this.orderIdParam + `/calculatePrice`;
      axios
        .put(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/order/` + urlToApi
        )
        .then((response) => {
          this.snackbar.message = this.$t("ConsoleOrderEdit.msgPriceUpdated");
          this.snackbar.timeout = 2000;
          this.snackbar.show = true;
          this.snackbar.color = "success";
        })
        .catch((error) => {
          this.snackbar.message = this.$t(
            "ConsoleOrderEdit.msgPriceNotUpdated"
          );
          this.snackbar.timeout = 4000;
          this.snackbar.show = true;
          this.snackbar.color = "error";
          console.error(
            "Orderedit.vue Error " +
              "callPriceUpdateApi" +
              " / " +
              this.orderIdParam +
              " / " +
              JSON.stringify(error)
          );
        });
    },

    setPaymentOption() {
      //Pre nefiremnu objednavku neposielat ID firmy
      if (this.orderDetailData.paymentType == "POSTPAID") {
        this.isDisabledAccountId = false;
      } else {
        this.isDisabledAccountId = true;
        this.orderDetailData.accountId = null;
      }
    },
    filterOnlyInteger: function (evt) {
      console.log("filterOnlyInteger", evt);
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getStatusColor(status) {
      return this.StatusColor[status];
    },
    //Nacitanie hodnoty z cookie
    getCookie(nameC) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      //console.log("getCookie - name: " + nameC);
      // Split cookie string and get all individual name=value pairs in an array
      var cookieArr = document.cookie.split(";");
      // Loop through the array elements
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        /* Removing whitespace at the beginning of the cookie name and compare it with the given string */
        if (nameC == cookiePair[0].trim()) {
          // Decode the cookie value and return
          //console.log("getCookieValue decode: " + decodeURIComponent(cookiePair[1]));
          return decodeURIComponent(cookiePair[1]);
        }
      }
      // Return null if not found
      return null;
    },
    animateProgressBar(progressStatus) {
      if (progressStatus == "start") {
        this.loading = true;
        this.loadingIndeterminate = false;
      } else if (progressStatus == "ok") {
        this.loading = false;
        this.loadingIndeterminate = false;
      } else if (progressStatus == "error") {
        //v pripade chyby sa prepne do rezimu animovaneho loadingu, kym sa neuskotocni uspesny pokus o ziskanie udajov
        this.loadingIndeterminate = true;
      }
    },
  },
};
</script>
