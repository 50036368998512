export const clientMark = Object.freeze({
    NEW: "NEW", 	// Nový zákazník (2)
    REGULAR: "REGULAR", 	// Stály zákazník (2)
    PRIORITY: "PRIORITY", 	// Prioritný zákazník (3) 
    FAMOUS: "FAMOUS", 	// Známa osobnosť (4)
    VIP: "VIP", 	// VIP klient (5)
    COLLEAGUE: "COLLEAGUE", 	// Kolega z taxislužby (6)
    STUDENT: "STUDENT", 	// Študent (7)
    CHILD: "CHILD", 	// Dieťa (8)
    JUNIOR: "JUNIOR", 	// Junior (9)
    SENIOR: "SENIOR", 	// Senior (10)
    DEBTOR: "DEBTOR", 	// Senior/dlžník (12)
    BLOCKED: "BLOCKED", 	// Blokovaný (11)
});

export const clientNotification = Object.freeze({
    NONE: "NONE", 	// Žiadne (2)
    PUSH: "PUSH", 	// Push správy (1)
    SMS: "SMS", 	// SMS (2)
    EMAIL: "EMAIL", 	// Email (3)
    CALL: "CALL", 	// Telefonická komunikácia (4)
    CHAT: "CHAT", 	// Chat (5)
    //VIBER: "VIBER", 	// Viber (6)
    //WHATSAPP: "WHATSAPP", 	// WhatsApp (7)
});

export const messageTypeColor = Object.freeze({
    SYSTEM: "red darken-4", 	// #B71C1C
    PUBLIC: "brown", 	// #5D4037
    DISP_DRIVER: "yellow darken-4", //#F57F17
    ORDER: "light-blue darken-4", 	// #E65100
    DISPATCHING: "green", //#4CAF50
    DIRECT: "blue-grey",
});

export const messageFromToTypeColor = Object.freeze({
    SYSTEM: "red darken-4", 	// #B71C1C
    DRIVER: "yellow darken-4", //#F57F17
    CLIENT: "light-blue darken-4", 	// #E65100
    DISPATCHER: "green", //#4CAF50
});