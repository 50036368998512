<template>
  <v-list three-line dense class="py-0" :height="itemHeight">
    <v-list-item :class="getStatusColor(source.status)" dense class="pl-2 pr-1">
      <!--<template v-slot:default="{ active }">-->
      <template v-slot:default>
        <v-list-item-content class="black--text py-1">
          <v-list-item-title
            :class="
              source.status == 'PENDING'
                ? source.source == 'CUSTOMER_ANDROID'
                  ? 'pink lighten-2'
                  : source.source == 'CUSTOMER_IOS'
                  ? 'pink lighten-2'
                  : source.source == 'BRAND_ANDROID'
                  ? 'pink lighten-2'
                  : source.source == 'BRAND_IOS'
                  ? 'pink lighten-2'
                  : ''
                : ''
            "
            ><v-icon
              v-if="source.preferredDriver"
              :title="
                $t('ConsoleOrderListItem.preferredDriver') +
                ' ' +
                source.preferredDriver.firstName +
                ' ' +
                source.preferredDriver.lastName
              "
              color="red darken-4"
              dense
              small
              >mdi-account-heart</v-icon
            >
            {{ source.id.slice(0, 8) + " | " }}
            {{ source.createdAt | formatDateTimeMini }}
            {{
              " | " +
              $t("orderType." + source.type) +
              " | " +
              $t("orderStatus." + source.status)
            }}</v-list-item-title
          ><!--<v-divider dense></v-divider>-->
          <!-- Pozadovany cas vyzdvihnutia a Odosielanie vopred v minutach-->
          <v-list-item-subtitle
            :class="
              source.status == 'PENDING'
                ? 'purple darken-1 white--text pa-0 ma-0'
                : 'black--text pa-0 ma-0'
            "
            v-if="
              source.type == 'TIME' &&
              ['NEW', 'PLANNED', 'PENDING', 'TRANSFERRED'].includes(
                source.status
              )
            "
            :title="$t('ConsoleOrderListItem.requestedTime')"
          >
            <v-icon
              dense
              :color="source.status == 'PENDING' ? 'white' : 'red darken-4'"
              class="pa-0 ma-0"
              >mdi-clock-outline</v-icon
            >
            <!-- {{ new Date(new Date(source.requestedTime) - new Date().getTimezoneOffset() * 60000) -->
            <!-- new Date(source.requestedTime).toLocaleString("sk-SK") -->
            {{ source.requestedTime | formatDateShort }}
            {{ " | " + source.presendTime }}
            min.</v-list-item-subtitle
          >
          <!-- Vodič, ktory realizuje objednavku -->
          <v-list-item-subtitle
            v-if="source.vehicle"
            class="black--text text-truncate"
            style="max-width: 310px"
            ><v-icon dense color="black">mdi-taxi</v-icon
            >{{
              (source.driver
                ? (source.driver.firstName ? source.driver.firstName : "") +
                  (source.driver.lastName ? " " + source.driver.lastName : "")
                : "") + " | "
            }}
            {{
              (source.vehicle.internalName ? source.vehicle.internalName : "") +
              (source.vehicle.brand ? " " + source.vehicle.brand : "") +
              (source.vehicle.model ? " " + source.vehicle.model : "") +
              (source.color
                ? " " + $t("vehicleColor_short." + source.color)
                : "") +
              (source.vehicle.lpn ? " | " + source.vehicle.lpn : "")
            }}</v-list-item-subtitle
          >
          <!-- Poznamka k objednavke -->
          <v-list-item-subtitle
            v-if="source.note"
            class="d-inline-block text-truncate black--text pa-0 ma-0"
            style="max-width: 310px"
            ><v-icon dense color="black" class="pa-0 ma-0"
              >mdi-text-box-outline</v-icon
            >
            {{ source.note }}</v-list-item-subtitle
          >

          <!-- Zoznam: Adresy (vyzdvihnutie, prejazdove body, ciel) -->
          <v-list-item
            dense
            class="black--text pa-0 ma-0"
            justify="center"
            style="min-height: 0px"
            v-for="waypoint in source.waypointList"
            :key="waypoint.id"
          >
            <v-list-item-content
              v-if="waypoint.type == 'BOARD' || waypoint.type == 'DESTINATION'"
              class="pa-0 ma-0"
            >
              <v-list-item-title
                class="d-inline-block text-truncate black--text"
                style="max-width: 300px"
              >
                <v-icon
                  dense
                  small
                  color="black"
                  justify="center"
                  title="Show on map"
                  @click="
                    showPositionCoordinatesToMap(
                      waypoint.latitude,
                      waypoint.longitude
                    )
                  "
                  >{{
                    waypoint.type == "BOARD"
                      ? "fas fa-street-view"
                      : waypoint.type == "DESTINATION"
                      ? "fas fa-flag-checkered"
                      : waypoint.type == "TRANSIT"
                      ? "mdi-transit-connection-variant"
                      : "mdi-map-marker"
                  }}</v-icon
                >
                <span
                  v-if="
                    waypoint.type === 'DESTINATION' &&
                    source.waypointList.length > 2
                  "
                  style="color: #b71c1c"
                >
                  (+)
                </span>
                {{ waypoint.address }}</v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
        </v-list-item-content>
        <!-- Bocne ikony -->
        <v-list-item-action class="ml-0">
          <v-icon
            :color="
              source.status == 'PENDING'
                ? source.source == 'CUSTOMER_ANDROID'
                  ? 'pink lighten-1'
                  : source.source == 'CUSTOMER_IOS'
                  ? 'pink lighten-1'
                  : source.source == 'BRAND_ANDROID'
                  ? 'pink lighten-1'
                  : source.source == 'BRAND_IOS'
                  ? 'pink lighten-1'
                  : 'black'
                : 'black'
            "
            :title="$t('source_tooltip.' + source.source)"
            class="mx-auto pb-0"
          >
            {{
              source.source == "DISPATCH_TAXISERVICE"
                ? "mdi-face-man-outline"
                : source.source == "DISPATCH_TAXXIMO"
                ? "mdi-face-man-outline"
                : source.source == "DISPATCH_BROKER"
                ? "mdi-face-man-outline"
                : source.source == "DRIVER_ANDROID"
                ? "mdi-account-tie-outline"
                : source.source == "CUSTOMER_ANDROID"
                ? "mdi-human-greeting-variant"
                : source.source == "CUSTOMER_IOS"
                ? "mdi-human-greeting-variant"
                : source.source == "BRAND_ANDROID"
                ? "mdi-human-greeting-variant"
                : source.source == "BRAND_IOS"
                ? "mdi-human-greeting-variant"
                : source.source == "PBX"
                ? "mdi-phone-classic"
                : "mdi-alien-outline"
            }}</v-icon
          >
          <v-icon
            :color="source.type == 'TIME' ? 'red darken-4' : 'black'"
            :title="$t('orderType_tooltip.' + source.type)"
            class="mx-auto pb-0"
          >
            {{
              source.type == "INSTANT"
                ? "mdi-bell-circle-outline"
                : source.type == "TIME"
                ? "mdi-clock-outline"
                : source.type == "STREET"
                ? "mdi-nature-people"
                : source.type == "DIRECT"
                ? "mdi-directions"
                : source.type == "REPEATED"
                ? "mdi-calendar-refresh"
                : source.type == "AIRPORT"
                ? "mdi-airplane-clock"
                : "mdi-alien-outline"
            }}</v-icon
          >

          <v-btn
            icon
            @click="showOrderDetail(source.id)"
            :title="
              source.onlyManualSending == true
                ? $t('ConsoleOrderListItem.onlyManualSending_true')
                : $t('ConsoleOrderListItem.onlyManualSending_false')
            "
          >
            <v-icon
              :color="
                source.onlyManualSending == true ? 'red darken-4' : 'black'
              "
              class="py-0 my-0"
              >mdi-information</v-icon
            >
          </v-btn>
        </v-list-item-action>
      </template>
    </v-list-item>

    <v-divider></v-divider>
  </v-list>
</template>

<script>
import { StatusColor } from "../enums/OrderEnum";
//import * as moment from "moment/moment";
export default {
  name: "item",
  props: {
    source: {
      type: Object,
      default() {
        return {};
      },
    },
    itemHeight: {
      type: String,
      default: 10,
    },
  },
  data() {
    return {
      StatusColor,
    };
  },
  methods: {
    getStatusColor(status) {
      return this.StatusColor[status];
    },

    showOrderDetail(orderId) {
      this.$emit("showOrderDetailByIdFromItem", orderId);
      //this.$store.state.consoleOrderId = orderId;
    },
    showPositionCoordinatesToMap(latitude, longitude) {
      if (latitude && longitude) {
        var positionCoordinates = [];
        positionCoordinates.push({
          latitude: latitude,
          longitude: longitude,
        });
        this.$emit(
          "passFindPositionCoordinatesToMapFromItem",
          positionCoordinates
        );
      }
    },
  },
};
</script>
