<template>
  <div>
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>{{
        $t("VehicleDriverList.page_title")
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>mdi-car-connected</v-icon>
    </v-toolbar>
    <v-row class="my-1" dense align="center">
      <v-col cols="12" md="2">
        <v-btn small color="info" @click="getAll()">
          <v-icon left dark small> fa-redo-alt </v-icon>
          {{ $t("btnUpdate") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :loading="loadingData"
      :headers="gridHeaders"
      :items="gridItems"
      class="elevation-1"
      sort-by="lastName"
      :mobile-breakpoint="mobileBreakpointTable"
      dense
      :items-per-page="50"
      :footer-props="{
        itemsPerPageOptions: [15, 25, 50, 100, -1],
        showFirstLastPage: true,
      }"
      ><!-- Filtrovanie tabulky -->
      <template
        v-slot:[`body.prepend`]
        v-if="$vuetify.breakpoint.width > mobileBreakpointTable"
      >
        <tr>
          <td>
            <v-icon class="px-3">mdi-filter</v-icon>
          </td>
          <td>
            <v-text-field
              v-model="filter.internalName"
              type="text"
              clearable
              :label="$t('VehicleDriverList.filterLabel.internalName')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.lpn"
              type="text"
              clearable
              :label="$t('VehicleDriverList.filterLabel.lpn')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.status"
              type="text"
              clearable
              :label="$t('VehicleDriverList.filterLabel.status')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.brand"
              type="text"
              clearable
              :label="$t('VehicleDriverList.filterLabel.brand')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.model"
              type="text"
              clearable
              :label="$t('VehicleDriverList.filterLabel.model')"
            ></v-text-field>
          </td>
          <!-- <td>
            <v-text-field
              v-model="filter.driverInternalName"
              type="text"
              clearable
              :label="$t('VehicleDriverList.filterLabel.driverInternalName')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.driverFirstName"
              type="text"
              clearable
              :label="$t('VehicleDriverList.filterLabel.driverFirstName')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.driverLastName"
              type="text"
              clearable
              :label="$t('VehicleDriverList.filterLabel.driverLastName')"
            ></v-text-field>
          </td> -->
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </template>
      <template v-slot:[`body.prepend`] v-else>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.internalName"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('VehicleDriverList.filterLabel.internalName')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.lpn"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('VehicleDriverList.filterLabel.lpn')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.status"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('VehicleDriverList.filterLabel.status')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.brand"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('VehicleDriverList.filterLabel.brand')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.model"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('VehicleDriverList.filterLabel.model')"
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <!--  <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.driverInternalName"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('VehicleDriverList.filterLabel.driverInternalName')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.driverFirstName"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('VehicleDriverList.filterLabel.driverFirstName')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.driverLastName"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('VehicleDriverList.filterLabel.driverLastName')"
              dense
              hide-details
            ></v-text-field>
          </v-col> -->
        </v-row>
      </template>
      <template v-slot:[`item.validFrom`]="{ item }">
        <span>{{ $options.filters.formatDateLong(item.validFrom) }}</span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          small
          :color="getVehicleStatusColor(item.status)"
          class="px-1"
          dark
          label
        >
          {{ $t("VehicleEnums.Status." + item.status) }}
        </v-chip>
      </template>

      <template v-slot:[`item.active`]="{ item }">
        <v-simple-checkbox v-model="item.active" disabled></v-simple-checkbox>
      </template>

      <template v-slot:[`item.driverActive`]="{ item }">
        <v-chip
          small
          :color="getActiveColor(item.driverActive)"
          class="px-1"
          dark
          label
          v-show="item.driverActive != null"
        >
          {{ $t("activeTaxiserviceSelection." + item.driverActive) }}
        </v-chip>
      </template>

      <template v-slot:[`item.driverWorkStatus`]="{ item }">
        <v-chip
          small
          :color="getWorkStatusColor(item.driverWorkStatus)"
          class="px-1"
          dark
          label
          v-show="item.driverWorkStatus != null"
        >
          {{ $t("workStatus." + item.driverWorkStatus) }}
        </v-chip>
      </template>

      <template v-slot:[`item.lpn`]="{ item }">
        <span
          :style="{
            color:
              (!item.lastMaintenanceAt &&
                item.maintenanceIntervalDay > 0 &&
                item.maintenanceNotificationDay > 0) ||
              (!item.lastMaintenanceMileage &&
                item.maintenanceIntervalMileage > 0 &&
                item.maintenanceNotificationMileage > 0)
                ? 'red'
                : '',
          }"
          >{{ item.lpn }}</span
        >
      </template>

      <template v-slot:[`item.lastMaintenanceAt`]="{ item }">
        <span
          :style="{ color: notificationMaintenanceDay(item) ? 'red' : '' }"
          >{{ $options.filters.formatDateShort(item.lastMaintenanceAt) }}</span
        >
      </template>
      <template v-slot:[`item.maintenanceNotificationDay`]="{ item }">
        <span
          :style="{ color: notificationMaintenanceDay(item) ? 'red' : '' }"
          >{{ item.maintenanceNotificationDay }}</span
        >
      </template>
      <template v-slot:[`item.maintenanceIntervalDay`]="{ item }">
        <span
          :style="{ color: notificationMaintenanceDay(item) ? 'red' : '' }"
          >{{ item.maintenanceIntervalDay }}</span
        >
      </template>

      <template v-slot:[`item.lastMaintenanceMileage`]="{ item }">
        <span
          :style="{
            color: notificationMaintenanceMileage(item) ? 'red' : '',
          }"
          >{{ item.lastMaintenanceMileage }}</span
        >
      </template>
      <template v-slot:[`item.maintenanceNotificationMileage`]="{ item }">
        <span
          :style="{
            color: notificationMaintenanceMileage(item) ? 'red' : '',
          }"
          >{{ item.maintenanceNotificationMileage }}</span
        >
      </template>
      <template v-slot:[`item.maintenanceIntervalMileage`]="{ item }">
        <span
          :style="{
            color: notificationMaintenanceMileage(item) ? 'red' : '',
          }"
          >{{ item.maintenanceIntervalMileage }}</span
        >
      </template>
      <!--<template v-slot:[`item.silence`]="{ item }">
        <v-simple-checkbox v-model="item.silence" disabled> </v-simple-checkbox>
      </template>-->
      <template v-slot:[`item.driverSilence`]="{ item }">
        <v-icon
          v-if="item.driverSilence"
          color="red"
          :title="$t('Dashboard.silenceEnabledTitle')"
        >
          mdi-bell-off
        </v-icon>
        <v-icon
          v-else-if="!item.driverSilence"
          color="grey"
          :title="$t('Dashboard.silenceDisabledTitle')"
        >
          mdi-bell
        </v-icon>
        <v-icon
          v-else
          color="grey"
          :title="$t('Dashboard.silenceDisabledTitle')"
        >
          mdi-bell-outline
        </v-icon>
      </template>

      <template v-slot:no-data>
        <v-btn color="error" @click="getAll()"> {{ $t("btnReload") }}</v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon v-if="item.driverId > 0" small @click="showDetail(item.id)"
          >mdi-eye</v-icon
        >
        <v-icon v-else small @click="showDetail(item.id)" disabled
          >mdi-eye-off</v-icon
        >
        <v-icon
          small
          @click="getVehicleMaintenanceList(item.id)"
          :disabled="!module.vehicleMaintenance"
          >mdi-folder-wrench-outline</v-icon
        >
        <v-icon
          small
          @click="showNewVehicleMaintenance(item.id)"
          :disabled="!module.vehicleMaintenance"
          >mdi-car-wrench</v-icon
        >
        <v-icon
          small
          @click="showUpdateVehicleMaintenanceDialog(item.id)"
          :disabled="!module.vehicleMaintenance"
          >mdi-bell-cog-outline</v-icon
        >
      </template>
    </v-data-table>

    <!-- Dialog na detail vozidla a vodiča -->
    <v-dialog v-model="showVehicleDetailDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t("VehicleDriverList.dialogDetailTitle")
          }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItem.brand"
                  type="text"
                  :label="$t('VehicleDriverList.tableHeaders.brand')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItem.model"
                  type="text"
                  :label="$t('VehicleDriverList.tableHeaders.model')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItem.lpn"
                  type="text"
                  :label="$t('VehicleDriverList.tableHeaders.lpn')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItem.mileages"
                  type="text"
                  :label="$t('VehicleDriverList.tableHeaders.mileages')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItem.driverFirstName"
                  type="text"
                  :label="$t('VehicleDriverList.tableHeaders.driverFirstName')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItem.driverLastName"
                  type="text"
                  :label="$t('VehicleDriverList.tableHeaders.driverLastName')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItem.driverPhone"
                  type="text"
                  :label="$t('VehicleDriverList.tableHeaders.driverPhone')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showVehicleDetailDialog = false">
            {{ $t("btnClose") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog na zoznam maintenance daneho vozidla -->
    <v-dialog v-model="showVehicleMaintenanceListDialog" max-width="98%">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t("VehicleDriverList.dialogMaintenanceListTitle")
          }}</span>
        </v-card-title>

        <v-card-text>
          <v-data-table
            :headers="gridMaintenanceHeaders"
            :items="gridMaintenanceItems"
            sort-by.sync="updatedAt"
            sort-desc.sync="true"
            class="elevation-1"
            :items-per-page="5"
            :footer-props="{
              itemsPerPageOptions: [5, 15, 25, 50, 100, 250, -1],
              showFirstLastPage: true,
            }"
          >
            <!-- <template v-slot:[`item.maintenanceId`]="{ item }">
              <v-btn
                color="primary"
                text
                @click="showMaintenanceDetailDialog(item)"
              >
                {{ item.maintenanceId }}
              </v-btn>
            </template> -->
            <template v-slot:[`item.plannedAt`]="{ item }">
              <span>{{
                $options.filters.formatDateShort(item.plannedAt)
              }}</span>
            </template>
            <template v-slot:[`item.startedAt`]="{ item }">
              <span>{{
                $options.filters.formatDateShort(item.startedAt)
              }}</span>
            </template>
            <template v-slot:[`item.resolvedAt`]="{ item }">
              <span>{{
                $options.filters.formatDateShort(item.resolvedAt)
              }}</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                small
                :color="getMaintenanceStatusColor(item.status)"
                class="px-1"
                dark
                label
              >
                {{ $t("MaintenanceStatus." + item.status) }}
              </v-chip>
            </template>

            <template v-slot:[`item.type`]="{ item }">
              <v-chip
                small
                :color="getMaintenanceTypeColor(item.type)"
                class="px-1"
                dark
                label
              >
                {{ $t("MaintenanceType." + item.type) }}
              </v-chip>
            </template>

            <template v-slot:[`item.fluidCheck`]="{ item }">
              <v-simple-checkbox
                v-model="item.fluidCheck"
                disabled
                :title="$t('VehicleDriverList.vehicleMaintenance.fluidCheck')"
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.oilTopUp`]="{ item }">
              <v-simple-checkbox
                v-model="item.oilTopUp"
                disabled
                :title="$t('VehicleDriverList.vehicleMaintenance.oilTopUp')"
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.oilChange`]="{ item }">
              <v-simple-checkbox
                v-model="item.oilChange"
                disabled
                :title="$t('VehicleDriverList.vehicleMaintenance.oilChange')"
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.coolantChange`]="{ item }">
              <v-simple-checkbox
                v-model="item.coolantChange"
                disabled
                :title="
                  $t('VehicleDriverList.vehicleMaintenance.coolantChange')
                "
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.brakeFluidChange`]="{ item }">
              <v-simple-checkbox
                v-model="item.brakeFluidChange"
                disabled
                :title="
                  $t('VehicleDriverList.vehicleMaintenance.brakeFluidChange')
                "
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.oilFilterReplacement`]="{ item }">
              <v-simple-checkbox
                v-model="item.oilFilterReplacement"
                disabled
                :title="
                  $t(
                    'VehicleDriverList.vehicleMaintenance.oilFilterReplacement'
                  )
                "
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.airFilterReplacement`]="{ item }">
              <v-simple-checkbox
                v-model="item.airFilterReplacement"
                disabled
                :title="
                  $t(
                    'VehicleDriverList.vehicleMaintenance.airFilterReplacement'
                  )
                "
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.airConFilterReplacement`]="{ item }">
              <v-simple-checkbox
                v-model="item.airConFilterReplacement"
                disabled
                :title="
                  $t(
                    'VehicleDriverList.vehicleMaintenance.airConFilterReplacement'
                  )
                "
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.sparkOrGlowPlugReplacement`]="{ item }">
              <v-simple-checkbox
                v-model="item.sparkOrGlowPlugReplacement"
                disabled
                :title="
                  $t(
                    'VehicleDriverList.vehicleMaintenance.sparkOrGlowPlugReplacement'
                  )
                "
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.tireReplacement`]="{ item }">
              <v-simple-checkbox
                v-model="item.tireReplacement"
                disabled
                :title="
                  $t('VehicleDriverList.vehicleMaintenance.tireReplacement')
                "
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.drivetrainCheck`]="{ item }">
              <v-simple-checkbox
                v-model="item.drivetrainCheck"
                disabled
                :title="
                  $t('VehicleDriverList.vehicleMaintenance.drivetrainCheck')
                "
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.serpentineBeltReplacement`]="{ item }">
              <v-simple-checkbox
                v-model="item.serpentineBeltReplacement"
                disabled
                :title="
                  $t(
                    'VehicleDriverList.vehicleMaintenance.serpentineBeltReplacement'
                  )
                "
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.otherFilterReplacement`]="{ item }">
              <v-simple-checkbox
                v-model="item.otherFilterReplacement"
                disabled
                :title="
                  $t(
                    'VehicleDriverList.vehicleMaintenance.otherFilterReplacement'
                  )
                "
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.otherFluidTopUp`]="{ item }">
              <v-simple-checkbox
                v-model="item.otherFluidTopUp"
                disabled
                :title="
                  $t('VehicleDriverList.vehicleMaintenance.otherFluidTopUp')
                "
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.lightRepair`]="{ item }">
              <v-simple-checkbox
                v-model="item.lightRepair"
                disabled
                :title="$t('VehicleDriverList.vehicleMaintenance.lightRepair')"
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.brakeRepair`]="{ item }">
              <v-simple-checkbox
                v-model="item.brakeRepair"
                disabled
                :title="$t('VehicleDriverList.vehicleMaintenance.brakeRepair')"
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.bodyRepair`]="{ item }">
              <v-simple-checkbox
                v-model="item.bodyRepair"
                disabled
                :title="$t('VehicleDriverList.vehicleMaintenance.bodyRepair')"
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.engineRepair`]="{ item }">
              <v-simple-checkbox
                v-model="item.engineRepair"
                disabled
                :title="$t('VehicleDriverList.vehicleMaintenance.engineRepair')"
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.electronicsRepair`]="{ item }">
              <v-simple-checkbox
                v-model="item.electronicsRepair"
                disabled
                :title="
                  $t('VehicleDriverList.vehicleMaintenance.electronicsRepair')
                "
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.drivetrainRepair`]="{ item }">
              <v-simple-checkbox
                v-model="item.drivetrainRepair"
                disabled
                :title="
                  $t('VehicleDriverList.vehicleMaintenance.drivetrainRepair')
                "
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.batteryReplacement`]="{ item }">
              <v-simple-checkbox
                v-model="item.batteryReplacement"
                disabled
                :title="
                  $t('VehicleDriverList.vehicleMaintenance.batteryReplacement')
                "
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.otherRepair`]="{ item }">
              <v-simple-checkbox
                v-model="item.otherRepair"
                disabled
                :title="$t('VehicleDriverList.vehicleMaintenance.otherRepair')"
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.cleaning`]="{ item }">
              <v-simple-checkbox
                v-model="item.cleaning"
                disabled
                :title="$t('VehicleDriverList.vehicleMaintenance.cleaning')"
              ></v-simple-checkbox>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="showVehicleMaintenanceListDialog = false"
          >
            {{ $t("btnClose") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog na novy maintenance zaznam -->
    <v-dialog v-model="showNewVehicleMaintenanceDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t("VehicleDriverList.dialogCreateMaintenanceTitle")
          }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" v-show="displayOnlyForAdmin">
                <v-text-field
                  v-model="createMaintenanceData.vehicleId"
                  type="text"
                  :label="$t('VehicleDriverList.vehicleMaintenance.vehicleId')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItemMaintenance.brand"
                  type="text"
                  :label="$t('VehicleDriverList.vehicleMaintenance.brand')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItemMaintenance.model"
                  type="text"
                  :label="$t('VehicleDriverList.vehicleMaintenance.model')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItemMaintenance.lpn"
                  type="text"
                  :label="$t('VehicleDriverList.vehicleMaintenance.lpn')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItemMaintenance.mileages"
                  type="text"
                  :label="$t('VehicleDriverList.vehicleMaintenance.mileages')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <my-datepicker
                  v-model="selectedItemMaintenance.lastMaintenanceAt"
                  :label="
                    $t('VehicleDriverList.vehicleMaintenance.lastMaintenanceAt')
                  "
                  dense
                  disabled
                  hide-details
                >
                </my-datepicker
              ></v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItemMaintenance.lastMaintenanceMileage"
                  type="text"
                  :label="
                    $t(
                      'VehicleDriverList.vehicleMaintenance.lastMaintenanceMileage'
                    )
                  "
                  readonly
                  dense
                  hide-details
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItemMaintenance.maintenanceIntervalDay"
                  type="text"
                  :label="
                    $t(
                      'VehicleDriverList.vehicleMaintenance.maintenanceIntervalDay'
                    )
                  "
                  readonly
                  dense
                  hide-details
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItemMaintenance.maintenanceIntervalMileage"
                  type="text"
                  :label="
                    $t(
                      'VehicleDriverList.vehicleMaintenance.maintenanceIntervalMileage'
                    )
                  "
                  readonly
                  dense
                  hide-details
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItemMaintenance.maintenanceNotificationDay"
                  type="text"
                  :label="
                    $t(
                      'VehicleDriverList.vehicleMaintenance.maintenanceNotificationDay'
                    )
                  "
                  readonly
                  dense
                  hide-details
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="
                    selectedItemMaintenance.maintenanceNotificationMileage
                  "
                  type="text"
                  :label="
                    $t(
                      'VehicleDriverList.vehicleMaintenance.maintenanceNotificationMileage'
                    )
                  "
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-divider class="pt-4 pb-2 mt-4 mb-2"></v-divider>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="createMaintenanceData.title"
                  type="text"
                  :label="$t('VehicleDriverList.vehicleMaintenance.title')"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="createMaintenanceData.type"
                  :label="$t('VehicleDriverList.vehicleMaintenance.type')"
                  dense
                  hide-details
                  :items="$t('MaintenanceTypeArray')"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="createMaintenanceData.status"
                  :label="$t('VehicleDriverList.vehicleMaintenance.status')"
                  dense
                  hide-details
                  :items="$t('MaintenanceStatusArray')"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="createMaintenanceData.price"
                  type="number"
                  :label="$t('VehicleDriverList.vehicleMaintenance.price')"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <my-datepicker
                  v-model="createMaintenanceData.plannedAt"
                  :label="$t('VehicleDriverList.vehicleMaintenance.plannedAt')"
                  dense
                  :hideDetails="true"
                >
                </my-datepicker>
              </v-col>
              <v-col cols="12" sm="6">
                <my-datepicker
                  v-model="createMaintenanceData.startedAt"
                  :label="$t('VehicleDriverList.vehicleMaintenance.startedAt')"
                  dense
                  :hideDetails="true"
                  :showClear="true"
                >
                </my-datepicker>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="createMaintenanceData.startedMileage"
                  type="number"
                  :label="
                    $t('VehicleDriverList.vehicleMaintenance.startedMileage')
                  "
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <my-datepicker
                  v-model="createMaintenanceData.resolvedAt"
                  :label="$t('VehicleDriverList.vehicleMaintenance.resolvedAt')"
                  dense
                  :hideDetails="true"
                  :showClear="true"
                >
                </my-datepicker>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="createMaintenanceData.resolvedMileage"
                  type="number"
                  :label="
                    $t('VehicleDriverList.vehicleMaintenance.resolvedMileage')
                  "
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  @click="setCreateVehicleMaintenanceDataMileage()"
                  ><v-icon left> mdi-car-arrow-right </v-icon>
                  {{
                    $t(
                      "VehicleDriverList.vehicleMaintenance.btnSetUpdateMaintenanceMileageAtFromVehicle"
                    )
                  }}
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  @click="setCreateVehicleMaintenanceDataDatesNow()"
                  ><v-icon left> mdi-clock-time-eight-outline </v-icon>
                  {{
                    $t(
                      "VehicleDriverList.vehicleMaintenance.btnSetUpdateMaintenanceDatesAtToNow"
                    )
                  }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="createMaintenanceData.note"
                  type="text"
                  :label="$t('VehicleDriverList.vehicleMaintenance.note')"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="createMaintenanceData.fluidCheck"
                  :label="$t('VehicleDriverList.vehicleMaintenance.fluidCheck')"
                  dense
                  hide-details
                  prepend-icon="mdi-beaker-check"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="createMaintenanceData.oilTopUp"
                  :label="$t('VehicleDriverList.vehicleMaintenance.oilTopUp')"
                  dense
                  hide-details
                  prepend-icon="mdi-oil-level"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="createMaintenanceData.oilChange"
                  :label="$t('VehicleDriverList.vehicleMaintenance.oilChange')"
                  dense
                  hide-details
                  prepend-icon="mdi-oil"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="createMaintenanceData.coolantChange"
                  :label="
                    $t('VehicleDriverList.vehicleMaintenance.coolantChange')
                  "
                  dense
                  hide-details
                  prepend-icon="mdi-coolant-temperature"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="createMaintenanceData.brakeFluidChange"
                  :label="
                    $t('VehicleDriverList.vehicleMaintenance.brakeFluidChange')
                  "
                  dense
                  hide-details
                  prepend-icon="mdi-car-brake-parking"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="createMaintenanceData.oilFilterReplacement"
                  :label="
                    $t(
                      'VehicleDriverList.vehicleMaintenance.oilFilterReplacement'
                    )
                  "
                  dense
                  hide-details
                  prepend-icon="mdi-barrel-outline"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="createMaintenanceData.airFilterReplacement"
                  :label="
                    $t(
                      'VehicleDriverList.vehicleMaintenance.airFilterReplacement'
                    )
                  "
                  dense
                  hide-details
                  prepend-icon="mdi-air-filter"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="createMaintenanceData.airConFilterReplacement"
                  :label="
                    $t(
                      'VehicleDriverList.vehicleMaintenance.airConFilterReplacement'
                    )
                  "
                  dense
                  hide-details
                  prepend-icon="mdi-air-conditioner"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="createMaintenanceData.sparkOrGlowPlugReplacement"
                  :label="
                    $t(
                      'VehicleDriverList.vehicleMaintenance.sparkOrGlowPlugReplacement'
                    )
                  "
                  dense
                  hide-details
                  prepend-icon="mdi-white-balance-incandescent"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="createMaintenanceData.tireReplacement"
                  :label="
                    $t('VehicleDriverList.vehicleMaintenance.tireReplacement')
                  "
                  dense
                  hide-details
                  prepend-icon="mdi-car-tire-alert"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="createMaintenanceData.drivetrainCheck"
                  :label="
                    $t('VehicleDriverList.vehicleMaintenance.drivetrainCheck')
                  "
                  dense
                  hide-details
                  prepend-icon="mdi-source-branch-check"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="createMaintenanceData.serpentineBeltReplacement"
                  :label="
                    $t(
                      'VehicleDriverList.vehicleMaintenance.serpentineBeltReplacement'
                    )
                  "
                  dense
                  hide-details
                  prepend-icon="mdi-ferris-wheel"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="createMaintenanceData.otherFilterReplacement"
                  :label="
                    $t(
                      'VehicleDriverList.vehicleMaintenance.otherFilterReplacement'
                    )
                  "
                  dense
                  hide-details
                  prepend-icon="mdi-image-filter-black-white"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="createMaintenanceData.otherFluidTopUp"
                  :label="
                    $t('VehicleDriverList.vehicleMaintenance.otherFluidTopUp')
                  "
                  dense
                  hide-details
                  prepend-icon="mdi-water"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="createMaintenanceData.lightRepair"
                  :label="
                    $t('VehicleDriverList.vehicleMaintenance.lightRepair')
                  "
                  dense
                  hide-details
                  prepend-icon="mdi-lightbulb-on-outline"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="createMaintenanceData.brakeRepair"
                  :label="
                    $t('VehicleDriverList.vehicleMaintenance.brakeRepair')
                  "
                  dense
                  hide-details
                  prepend-icon="mdi-car-brake-parking"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="createMaintenanceData.bodyRepair"
                  :label="$t('VehicleDriverList.vehicleMaintenance.bodyRepair')"
                  dense
                  hide-details
                  prepend-icon="mdi-car-hatchback"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="createMaintenanceData.engineRepair"
                  :label="
                    $t('VehicleDriverList.vehicleMaintenance.engineRepair')
                  "
                  dense
                  hide-details
                  prepend-icon="mdi-engine"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="createMaintenanceData.electronicsRepair"
                  :label="
                    $t('VehicleDriverList.vehicleMaintenance.electronicsRepair')
                  "
                  dense
                  hide-details
                  prepend-icon="mdi-electric-switch"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="createMaintenanceData.drivetrainRepair"
                  :label="
                    $t('VehicleDriverList.vehicleMaintenance.drivetrainRepair')
                  "
                  dense
                  hide-details
                  prepend-icon="mdi-source-branch-remove"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="createMaintenanceData.batteryReplacement"
                  :label="
                    $t(
                      'VehicleDriverList.vehicleMaintenance.batteryReplacement'
                    )
                  "
                  dense
                  hide-details
                  prepend-icon="mdi-battery-charging-90"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="createMaintenanceData.otherRepair"
                  :label="
                    $t('VehicleDriverList.vehicleMaintenance.otherRepair')
                  "
                  dense
                  hide-details
                  prepend-icon="mdi-wrench"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="createMaintenanceData.cleaning"
                  :label="$t('VehicleDriverList.vehicleMaintenance.cleaning')"
                  dense
                  hide-details
                  prepend-icon="mdi-car-wash"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="showNewVehicleMaintenanceDialog = false"
          >
            {{ $t("btnClose") }}
          </v-btn>
          <v-btn
            color="success"
            text
            @click.stop="submitCreateVehicleMaintenanceDialog()"
            >{{ $t("btnCreate") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog na aktualizaciu maintenance casu a kilometrov vozidla -->
    <v-dialog v-model="updateLastVehicleMaintenanceDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t("VehicleDriverList.dialogUpdateLastMaintenanceTitle")
          }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" v-show="displayOnlyForAdmin">
                <v-text-field
                  v-model="updateMaintenanceData.id"
                  type="text"
                  :label="$t('VehicleDriverList.vehicleMaintenance.vehicleId')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItemMaintenance.brand"
                  type="text"
                  :label="$t('VehicleDriverList.vehicleMaintenance.brand')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItemMaintenance.model"
                  type="text"
                  :label="$t('VehicleDriverList.vehicleMaintenance.model')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItemMaintenance.lpn"
                  type="text"
                  :label="$t('VehicleDriverList.vehicleMaintenance.lpn')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItemMaintenance.mileages"
                  type="text"
                  :label="$t('VehicleDriverList.vehicleMaintenance.mileages')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-divider class="pt-4 pb-2 mt-4 mb-2"></v-divider>
            <v-row>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  @click="setLastMaintenanceAtLast(updateMaintenanceData.id)"
                >
                  {{
                    $t(
                      "VehicleDriverList.vehicleMaintenance.btnSetLastMaintenanceAtFromVehicle"
                    )
                  }}
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  @click="setLastMaintenanceAtNow(updateMaintenanceData.id)"
                >
                  {{
                    $t(
                      "VehicleDriverList.vehicleMaintenance.btnSetLastMaintenanceAtToNow"
                    )
                  }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <my-datepicker
                  v-model="updateMaintenanceData.lastMaintenanceAt"
                  :label="
                    $t('VehicleDriverList.vehicleMaintenance.lastMaintenanceAt')
                  "
                  dense
                  :hideDetails="true"
                >
                </my-datepicker>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="updateMaintenanceData.lastMaintenanceMileage"
                  type="number"
                  :label="
                    $t(
                      'VehicleDriverList.vehicleMaintenance.lastMaintenanceMileage'
                    )
                  "
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="updateLastVehicleMaintenanceDialog = false"
          >
            {{ $t("btnClose") }}
          </v-btn>
          <v-btn
            color="success"
            text
            @click.stop="submitUpdateLastVehicleMaintenanceDialog()"
            >{{ $t("btnUpdate") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";
import { moduleDefaultSettings } from "../enums/ModuleEnum";

export default {
  data() {
    return {
      displayOnlyForAdmin: false,
      moment: moment,
      //Nastavenia modulov
      module: moduleDefaultSettings,
      //Prepinanie zobrazenia tabulky pri malom rozliseni z dat v riadkoch na standardnu stlpcovu tabulku
      mobileBreakpointSwitch: false,
      mobileBreakpointTable: 600,

      gridHeaders: [
        {
          text: this.$t("VehicleDriverList.tableHeaders.actions"),
          value: "actions",
          sortable: false,
        },
        {
          text: this.$t("VehicleDriverList.tableHeaders.internalName"),
          value: "internalName",
          sortable: true,
          filter: (value) => {
            if (!this.filter.internalName) {
              return value === "" || value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.internalName.toLowerCase());
            }
          },
        },
        {
          text: this.$t("VehicleDriverList.tableHeaders.lpn"),
          value: "lpn",
          sortable: true,
          filter: (value) => {
            if (!this.filter.lpn) {
              return value === "" || value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.lpn.toLowerCase());
            }
          },
        },
        {
          text: this.$t("VehicleDriverList.tableHeaders.status"),
          value: "status",
          sortable: true,
          filter: (value) => {
            if (!this.filter.status) {
              return value === "" || value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.status.toLowerCase());
            }
          },
        },
        {
          text: this.$t("VehicleDriverList.tableHeaders.brand"),
          value: "brand",
          sortable: true,
          filter: (value) => {
            if (!this.filter.brand) {
              return value === "" || value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.brand.toLowerCase());
            }
          },
        },
        {
          text: this.$t("VehicleDriverList.tableHeaders.model"),
          value: "model",
          sortable: true,
          filter: (value) => {
            if (!this.filter.model) {
              return value === "" || value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.model.toLowerCase());
            }
          },
        },
        {
          text: this.$t("VehicleDriverList.tableHeaders.active"),
          value: "active",
          sortable: true,
        },
        {
          text: this.$t("VehicleDriverList.tableHeaders.mileages"),
          value: "mileages",
          sortable: true,
        },
        {
          text: this.$t("VehicleDriverList.tableHeaders.driverInternalName"),
          value: "driverInternalName",
          sortable: true,
          filter: (value) => {
            if (value === null) {
              return true;
            } else if (!this.filter.driverInternalName) {
              return value === "" || value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.driverInternalName.toLowerCase());
            }
          },
        },
        {
          text: this.$t("VehicleDriverList.tableHeaders.driverFirstName"),
          value: "driverFirstName",
          sortable: true,
          filter: (value) => {
            if (value === null) {
              return true;
            } else if (!this.filter.driverFirstName) {
              return value === "" || value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.driverFirstName.toLowerCase());
            }
          },
        },
        {
          text: this.$t("VehicleDriverList.tableHeaders.driverLastName"),
          value: "driverLastName",
          sortable: true,
          filter: (value) => {
            if (value === null) {
              return true;
            } else if (!this.filter.driverLastName) {
              return value === "" || value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.driverLastName.toLowerCase());
            }
          },
        },
        {
          text: this.$t("VehicleDriverList.tableHeaders.driverActive"),
          value: "driverActive",
        },
        {
          text: this.$t("VehicleDriverList.tableHeaders.driverWorkStatus"),
          value: "driverWorkStatus",
        },
        /*{
          text: this.$t("VehicleDriverList.tableHeaders.driverSilence"),
          value: "driverSilence",
        },
         {
          text: this.$t("VehicleDriverList.tableHeaders.driverPhone"),
          value: "driverPhone",
        }, */
        {
          text: this.$t("VehicleList.tableHeaders.lastMaintenanceAt"),
          value: "lastMaintenanceAt",
        },
        {
          text: this.$t("VehicleList.tableHeaders.lastMaintenanceMileage"),
          value: "lastMaintenanceMileage",
        },
        {
          text: this.$t("VehicleList.tableHeaders.maintenanceIntervalDay"),
          value: "maintenanceIntervalDay",
        },
        {
          text: this.$t("VehicleList.tableHeaders.maintenanceIntervalMileage"),
          value: "maintenanceIntervalMileage",
        },
        {
          text: this.$t("VehicleList.tableHeaders.maintenanceNotificationDay"),
          value: "maintenanceNotificationDay",
        },
        {
          text: this.$t(
            "VehicleList.tableHeaders.maintenanceNotificationMileage"
          ),
          value: "maintenanceNotificationMileage",
        },
      ],
      gridItems: [],
      gridMaintenanceItems: [],

      loadingData: false,

      //Zoznam vozidiel
      vehicleList: [],
      search: null,

      filter: {
        search: "",
        internalName: "",
        lpn: "",
        status: "",
        brand: "",
        model: "",
        driverInternalName: "",
        driverFirstName: "",
        driverLastName: "",
      },

      showVehicleDetailDialog: false,
      selectedItem: {
        brand: "",
        model: "",
        internalName: "",
        lpn: "",
        status: "",
        active: "",
        mileages: "",
        driverInternalName: "",
        driverFirstName: "",
        driverLastName: "",
        driverActive: "",
        driverWorkStatus: "",
        driverSilence: "",
        driverPhone: "",
      },

      showNewVehicleMaintenanceDialog: false,
      selectedItemMaintenance: {
        vehicleId: "",
        brand: "",
        model: "",
        internalName: "",
        lpn: "",
        status: "",
        active: "",
        mileages: "",
      },
      createMaintenanceData: {},
      createMaintenanceDataDefault: {
        vehicleId: "",
        plannedAt: null,
        startedAt: null,
        startedMileage: 0,
        resolvedAt: null,
        resolvedMileage: 0,
        title: "",
        type: "MAINTENANCE",
        status: "COMPLETED",
        note: "",
        price: 0,
        fluidCheck: false,
        oilTopUp: false,
        oilChange: false,
        coolantChange: false,
        brakeFluidChange: false,
        oilFilterReplacement: false,
        airFilterReplacement: false,
        airConFilterReplacement: false,
        sparkOrGlowPlugReplacement: false,
        tireReplacement: false,
        drivetrainCheck: false,
        serpentineBeltReplacement: false,
        otherFilterReplacement: false,
        otherFluidTopUp: false,
        lightRepair: false,
        brakeRepair: false,
        bodyRepair: false,
        engineRepair: false,
        electronicsRepair: false,
        drivetrainRepair: false,
        batteryReplacement: false,
        otherRepair: false,
        cleaning: false,
      },
      updateLastVehicleMaintenanceDialog: false,
      updateMaintenanceData: {
        id: null,
        lastMaintenanceAt: null,
        lastMaintenanceMileage: null,
      },

      showVehicleMaintenanceListDialog: false,
      gridMaintenanceHeaders: [
        {
          text: this.$t("VehicleDriverList.tableMaintenanceHeaders.title"),
          value: "title",
          sortable: false,
        },
        {
          text: this.$t("VehicleDriverList.tableMaintenanceHeaders.type"),
          value: "type",
          sortable: true,
        },
        {
          text: this.$t("VehicleDriverList.tableMaintenanceHeaders.status"),
          value: "status",
          sortable: true,
        },
        {
          text: this.$t("VehicleDriverList.tableMaintenanceHeaders.price"),
          value: "price",
          sortable: true,
        },
        {
          text: this.$t("VehicleDriverList.tableMaintenanceHeaders.plannedAt"),
          value: "plannedAt",
          sortable: true,
        },
        {
          text: this.$t("VehicleDriverList.tableMaintenanceHeaders.startedAt"),
          value: "startedAt",
          sortable: true,
        },
        {
          text: this.$t(
            "VehicleDriverList.tableMaintenanceHeaders.startedMileage"
          ),
          value: "startedMileage",
          sortable: true,
        },
        {
          text: this.$t("VehicleDriverList.tableMaintenanceHeaders.resolvedAt"),
          value: "resolvedAt",
          sortable: true,
        },
        {
          text: this.$t(
            "VehicleDriverList.tableMaintenanceHeaders.resolvedMileage"
          ),
          value: "resolvedMileage",
          sortable: true,
        },
        {
          text: this.$t("VehicleDriverList.tableMaintenanceHeaders.fluidCheck"),
          value: "fluidCheck",
          sortable: false,
        },
        {
          text: this.$t("VehicleDriverList.tableMaintenanceHeaders.oilTopUp"),
          value: "oilTopUp",
          sortable: false,
        },
        {
          text: this.$t("VehicleDriverList.tableMaintenanceHeaders.oilChange"),
          value: "oilChange",
          sortable: false,
        },
        {
          text: this.$t(
            "VehicleDriverList.tableMaintenanceHeaders.coolantChange"
          ),
          value: "coolantChange",
          sortable: false,
        },
        {
          text: this.$t(
            "VehicleDriverList.tableMaintenanceHeaders.brakeFluidChange"
          ),
          value: "brakeFluidChange",
          sortable: false,
        },
        {
          text: this.$t(
            "VehicleDriverList.tableMaintenanceHeaders.oilFilterReplacement"
          ),
          value: "oilFilterReplacement",
          sortable: false,
        },
        {
          text: this.$t(
            "VehicleDriverList.tableMaintenanceHeaders.airFilterReplacement"
          ),
          value: "airFilterReplacement",
          sortable: false,
        },
        {
          text: this.$t(
            "VehicleDriverList.tableMaintenanceHeaders.airConFilterReplacement"
          ),
          value: "airConFilterReplacement",
          sortable: false,
        },
        {
          text: this.$t(
            "VehicleDriverList.tableMaintenanceHeaders.sparkOrGlowPlugReplacement"
          ),
          value: "sparkOrGlowPlugReplacement",
          sortable: false,
        },
        {
          text: this.$t(
            "VehicleDriverList.tableMaintenanceHeaders.tireReplacement"
          ),
          value: "tireReplacement",
          sortable: false,
        },
        {
          text: this.$t(
            "VehicleDriverList.tableMaintenanceHeaders.drivetrainCheck"
          ),
          value: "drivetrainCheck",
          sortable: false,
        },
        {
          text: this.$t(
            "VehicleDriverList.tableMaintenanceHeaders.serpentineBeltReplacement"
          ),
          value: "serpentineBeltReplacement",
          sortable: false,
        },
        {
          text: this.$t(
            "VehicleDriverList.tableMaintenanceHeaders.otherFilterReplacement"
          ),
          value: "otherFilterReplacement",
          sortable: false,
        },
        {
          text: this.$t(
            "VehicleDriverList.tableMaintenanceHeaders.otherFluidTopUp"
          ),
          value: "otherFluidTopUp",
          sortable: false,
        },
        {
          text: this.$t(
            "VehicleDriverList.tableMaintenanceHeaders.lightRepair"
          ),
          value: "lightRepair",
          sortable: false,
        },
        {
          text: this.$t(
            "VehicleDriverList.tableMaintenanceHeaders.brakeRepair"
          ),
          value: "brakeRepair",
          sortable: false,
        },
        {
          text: this.$t("VehicleDriverList.tableMaintenanceHeaders.bodyRepair"),
          value: "bodyRepair",
          sortable: false,
        },
        {
          text: this.$t(
            "VehicleDriverList.tableMaintenanceHeaders.engineRepair"
          ),
          value: "engineRepair",
          sortable: false,
        },
        {
          text: this.$t(
            "VehicleDriverList.tableMaintenanceHeaders.drivetrainRepair"
          ),
          value: "drivetrainRepair",
          sortable: false,
        },
        {
          text: this.$t(
            "VehicleDriverList.tableMaintenanceHeaders.batteryReplacement"
          ),
          value: "batteryReplacement",
          sortable: false,
        },
        {
          text: this.$t(
            "VehicleDriverList.tableMaintenanceHeaders.otherRepair"
          ),
          value: "otherRepair",
          sortable: false,
        },
        {
          text: this.$t("VehicleDriverList.tableMaintenanceHeaders.cleaning"),
          value: "cleaning",
          sortable: false,
        },
        {
          text: this.$t("VehicleDriverList.tableMaintenanceHeaders.note"),
          value: "note",
          sortable: false,
        },
      ],

      //Snackbar
      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 4000,
      },
    };
  },

  created() {
    //Nastavenie modulov
    var modules = this.$store.state.module;
    if (modules !== null && modules !== undefined) {
      this.module = modules;
    }
  },

  mounted() {
    //Nacitanie role podla ktorej sa potom zobrazuju niektore extra polia alebo tlacidla
    this.getUserRole();
    this.getAll();
  },
  watch: {
    mobileBreakpointSwitch: function () {
      //ak sa zmeni mobileBreakpointSwitch, nadefinovat hodnotu pre mobileBreakpoint
      if (this.mobileBreakpointSwitch === true) {
        this.mobileBreakpointTable = 0;
      } else {
        this.mobileBreakpointTable = 600;
      }
      //console.log("mobileBreakpointTable", this.mobileBreakpointTable);
      this.setCookie("mobileBreakpointSwitch", this.mobileBreakpointSwitch, 30);
    },
  },

  methods: {
    getUserRole() {
      let enabledRoles = ["ADMIN", "SUPPORT"];
      let userRoles = this.$store.state.user.roleList;
      this.displayOnlyForAdmin =
        userRoles.some((a) => (enabledRoles.includes(a) ? true : false)) ===
        true;
    },
    getAll() {
      this.loadingData = true;
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/report/vehicle-driver`
        )

        .then((response) => {
          this.loadingData = false;
          var gridItems = response.data;
          //this.getAllDrivers(gridItems);
          this.gridItems = gridItems;
        })
        .catch((e) => {
          this.loadingData = false;
          this.snackbar.show = true;
          this.snackbar.message = this.$t("msgGetDataError");
          this.snackbar.color = "error";
        });
    },
    /* detail(id) {
      this.$router.push({ name: "pricelist-detail", params: { id: id } });
    },*/

    getAllDrivers(gridItems) {
      //this.loadingData = true;
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/driver?enabled=true`
        )
        .then((response) => {
          var driverList = response.data;
          gridItems.forEach((vehicle) => {
            var driver = driverList.filter(function (elem) {
              if (elem.vehicleId == vehicle.id) return elem;
            })[0];
            if (driver) {
              vehicle.driverId = driver.id;
              vehicle.driverInternalName = driver.internalName;
              vehicle.driverFirstName = driver.firstName;
              vehicle.driverLastName = driver.lastName;
              vehicle.driverActive = driver.active;
              vehicle.driverWorkStatus = driver.workStatus;
              vehicle.driverSilence = driver.silence;
              vehicle.driverPhone = driver.phone;
            } else {
              vehicle.driverId = 0;
              vehicle.driverInternalName = "";
              vehicle.driverFirstName = "";
              vehicle.driverLastName = "";
              vehicle.driverActive = "UNDEFINED";
              vehicle.driverWorkStatus = "UNDEFINED";
              vehicle.driverSilence = false;
              vehicle.driverPhone = "";
            }
          });
          this.gridItems = gridItems;
          //this.loadingData = false;
        })
        .catch((e) => {
          //this.loadingData = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgUpdateError");
          this.snackbar.color = "error";
        });
    },
    getVehicleMaintenanceList(vehicleId) {
      this.loadingData = true;
      var vehicle = "";
      if (vehicleId) {
        vehicle = "?vehicleId=" + vehicleId;
      }
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/vehicle-maintenance` +
            vehicle
        )

        .then((response) => {
          this.loadingData = false;
          var gridMaintenanceItems = response.data;
          this.gridMaintenanceItems = gridMaintenanceItems;
          this.showVehicleMaintenanceListDialog = true;
        })
        .catch((e) => {
          this.loadingData = false;
          this.snackbar.show = true;
          this.snackbar.message = this.$t("msgGetDataError");
          this.snackbar.color = "error";
        });
    },
    showDetail(id) {
      var selectedItem;
      //gridItems = this.gridItems;
      selectedItem = Object.assign(
        {},
        this.gridItems.filter(function (elem) {
          if (elem.id == id) return elem;
        })[0]
      );
      if (selectedItem) {
        //alert("selectedItem: " + JSON.stringify(selectedItem));
        this.selectedItem = selectedItem;
        this.showVehicleDetailDialog = true;
      }
    },
    showUpdateVehicleMaintenanceDialog(id) {
      var selectedItem;
      selectedItem = Object.assign(
        {},
        this.gridItems.filter(function (elem) {
          if (elem.id == id) return elem;
        })[0]
      );
      if (selectedItem) {
        this.selectedItemMaintenance = selectedItem;
        this.updateMaintenanceData.id = selectedItem.id;
        this.updateMaintenanceData.lastMaintenanceAt =
          selectedItem.lastMaintenanceAt;
        this.updateMaintenanceData.lastMaintenanceMileage =
          selectedItem.lastMaintenanceMileage;
        this.updateLastVehicleMaintenanceDialog = true;
      }
    },
    showNewVehicleMaintenance(id) {
      this.createMaintenanceData = Object.assign(
        {},
        this.createMaintenanceDataDefault
      );
      this.createMaintenanceData.resolvedAt = null;

      var selectedItem;
      selectedItem = Object.assign(
        {},
        this.gridItems.filter(function (elem) {
          if (elem.id == id) return elem;
        })[0]
      );
      if (selectedItem) {
        //alert("selectedItem: " + JSON.stringify(selectedItem));
        this.selectedItemMaintenance = selectedItem;
        this.createMaintenanceData.vehicleId = id;
        this.setCurrentTime();
        this.createMaintenanceData.title =
          selectedItem.brand +
          " " +
          selectedItem.model +
          " " +
          selectedItem.lpn;
        this.showNewVehicleMaintenanceDialog = true;
      }
    },
    setCurrentTime() {
      //iba plannedAt alebo to sa nastavuje vzdy, je to zakladny stav
      this.createMaintenanceData.plannedAt = new Date();
      this.createMaintenanceData.plannedAt.setSeconds(0);
      this.createMaintenanceData.plannedAt.setMilliseconds(0);
      /*this.createMaintenanceData.startedAt = new Date();
      this.createMaintenanceData.startedAt.setSeconds(0);
      this.createMaintenanceData.startedAt.setMilliseconds(0);
      this.createMaintenanceData.resolvedAt = new Date();
      this.createMaintenanceData.resolvedAt.setSeconds(0);
      this.createMaintenanceData.resolvedAt.setMilliseconds(0);*/
    },
    submitCreateVehicleMaintenanceDialog() {
      if (this.createMaintenanceData.status == "PLANNED") {
        if (
          this.createMaintenanceData.plannedAt == null ||
          this.createMaintenanceData.plannedAt == 0
        ) {
          alert("Zadajte čas plánovanej údržby!");
          return;
        }
      } else if (this.createMaintenanceData.status == "ONGOING") {
        if (
          this.createMaintenanceData.startedMileage == null ||
          this.createMaintenanceData.startedMileage == 0
        ) {
          alert("Zadajte hodnotu pre stav kilometrov na začiatku!");
          return;
        }
        if (
          this.createMaintenanceData.startedAt == null ||
          this.createMaintenanceData.startedAt == 0
        ) {
          alert("Zadajte čas začatia údržby!");
          return;
        }
      } else if (this.createMaintenanceData.status == "COMPLETED") {
        if (
          this.createMaintenanceData.resolvedMileage == null ||
          this.createMaintenanceData.resolvedMileage == 0
        ) {
          alert("Zadajte hodnotu pre stav kilometrov!");
          return;
        }
        if (
          this.createMaintenanceData.resolvedAt == null ||
          this.createMaintenanceData.resolvedAt == 0
        ) {
          alert("Zadajte čas ukončenia údržby!");
          return;
        }
      }
      this.loadingData = true;
      axios
        .post(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/vehicle-maintenance`,
          this.createMaintenanceData
        )
        .then((response) => {
          this.loadingData = false;
          this.snackbar.show = true;
          this.snackbar.message = this.$t("msgCreateSuccess");
          this.snackbar.color = "success";
          this.showNewVehicleMaintenanceDialog = false;
          if (this.createMaintenanceData.status == "COMPLETED") {
            this.updateMaintenanceData.id =
              this.createMaintenanceData.vehicleId;
            this.updateMaintenanceData.lastMaintenanceAt =
              this.createMaintenanceData.resolvedAt;
            this.updateMaintenanceData.lastMaintenanceMileage =
              this.createMaintenanceData.resolvedMileage;
            this.updateLastVehicleMaintenanceDialog = true;
          } else {
            this.getAll();
          }
        })
        .catch((e) => {
          this.loadingData = false;
          this.snackbar.show = true;
          this.snackbar.message = this.$t("msgCreateError");
          this.snackbar.color = "error";
        });
    },

    submitUpdateLastVehicleMaintenanceDialog() {
      axios
        .put(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/vehicle/maintenance`,
          this.updateMaintenanceData
        )
        .then((response) => {
          this.updateLastVehicleMaintenanceDialog = false;
          this.getAll();
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgUpdateError");
          this.snackbar.color = "error";
        });
    },

    setLastMaintenanceAtLast(id) {
      var selectedItem;
      selectedItem = Object.assign(
        {},
        this.gridItems.filter(function (elem) {
          if (elem.id == id) return elem;
        })[0]
      );
      if (selectedItem) {
        this.updateMaintenanceData.lastMaintenanceAt =
          selectedItem.lastMaintenanceAt;
        this.updateMaintenanceData.lastMaintenanceMileage =
          selectedItem.lastMaintenanceMileage;
      }
    },
    setLastMaintenanceAtNow(id) {
      var selectedItem;
      selectedItem = Object.assign(
        {},
        this.gridItems.filter(function (elem) {
          if (elem.id == id) return elem;
        })[0]
      );
      if (selectedItem) {
        this.updateMaintenanceData.lastMaintenanceAt = new Date();
        this.updateMaintenanceData.lastMaintenanceAt.setSeconds(0);
        this.updateMaintenanceData.lastMaintenanceAt.setMilliseconds(0);
        this.updateMaintenanceData.lastMaintenanceMileage =
          selectedItem.mileages;
      }
    },
    setCreateVehicleMaintenanceDataMileage() {
      if (this.selectedItemMaintenance) {
        if (this.createMaintenanceData.status == "PLANNED") {
          this.createMaintenanceData.startedMileage =
            this.selectedItemMaintenance.mileages;
        } else if (this.createMaintenanceData.status == "ONGOING") {
          this.createMaintenanceData.startedMileage =
            this.selectedItemMaintenance.mileages;
        } else if (this.createMaintenanceData.status == "COMPLETED") {
          if (
            this.selectedItemMaintenance.startedMileage == null ||
            this.selectedItemMaintenance.startedMileage == 0
          ) {
            this.createMaintenanceData.startedMileage =
              this.selectedItemMaintenance.mileages;
          }
          this.createMaintenanceData.resolvedMileage =
            this.selectedItemMaintenance.mileages;
        } else {
          alert("Pre tento stav doplňte údaje ručne.");
        }
      }
    },
    setCreateVehicleMaintenanceDataDatesNow() {
      if (this.createMaintenanceData.status == "PLANNED") {
        this.createMaintenanceData.plannedAt = new Date();
        this.createMaintenanceData.plannedAt.setSeconds(0);
        this.createMaintenanceData.plannedAt.setMilliseconds(0);
      } else if (this.createMaintenanceData.status == "ONGOING") {
        this.createMaintenanceData.plannedAt = new Date();
        this.createMaintenanceData.plannedAt.setSeconds(0);
        this.createMaintenanceData.plannedAt.setMilliseconds(0);
        this.createMaintenanceData.startedAt = new Date();
        this.createMaintenanceData.startedAt.setSeconds(0);
        this.createMaintenanceData.startedAt.setMilliseconds(0);
      } else if (this.createMaintenanceData.status == "COMPLETED") {
        this.createMaintenanceData.plannedAt = new Date();
        this.createMaintenanceData.plannedAt.setSeconds(0);
        this.createMaintenanceData.plannedAt.setMilliseconds(0);
        this.createMaintenanceData.startedAt = new Date();
        this.createMaintenanceData.startedAt.setSeconds(0);
        this.createMaintenanceData.startedAt.setMilliseconds(0);
        this.createMaintenanceData.resolvedAt = new Date();
        this.createMaintenanceData.resolvedAt.setSeconds(0);
        this.createMaintenanceData.resolvedAt.setMilliseconds(0);
      } else {
        alert("Pre tento stav doplňte údaje ručne.");
      }
    },

    notificationMaintenanceDay(item) {
      const now = new Date();
      const lastMaintenanceDate = new Date(item.lastMaintenanceAt);
      const maintenanceIntervalDay = item.maintenanceIntervalDay;
      const maintenanceNotificationDay = item.maintenanceNotificationDay;
      if (
        maintenanceIntervalDay == null ||
        maintenanceNotificationDay == null ||
        maintenanceIntervalDay == 0 ||
        maintenanceNotificationDay == 0
      ) {
        return false;
      }
      const nextMaintenanceDate = new Date(lastMaintenanceDate);
      nextMaintenanceDate.setDate(
        nextMaintenanceDate.getDate() + maintenanceIntervalDay
      );

      const notificationDate = new Date(nextMaintenanceDate);
      notificationDate.setDate(
        notificationDate.getDate() - maintenanceNotificationDay
      );

      return now > notificationDate;
    },
    notificationMaintenanceMileage(item) {
      const lastMaintenanceMileage = item.lastMaintenanceMileage;
      const maintenanceIntervalMileage = item.maintenanceIntervalMileage;
      const maintenanceNotificationMileage =
        item.maintenanceNotificationMileage;
      if (
        maintenanceIntervalMileage == null ||
        maintenanceNotificationMileage == null ||
        maintenanceIntervalMileage == 0 ||
        maintenanceNotificationMileage == 0
      ) {
        return false;
      }
      const nextMaintenanceMileage =
        lastMaintenanceMileage + maintenanceIntervalMileage;
      const notificationMileage =
        nextMaintenanceMileage - maintenanceNotificationMileage;

      return item.mileages > notificationMileage;
    },

    getVehicleStatusColor(status) {
      switch (status) {
        case "ACTIVE":
          return "green";
        case "DISABLED":
          return "red";
        default:
          return "black";
      }
    },
    getStatusColor(status) {
      switch (status) {
        case "ENABLED":
          return "green";
        case "DISABLED":
          return "red";
        default:
          return "black";
      }
    },
    getActiveColor(status) {
      switch (status) {
        case "ACTIVE":
          return "green";
        case "DISABLED":
          return "red";
        default:
          return "black";
      }
    },
    getWorkStatusColor(status) {
      switch (status) {
        case "ONLINE":
          return "green";
        case "OFFLINE":
          return "red";
        case "ON_BREAK":
          return "orange";
        default:
          return "black";
      }
    },
    getVehicleColor(color) {
      switch (color) {
        case "WHITE":
          return "#FFFFFF";
        case "BLACK":
          return "#000000";
        case "SILVER":
          return "#C0C0C0";
        case "GRAY":
          return "#696969";
        case "BROWN":
          return "#996633";
        case "PURPLE":
          return "#800080";
        case "VIOLET":
          return "#EE82EE";
        case "BLUE":
          return "#0000FF";
        case "DARKBLUE":
          return "#00008B";
        case "LIGHTBLUE":
          return "#ADD8E6";
        case "SKYBLUE":
          return "#87CEEB";
        case "RED":
          return "#FF0000";
        case "ORANGE":
          return "#ffa500";
        case "DARKORANGE":
          return "#FF8C00";
        case "YELLOW":
          return "#FFFF00";
        case "GREEN":
          return "#00FF00";
        case "DARKGREEN":
          return "#006400";
        case "LIGHTGREEN":
          return "#90EE90";
        case "PINK":
          return "#FF99CC";
        case "BEIGE":
          return "#F5F5DC";
        case "GOLD":
          return "#FFD700";
        default:
          return "#000000";
      }
    },
    getMaintenanceStatusColor(status) {
      switch (status) {
        case "PLANNED":
          return "purple";
        case "DELAYED":
          return "red";
        case "ONGOING":
          return "orange";
        case "PAUSED":
          return "orange";
        case "COMPLETED":
          return "green";
        case "CANCELLED":
          return "grey";
        case "UNFINISHED":
          return "red";
        default:
          return "black";
      }
    },
    getMaintenanceTypeColor(type) {
      switch (type) {
        case "REPAIR":
          return "red";
        case "MAINTENANCE":
          return "green";
        case "CHECKUP":
          return "orange";
        case "SHORT_TERM_INSPECTION":
          return "purple";
        case "LONG_TERM_INSPECTION":
          return "purple";
        case "SEASONAL_INSPECTION":
          return "purple";
        case "CLEANING":
          return "blue";
        default:
          return "black";
      }
    },
  },
};
</script>
