var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-toolbar',{attrs:{"color":"secondary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("Dashboard.page_title")))]),_c('v-spacer'),_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-monitor-dashboard")])],1),_c('v-container',{attrs:{"id":"dashboard-view","fluid":"","tag":"section"}},[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(
        (_vm.messagePayment &&
          (_vm.roleAdmin || _vm.roleSupport || _vm.roleOwner) &&
          _vm.timeRemaining < 604800000) ||
        (_vm.messagePayment &&
          (_vm.roleAdmin || _vm.roleSupport || _vm.roleOwner || _vm.roleManager) &&
          _vm.timeRemaining < 302400000) ||
        (_vm.messagePayment && _vm.timeRemaining < 86400000)
      ),expression:"\n        (messagePayment &&\n          (roleAdmin || roleSupport || roleOwner) &&\n          timeRemaining < 604800000) ||\n        (messagePayment &&\n          (roleAdmin || roleSupport || roleOwner || roleManager) &&\n          timeRemaining < 302400000) ||\n        (messagePayment && timeRemaining < 86400000)\n      "}]},[_c('v-col',[_c('v-card',{staticClass:"white--text",staticStyle:{"margin-top":"10px"},attrs:{"color":"purple","dark":""}},[_c('v-card-title',[_c('v-icon',{staticClass:"pa-2",attrs:{"large":""}},[_vm._v("mdi-cash-clock")]),_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formattedTime))])],1)],1)],1)],1),_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.messageList.length > 0),expression:"messageList.length > 0"}],attrs:{"cols":"12"}},[_c('dashboard-card-half',{attrs:{"color":"primary","icon":"mdi-bell-outline"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('small',[_vm._v(_vm._s(_vm.$t("Dashboard.cardMessageDispatch")))])]},proxy:true}])},[_c('v-card-text',[_c('v-list',{attrs:{"flat":"","subheader":"","three-line":""}},[_c('v-list-item-group',{model:{value:(_vm.selectedMessageItem),callback:function ($$v) {_vm.selectedMessageItem=$$v},expression:"selectedMessageItem"}},_vm._l((_vm.messageList),function(message){return _c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(
                    (message.type == 'PAYMENT' &&
                      (_vm.roleAdmin ||
                        _vm.roleSupport ||
                        _vm.roleOwner ||
                        _vm.roleManager)) ||
                    message.type != 'PAYMENT'
                  ),expression:"\n                    (message.type == 'PAYMENT' &&\n                      (roleAdmin ||\n                        roleSupport ||\n                        roleOwner ||\n                        roleManager)) ||\n                    message.type != 'PAYMENT'\n                  "}],key:message.title,attrs:{"link":""},on:{"click":function($event){_vm.messageDialog = true;
                    _vm.messageDialogTitle = message.title;
                    _vm.messageDialogText = message.message;
                    message.type == 'CONTRACT'
                      ? (_vm.messageDialogColor = 'blue')
                      : message.type == 'MAINTENANCE'
                      ? (_vm.messageDialogColor = 'orange')
                      : message.type == 'PAYMENT'
                      ? (_vm.messageDialogColor = 'purple')
                      : message.type == 'RELEASE'
                      ? (_vm.messageDialogColor = 'teal')
                      : message.type == 'SYSTEM_STATUS'
                      ? (_vm.messageDialogColor = 'red')
                      : message.type == 'OTHER '
                      ? (_vm.messageDialogColor = 'grey')
                      : (_vm.messageDialogColor = 'black');}}},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":message.type == 'CONTRACT'
                          ? 'blue'
                          : message.type == 'MAINTENANCE'
                          ? 'orange'
                          : message.type == 'PAYMENT'
                          ? 'purple'
                          : message.type == 'RELEASE'
                          ? 'teal'
                          : message.type == 'SYSTEM_STATUS'
                          ? 'red'
                          : message.type == 'OTHER '
                          ? 'grey'
                          : 'black',"dark":"","large":""}},[_vm._v(_vm._s(message.type == "CONTRACT" ? "mdi-file-sign" : message.type == "MAINTENANCE" ? "mdi-wrench-clock" : message.type == "PAYMENT" ? "mdi-cash-clock" : message.type == "RELEASE" ? "mdi-notebook-outline" : message.type == "SYSTEM_STATUS" ? "mdi-traffic-light-outline" : message.type == "OTHER " ? "mdi-help-circle-outline" : "mdi-help-circle-outline"))])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text--primary",class:message.validFrom
                          ? new Date() - new Date(message.validFrom) <
                            86400000
                            ? 'font-weight-bold'
                            : ''
                          : ''},[_vm._v(_vm._s(message.title))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(message.validFrom ? _vm.moment(message.validFrom).format("DD.MM.YY HH:mm") : message.createdAt ? _vm.moment(message.createdAt).format("DD.MM.YY HH:mm") : "-"))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.strip_tags(message.message)))])],1),_c('v-list-item-action',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.smAndDown),expression:"!$vuetify.breakpoint.smAndDown"}]},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.messageDialog = true;
                        _vm.messageDialogTitle = message.title;
                        _vm.messageDialogText = message.message;
                        message.type == 'CONTRACT'
                          ? (_vm.messageDialogColor = 'blue')
                          : message.type == 'MAINTENANCE'
                          ? (_vm.messageDialogColor = 'orange')
                          : message.type == 'PAYMENT'
                          ? (_vm.messageDialogColor = 'purple')
                          : message.type == 'RELEASE'
                          ? (_vm.messageDialogColor = 'teal')
                          : message.type == 'SYSTEM_STATUS'
                          ? (_vm.messageDialogColor = 'red')
                          : message.type == 'OTHER '
                          ? (_vm.messageDialogColor = 'grey')
                          : (_vm.messageDialogColor = 'black');}}},[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-dots-horizontal-circle-outline")])],1)],1)],1)}),1)],1)],1)],1)],1),_vm._l((_vm.dashCards),function({ actionIcon, actionText, ...attrs },i){return _c('v-col',{key:i,attrs:{"cols":"12","md":"6","lg":"3"}},[_c('dashboard-card-small',_vm._b({scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","title":actionText},on:{"click":function($event){_vm.messageDialog = true;
                _vm.messageDialogTitle = attrs.title;
                _vm.messageDialogText = actionText;
                _vm.messageDialogColor = attrs.color;}}},[_vm._v(" "+_vm._s(actionIcon)+" ")]),_c('div',{staticClass:"text-truncate",attrs:{"title":actionText}},[_vm._v(" "+_vm._s(actionText)+" ")])]},proxy:true}],null,true)},'dashboard-card-small',attrs,false))],1)})],2),_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.driverOnBreakItems.length > 0 &&
          _vm.dashboardSelectedItems.includes('driverOnBreakItems')
        ),expression:"\n          driverOnBreakItems.length > 0 &&\n          dashboardSelectedItems.includes('driverOnBreakItems')\n        "}],attrs:{"cols":"12","md":"6"}},[_c('dashboard-card-half',{attrs:{"color":"deep-purple","full-header":""},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"pa-8 white--text"},[_c('div',{staticClass:"text-h4 font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t("Dashboard.cardDdriverOnBreak"))+" ")]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t("Dashboard.cardDdriverOnBreakSubtitle"))+" ")])])]},proxy:true}])},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.driverOnBreakHeaders,"items":_vm.driverOnBreakItems,"items-per-page":5}})],1)],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.worklogRequestItems.length > 0 &&
          _vm.dashboardSelectedItems.includes('worklogRequestItems')
        ),expression:"\n          worklogRequestItems.length > 0 &&\n          dashboardSelectedItems.includes('worklogRequestItems')\n        "}],attrs:{"cols":"12","md":"6"}},[_c('dashboard-card-half',{attrs:{"color":"deep-purple","full-header":""},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"pa-8 white--text"},[_c('div',{staticClass:"text-h4 font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t("Dashboard.cardWorklogRequest"))+" ")]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t("Dashboard.cardWorklogRequestSubtitle"))+" ")])])]},proxy:true}])},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.worklogRequestHeaders,"items":_vm.worklogRequestItems,"items-per-page":5},scopedSlots:_vm._u([{key:`item.createdAt`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.createdAt ? _vm.moment(item.createdAt).format("DD.MM.YY HH:mm") : "-")+" ")]}},{key:`item.type`,fn:function({ item }){return [_c('v-chip',{attrs:{"title":_vm.$t('worklogRequestType.' + item.type),"color":item.type == 'OFFLINE'
                      ? 'blue'
                      : item.type == 'ONLINE'
                      ? 'green'
                      : item.type == 'ON_BREAK'
                      ? 'orange'
                      : 'grey',"dense":"","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(_vm.$t("worklogRequestType." + item.type))+" ")])]}},{key:`item.status`,fn:function({ item }){return [_c('v-chip',{attrs:{"title":_vm.$t('worklogRequestStatus.' + item.status),"color":item.status == 'NEW'
                      ? 'blue'
                      : item.status == 'CONFIRMED'
                      ? 'green'
                      : item.status == 'DECLINED'
                      ? 'red'
                      : item.status == 'DONE'
                      ? 'teal'
                      : item.status == 'CANCELLED'
                      ? 'grey'
                      : 'grey',"dense":"","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(_vm.$t("worklogRequestStatus." + item.status))+" ")])]}}],null,true)})],1)],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.driverOnlineItems.length > 0 &&
          _vm.dashboardSelectedItems.includes('driverOnlineItems')
        ),expression:"\n          driverOnlineItems.length > 0 &&\n          dashboardSelectedItems.includes('driverOnlineItems')\n        "}],attrs:{"cols":"12","md":"6"}},[_c('dashboard-card-half',{attrs:{"color":"green","full-header":""},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"pa-8 white--text"},[_c('div',{staticClass:"text-h4 font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t("Dashboard.cardDriverOnline"))+" ")]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t("Dashboard.cardDriverOnlineSubtitle"))+" ")])])]},proxy:true}])},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.driverOnlineHeaders,"items":_vm.driverOnlineItems,"items-per-page":5},scopedSlots:_vm._u([{key:`item.silence`,fn:function({ item }){return [(item.silence)?_c('v-icon',{attrs:{"color":"red","title":_vm.$t('Dashboard.silenceEnabledTitle')}},[_vm._v(" mdi-bell-off ")]):_c('v-icon',{attrs:{"color":"grey","title":_vm.$t('Dashboard.silenceDisabledTitle')}},[_vm._v(" mdi-bell ")])]}}],null,true)})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('dashboard-card-half',{attrs:{"color":"cyan darken-1","full-header":""},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"pa-8 white--text"},[_c('div',{staticClass:"text-h4 font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t("Dashboard.cardWeatherForecast"))+" ")]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t("Dashboard.cardWeatherForecastSubtitle"))+" ")])])]},proxy:true}])},[_c('v-card-text',[_c('weather-forecast')],1)],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.priceLocalityList.length > 0 &&
          _vm.dashboardSelectedItems.includes('priceLocalityList')
        ),expression:"\n          priceLocalityList.length > 0 &&\n          dashboardSelectedItems.includes('priceLocalityList')\n        "}],attrs:{"cols":"12","md":"6"}},[_c('dashboard-card-half',{attrs:{"color":"blue","full-header":""},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"pa-8 white--text"},[_c('div',{staticClass:"text-h4 font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t("Dashboard.cardPriceLocality"))+" ")]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t("Dashboard.cardPriceLocalitySubtitle"))+" ")])])]},proxy:true}])},[_c('v-card-text',[_c('shapes-google-map',{attrs:{"passDataOnMap":_vm.priceLocalityList}})],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600px"},model:{value:(_vm.messageDialog),callback:function ($$v) {_vm.messageDialog=$$v},expression:"messageDialog"}},[_c('v-toolbar',{attrs:{"color":_vm.messageDialogColor,"dark":"","flat":""}},[_c('v-toolbar-title',{attrs:{"title":_vm.messageDialogTitle}},[_vm._v(_vm._s(_vm.messageDialogTitle))])],1),_c('v-card',[_c('v-card-text',[_c('div',{staticClass:"pt-4",domProps:{"innerHTML":_vm._s(_vm.messageDialogText)}})]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){$event.stopPropagation();_vm.messageDialog = false}}},[_vm._v(_vm._s(_vm.$t("btnClose")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }