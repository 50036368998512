export const moduleDefaultSettings = Object.freeze({
  account: false,
  cardPayments: false, // platba kartou sa este nepouziva
  discount: false, // zlavy sa este nepouzivaju
  dispatchingChat: false,
  limitLocalityPrice: 1,
  limitStand: 1,
  limitWorkshift: 1,
  pricelistType: "SIMPLE",
  report: false,
  timeOrder: false,
  worklog: false, 
  driverDispatching: false,
  vehicleMaintenance: false,
  dispatchingCreateOrder: false,
  brandApp: false,
  commercialAds: false,
});
