<template>
  <div>
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>{{ $t("Dashboard.page_title") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>mdi-monitor-dashboard</v-icon>
    </v-toolbar>
    <!--<div
      class="sorry-status-bar"
      data-status-bar-for="7cbecf64"
      data-dismissible="false"
      data-filter-components="23252,22735,22736,22738,25288,25289,25290,25291,29120"
    ></div> -->
    <v-container id="dashboard-view" fluid tag="section">
      <v-row
        v-show="
          (messagePayment &&
            (roleAdmin || roleSupport || roleOwner) &&
            timeRemaining < 604800000) ||
          (messagePayment &&
            (roleAdmin || roleSupport || roleOwner || roleManager) &&
            timeRemaining < 302400000) ||
          (messagePayment && timeRemaining < 86400000)
        "
        ><v-col>
          <!-- simple countdown timer without imported custom element, will countdown until messagePayment.validTo -->
          <v-card
            color="purple"
            dark
            class="white--text"
            style="margin-top: 10px"
          >
            <v-card-title>
              <v-icon large class="pa-2">mdi-cash-clock</v-icon>
              <!-- <span class="headline">{{ $t("Dashboard.cardPaymentDue") }}</span> -->
              <span class="headline">{{ formattedTime }}</span>
            </v-card-title>
            <!-- <v-card-text>
              <div v-if="timeRemaining > 172800000">
                {{ formattedTime }}
              </div>
              <div v-else-if="timeRemaining > 0">
                <v-progress-linear
                  :value="timeRemaining"
                  :total="messagePayment.validTo - messagePayment.validFrom"
                  height="10"
                  color="red"
                ></v-progress-linear> 
                <div class="text-h4 white--text pa-2">
                  Čas do obmedzenia systému: {{ formattedTime }}
                </div>
              </div>
              <div v-else>Funkčnosť systému bude čoskoro obmedzená!</div>
            </v-card-text>-->
          </v-card>
        </v-col></v-row
      >
      <v-row>
        <v-col cols="12" v-show="messageList.length > 0">
          <dashboard-card-half color="primary" icon="mdi-bell-outline">
            <template #title>
              <small>{{ $t("Dashboard.cardMessageDispatch") }}</small></template
            >
            <v-card-text>
              <v-list flat subheader three-line>
                <v-list-item-group v-model="selectedMessageItem">
                  <v-list-item
                    link
                    v-for="message in messageList"
                    :key="message.title"
                    v-show="
                      (message.type == 'PAYMENT' &&
                        (roleAdmin ||
                          roleSupport ||
                          roleOwner ||
                          roleManager)) ||
                      message.type != 'PAYMENT'
                    "
                    @click="
                      messageDialog = true;
                      messageDialogTitle = message.title;
                      messageDialogText = message.message;
                      message.type == 'CONTRACT'
                        ? (messageDialogColor = 'blue')
                        : message.type == 'MAINTENANCE'
                        ? (messageDialogColor = 'orange')
                        : message.type == 'PAYMENT'
                        ? (messageDialogColor = 'purple')
                        : message.type == 'RELEASE'
                        ? (messageDialogColor = 'teal')
                        : message.type == 'SYSTEM_STATUS'
                        ? (messageDialogColor = 'red')
                        : message.type == 'OTHER '
                        ? (messageDialogColor = 'grey')
                        : (messageDialogColor = 'black');
                    "
                  >
                    <v-list-item-avatar>
                      <v-icon
                        :color="
                          message.type == 'CONTRACT'
                            ? 'blue'
                            : message.type == 'MAINTENANCE'
                            ? 'orange'
                            : message.type == 'PAYMENT'
                            ? 'purple'
                            : message.type == 'RELEASE'
                            ? 'teal'
                            : message.type == 'SYSTEM_STATUS'
                            ? 'red'
                            : message.type == 'OTHER '
                            ? 'grey'
                            : 'black'
                        "
                        dark
                        large
                        >{{
                          message.type == "CONTRACT"
                            ? "mdi-file-sign"
                            : message.type == "MAINTENANCE"
                            ? "mdi-wrench-clock"
                            : message.type == "PAYMENT"
                            ? "mdi-cash-clock"
                            : message.type == "RELEASE"
                            ? "mdi-notebook-outline"
                            : message.type == "SYSTEM_STATUS"
                            ? "mdi-traffic-light-outline"
                            : message.type == "OTHER "
                            ? "mdi-help-circle-outline"
                            : "mdi-help-circle-outline"
                        }}</v-icon
                      >
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <!-- if message.createdAt is less than 24 hours ago, show class "font-weight-bold" -->
                      <v-list-item-title
                        class="text--primary"
                        :class="
                          message.validFrom
                            ? new Date() - new Date(message.validFrom) <
                              86400000
                              ? 'font-weight-bold'
                              : ''
                            : ''
                        "
                        >{{ message.title }}</v-list-item-title
                      >

                      <v-list-item-subtitle>{{
                        message.validFrom
                          ? moment(message.validFrom).format("DD.MM.YY HH:mm")
                          : message.createdAt
                          ? moment(message.createdAt).format("DD.MM.YY HH:mm")
                          : "-"
                      }}</v-list-item-subtitle>

                      <v-list-item-subtitle>{{
                        strip_tags(message.message)
                      }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action v-show="!$vuetify.breakpoint.smAndDown">
                      <v-btn
                        icon
                        @click="
                          messageDialog = true;
                          messageDialogTitle = message.title;
                          messageDialogText = message.message;
                          message.type == 'CONTRACT'
                            ? (messageDialogColor = 'blue')
                            : message.type == 'MAINTENANCE'
                            ? (messageDialogColor = 'orange')
                            : message.type == 'PAYMENT'
                            ? (messageDialogColor = 'purple')
                            : message.type == 'RELEASE'
                            ? (messageDialogColor = 'teal')
                            : message.type == 'SYSTEM_STATUS'
                            ? (messageDialogColor = 'red')
                            : message.type == 'OTHER '
                            ? (messageDialogColor = 'grey')
                            : (messageDialogColor = 'black');
                        "
                      >
                        <v-icon color="grey lighten-1"
                          >mdi-dots-horizontal-circle-outline</v-icon
                        >
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
          </dashboard-card-half>
        </v-col>
        <v-col
          v-for="({ actionIcon, actionText, ...attrs }, i) in dashCards"
          :key="i"
          cols="12"
          md="6"
          lg="3"
        >
          <dashboard-card-small v-bind="attrs">
            <template #actions>
              <v-icon
                class="mr-2"
                small
                :title="actionText"
                @click="
                  messageDialog = true;
                  messageDialogTitle = attrs.title;
                  messageDialogText = actionText;
                  messageDialogColor = attrs.color;
                "
              >
                {{ actionIcon }}
              </v-icon>
              <div class="text-truncate" :title="actionText">
                {{ actionText }}
              </div>
            </template>
          </dashboard-card-small>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
          v-show="
            driverOnBreakItems.length > 0 &&
            dashboardSelectedItems.includes('driverOnBreakItems')
          "
        >
          <dashboard-card-half color="deep-purple" full-header>
            <template #heading>
              <div class="pa-8 white--text">
                <div class="text-h4 font-weight-light">
                  {{ $t("Dashboard.cardDdriverOnBreak") }}
                </div>
                <div class="text-caption">
                  {{ $t("Dashboard.cardDdriverOnBreakSubtitle") }}
                </div>
              </div>
            </template>
            <v-card-text>
              <v-data-table
                :headers="driverOnBreakHeaders"
                :items="driverOnBreakItems"
                :items-per-page="5"
              />
            </v-card-text>
          </dashboard-card-half>
        </v-col>
        <v-col
          cols="12"
          md="6"
          v-show="
            worklogRequestItems.length > 0 &&
            dashboardSelectedItems.includes('worklogRequestItems')
          "
        >
          <dashboard-card-half color="deep-purple" full-header>
            <template #heading>
              <div class="pa-8 white--text">
                <div class="text-h4 font-weight-light">
                  {{ $t("Dashboard.cardWorklogRequest") }}
                </div>
                <div class="text-caption">
                  {{ $t("Dashboard.cardWorklogRequestSubtitle") }}
                </div>
              </div>
            </template>
            <v-card-text>
              <v-data-table
                :headers="worklogRequestHeaders"
                :items="worklogRequestItems"
                :items-per-page="5"
              >
                <template v-slot:[`item.createdAt`]="{ item }">
                  {{
                    item.createdAt
                      ? moment(item.createdAt).format("DD.MM.YY HH:mm")
                      : "-"
                  }}
                </template>
                <template v-slot:[`item.type`]="{ item }">
                  <v-chip
                    :title="$t('worklogRequestType.' + item.type)"
                    :color="
                      item.type == 'OFFLINE'
                        ? 'blue'
                        : item.type == 'ONLINE'
                        ? 'green'
                        : item.type == 'ON_BREAK'
                        ? 'orange'
                        : 'grey'
                    "
                    dense
                    dark
                    label
                    small
                  >
                    {{ $t("worklogRequestType." + item.type) }}
                  </v-chip>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-chip
                    :title="$t('worklogRequestStatus.' + item.status)"
                    :color="
                      item.status == 'NEW'
                        ? 'blue'
                        : item.status == 'CONFIRMED'
                        ? 'green'
                        : item.status == 'DECLINED'
                        ? 'red'
                        : item.status == 'DONE'
                        ? 'teal'
                        : item.status == 'CANCELLED'
                        ? 'grey'
                        : 'grey'
                    "
                    dense
                    dark
                    label
                    small
                  >
                    {{ $t("worklogRequestStatus." + item.status) }}
                  </v-chip>
                </template>
              </v-data-table>
            </v-card-text>
          </dashboard-card-half>
        </v-col>
        <v-col
          cols="12"
          md="6"
          v-show="
            driverOnlineItems.length > 0 &&
            dashboardSelectedItems.includes('driverOnlineItems')
          "
        >
          <dashboard-card-half color="green" full-header>
            <template #heading>
              <div class="pa-8 white--text">
                <div class="text-h4 font-weight-light">
                  {{ $t("Dashboard.cardDriverOnline") }}
                </div>
                <div class="text-caption">
                  {{ $t("Dashboard.cardDriverOnlineSubtitle") }}
                </div>
              </div>
            </template>
            <v-card-text>
              <v-data-table
                :headers="driverOnlineHeaders"
                :items="driverOnlineItems"
                :items-per-page="5"
              >
                <template v-slot:[`item.silence`]="{ item }">
                  <v-icon
                    v-if="item.silence"
                    color="red"
                    :title="$t('Dashboard.silenceEnabledTitle')"
                  >
                    mdi-bell-off
                  </v-icon>
                  <v-icon
                    v-else
                    color="grey"
                    :title="$t('Dashboard.silenceDisabledTitle')"
                  >
                    mdi-bell
                  </v-icon>
                </template>
              </v-data-table>
            </v-card-text>
          </dashboard-card-half>
        </v-col>

        <v-col cols="12" md="6">
          <dashboard-card-half color="cyan darken-1" full-header>
            <template #heading>
              <div class="pa-8 white--text">
                <div class="text-h4 font-weight-light">
                  {{ $t("Dashboard.cardWeatherForecast") }}
                </div>
                <div class="text-caption">
                  {{ $t("Dashboard.cardWeatherForecastSubtitle") }}
                </div>
              </div>
            </template>
            <v-card-text>
              <weather-forecast></weather-forecast>
            </v-card-text>
          </dashboard-card-half>
        </v-col>
        <v-col
          cols="12"
          md="6"
          v-show="
            priceLocalityList.length > 0 &&
            dashboardSelectedItems.includes('priceLocalityList')
          "
        >
          <dashboard-card-half color="blue" full-header>
            <template #heading>
              <div class="pa-8 white--text">
                <div class="text-h4 font-weight-light">
                  {{ $t("Dashboard.cardPriceLocality") }}
                </div>
                <div class="text-caption">
                  {{ $t("Dashboard.cardPriceLocalitySubtitle") }}
                </div>
              </div>
            </template>
            <v-card-text>
              <shapes-google-map
                :passDataOnMap="priceLocalityList"
              ></shapes-google-map>
            </v-card-text>
          </dashboard-card-half>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="messageDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar :color="messageDialogColor" dark flat>
        <v-toolbar-title :title="messageDialogTitle">{{
          messageDialogTitle
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-card-text
          ><div v-html="messageDialogText" class="pt-4"></div
        ></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click.stop="messageDialog = false">{{
            $t("btnClose")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";
import { Role } from "../enums/RoleEnum";
import BarChart from "../components/BarChart";
import PieChart from "../components/PieChart";
import DashboardCardSmall from "../components/DashboardCardSmall";
import DashboardCardHalf from "../components/DashboardCardHalf";
import ShapesGoogleMap from "../components/ShapesGoogleMap.vue";
import WeatherForecast from "../components/WeatherForecast.vue";

export default {
  name: "Dashboard",
  components: {
    BarChart,
    PieChart,
    DashboardCardSmall,
    ShapesGoogleMap,
    DashboardCardHalf,
    WeatherForecast,
  },
  data() {
    return {
      moment: moment,
      refreshTimerOperational: null,
      refreshTimerWorklogRequestItems: null,
      refreshTimerMessageList: null,
      //Roles
      Role,
      roleAdmin: false,
      roleSupport: false,
      roleOwner: false,
      roleManager: false,
      roleMaintainer: false,
      roleDispatcher: false,

      //zapinanie itemov
      dashboardSelectedItems: [
        "utilizationRatio",
        "loadRatio",
        "immediateAvailabilityRatio",
        "counterPENDING",
        "sumPerformedOrders",
        "sumActiveDrivers",
        "sumBusyDrivers",
        "counterOnlineFREE",
        "counterON_BREAK",
        //"priceLocalityList",
        "driverOnlineItems",
        "driverOnBreakItems",
        //"worklogRequestItems",
      ],
      operationalStatus: {
        //Sumare objednavok
        counterPENDING: 0,
        counterACCEPTED: 0,
        counterWAITING: 0,
        counterIN_PROGRESS: 0,
        counterFINISHED: 0,
        sumPerformedOrders: 0,
        sumActiveOrders: 0,
        //Sumare vodičov
        counterON_BREAK: 0,
        counterONLINE: 0,
        counterOnlineFREE: 0,
        counterOnlineACCEPTED: 0,
        counterOnlineWAITING: 0,
        counterOnlineIN_PROGRESS: 0,
        counterOnlineFINISHED: 0,
        counterOnlineOTHER: 0,
        //vytazenost taxisluzby
        utilizationRatio: 100,
        sumBusyDrivers: 0,
        sumActiveDrivers: 1,
        //Index zaťaženia taxislužby
        loadRatio: 0,
        //Index Okamzitej dostupnosti
        immediateAvailabilityRatio: 0,
      },

      //Zoznam dashboardových kariet
      dashUtilizationRatio: true,

      //All operational data
      getOperationalItemsData: {
        showOnlyActiveOrder: true,
        limitOrders: 1,
        countDriverActiveOrders: true,
        messageMinDatetime: null,
        limitMessages: 1,
        showWorklogRequests: false,
      },
      //data pre dashCards s prikladom
      dashCards: [
        /*  {
          actionIcon: "mdi-alert",
          actionText: "",
          color: "#AAAAAA",
          icon: "mdi-sofa-single",
          title: "",
          value: "",
        },*/
      ],
      //Zobrazenie OZNAMENI pre dispecerov
      messageList: [],
      messageDialog: false,
      selectedMessageItem: 0,
      messageDialogTitle: "Oznámenie",
      messageDialogText: "",
      messageDialogColor: "secondary",
      //zobrazenie mapy
      priceLocalityList: [],
      //request data pre zobrazenie schvalovanych pracovnych zmien
      getWorklogRequestItemsData: {
        onlyActive: true,
      },
      //zobrazenie odpocitavania casu nedoplatku za faktury
      messagePayment: null,
      timeRemaining: 0,
      countdownIntervalId: null,
      //zobrazenie zoznamu ziadosti na schvalenie pracovnych zmien
      worklogRequestHeaders: [
        /* {
          sortable: false,
          text: "ID",
          value: "driverId",
        },*/
        {
          sortable: false,
          text: this.$t("Dashboard.tableHeadersWorklog.type"),
          value: "type",
          align: "left",
        },
        {
          sortable: false,
          text: this.$t("Dashboard.tableHeadersWorklog.status"),
          value: "status",
          align: "left",
        },
        {
          sortable: false,
          text: this.$t("Dashboard.tableHeadersWorklog.driverFirstName"),
          value: "driverFirstName",
          align: "left",
        },
        {
          sortable: false,
          text: this.$t("Dashboard.tableHeadersWorklog.driverLastName"),
          value: "driverLastName",
          align: "left",
        },
        {
          sortable: true,
          text: this.$t("Dashboard.tableHeadersWorklog.createdAt"),
          value: "createdAt",
          align: "left",
        },
      ],
      worklogRequestItems: [],

      driverOnBreakHeaders: [
        {
          sortable: false,
          text: this.$t("Dashboard.tableHeadersDriverOnBreak.id"),
          value: "id",
        },
        {
          sortable: false,
          text: this.$t("Dashboard.tableHeadersDriverOnBreak.firstName"),
          value: "firstName",
          align: "left",
        },
        {
          sortable: false,
          text: this.$t("Dashboard.tableHeadersDriverOnBreak.lastName"),
          value: "lastName",
          align: "left",
        },
      ],
      driverOnBreakItems: [],
      driverOnlineHeaders: [
        {
          sortable: false,
          text: this.$t("Dashboard.tableHeadersDriverOnline.id"),
          value: "id",
        },
        {
          sortable: false,
          text: this.$t("Dashboard.tableHeadersDriverOnline.firstName"),
          value: "firstName",
          align: "left",
        },
        {
          sortable: false,
          text: this.$t("Dashboard.tableHeadersDriverOnline.lastName"),
          value: "lastName",
          align: "left",
        },
        {
          sortable: false,
          text: this.$t("Dashboard.tableHeadersDriverOnline.silence"),
          value: "silence",
          align: "center",
        },
      ],
      driverOnlineItems: [],

      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },
    };
  },
  created() {
    //Zistenie zoznamu položiek pre Dashboard z Cookies
    var dashboardValue = this.getCookie("dashboardItems")
      ? JSON.parse(this.getCookie("dashboardItems"))
      : this.dashboardSelectedItems;
    this.dashboardSelectedItems = dashboardValue;

    //Skript na načítanie sorryapp bannera data-for="7cbecf64", ktorý zobrazuje či sú výpadky v datacentre pre komponenty VDC 23252, 22735, 22736, 22738, 25288, 25289, 25290, 25291
    /*const sorryAppScript = document.createElement("script");
    sorryAppScript.src =
      "https://code.sorryapp.com/status-bar/4.latest/status-bar.min.js";
    sorryAppScript.setAttribute("data-for", "7cbecf64");
    sorryAppScript.async = true;
    document.body.appendChild(sorryAppScript);*/
  },

  mounted() {
    let userRoles = this.$store.state.user.roleList;
    this.roleAdmin = userRoles.includes("ADMIN"); //Role.ADMIN
    this.roleSupport = userRoles.includes("SUPPORT");
    this.roleOwner = userRoles.includes("OWNER");
    this.roleManager = userRoles.includes("MANAGER");
    this.roleMaintainer = userRoles.includes("MAINTAINER");
    this.roleDispatcher = userRoles.includes("DISPATCHER");
    //setTimeout(() => {
    this.getOperationalItems();
    //}, 50);
    //refresh kazdu 1 minutu
    this.refreshTimerOperational = setInterval(() => {
      this.getOperationalItems();
    }, 60000);

    if (this.dashboardSelectedItems.includes("priceLocalityList")) {
      this.getItemsLocalityPrice();
    }
    //setTimeout(() => {
    this.getMessageList();
    //}, 100);
    //refresh staci kazdych 15 minut
    this.refreshTimerMessageList = setInterval(() => {
      this.getMessageList();
    }, 900000);
    if (this.dashboardSelectedItems.includes("worklogRequestItems")) {
      this.getWorklogRequestItems();
      this.refreshTimerWorklogRequestItems = setInterval(() => {
        this.getWorklogRequestItems();
      }, 60000);
    }
  },
  computed: {
    formattedTime() {
      const duration = moment.duration(this.timeRemaining);
      const days = Math.floor(duration.asDays());
      const hours = duration.hours().toString().padStart(2, "0");
      const minutes = duration.minutes().toString().padStart(2, "0");
      const seconds = duration.seconds().toString().padStart(2, "0");
      return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    },
  },
  methods: {
    getMessageList() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/message-dispatch/active-for-taxiservice`
        )
        .then((response) => {
          if (response) {
            this.messageList = response.data;
          }
          //find first object in messages if type is PAYMENT and set tu messagePayment
          this.messagePayment = this.messageList.find(
            (message) => message.type === "PAYMENT"
          );
          console.log(this.messagePayment);
          if (this.messagePayment) {
            this.updateTime();
            this.countdownIntervalId = setInterval(this.updateTime, 1000);
          }
        })
        .catch((e) => {
          console.log(e);
          //this.errors.push(e);
        });
    },
    updateTime() {
      const now = moment();
      const validTo = moment(this.messagePayment.validTo);
      this.timeRemaining = validTo.diff(now);
      if (this.timeRemaining <= 0) {
        clearInterval(this.countdownIntervalId);
        this.timeRemaining = 0;
      }
    },

    getOperationalItems() {
      //console.log(OrderStatus.NEW),
      //this.animateProgressBar("start"),
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/operationalData`,
          {
            params: this.getOperationalItemsData,
          }
        )
        .then((response) => {
          if (response) {
            //this.animateProgressBar("ok");
            var allData = response.data;
            var orderList = response.data.orderList;
            var driverList = response.data.driverList;
            //this.items = response.data.orderList;
            //check if orderList is empty
            if (orderList && driverList) {
              if (orderList.length > 0 && driverList.length > 0) {
                this.countStatistic(allData);
              }
              if (driverList.length > 0) {
                //filter status ON_BREAK
                this.driverOnBreakItems = driverList.filter(
                  (driver) => driver.workStatus === "ON_BREAK"
                );
                //filter status ONLINE
                this.driverOnlineItems = driverList.filter(
                  (driver) => driver.workStatus === "ONLINE"
                );
              }
            }
          }
        })
        .catch((e) => {
          console.log(e);
          //console.log(encodeURI);
          //this.animateProgressBar("error"), this.errors.push(e);
        });
    },

    countStatistic(inputData) {
      var orderList = inputData.orderList;
      var driverList = inputData.driverList;
      //Sumare objednavok
      var counterPREREGISTERED = 0;
      var counterTRANSFERED = 0;
      var counterNEW = 0;
      var counterPLANNED = 0;
      var counterPENDING = 0;
      var counterACCEPTED = 0;
      var counterWAITING = 0;
      var counterIN_PROGRESS = 0;
      var counterFINISHED = 0;
      var counterCHARGED = 0;
      var counterSETTLED = 0;
      var counterCANCELLED = 0;
      var sumPerformedOrders = 0; //Priradene k vodicom
      var sumActiveOrders = 0; // Priradene k vodicom + Cakajuce na priradenie
      //Sumare vodičov
      var counterOFFLINE = 0;
      var counterON_BREAK = 0;
      var counterONLINE = 0;
      var counterOnlineFREE = 0; //Vodici bez objednavky
      var counterOnlineACCEPTED = 0;
      var counterOnlineWAITING = 0;
      var counterOnlineIN_PROGRESS = 0;
      var counterOnlineFINISHED = 0;
      var counterOnlineOTHER = 0;
      //vytazenost taxisluzby
      var utilizationRatio = 0;
      var utilizationStatus = "OK";
      var sumBusyDrivers = 0; //Vodici ktori maju priradenu objednavku
      var sumActiveDrivers = 1; //Vodici ktori su na pracovnej zmene a nie su na prestavke
      //Index zaťaženia taxislužby
      var loadRatio = 0; //Pocet vsetkych aktivnych objednavok k poctu aktivnych vodicov
      var tempOperationalStatus = {};
      //Index okamžitej dostupnosti
      var immediateAvailabilityRatio = 0;

      orderList.forEach(function (item, index) {
        if (item.status == "PREREGISTERED") {
          ++counterPREREGISTERED;
        } else if (item.status == "TRANSFERED") {
          ++counterTRANSFERED;
        } else if (item.status == "NEW") {
          ++counterNEW;
        } else if (item.status == "PLANNED") {
          ++counterPLANNED;
        } else if (item.status == "PENDING") {
          ++counterPENDING;
        } else if (item.status == "ACCEPTED") {
          ++counterACCEPTED;
        } else if (item.status == "WAITING") {
          ++counterWAITING;
        } else if (item.status == "IN_PROGRESS") {
          ++counterIN_PROGRESS;
        } else if (item.status == "FINISHED") {
          ++counterFINISHED;
        } else if (item.status == "CHARGED") {
          ++counterCHARGED;
        } else if (item.status == "SETTLED") {
          ++counterSETTLED;
        } else if (
          item.status == "CANCELLED_PLANNED" ||
          item.status == "CANCELLED_PENDING" ||
          item.status == "CANCELLED_ACCEPTED" ||
          item.status == "CANCELLED_WAITING" ||
          item.status == "CANCELLED_IN_PROGRESS" ||
          item.status == "CANCELLED_FINISHED" ||
          item.status == "CANCELLED_CHARGED" ||
          item.status == "CANCELLED_SETTLED"
        ) {
          ++counterCANCELLED;
        }
      });
      tempOperationalStatus.counterPENDING = counterPENDING;
      tempOperationalStatus.counterACCEPTED = counterACCEPTED;
      tempOperationalStatus.counterWAITING = counterWAITING;
      tempOperationalStatus.counterIN_PROGRESS = counterIN_PROGRESS;
      tempOperationalStatus.counterFINISHED = counterFINISHED;
      //console.log(        "PLANNED: ",        counterPLANNED,        " | PENDING: ",        counterPENDING,        " | ACCEPTED: ",        counterACCEPTED,        " | WAITING: ",        counterWAITING,        " | IN_PROGRESS: ",        counterIN_PROGRESS,        " | FINISHED: ",        counterFINISHED,        " | CANCELLED: ",        counterCANCELLED      );

      driverList.forEach(function (item, index) {
        if (item.workStatus == "OFFLINE") {
          ++counterOFFLINE;
        } else if (item.workStatus == "ON_BREAK") {
          ++counterON_BREAK;
        } else if (item.workStatus == "ONLINE") {
          if (item.orderActual != null) {
            if (item.orderActual.status == "ACCEPTED") {
              ++counterOnlineACCEPTED;
            } else if (item.orderActual.status == "WAITING") {
              ++counterOnlineWAITING;
            } else if (item.orderActual.status == "IN_PROGRESS") {
              ++counterOnlineIN_PROGRESS;
            } else if (item.orderActual.status == "FINISHED") {
              ++counterOnlineFINISHED;
            } else {
              ++counterOnlineOTHER;
            }
          } else {
            ++counterONLINE;
          }
        }
      });
      counterOnlineFREE = counterONLINE + counterOnlineOTHER;
      tempOperationalStatus.counterON_BREAK = counterON_BREAK;
      tempOperationalStatus.counterOnlineACCEPTED = counterOnlineACCEPTED;
      tempOperationalStatus.counterOnlineWAITING = counterOnlineWAITING;
      tempOperationalStatus.counterOnlineIN_PROGRESS = counterOnlineIN_PROGRESS;
      tempOperationalStatus.counterOnlineFINISHED = counterOnlineFINISHED;
      tempOperationalStatus.counterONLINE = counterONLINE;
      tempOperationalStatus.counterOnlineFREE = counterOnlineFREE;
      tempOperationalStatus.counterOnlineOTHER = counterOnlineOTHER;
      //console.log(        "OFFLINE: ",        counterOFFLINE,        " | ON_BREAK: ",        counterON_BREAK,        " | ONLINE: ",        counterONLINE,        " | ACCEPTED: ",        counterOnlineACCEPTED,        " | WAITING: ",        counterOnlineWAITING,        " | IN_PROGRESS: ",        counterOnlineIN_PROGRESS,        " | FINISHED: ",        counterOnlineFINISHED      );
      sumBusyDrivers =
        counterOnlineACCEPTED +
        counterOnlineWAITING +
        counterOnlineIN_PROGRESS +
        counterOnlineFINISHED;
      tempOperationalStatus.sumBusyDrivers = sumBusyDrivers;
      sumActiveDrivers = sumBusyDrivers + counterOnlineFREE; //nesmie byt 0, lebo nizsie prebieha delenie tymto cislom
      tempOperationalStatus.sumActiveDrivers = sumActiveDrivers;
      //Vytazenost v %
      utilizationRatio = Math.round(
        sumBusyDrivers / ((sumActiveDrivers > 0 ? sumActiveDrivers : 1) / 100)
      );
      tempOperationalStatus.utilizationRatio = utilizationRatio;
      //console.log("utilizationRatio: ", utilizationRatio);
      sumPerformedOrders =
        counterACCEPTED + counterWAITING + counterIN_PROGRESS + counterFINISHED;
      tempOperationalStatus.sumPerformedOrders = sumPerformedOrders;
      sumActiveOrders = counterPENDING + sumPerformedOrders;
      tempOperationalStatus.sumActiveOrders = sumActiveOrders;
      //Index zaťaženia (load ratio)
      if (sumActiveDrivers > 0) {
        loadRatio =
          Math.round((sumActiveOrders / sumActiveDrivers) * 100) / 100;
      } else {
        loadRatio = 0;
      }

      tempOperationalStatus.loadRatio = loadRatio;
      //Index okamžitej dostupnosti vozidla (immediate availability ratio)
      immediateAvailabilityRatio =
        Math.round(
          (counterPENDING / (counterOnlineFREE > 0 ? counterOnlineFREE : 1)) *
            100
        ) / 100;
      tempOperationalStatus.immediateAvailabilityRatio =
        immediateAvailabilityRatio;
      //? index pokrytie (occupancy ratio) - pocet zaradenych vozidiel v poradí / pocet vozidiel v poradí
      this.operationalStatus = Object.assign({}, tempOperationalStatus);
      this.generateDashCards();
    },
    generateDashCards() {
      var tempDashCards = [];
      var tempDashCard = {};
      var operationalStatus = this.operationalStatus;

      //for (var i = 0; i < 4; i++) {
      if (this.dashboardSelectedItems.includes("utilizationRatio")) {
        tempDashCard = {
          title: this.$t("Dashboard.dashcardUtilizationRatio.title"), //"Vyťaženosť vodičov",
          value: "" + operationalStatus.utilizationRatio + "%",
          icon: "mdi-gauge",
          color: "teal",
          actionIcon: "mdi-information",
          actionText: this.$t("Dashboard.dashcardUtilizationRatio.actionText"),
          /* "Percentuálna vyťaženosť vodičov: Percento zaneprázdnených voči aktívnym vodičom. " +
            "\nIdeálna hodnota pre pružné vybavovanie zákazníkov je do 80%." +
            "\nOptimálna pre efektivitu taxislužby je 100%",*/
          //link: "",
        };
        tempDashCards.push(tempDashCard);
      }
      if (this.dashboardSelectedItems.includes("loadRatio")) {
        tempDashCard = {
          title: this.$t("Dashboard.dashcardLoadRatio.title"), //"Zaťaženie taxislužby",
          value: "" + operationalStatus.loadRatio,
          icon: "mdi-heart-pulse",
          color: "purple",
          actionIcon: "mdi-information",
          actionText: this.$t("Dashboard.dashcardLoadRatio.actionText"),
          /*
            "Index záťaže taxislužby: Pomer Aktívnych objednávok (realizované + čakajúce)  / Aktívnych vodičov." +
            "\nIdeálna hodnota pre vybavovanie zákazníkov je 1 a menej." +
            "\nHodnota nad 1 spôsobuje, že zákazníci dlhšie čakajú na pridelenie vozidla." +
            "\nOptimálna pre efektivitu (rýchle vyťažovanie vodičov) taxislužby je od 1 do 1,4. " +
            "\nNad 2 je preťažená taxislužba. Vtedy je vhodné uvažovať nad odmietaním nových objednávok.",*/
          //link: "",
        };
        tempDashCards.push(tempDashCard);
      }

      if (this.dashboardSelectedItems.includes("immediateAvailabilityRatio")) {
        tempDashCard = {
          title: this.$t("Dashboard.dashcardImmediateAvailabilityRatio.title"), //"Okamžitá dostupnosť",
          value: "" + operationalStatus.immediateAvailabilityRatio,
          icon: "mdi-car-multiple",
          color: "cyan",
          actionIcon: "mdi-information",
          actionText: this.$t(
            "Dashboard.dashcardImmediateAvailabilityRatio.actionText"
          ),
          /*
            "Index okamžitej dostupnosti: Pomer objednávok na pridelenie a voľných vodičov." +
            "\nIdeálna hodnota pre vybavovanie zákazníkov 1 a menej.",*/
          //link: "",
        };
        tempDashCards.push(tempDashCard);
      }

      if (this.dashboardSelectedItems.includes("counterPENDING")) {
        tempDashCard = {
          title: this.$t("Dashboard.dashcardCounterPENDING.title"), //"Nepriradené objednávky",
          value: "" + operationalStatus.counterPENDING,
          icon: "mdi-routes-clock",
          color: "orange",
          actionIcon: "mdi-information",
          actionText: this.$t("Dashboard.dashcardCounterPENDING.actionText"),
          /* "Objednávky čakajúce na priradenie vodičom.",*/
          //link: "",
        };
        tempDashCards.push(tempDashCard);
      }

      if (this.dashboardSelectedItems.includes("sumPerformedOrders")) {
        tempDashCard = {
          title: this.$t("Dashboard.dashcardSumPerformedOrders.title"), //"Realizované objednávky",
          value: "" + operationalStatus.sumPerformedOrders,
          icon: "mdi-routes",
          color: "blue",
          actionIcon: "mdi-information",
          actionText: this.$t(
            "Dashboard.dashcardSumPerformedOrders.actionText"
          ),
          /*"Objednávky pridelené vodičom a práve realizované.",*/
          //link: "",
        };
        tempDashCards.push(tempDashCard);
      }

      if (this.dashboardSelectedItems.includes("sumActiveDrivers")) {
        tempDashCard = {
          title: this.$t("Dashboard.dashcardSumActiveDrivers.title"), //"Aktívni vodiči",
          value: "" + operationalStatus.sumActiveDrivers,
          icon: "mdi-account-group-outline",
          color: "green",
          actionIcon: "mdi-information",
          actionText: this.$t("Dashboard.dashcardSumActiveDrivers.actionText"),
          /*"Voľní a zaneprázdnení vodiči s objednávkami.",*/
          //link: "",
        };
        tempDashCards.push(tempDashCard);
      }

      if (this.dashboardSelectedItems.includes("sumBusyDrivers")) {
        tempDashCard = {
          title: this.$t("Dashboard.dashcardSumBusyDrivers.title"), //"Zaneprázdnení vodiči",
          value: "" + operationalStatus.sumBusyDrivers,
          icon: "mdi-account-multiple-remove-outline",
          color: "red",
          actionIcon: "mdi-information",
          actionText: this.$t("Dashboard.dashcardSumBusyDrivers.actionText"),
          /* "Vodiči s pridelenými objednávkami.",*/
          //link: "",
        };
        tempDashCards.push(tempDashCard);
      }

      if (this.dashboardSelectedItems.includes("counterOnlineFREE")) {
        tempDashCard = {
          title: this.$t("Dashboard.dashcardCounterOnlineFREE.title"), // "Voľní vodiči",
          value: "" + operationalStatus.counterOnlineFREE,
          icon: "mdi-account-multiple-check-outline",
          color: "amber",
          actionIcon: "mdi-information",
          actionText: this.$t("Dashboard.dashcardCounterOnlineFREE.actionText"),
          /*"Voľní vodiči bez objednávok.",*/
          //link: "",
        };
        tempDashCards.push(tempDashCard);
      }

      if (this.dashboardSelectedItems.includes("counterON_BREAK")) {
        tempDashCard = {
          title: this.$t("Dashboard.dashcardCounterONBREAK.title"), // "Vodiči na prestávke",
          value: "" + operationalStatus.counterON_BREAK,
          icon: "mdi-coffee-to-go-outline",
          color: "deep-purple",
          actionIcon: "mdi-information",
          actionText: this.$t("Dashboard.dashcardCounterONBREAK.actionText"),
          /* "Vodiči aktuálne na prestávke.",*/
          //link: "",
        };
        tempDashCards.push(tempDashCard);
      }

      this.dashCards = tempDashCards;
      //      this.stats.push(Object.assign({}, tempDashCard));
    },

    getItemsLocalityPrice() {
      // if (this.dashboardSelectedItems.includes("priceLocalityList")) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/locality-price`)
        .then((response) => {
          var priceLocalityList = response.data;
          //display only active items
          if (priceLocalityList) {
            this.priceLocalityList = priceLocalityList.filter(
              (item) => item.active == true
            );
          }
        })
        .catch((e) => {
          console.log(e);
          //this.errors.push(e);
        });
      //}
    },
    getWorklogRequestItems() {
      if (this.dashboardSelectedItems.includes("worklogRequestItems")) {
        axios
          .get(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/driver/worklog`,
            {
              params: this.getWorklogRequestItemsData,
            }
          )
          .then((response) => {
            this.worklogRequestItems = response.data;
          })
          .catch((e) => {
            console.log(e);
            //this.errors.push(e);
          });
      }
    },
    //funtion to remove html tags from string
    strip_tags(html) {
      var tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    },

    sortObjectAsc(obj) {
      return Object.keys(obj)
        .sort()
        .reduce(function (result, key) {
          result[key] = obj[key];
          return result;
        }, {});
    },
    sortObjectDesc(obj) {
      return Object.keys(obj)
        .sort()
        .reverse()
        .reduce(function (result, key) {
          result[key] = obj[key];
          return result;
        }, {});
    },
    //Nacitanie hodnoty z cookie
    getCookie(nameC) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      //console.log("getCookie - name: " + nameC);
      // Split cookie string and get all individual name=value pairs in an array
      var cookieArr = document.cookie.split(";");
      // Loop through the array elements
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        /* Removing whitespace at the beginning of the cookie name and compare it with the given string */
        if (nameC == cookiePair[0].trim()) {
          // Decode the cookie value and return
          //console.log("getCookieValue decode: " + decodeURIComponent(cookiePair[1]));
          return decodeURIComponent(cookiePair[1]);
        }
      }
      // Return null if not found
      return null;
    },
  },
  beforeRouteLeave(to, from, next) {
    //console.log("dashboard beforeRouteLeave");
    /*const answer = window.confirm("Do you really want to leave?");
    if (answer) {*/
    clearInterval(this.refreshTimerOperational);
    clearInterval(this.refreshTimerWorklogRequestItems);
    clearInterval(this.refreshTimerMessageList);
    clearInterval(this.countdownIntervalId);
    next();
    /* } else {
      next(false);
    }*/
  },
};
</script>
