<template>
  <!--<v-card class="mx-auto">
    <v-card-title> Aktuálne počasie a 9-hodinová prognóza </v-card-title>
     <v-card-text> -->
  <v-row>
    <v-col
      v-for="(forecast, index) in weatherData.list"
      :key="index"
      cols="12"
      sm="6"
      md="3"
    >
      <v-card class="ma-0 pa-0">
        <v-card-title
          :title="weatherData.city.name + ' | ' + formatDateFull(forecast.dt)"
          :class="isTaxiRecommended(forecast) ? 'red--text' : ''"
        >
          {{ formatDate(forecast.dt) }}

          <v-img
            :src="getIconUrl(forecast.weather[0].icon)"
            max-height="100"
            :title="
              weatherEvent(forecast.weather[0].id) +
              ' | ' +
              forecast.weather[0].description
            "
            class="mx-auto"
        /></v-card-title>
        <v-card-text>
          <!-- Matematicky zaokruhlit teplotu -->
          <div>
            <span class="text-h3" :title="$t('WeatherForecast.temperature')">{{
              Math.round(forecast.main.temp)
            }}</span>
            °C
          </div>
          <v-divider></v-divider>
          <div>
            <v-icon small class="mr-2" :title="$t('WeatherForecast.feelsLike')"
              >mdi-thermostat</v-icon
            >{{ Math.round(forecast.main.feels_like) }} °C
          </div>
          <div :class="forecast.pop > 50 ? 'red--text' : ''">
            <v-icon
              small
              class="mr-2"
              :title="$t('WeatherForecast.probabilityOfPrecipitation')"
              >mdi-weather-rainy</v-icon
            >{{ Math.round(forecast.pop * 100) }}%
          </div>
          <div :class="forecast.wind.speed > 10 ? 'red--text' : ''">
            <v-icon small class="mr-2" :title="$t('WeatherForecast.wind')"
              >mdi-weather-windy</v-icon
            >{{ Math.round(forecast.wind.speed * 3.6) }} km/h
          </div>
          <div>
            <v-icon small class="mr-2" :title="$t('WeatherForecast.humidity')"
              >mdi-water-percent</v-icon
            >{{ Math.round(forecast.main.humidity) }}%
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <!-- </v-card-text> 
  </v-card>-->
</template>

<script>
import axios from "axios";

export default {
  /*name: "WeatherForecast",
  props: {
    latitude: {
      type: Number,
      required: true,
      default: 48.1486,
    },
    longitude: {
      type: Number,
      required: true,
      default: 17.1077,
    },
    apiKey: {
      type: String,
      default: "515cda9e36793d521aa3d20c6858c681",
    },
  },*/
  data() {
    return {
      weatherData: { list: [] },
      latitude: 48.1486,
      longitude: 17.1077,
      apiKey: "515cda9e36793d521aa3d20c6858c681",
    };
  },
  created() {
    this.getDefaultStand();
  },

  async mounted() {
    //await this.fetchWeatherData();
    setInterval(async () => {
      await this.fetchWeatherData();
    }, 30 * 60 * 1000); // 30 minút
  },
  computed: {
    /*isTaxiRecommended() {
      return isTaxiRecommended(this.weatherData);
    },*/
  },
  methods: {
    getDefaultStand() {
      var defaultStandPosition = this.getCookie("defaultStandPosition");
      if (defaultStandPosition && defaultStandPosition != null) {
        if (defaultStandPosition.length > 10) {
          defaultStandPosition = JSON.parse(defaultStandPosition);

          if (
            defaultStandPosition.lat != null ||
            defaultStandPosition.lng != null ||
            defaultStandPosition.lat != "" ||
            defaultStandPosition.lng != ""
          ) {
            //console.log("defaultStandPosition from cookie is not null");
            this.latitude = defaultStandPosition.lat;
            this.longitude = defaultStandPosition.lng;
            this.fetchWeatherData();
            return;
          }
        }
      }
      this.getStands();
    },

    getStands() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/stand`)
        .then((response) => {
          var itemList = response.data;
          //Ak pride Object, tak zo treba zmenit na Array, aby sme mohli urobit loop/for
          if (itemList.constructor.name != "Array") {
            var newItemList = [];
            newItemList.push(itemList);
            itemList = newItemList;
          }
          //if empty list
          var defaultStandExists = false;
          if (itemList.length > 0) {
            itemList.forEach((item) => {
              if (item.active == true) {
                if (item.defaultStand == true) {
                  this.latitude = item.latitude;
                  this.longitude = item.longitude;
                  this.fetchWeatherData();
                  defaultStandExists = true;
                }
              }
            });
          } 
          if(defaultStandExists == false) {
            this.getCenterOfMap();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCenterOfMap() {
      var centerOfMap = this.getCookie("centerOfMap");
      if (centerOfMap && centerOfMap != null) {
        if (centerOfMap.length > 10) {
          centerOfMap = JSON.parse(centerOfMap);
          if (
            this.centerOfMap.lat != null ||
            this.centerOfMap.lng != null ||
            this.centerOfMap.lat != "" ||
            this.centerOfMap.lng != ""
          ) {
            console.log("centerOfMap from cookie is null");
            this.latitude = centerOfMap.lat;
            this.longitude = centerOfMap.lng;
            this.fetchWeatherData();
            return;
          }
        }
      } else {
        this.fetchWeatherData();
      }
    },
    async fetchWeatherData() {
      const axiosInstance = axios.create({
        headers: {},
      });

      const response = await axiosInstance.get(
        "https://api.openweathermap.org/data/2.5/forecast",
        {
          params: {
            lat: this.latitude,
            lon: this.longitude,
            appid: this.apiKey,
            units: "metric",
            cnt: 4, // Získajte 5 prognóz (1 aktuálna + 4 nasledujúce)
          },
        }
      );

      this.weatherData = response.data;
    },
    formatDate(timestamp) {
      const date = new Date(timestamp * 1000);
      const weekday = date.toLocaleString("default", { weekday: "short" });
      return `${weekday} ${date.getHours()}:00`;
    },
    formatDateFull(timestamp) {
      const date = new Date(timestamp * 1000);
      return `${date.getHours()}:00, ${date.getDate()}.${
        date.getMonth() + 1
      }.${date.getFullYear()}`;
    },
    weatherEvent(weatherCode) {
      //Ak je kód do 800, zamenime posledne 2 cisla za 00
      if (weatherCode < 800) {
        weatherCode = weatherCode.toString().slice(0, -2) + "00";
      }
      return this.$t("WeatherEvent." + weatherCode);
    },
    getIconUrl(icon) {
      return `https://openweathermap.org/img/wn/${icon}@2x.png`;
    },
    // Vyhodnotenie vhodnosti objednania taxíka na základe aktuálneho počasia
    isTaxiRecommended(weatherData) {
      //Iba ak má pršať a bude fúkať viac ako 10m/s a je pracovný deň od 6:00 do 22:00
      //ak je pracovný deň
      const weekDay = new Date(weatherData.dt * 1000).getDay();
      if (weekDay > 0 && weekDay < 6) {
        // Vyhodnotenie denného obdobia
        const currentHour = new Date(weatherData.dt * 1000).getHours();
        if (currentHour > 6 && currentHour < 22) {
          // Vyhodnotenie zrážok
          if (weatherData.pop > 0.5) {
            // Vyhodnotenie vetra
            if (weatherData.wind.speed > 10) {
              // Vyhodnotenie teploty
              /*if (currentForecast.main.temp > 30 || currentForecast.main.temp < -5) {
                    return true;
                }*/
              return true;
            }
          }
        }
      }
      return false;
    },
    //Nacitanie hodnoty z cookie
    getCookie(nameC) {
      // Split cookie string and get all individual name=value pairs in an array
      var cookieArr = document.cookie.split(";");
      // Loop through the array elements
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        /* Removing whitespace at the beginning of the cookie name and compare it with the given string */
        if (nameC == cookiePair[0].trim()) {
          // Decode the cookie value and return
          return decodeURIComponent(cookiePair[1]);
        }
      }
      // Return null if not found
      return null;
    },
  },
};
</script>