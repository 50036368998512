<template>
  <span>
    <v-btn
      color="info"
      small
      class="ml-1"
      :title="$t('ConsoleOrderWaypointEdit.btnEditWaypoint_title')"
      @click.stop="openEditSelectorDialog(), getOrderWaypoints()"
      :disabled="disabled"
    >
      <v-icon dark> mdi-map-marker-path </v-icon>
      <!--{{ $t("ConsoleOrderWaypointEdit.btnEditWaypoint") }}-->
    </v-btn>
    <v-dialog
      v-model="editSelectorDialog"
      transition="dialog-top-transition"
      :max-width="dialogWidth"
    >
      <v-card>
        <v-toolbar color="secondary" dark dense flat>
          <v-toolbar-title>{{
            $t("ConsoleOrderWaypointEdit.dialogTitle")
          }}</v-toolbar-title></v-toolbar
        >
        <v-card-title
          >{{ $t("ConsoleOrderWaypointEdit.id_short") }}:
          {{ orderIdParam.slice(0, 8) }}</v-card-title
        >
        <v-card-subtitle class="py-0"
          >{{ $t("ConsoleOrderWaypointEdit.status") }}:
          {{ $t("orderStatus." + orderStatusParam) }}
        </v-card-subtitle>
        <!--<v-card-subtitle class="py-0"
          >{{ $t("ConsoleOrderWaypointEdit.createdAt") }}:
          {{ orderCreatedAtParam }}
        </v-card-subtitle>-->

        <v-divider></v-divider>
        <v-form ref="editSelectorForm">
          <v-card-text>
            <v-container>
              <v-row no-gutters>
                <v-col cols="12" md="9">
                  <autocomplete-address-remote
                    v-model="boardAddressModel"
                    :label="$t('ConsoleOrderWaypointEdit.boardAddress')"
                    prepend-icon="fas fa-street-view"
                    dense
                    :disabled="boardAddressSwitchModel"
                    :searchParams="addressSearchData"
                    :addAddress="addBoardAddress"
                  >
                  </autocomplete-address-remote>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" md="9">
                  <autocomplete-address-remote
                    v-model="transit1AddressModel"
                    :label="$t('ConsoleOrderWaypointEdit.transit1Address')"
                    prepend-icon="mdi-transit-connection-variant"
                    dense
                    :searchParams="addressSearchData"
                    :disabled="transit1AddressSwitchModel"
                    :addAddress="addTransit1Address"
                  >
                  </autocomplete-address-remote>
                </v-col>
                <v-col cols="12" md="3">
                  <v-switch
                    v-model="transit1AddressSwitchModel"
                    :label="$t('btnRemove')"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" md="9">
                  <autocomplete-address-remote
                    v-model="transit2AddressModel"
                    :label="$t('ConsoleOrderWaypointEdit.transit2Address')"
                    prepend-icon="mdi-transit-connection-variant"
                    dense
                    :searchParams="addressSearchData"
                    :disabled="transit2AddressSwitchModel"
                    :addAddress="addTransit2Address"
                  >
                  </autocomplete-address-remote>
                </v-col>
                <v-col cols="12" md="3">
                  <v-switch
                    v-model="transit2AddressSwitchModel"
                    :label="$t('btnRemove')"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" md="9">
                  <autocomplete-address-remote
                    v-model="transit3AddressModel"
                    :label="$t('ConsoleOrderWaypointEdit.transit3Address')"
                    prepend-icon="mdi-transit-connection-variant"
                    dense
                    :searchParams="addressSearchData"
                    :disabled="transit3AddressSwitchModel"
                    :addAddress="addTransit3Address"
                  >
                  </autocomplete-address-remote> </v-col
                ><v-col cols="12" md="3">
                  <v-switch
                    v-model="transit3AddressSwitchModel"
                    :label="$t('btnRemove')"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" md="9">
                  <autocomplete-address-remote
                    v-model="transit4AddressModel"
                    :label="$t('ConsoleOrderWaypointEdit.transit4Address')"
                    prepend-icon="mdi-transit-connection-variant"
                    dense
                    :searchParams="addressSearchData"
                    :disabled="transit4AddressSwitchModel"
                    :addAddress="addTransit4Address"
                  >
                  </autocomplete-address-remote> </v-col
                ><v-col cols="12" md="3">
                  <v-switch
                    v-model="transit4AddressSwitchModel"
                    :label="$t('btnRemove')"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" md="9">
                  <autocomplete-address-remote
                    v-model="destAddressModel"
                    :label="$t('ConsoleOrderWaypointEdit.destAddress')"
                    prepend-icon="fas fa-flag-checkered"
                    dense
                    :searchParams="addressSearchData"
                    :disabled="destAddressSwitchModel"
                    :addAddress="addDestAddress"
                  >
                  </autocomplete-address-remote>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click.stop="callUpdateApi()">{{
              $t("btnSave")
            }}</v-btn>
            <v-btn
              color="error"
              text
              @click.stop="editSelectorDialog = false"
              >{{ $t("btnClose") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Snackbar o úspešnosti volania na API -->
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </span>
</template>

<script>
import axios from "axios";
import { Status, StatusColor } from "../enums/OrderEnum";
import AutocompleteAddressRemote from "../components/AutocompleteAddressRemote.vue";
import * as moment from "moment/moment";
const DEFAULT_DIALOG_WIDTH = 600;
export default {
  name: "OrderEdit",
  components: { AutocompleteAddressRemote },
  props: {
    orderIdParam: {
      type: String,
      default: "",
    },
    orderStatusParam: {
      type: String,
      default: "",
    },
    orderCreatedAtParam: {
      type: String,
      default: "",
    },
    filled: {
      type: Boolean,
    },
    outlined: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
    medium: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    label: {
      type: String,
      default: "",
    },
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH,
    },
  },
  data() {
    return {
      Status,
      StatusColor,
      //Snackbar
      snackbar: {
        show: false,
        message: "OK",
        color: "info",
        timeout: 3000,
      },

      items: [],
      posts: [],
      errors: [],

      //Zobrazenie dialogovych okien
      editSelectorDialog: false,

      //Blokovania poloziek menu na zobrazenie dialogovych okien
      disableAbortOrderDialog: false,

      orderWaypointData: [
        {
          id: null,
          status: null,
          ordering: null,
          latitude: null,
          longitude: null,
          address: null,
          type: "BOARD",
          localityId: null,
        },
        {
          id: null,
          status: null,
          ordering: null,
          latitude: null,
          longitude: null,
          address: null,
          type: "DESTINATION",
          localityId: null,
        },
        {
          id: null,
          status: null,
          ordering: null,
          latitude: null,
          longitude: null,
          address: null,
          type: "TRANSIT",
          localityId: null,
        },
      ],
      //priamy zapis do autocomplete
      addBoardAddress: null,
      addDestAddress: null,
      addTransit1Address: null,
      addTransit2Address: null,
      addTransit3Address: null,
      addTransit4Address: null,
      //vymazanie prejazdovych bodov
      boardAddressSwitchModel: false,
      destAddressSwitchModel: false,
      transit1AddressSwitchModel: false,
      transit2AddressSwitchModel: false,
      transit3AddressSwitchModel: false,
      transit4AddressSwitchModel: false,

      //jednotlive elementy waypointov ktore prisli zo servera
      boardWaypoint: null,
      destWaypoint: null,
      transit1Waypoint: null,
      transit2Waypoint: null,
      transit3Waypoint: null,
      transit4Waypoint: null,
      //novo definovane elementy waypointov
      orderBoardAddress: {
        address: null,
        latitude: null,
        longitude: null,
        ordering: 1,
        status: "ACTIVE",
        type: "BOARD",
      },
      orderDestinationAddress: {
        address: null,
        latitude: null,
        longitude: null,
        ordering: 9,
        status: "ACTIVE",
        type: "DESTINATION",
      },
      orderTransit1Address: {
        address: null,
        latitude: null,
        longitude: null,
        ordering: 2,
        status: "ACTIVE",
        type: "TRANSIT",
      },
      orderTransit2Address: {
        address: null,
        latitude: null,
        longitude: null,
        ordering: 3,
        status: "ACTIVE",
        type: "TRANSIT",
      },
      orderTransit3Address: {
        address: null,
        latitude: null,
        longitude: null,
        ordering: 4,
        status: "ACTIVE",
        type: "TRANSIT",
      },
      orderTransit4Address: {
        address: null,
        latitude: null,
        longitude: null,
        ordering: 5,
        status: "ACTIVE",
        type: "TRANSIT",
      },
      //pouzije sa na vynulovanie dat
      defaultAddressModel: {
        displayName: null,
        latitude: null,
        longitude: null,
      },
      //najdena adresa z autocomplete
      boardAddressModel: {
        displayName: null,
        latitude: null,
        longitude: null,
      },
      destAddressModel: {
        displayName: null,
        latitude: null,
        longitude: null,
      },
      transit1AddressModel: {
        displayName: null,
        latitude: null,
        longitude: null,
      },
      transit2AddressModel: {
        displayName: null,
        latitude: null,
        longitude: null,
      },
      transit3AddressModel: {
        displayName: null,
        latitude: null,
        longitude: null,
      },
      transit4AddressModel: {
        displayName: null,
        latitude: null,
        longitude: null,
      },
      //upresnovacie parametre pre autocomplete
      addressSearchData: {
        query: null,
        countryCode: null,
        city: null,
        taxiserviceId: null,
        //language: "sk",
      },
    };
  },

  created() {},

  mounted() {},

  computed: {},

  watch: {},

  methods: {
    getOrderWaypoints() {
      if (!this.orderIdParam) {
        this.toastSnackbar(false);
      } else {
        //console.log("getOrderWaypoints", this.orderIdParam);
        axios
          .get(
            process.env.VUE_APP_API_BASE_URL +
              `/dispatching/order/` +
              this.orderIdParam +
              "/waypoint"
          )
          .then((response) => {
            //this.animateProgressBar("ok"), (this.items = response.data);
            console.log(response.data);
            this.orderWaypointData = response.data;

            //vynulovanie waypointov
            this.boardWaypoint = null;
            this.destWaypoint = null;
            this.transit1Waypoint = null;
            this.transit2Waypoint = null;
            this.transit3Waypoint = null;
            this.transit4Waypoint = null;
            this.boardAddressModel = Object.assign(
              {},
              this.defaultAddressModel
            );
            this.destAddressModel = Object.assign({}, this.defaultAddressModel);
            this.transit1AddressModel = Object.assign(
              {},
              this.defaultAddressModel
            );
            this.transit2AddressModel = Object.assign(
              {},
              this.defaultAddressModel
            );
            this.transit3AddressModel = Object.assign(
              {},
              this.defaultAddressModel
            );
            this.transit4AddressModel = Object.assign(
              {},
              this.defaultAddressModel
            );

            var transitIndex = 1;
            response.data.forEach((element) => {
              /* ... */
              console.log(element);
              //Natlaci adresu do komponentu s autocomplete adresy
              if (element.type == "BOARD") {
                this.boardWaypoint = element;
                this.boardAddressModel.displayName = element.address;
                //console.log("element.address", element.address);
                this.boardAddressModel.latitude = element.latitude;
                this.boardAddressModel.longitude = element.longitude;
                this.boardAddressModel = Object.assign(
                  {},
                  this.boardAddressModel
                );
                this.addBoardAddress = this.boardAddressModel;
                //console.log("addBoardAddress", this.addBoardAddress);
              } else if (element.type == "DESTINATION") {
                this.destWaypoint = element;
                this.destAddressModel.displayName = element.address;
                //console.log("element.address", element.address);
                this.destAddressModel.latitude = element.latitude;
                this.destAddressModel.longitude = element.longitude;
                this.destAddressModel = Object.assign(
                  {},
                  this.destAddressModel
                );
                this.addDestAddress = this.destAddressModel;
                //console.log("addDestAddress", this.addDestAddress);
              } else if (
                element.type == "TRANSIT" &&
                transitIndex == 1 &&
                element.status != "DISABLED"
              ) {
                //Transit adresu, ktorá je zneplatnena/DISABLED nezobrazujeme v editacii, ale historicky ostava v DB, aby bolo vidno pripadne zmeny
                this.transit1Waypoint = element;
                transitIndex = transitIndex + 1;
                this.transit1AddressModel.displayName = element.address;
                //console.log("element.address", element.address);
                this.transit1AddressModel.latitude = element.latitude;
                this.transit1AddressModel.longitude = element.longitude;
                this.transit1AddressModel = Object.assign(
                  {},
                  this.transit1AddressModel
                );
                this.addTransit1Address = this.transit1AddressModel;
                //console.log("addTransit1Address", this.addTransit1Address);
              } else if (
                element.type == "TRANSIT" &&
                transitIndex == 2 &&
                element.status != "DISABLED"
              ) {
                //Transit adresu, ktorá je zneplatnena/DISABLED nezobrazujeme v editacii, ale historicky ostava v DB, aby bolo vidno pripadne zmeny
                this.transit2Waypoint = element;
                transitIndex = transitIndex + 1;
                this.transit2AddressModel.displayName = element.address;
                //console.log("element.address", element.address);
                this.transit2AddressModel.latitude = element.latitude;
                this.transit2AddressModel.longitude = element.longitude;
                this.transit2AddressModel = Object.assign(
                  {},
                  this.transit2AddressModel
                );
                this.addTransit2Address = this.transit2AddressModel;
                //console.log("transit2AddressModel", this.transit2AddressModel);
              } else if (
                element.type == "TRANSIT" &&
                transitIndex == 3 &&
                element.status != "DISABLED"
              ) {
                //Transit adresu, ktorá je zneplatnena/DISABLED nezobrazujeme v editacii, ale historicky ostava v DB, aby bolo vidno pripadne zmeny
                this.transit3Waypoint = element;
                transitIndex = transitIndex + 1;
                this.transit3AddressModel.displayName = element.address;
                //console.log("element.address", element.address);
                this.transit3AddressModel.latitude = element.latitude;
                this.transit3AddressModel.longitude = element.longitude;
                this.transit3AddressModel = Object.assign(
                  {},
                  this.transit3AddressModel
                );
                this.addTransit3Address = this.transit3AddressModel;
                //console.log("transit3AddressModel", this.transit3AddressModel);
              } else if (
                element.type == "TRANSIT" &&
                transitIndex == 4 &&
                element.status != "DISABLED"
              ) {
                //Transit adresu, ktorá je zneplatnena/DISABLED nezobrazujeme v editacii, ale historicky ostava v DB, aby bolo vidno pripadne zmeny
                this.transit4Waypoint = element;
                transitIndex = transitIndex + 1;
                this.transit4AddressModel.displayName = element.address;
                //console.log("element.address", element.address);
                this.transit4AddressModel.latitude = element.latitude;
                this.transit4AddressModel.longitude = element.longitude;
                this.transit4AddressModel = Object.assign(
                  {},
                  this.transit4AddressModel
                );
                this.addTransit4Address = this.transit4AddressModel;
                //console.log("transit4AddressModel", this.transit4AddressModel);
              }
            });
          })
          .catch((e) => {
            //this.animateProgressBar("error"), this.errors.push(e);
          });
      }
    },
    openEditSelectorDialog() {
      if (!this.orderIdParam) {
        this.toastSnackbar(false);
      } else {
        //Defaultne hodnoty pre ponuku s povolenymi vsetkymi polozkami
        this.disableAbortOrderDialog = false;
        //Skryvanie tlacidiel na zmenu na zaklade STAVOV objednavky
        if (this.orderStatusParam == "PREREGISTERED") {
          //Zobrazenie Dialogoveho okna s zoznamom moznosti na zmenu objednavky
          this.editSelectorDialog = true;
        } else if (this.orderStatusParam == "TRANSFERED") {
          //Zobrazenie Dialogoveho okna s zoznamom moznosti na zmenu objednavky
          this.editSelectorDialog = true;
        } else if (this.orderStatusParam == "NEW") {
          //Zobrazenie Dialogoveho okna s zoznamom moznosti na zmenu objednavky
          this.editSelectorDialog = true;
        } else if (this.orderStatusParam == "PLANNED") {
          //Zobrazenie Dialogoveho okna s zoznamom moznosti na zmenu objednavky
          this.editSelectorDialog = true;
        } else if (this.orderStatusParam == "PENDING") {
          //Zobrazenie Dialogoveho okna s zoznamom moznosti na zmenu objednavky
          this.editSelectorDialog = true;
        } else if (this.orderStatusParam == "ACCEPTED") {
          //Zobrazenie Dialogoveho okna s zoznamom moznosti na zmenu objednavky
          this.editSelectorDialog = false;
          this.toastSnackbar(false);
        } else if (this.orderStatusParam == "WAITING") {
          //Zobrazenie Dialogoveho okna s zoznamom moznosti na zmenu objednavky
          this.editSelectorDialog = false;
          this.toastSnackbar(false);
        } else if (this.orderStatusParam == "IN_PROGRESS") {
          //Zobrazenie Dialogoveho okna s zoznamom moznosti na zmenu objednavky
          this.editSelectorDialog = false;
          this.toastSnackbar(false);
        } else if (
          this.orderStatusParam == "FINISHED" ||
          this.orderStatusParam == "CHARGED" ||
          this.orderStatusParam == "SETTLED" ||
          this.orderStatusParam == "ARCHIVED" ||
          this.orderStatusParam == "CANCELLED_PREREGISTERED" ||
          this.orderStatusParam == "CANCELLED_NEW" ||
          this.orderStatusParam == "CANCELLED_PLANNED" ||
          this.orderStatusParam == "CANCELLED_PENDING" ||
          this.orderStatusParam == "CANCELLED_ACCEPTED" ||
          this.orderStatusParam == "CANCELLED_WAITING" ||
          this.orderStatusParam == "CANCELLED_IN_PROGRESS" ||
          this.orderStatusParam == "CANCELLED_FINISHED" ||
          this.orderStatusParam == "CANCELLED_CHARGED" ||
          this.orderStatusParam == "CANCELLED_SETTLED"
        ) {
          //NEzobrazenie Dialogoveho okna s zoznamom moznosti na zmenu objednavky
          this.editSelectorDialog = false;
          this.toastSnackbar(false);
        } else {
        }
      }
    },
    toastSnackbar(successType) {
      if (successType == true) {
        this.snackbar.message = this.$t("msgUpdateSuccess");
        this.snackbar.timeout = 2000;
        this.snackbar.color = "success";
      } else {
        this.snackbar.message = this.$t("msgUpdateError");
        this.snackbar.timeout = 4000;
        this.snackbar.color = "error";
      }
      this.snackbar.show = true;
    },

    callUpdateApi() {
      var dataForUpdate = [];
      //Vyskladanie odosielanych adries
      if (this.boardWaypoint) {
        //Ak existuje / dosiel zo servera waypoint
        if (this.boardAddressSwitchModel) {
          //Ak waypoint chceme vymazat
          this.orderBoardAddress = this.boardWaypoint; //vsetky povodne udaje waypointu
          this.orderBoardAddress.status = "DISABLED"; //zmena stavu na DISABLED, teda akokeby vymazany
        } else {
          //alebo ho upravime
          this.orderBoardAddress.address = this.boardAddressModel.displayName;
          this.orderBoardAddress.latitude = this.boardAddressModel.latitude;
          this.orderBoardAddress.longitude = this.boardAddressModel.longitude;

          this.orderBoardAddress.id = this.boardWaypoint.id;
        }
      } else if (!this.boardAddressSwitchModel) {
        //Vytvorenie noveho waypointu, iba ak nie je zaskrtnute ze sa ma vymazat
        this.orderBoardAddress.address = this.boardAddressModel.displayName;
        this.orderBoardAddress.latitude = this.boardAddressModel.latitude;
        this.orderBoardAddress.longitude = this.boardAddressModel.longitude;
      }
      if (this.destWaypoint) {
        //Ak existuje / dosiel zo servera waypoint
        if (this.destAddressSwitchModel) {
          //Ak waypoint chceme vymazat
          this.orderDestinationAddress = this.destWaypoint; //vsetky povodne udaje waypointu
          this.orderDestinationAddress.status = "DISABLED"; //zmena stavu na DISABLED, teda akokeby vymazany
        } else {
          //alebo ho upravime
          this.orderDestinationAddress.address =
            this.destAddressModel.displayName;
          this.orderDestinationAddress.latitude =
            this.destAddressModel.latitude;
          this.orderDestinationAddress.longitude =
            this.destAddressModel.longitude;

          this.orderDestinationAddress.id = this.destWaypoint.id;
        }
      } else if (!this.destAddressSwitchModel) {
        //Vytvorenie noveho waypointu, iba ak nie je zaskrtnute ze sa ma vymazat
        this.orderDestinationAddress.address =
          this.destAddressModel.displayName;
        this.orderDestinationAddress.latitude = this.destAddressModel.latitude;
        this.orderDestinationAddress.longitude =
          this.destAddressModel.longitude;
      }
      if (this.transit1Waypoint) {
        //Ak existuje / dosiel zo servera waypoint
        if (this.transit1AddressSwitchModel) {
          //Ak waypoint chceme vymazat
          this.orderTransit1Address = this.transit1Waypoint; //vsetky povodne udaje waypointu
          this.orderTransit1Address.status = "DISABLED"; //zmena stavu na DISABLED, teda akokeby vymazany
        } else {
          //alebo ho upravime
          this.orderTransit1Address.address =
            this.transit1AddressModel.displayName;
          this.orderTransit1Address.latitude =
            this.transit1AddressModel.latitude;
          this.orderTransit1Address.longitude =
            this.transit1AddressModel.longitude;
          this.orderTransit1Address.id = this.transit1Waypoint.id;
        }
      } else if (!this.transit1AddressSwitchModel) {
        //Vytvorenie noveho waypointu, iba ak nie je zaskrtnute ze sa ma vymazat
        this.orderTransit1Address.address =
          this.transit1AddressModel.displayName;
        this.orderTransit1Address.latitude = this.transit1AddressModel.latitude;
        this.orderTransit1Address.longitude =
          this.transit1AddressModel.longitude;
      }
      if (this.transit2Waypoint) {
        //Ak existuje / dosiel zo servera waypoint
        if (this.transit2AddressSwitchModel) {
          //Ak waypoint chceme vymazat
          this.orderTransit2Address = this.transit2Waypoint; //vsetky povodne udaje waypointu
          this.orderTransit2Address.status = "DISABLED"; //zmena stavu na DISABLED, teda akokeby vymazany
        } else {
          //alebo ho upravime
          this.orderTransit2Address.address =
            this.transit2AddressModel.displayName;
          this.orderTransit2Address.latitude =
            this.transit2AddressModel.latitude;
          this.orderTransit2Address.longitude =
            this.transit2AddressModel.longitude;

          this.orderTransit2Address.id = this.transit2Waypoint.id;
        }
      } else if (!this.transit2AddressSwitchModel) {
        //Vytvorenie noveho waypointu, iba ak nie je zaskrtnute ze sa ma vymazat
        this.orderTransit2Address.address =
          this.transit2AddressModel.displayName;
        this.orderTransit2Address.latitude = this.transit2AddressModel.latitude;
        this.orderTransit2Address.longitude =
          this.transit2AddressModel.longitude;
      }
      if (this.transit3Waypoint) {
        //Ak existuje / dosiel zo servera waypoint
        if (this.transit3AddressSwitchModel) {
          //Ak waypoint chceme vymazat
          this.orderTransit3Address = this.transit3Waypoint; //vsetky povodne udaje waypointu
          this.orderTransit3Address.status = "DISABLED"; //zmena stavu na DISABLED, teda akokeby vymazany
        } else {
          //alebo ho upravime
          this.orderTransit3Address.address =
            this.transit3AddressModel.displayName;
          this.orderTransit3Address.latitude =
            this.transit3AddressModel.latitude;
          this.orderTransit3Address.longitude =
            this.transit3AddressModel.longitude;

          this.orderTransit3Address.id = this.transit3Waypoint.id;
        }
      } else if (!this.transit3AddressSwitchModel) {
        //Vytvorenie noveho waypointu, iba ak nie je zaskrtnute ze sa ma vymazat
        this.orderTransit3Address.address =
          this.transit3AddressModel.displayName;
        this.orderTransit3Address.latitude = this.transit3AddressModel.latitude;
        this.orderTransit3Address.longitude =
          this.transit3AddressModel.longitude;
      }
      if (this.transit4Waypoint) {
        //Ak existuje / dosiel zo servera waypoint
        if (this.transit4AddressSwitchModel) {
          //Ak waypoint chceme vymazat
          this.orderTransit4Address = this.transit4Waypoint; //vsetky povodne udaje waypointu
          this.orderTransit4Address.status = "DISABLED"; //zmena stavu na DISABLED, teda akokeby vymazany
        } else {
          //alebo ho upravime
          this.orderTransit4Address.address =
            this.transit4AddressModel.displayName;
          this.orderTransit4Address.latitude =
            this.transit4AddressModel.latitude;
          this.orderTransit4Address.longitude =
            this.transit4AddressModel.longitude;

          this.orderTransit4Address.id = this.transit4Waypoint.id;
        }
      } else if (!this.transit4AddressSwitchModel) {
        //Vytvorenie noveho waypointu, iba ak nie je zaskrtnute ze sa ma vymazat
        this.orderTransit4Address.address =
          this.transit4AddressModel.displayName;
        this.orderTransit4Address.latitude = this.transit4AddressModel.latitude;
        this.orderTransit4Address.longitude =
          this.transit4AddressModel.longitude;
      }

      var dataForUpdate = [];
      if (
        this.orderBoardAddress.longitude &&
        this.orderBoardAddress.latitude &&
        this.orderBoardAddress.address
      ) {
        dataForUpdate.push(this.orderBoardAddress);
      }
      if (
        this.orderDestinationAddress.longitude &&
        this.orderDestinationAddress.latitude &&
        this.orderDestinationAddress.address
      ) {
        dataForUpdate.push(this.orderDestinationAddress);
      }
      if (
        this.orderTransit1Address.longitude &&
        this.orderTransit1Address.latitude &&
        this.orderTransit1Address.address
      ) {
        dataForUpdate.push(this.orderTransit1Address);
      }
      if (
        this.orderTransit2Address.longitude &&
        this.orderTransit2Address.latitude &&
        this.orderTransit2Address.address
      ) {
        dataForUpdate.push(this.orderTransit2Address);
      }
      if (
        this.orderTransit3Address.longitude &&
        this.orderTransit3Address.latitude &&
        this.orderTransit3Address.address
      ) {
        dataForUpdate.push(this.orderTransit3Address);
      }
      if (
        this.orderTransit4Address.longitude &&
        this.orderTransit4Address.latitude &&
        this.orderTransit4Address.address
      ) {
        dataForUpdate.push(this.orderTransit4Address);
      }
      axios
        .put(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/order/` +
            this.orderIdParam +
            "/waypoint",
          dataForUpdate
        )
        .then((response) => {
          //this.animateProgressBar("ok"), (this.items = response.data);
          console.log("OrderWaypointEdit.vue - callUpdateApi: ", response.data);
          //this.callPriceUpdateApi();
          this.editSelectorDialog = false;
          this.toastSnackbar(true);
          //delay execution of function to show order detail - aby sa stihol updatnut price
          setTimeout(() => {
            this.$emit("showOrderDetailByIdFromEdit", this.orderIdParam);
          }, 1500);
        })
        .catch((e) => {
          //this.animateProgressBar("error"), this.errors.push(e);
          console.log("OrderWaypointEdit.vue - callUpdateApi -error: ", e);
          this.toastSnackbar(false);
        });
      //console.log("OrderEmit.vue: ", this.orderIdParam);
    },

    //PUT api to request price change
    callPriceUpdateApi() {
      var urlToApi = this.orderIdParam + `/calculatePrice`;
      axios
        .put(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/order/` + urlToApi
        )
        .then((response) => {
          this.snackbar.message = this.$t(
            "ConsoleOrderWaypointEdit.msgPriceUpdated"
          );
          this.snackbar.timeout = 1500;
          this.snackbar.color = "success";
          this.snackbar.show = true;
        })
        .catch((error) => {
          this.snackbar.message = this.$t(
            "ConsoleOrderWaypointEdit.msgPriceNotUpdated"
          );
          this.snackbar.timeout = 4000;
          this.snackbar.color = "error";
          this.snackbar.show = true;
          console.error(
            "Orderedit.vue Error " +
              "callPriceUpdateApi" +
              " / " +
              this.orderIdParam +
              " / " +
              JSON.stringify(error)
          );
        });
    },

    getStatusColor(status) {
      return this.StatusColor[status];
    },
    animateProgressBar(progressStatus) {
      if (progressStatus == "start") {
        this.loading = true;
        this.loadingIndeterminate = false;
      } else if (progressStatus == "ok") {
        this.loading = false;
        this.loadingIndeterminate = false;
      } else if (progressStatus == "error") {
        //v pripade chyby sa prepne do rezimu animovaneho loadingu, kym sa neuskotocni uspesny pokus o ziskanie udajov
        this.loadingIndeterminate = true;
      }
    },
  },
};
</script>
