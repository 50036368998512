<template>
  <div>
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>{{
        $t("DriverVehicleList.page_title")
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>mdi-car-cog</v-icon>
    </v-toolbar>
    <v-row class="my-1" dense align="center">
      <v-col cols="12" md="2">
        <v-switch
          v-model="enabledDrivers"
          :label="$t('DriverVehicleList.enabledDriversSwitch')"
          dense
          small
        ></v-switch>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn small color="info" @click="getAll()">
          <v-icon left dark small> fa-redo-alt </v-icon>
          {{ $t("btnUpdate") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :loading="loadingData"
      :headers="gridHeaders"
      :items="gridItems"
      class="elevation-1"
      sort-by="lastName"
      :mobile-breakpoint="mobileBreakpointTable"
      dense
      :items-per-page="50"
      :footer-props="{
        itemsPerPageOptions: [15, 25, 50, 100, -1],
        showFirstLastPage: true,
      }"
      ><!-- Filtrovanie tabulky -->
      <template
        v-slot:[`body.prepend`]
        v-if="$vuetify.breakpoint.width > mobileBreakpointTable"
      >
        <tr>
          <td>
            <v-icon class="px-3">mdi-filter</v-icon>
          </td>
          <td>
            <v-text-field
              v-model="filter.internalName"
              type="text"
              clearable
              :label="$t('DriverList.filterLabel.internalName')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.firstName"
              type="text"
              clearable
              :label="$t('DriverList.filterLabel.firstName')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.lastName"
              type="text"
              clearable
              :label="$t('DriverList.filterLabel.lastName')"
            ></v-text-field>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </template>
      <template v-slot:[`body.prepend`] v-else>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.internalName"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('DriverList.filterLabel.internalName')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.firstName"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('DriverList.filterLabel.firstName')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.lastName"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('DriverList.filterLabel.lastName')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.validFrom`]="{ item }">
        <span>{{ $options.filters.formatDateLong(item.validFrom) }}</span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          small
          :color="getStatusColor(item.status)"
          class="px-1"
          dark
          label
        >
          {{ item.status }}
        </v-chip>
      </template>

      <template v-slot:[`item.active`]="{ item }">
        <v-chip
          small
          :color="getActiveColor(item.active)"
          class="px-1"
          dark
          label
        >
          {{ $t("activeTaxiserviceSelection." + item.active) }}
        </v-chip>
      </template>

      <template v-slot:[`item.workStatus`]="{ item }">
        <v-chip
          small
          :color="getWorkStatusColor(item.workStatus)"
          class="px-1"
          dark
          label
        >
          {{ $t("workStatus." + item.workStatus) }}
        </v-chip>
      </template>

      <!--<template v-slot:[`item.silence`]="{ item }">
        <v-simple-checkbox v-model="item.silence" disabled> </v-simple-checkbox>
      </template>-->
      <template v-slot:[`item.silence`]="{ item }">
        <v-icon
          v-if="item.silence"
          color="red"
          :title="$t('Dashboard.silenceEnabledTitle')"
        >
          mdi-bell-off
        </v-icon>
        <v-icon
          v-else
          color="grey"
          :title="$t('Dashboard.silenceDisabledTitle')"
        >
          mdi-bell
        </v-icon></template
      >

      <template v-slot:no-data>
        <v-btn color="error" @click="getAll()"> {{ $t("btnReload") }}</v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <!-- <v-icon small @click="detail(item.id)">fa-eye</v-icon> -->
        <!-- <v-icon small @click="deleteTutorial(item.id)">mdi-delete</v-icon> -->
        <v-icon
          small
          class="ml-1"
          @click="openAssignVehicleDialog(item.id)"
          :title="$t('DriverVehicleList.btnAssignVehicleTitle')"
        >
          mdi-car-cog</v-icon
        >
        <v-icon
          small
          class="ml-1"
          @click="openEndWorkShiftDialog(item.id)"
          title="Ukončiť pracovnú zmenu"
          :disabled="item.workStatus === 'OFFLINE'"
          >mdi-briefcase-clock-outline</v-icon
        >
      </template>
    </v-data-table>

    <!-- Dialog na pridelenie vozidla vodicovi -->
    <v-dialog v-model="showAssignVehicleDialog" max-width="600px">
      <v-card
        ><v-form ref="formAssignVehicle">
          <v-card-title style="word-break: normal">
            {{ $t("DriverVehicleList.dialogAssignTitle") }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" v-show="displayOnlyForAdmin">
                  <v-text-field
                    :label="$t('DriverVehicleList.driverId')"
                    v-model="assignVehicleData.driverId"
                    disabled
                    type="number"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('DriverVehicleList.firstName')"
                    v-model="assignVehicleInfoData.firstName"
                    disabled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('DriverVehicleList.lastName')"
                    v-model="assignVehicleInfoData.lastName"
                    disabled
                    dense
                  ></v-text-field>
                </v-col>
                <!-- autocomplete field with vehicleList values -->
                <v-col cols="12">
                  <v-autocomplete
                    v-model="assignVehicleData.vehicleId"
                    :items="vehicleList"
                    :search-input.sync="search"
                    item-text="fullName"
                    item-value="id"
                    :label="$t('DriverVehicleList.fullNameVehicle')"
                    dense
                    clearable
                    :rules="[(v) => !!v || 'Povinné pole']"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-avatar
                        :color="getVehicleColor(item.color)"
                        :class="
                          item.color == 'WHITE' ||
                          item.color == 'SILVER' ||
                          item.color == 'BEIGE' ||
                          item.color == 'LIGHTBLUE' ||
                          item.color == 'SKYBLUE'
                            ? 'text-h5 font-weight-light black--text'
                            : 'text-h5 font-weight-light white--text'
                        "
                      >
                        {{
                          item.internalName
                            ? item.internalName.substring(0, 3)
                            : "-"
                        }}
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          item.fullName
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          item.assignedList
                        }}</v-list-item-subtitle>
                        <v-list-item-subtitle>{{
                          item.inUseList
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon>mdi-plus-circle</v-icon>
                      </v-list-item-action>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="submitAssignVehicle(true)"
            >
              {{ $t("btnAssign") }}
            </v-btn>
            <v-btn color="error" text @click="showAssignVehicleDialog = false">
              {{ $t("btnClose") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Dialog na ukoncenie pracovnej zmeny vodica dispecerom -->
    <v-dialog v-model="showEndWorkShiftDialog" max-width="600px">
      <v-card
        ><v-form ref="formEndWorkShift">
          <v-card-title style="word-break: normal">
            {{ $t("DriverVehicleList.dialogEndWorkshiftTitle") }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" v-show="displayOnlyForAdmin">
                  <v-text-field
                    :label="$t('DriverVehicleList.driverId')"
                    v-model="endWorkShiftData.driverId"
                    disabled
                    type="number"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('DriverVehicleList.firstName')"
                    v-model="endWorkShiftInfoData.firstName"
                    disabled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('DriverVehicleList.lastName')"
                    v-model="endWorkShiftInfoData.lastName"
                    disabled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <!-- minimum 0 -->
                  <v-text-field
                    :label="$t('DriverVehicleList.mileages')"
                    v-model="endWorkShiftData.mileages"
                    type="number"
                    min="0"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="submitEndWorkShift()">
              {{ $t("DriverVehicleList.btnEndShift") }}
            </v-btn>
            <v-btn color="error" text @click="showEndWorkShiftDialog = false">
              {{ $t("btnClose") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";

export default {
  data() {
    return {
      displayOnlyForAdmin: false,
      enabledDrivers: true,
      moment: moment,
      //Prepinanie zobrazenia tabulky pri malom rozliseni z dat v riadkoch na standardnu stlpcovu tabulku
      mobileBreakpointSwitch: false,
      mobileBreakpointTable: 600,

      gridHeaders: [
        {
          text: this.$t("DriverVehicleList.tableHeaders.actions"),
          value: "actions",
          sortable: false,
        },
        {
          text: this.$t("DriverVehicleList.tableHeaders.internalName"),
          value: "internalName",
          sortable: true,
          filter: (value) => {
            if (!this.filter.internalName) {
              return value === "" || value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.internalName.toLowerCase());
            }
          },
        },
        {
          text: this.$t("DriverVehicleList.tableHeaders.firstName"),
          value: "firstName",
          sortable: true,
          filter: (value) => {
            if (!this.filter.firstName) {
              return value === "" || value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.firstName.toLowerCase());
            }
          },
        },
        {
          text: this.$t("DriverVehicleList.tableHeaders.lastName"),
          value: "lastName",
          sortable: true,
          filter: (value) => {
            if (!this.filter.lastName) {
              return value === "" || value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.lastName.toLowerCase());
            }
          },
        },
        {
          text: this.$t("DriverVehicleList.tableHeaders.active"),
          value: "active",
        },
        {
          text: this.$t("DriverVehicleList.tableHeaders.workStatus"),
          value: "workStatus",
        },
        {
          text: this.$t("DriverVehicleList.tableHeaders.silence"),
          value: "silence",
        },
        {
          text: this.$t(
            "DriverVehicleList.tableHeaders.vehicleAssignedInternalNameLpn"
          ),
          value: "vehicleAssignedInternalNameLpn",
        },
        {
          text: this.$t(
            "DriverVehicleList.tableHeaders.vehicleAssignedBrandModel"
          ),
          value: "vehicleAssignedBrandModel",
        },
        {
          text: this.$t(
            "DriverVehicleList.tableHeaders.vehicleSelectedInternalNameLpn"
          ),
          value: "vehicleSelectedInternalNameLpn",
        },
        {
          text: this.$t(
            "DriverVehicleList.tableHeaders.vehicleSelectedBrandModel"
          ),
          value: "vehicleSelectedBrandModel",
        },
      ],
      gridItems: [],

      loadingData: false,

      //Zoznam vozidiel
      vehicleList: [],
      search: null,

      filter: {
        search: "",
        internalName: "",
        firstName: "",
        lastName: "",
      },

      //Dialogove okno na pridelovanie vozidiel
      showAssignVehicleDialog: false,

      assignVehicleData: {
        driverId: null,
        vehicleId: null,
      },
      assignVehicleInfoData: {
        firstName: null,
        lastName: null,
        status: null,
      },

      //Dialogove okno na ukoncenie zmeny
      showEndWorkShiftDialog: false,

      endWorkShiftData: {
        driverId: null,
        mileages: null,
      },

      endWorkShiftInfoData: {
        driverId: null,
        firstName: null,
        lastName: null,
        status: null,
      },

      //Snackbar
      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 4000,
      },
    };
  },

  mounted() {
    //Nacitanie role podla ktorej sa potom zobrazuju niektore extra polia alebo tlacidla
    this.getUserRole();
    this.getAll();
  },
  watch: {
    mobileBreakpointSwitch: function () {
      //ak sa zmeni mobileBreakpointSwitch, nadefinovat hodnotu pre mobileBreakpoint
      if (this.mobileBreakpointSwitch === true) {
        this.mobileBreakpointTable = 0;
      } else {
        this.mobileBreakpointTable = 600;
      }
      //console.log("mobileBreakpointTable", this.mobileBreakpointTable);
      this.setCookie("mobileBreakpointSwitch", this.mobileBreakpointSwitch, 30);
    },
  },

  methods: {
    getUserRole() {
      let enabledRoles = ["ADMIN", "SUPPORT"];
      let userRoles = this.$store.state.user.roleList;
      this.displayOnlyForAdmin =
        userRoles.some((a) => (enabledRoles.includes(a) ? true : false)) ===
        true;
    },
    getAll() {
      this.loadingData = true;
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/driver?enabled=${this.enabledDrivers}`
        )
        .then((response) => {
          this.loadingData = false;
          this.gridItems = response.data;
          this.getAllVehicles();
        })
        .catch((e) => {
          this.loadingData = false;
          this.snackbar.show = true;
          this.snackbar.message = this.$t("msgGetDataError");
          this.snackbar.color = "error";
        });
    },
    /* detail(id) {
      this.$router.push({ name: "pricelist-detail", params: { id: id } });
    },*/

    getAllVehicles() {
      //this.loadingData = true;
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/vehicle`)
        .then((response) => {
          //this.loadingData = false;
          var vehicleList = response.data;
          var tempModifiedVehicleData = [];
          var gridItems = this.gridItems;
          //add to array this.vehicleList full name of vehicle (internal name + lpn + brand + model)
          vehicleList.forEach((vehicle) => {
            //zistim ci je vozidlo pridelene nejakemu vodicovi
            var vehicleAssigned = false;
            var vehicleAssignedText = "";
            var vehicleInUse = false;
            var vehicleInUseText = "";
            gridItems.forEach((driver) => {
              if (
                driver.vehicleAssignedId == vehicle.id
                //&& driver.active == "ACTIVE" &&
                //driver.workStatus != "OFFLINE"
              ) {
                vehicleAssigned = true;
                vehicleAssignedText =
                  (vehicleAssignedText.length > 0
                    ? vehicleAssignedText + ", "
                    : "") +
                  driver.internalName +
                  " " +
                  driver.firstName +
                  " " +
                  driver.lastName;
              }
              if (
                driver.vehicleId == vehicle.id &&
                driver.active == "ACTIVE" &&
                driver.workStatus != "OFFLINE"
              ) {
                vehicleInUse = true;
                vehicleInUseText =
                  driver.internalName +
                  " " +
                  driver.firstName +
                  " " +
                  driver.lastName;
              }
            });
            //ak je pridelene tak ho oznacim v zozname vozidiel
            vehicle.fullName =
              vehicle.internalName +
              " " +
              vehicle.lpn +
              " " +
              vehicle.brand +
              " " +
              vehicle.model;
            if (vehicleAssigned) {
              vehicle.assignedList =
                this.$t("DriverVehicleList.listAssigned") +
                ": " +
                vehicleAssignedText;
            }
            if (vehicleInUse) {
              vehicle.inUseList =
                this.$t("DriverVehicleList.listUsed") + ": " + vehicleInUseText;
            }
            tempModifiedVehicleData.push(vehicle);
          });
          // tempModifiedVehicleData order by fullName
          tempModifiedVehicleData.sort(function (a, b) {
            var nameA = a.fullName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.fullName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          });
          //add at begining of array this.vehicleList object -1
          tempModifiedVehicleData.unshift({
            id: -1,
            fullName: this.$t("DriverVehicleList.listRemoveVehicle"),
            internalName: "-",
            color: "RED",
          });
          this.vehicleList = tempModifiedVehicleData;

          //add vehicle to driver where vehicleAssignedId from this.gridItems is equal to id from this.vehicleList
          var tempModifiedData = [];
          this.gridItems.forEach((driver) => {
            vehicleList.forEach((vehicle) => {
              if (driver.vehicleAssignedId != null) {
                if (driver.vehicleAssignedId == vehicle.id) {
                  driver.vehicleAssignedInternalName = vehicle.internalName;
                  driver.vehicleAssignedLpn = vehicle.lpn;
                  driver.vehicleAssignedBrand = vehicle.brand;
                  driver.vehicleAssignedModel = vehicle.model;
                  driver.vehicleAssignedBrandModel =
                    vehicle.brand + " " + vehicle.model;
                  driver.vehicleAssignedInternalNameLpn =
                    vehicle.internalName + " " + vehicle.lpn;
                }
              }
              if (driver.vehicleId != null) {
                if (driver.vehicleId == vehicle.id) {
                  driver.vehicleSelectedInternalName = vehicle.internalName;
                  driver.vehicleSelectedLpn = vehicle.lpn;
                  driver.vehicleSelectedBrand = vehicle.brand;
                  driver.vehicleSelectedModel = vehicle.model;
                  driver.vehicleSelectedBrandModel =
                    vehicle.brand + " " + vehicle.model;
                  driver.vehicleSelectedInternalNameLpn =
                    vehicle.internalName + " " + vehicle.lpn;
                }
              }
            });
            tempModifiedData.push(driver);
          });
          this.gridItems = tempModifiedData;
          //console.log("this.gridItems", tempModifiedData);
        })
        .catch((e) => {
          //this.loadingData = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgUpdateError");
          this.snackbar.color = "error";
        });
    },

    openAssignVehicleDialog(id) {
      if (this.$refs.formAssignVehicle) {
        this.$refs.formAssignVehicle.resetValidation();
        /*this.$nextTick(() => {
          if (this.$refs[formEdit]) {
            this.$refs.formEdit.resetValidation();
          }
        });*/
      }
      var gridItems;
      var selectedItem;
      gridItems = this.gridItems;
      selectedItem = Object.assign(
        {},
        gridItems.filter(function (elem) {
          if (elem.id == id) return elem;
        })[0]
      );
      //console.log("selectedItem", selectedItem);
      if (selectedItem) {
        this.assignVehicleData.driverId = selectedItem.id;
        this.assignVehicleData.vehicleId = null;
        this.assignVehicleInfoData.firstName = selectedItem.firstName;
        this.assignVehicleInfoData.lastName = selectedItem.lastName;
        this.assignVehicleInfoData.status = selectedItem.status;

        this.showAssignVehicleDialog = true;
      } else {
        this.snackbar.show = true;
        this.snackbar.message = this.$t("msgDataMissing");
        this.snackbar.color = "error";
      }
    },
    submitAssignVehicle() {
      const isValid = this.$refs.formAssignVehicle.validate();
      //console.log("submitChangeStatus", this.assignVehicleData);
      var suffixVehicle = "";
      if (isValid) {
        if (this.assignVehicleData.vehicleId == -1) {
          //-1 means that driver will be unassigned from vehicle
          suffixVehicle = "";
        } else {
          suffixVehicle = "&vehicleId=" + this.assignVehicleData.vehicleId;
        }
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL +
              `/dispatching/vehicle/assignToDriver?driverId=${this.assignVehicleData.driverId}` +
              suffixVehicle
          )
          .then((response) => {
            this.showAssignVehicleDialog = false;
            //this.detail(response.data.id);
            this.getAll(); // zatial len refresh nemame detail
            this.snackbar.show = true;
            this.snackbar.message = this.$t(
              "DriverVehicleList.msgAssigmentSuccesufull"
            );
            this.snackbar.color = "success";
          })
          .catch((e) => {
            this.snackbar.show = true;
            this.snackbar.message = this.$t("msgUpdateError");
            this.snackbar.color = "error";
          });
      }
    },
    openEndWorkShiftDialog(id) {
      if (this.$refs.formEndWorkShift) {
        this.$refs.formEndWorkShift.resetValidation();
      }
      var gridItems;
      var selectedItem;
      gridItems = this.gridItems;
      selectedItem = Object.assign(
        {},
        gridItems.filter(function (elem) {
          if (elem.id == id) return elem;
        })[0]
      );
      if (selectedItem) {
        this.endWorkShiftData.driverId = selectedItem.id;
        this.endWorkShiftInfoData.firstName = selectedItem.firstName;
        this.endWorkShiftInfoData.lastName = selectedItem.lastName;
        this.endWorkShiftInfoData.status = selectedItem.status;

        this.showEndWorkShiftDialog = true;
      } else {
        this.snackbar.show = true;
        this.snackbar.message = this.$t("msgDataMissing");
        this.snackbar.color = "error";
      }
    },
    submitEndWorkShift() {
      const isValid = this.$refs.formEndWorkShift.validate();
      //console.log("formEndWorkShift", this.endWorkShiftData);
      if (isValid) {
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL +
              `/dispatching/driver/worklog/finish-work`,
            this.endWorkShiftData
          )
          .then((response) => {
            this.showEndWorkShiftDialog = false;
            //this.detail(response.data.id);
            this.getAll(); // zatial len refresh vsetkeho, nemame api na detail
            this.snackbar.show = true;
            if (response.data.message == "DRIVER_HAS_ORDER") {
              this.snackbar.message = this.$t(
                "DriverVehicleList.msgEndWorkshiftErrorDriverHasOrder"
              );
              this.snackbar.color = "error";
            } else {
              this.snackbar.message = this.$t(
                "DriverVehicleList.msgEndWorkshiftSuccesufull"
              );
              this.snackbar.color = "success";
            }
          })
          .catch((e) => {
            this.snackbar.show = true;
            this.snackbar.message = this.$t("msgUpdateError");
            this.snackbar.color = "error";
          });
      }
    },
    getStatusColor(status) {
      switch (status) {
        case "ENABLED":
          return "green";
        case "DISABLED":
          return "red";
        default:
          return "black";
      }
    },
    getActiveColor(status) {
      switch (status) {
        case "ACTIVE":
          return "green";
        case "DISABLED":
          return "red";
        default:
          return "black";
      }
    },
    getWorkStatusColor(status) {
      switch (status) {
        case "ONLINE":
          return "green";
        case "OFFLINE":
          return "red";
        case "ON_BREAK":
          return "orange";
        default:
          return "black";
      }
    },
    getVehicleColor(color) {
      switch (color) {
        case "WHITE":
          return "#FFFFFF";
        case "BLACK":
          return "#000000";
        case "SILVER":
          return "#C0C0C0";
        case "GRAY":
          return "#696969";
        case "BROWN":
          return "#996633";
        case "PURPLE":
          return "#800080";
        case "VIOLET":
          return "#EE82EE";
        case "BLUE":
          return "#0000FF";
        case "DARKBLUE":
          return "#00008B";
        case "LIGHTBLUE":
          return "#ADD8E6";
        case "SKYBLUE":
          return "#87CEEB";
        case "RED":
          return "#FF0000";
        case "ORANGE":
          return "#ffa500";
        case "DARKORANGE":
          return "#FF8C00";
        case "YELLOW":
          return "#FFFF00";
        case "GREEN":
          return "#00FF00";
        case "DARKGREEN":
          return "#006400";
        case "LIGHTGREEN":
          return "#90EE90";
        case "PINK":
          return "#FF99CC";
        case "BEIGE":
          return "#F5F5DC";
        case "GOLD":
          return "#FFD700";
        default:
          return "#000000";
      }
    },
  },
};
</script>
