//https://en.wikipedia.org/wiki/List_of_mobile_phone_number_series_by_country
export const mobilePrefixes = {
    "421": ["901", "902", "903", "904", "905", "906", "907", "908", "910", "911", "912", "914", "915", "916", "917", "918", "940", "944", "945", "948", "949", "950", "951"], //Slovakia
    "420": ["601", "602", "603", "604", "605", "606", "607", "608", "702", "72", "73", "77", "790"], //Czech Republic
    "36": ["20", "30", "31", "38", "50", "60", "70"], //Hungary
    "48": ["45", "50", "51", "53", "57", "60", "66", "69", "72", "73", "78", "79", "88"], //Poland
    "380": ["50", "63", "66", "67", "68", "73", "91", "92", "93", "94", "95", "96", "97", "98", "99"], //Ukraine
    "44": ["71", "72", "73", "74", "75", "76", "77", "78", "79"], //United Kingdom
    "93": ["24", "70", "71", "72", "73", "74", "75", "76", "77", "78", "79"], //Afghanistan
    "355": ["6"], //Albania
    "213": ["5", "6", "7"], //Algeria
    "1684": [""], //American Samoa
    "376": ["3"], //Andorra
    "244": ["9"], //Angola
    "1264": ["772"], //Anguilla
    "1268": ["7"], //Antigua and Barbuda
    "54": ["9"], //Argentina
    "374": ["41", "43", "44", "55", "77", "93", "94", "95", "96", "97", "98", "99"], //Armenia
    "297": ["5", "6", "7"], //Aruba
    "61": ["4", "7"], //Australia
    "43": ["650", "660", "664", "676", "677", "680", "681", "682", "683", "688", "699"], //Austria
    "994": ["41", "50", "51", "55", "70", "77", "99"], //Azerbaijan
    "1242": ["35", "45", "55"], //Bahamas
    "973": ["3", "6"], //Bahrain
    "880": ["13", "14", "15", "16", "17", "18", "19"], //Bangladesh
    "1246": [""], //Barbados
    "375": ["25", "29", "33", "44"], //Belarus
    "32": ["456", "47", "48", "49"], //Belgium
    "501": ["6"], //Belize
    "229": ["6", "9"], //Benin
    "1441": [""], //Bermuda
    "975": ["17"], //Bhutan
    "591": ["6", "7"], //Bolivia
    "387": ["60", "61", "62", "63", "64", "65", "66", "67", "68", "69"], //Bosnia and Herzegovina
    "267": ["5", "71", "72", "73", "74", "75", "76", "77", "78", "79"], //Botswana
    "55": [], //Brazil
    "246": ["380", "387"], //British Indian Ocean Territory
    "1284": [""], //British Virgin Islands
    "673": ["7", "8"], //Brunei
    "359": ["48", "87", "88", "89", "98"], //Bulgaria
    "226": ["25", "7    "], //Burkina Faso
    "257": ["7"], //Burundi
};