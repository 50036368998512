<template>
  <div>
    <v-autocomplete
      :value="value"
      @input="updateValue($event)"
      :items="items"
      :loading="isLoading"
      :search-input.sync="searchAddress"
      :label="label"
      :placeholder="$t('AutocompleteCityRemote.placeholder')"
      :prepend-icon="prependIcon"
      clearable
      :outlined="outlined"
      :filled="filled"
      :disabled="disabled"
      :filter="filter"
      v-bind="searchParams"
      :hide-details="hideDetails"
      dense
    >
      <!--    <template slot="selection" slot-scope="{ item }">
      {{ item.displayName }}
    </template>

    <template slot="item" slot-scope="{ item }">
      {{ item.displayName }}
    </template>-->
      <!-- Dialog na Vytvorenie novej objednavky-->
    </v-autocomplete>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    value: {
      type: String,
      default: null,
    },

    label: {
      type: String,
      default: "City",
    },
    prependIcon: {
      type: String,
      default: "",
    },

    filled: {
      type: Boolean,
    },
    outlined: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    hideDetails: {
      type: Boolean,
    },
    searchParams: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      items: [], //["bratislava", "trnava"]
      isLoading: false,
      model: null,

      historyItems: [],

      searchAddress: null,

      searchData: {
        query: null,
        country: null,
        city: null,
        taxiservicesId: null,
        language: null,
      },

      debounce: null,

      //Snackbar
      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },
    };
  },

  created() {},

  mounted() {},

  computed: {},

  watch: {
    searchAddress(value) {
      //Ak nie je ziadna hodnota, nevyhladavat.
      if (!value) {
        return;
      }
      //Ak je adresa z google, nevyhladavat
      if (this.newPlaceFlag == true) {
        this.newPlaceFlag = false; //po jednorazovom obmedzeni vyhladavania ak je priamo z google, tak opat povolit vyhladavanie
        return;
      }
      //Ak je hodnota kratšia ako 3 znaky, tak nevyhladavat
      if (value.length < 3) {
        return;
      }
      //countryCode je povinny udaj, bez neho nevyhladavat
      if (!this.searchParams.countryCode) {
        alert(this.$t("AutocompleteCityRemote.msgSelectCountry"));
        return;
      }
      // this.clearEntries();
      this.searchData.query = value;
      this.searchData.countryCode = this.searchParams.countryCode;
      //Nacitat z localstorage ID taxisluzby, lebo kazda taxisluzba si tvori vlasny zoznam adries/Places
      this.searchData.taxiserviceId = localStorage.getItem(
        "selectedTaxiserviceId"
      );
      //this.searchData.taxiserviceId = this.searchParams.taxiserviceId;
      //this.searchData.language = this.searchParams.language;
      //this.searchAddressApi();
      this.isLoading = true;
      this.fetchEntriesDebounced();
    },
    /* findNewPlaceDialog: function (val) {
      this.$refs.autocomplete.focus();
    },*/
  },

  methods: {
    fetchEntriesDebounced() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchAddressApi();
      }, 800); /* 500ms throttle */
    },

    clearSearch() {
      //console.log("test cleareable");
      var temporaryAddress = [];
      //set historyItems to temporaryAddress
      //temporaryAddress = this.historyItems;
      /*temporaryAddress.latitude = null;
      temporaryAddress.longitude = null;
      temporaryAddress.displayName = null;*/
      this.updateValue(Object.assign({}, temporaryAddress));
      this.items.push(Object.assign({}, temporaryAddress));
    },

    updateValue(value) {
      //console.log("updateValue: ", value);
      /* if (!value) {
        var temporaryAddress = [];
        temporaryAddress.latitude = null;
        temporaryAddress.longitude = null;
        temporaryAddress.displayName = "";
        value = Object.assign({}, temporaryAddress);
      }*/
      this.$emit("input", value);
      //this.historyItems.push(value);
      //remove duplicates
      
    },

    filter(item, queryText, itemText) {
      return item
        .toLocaleLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(
          queryText
            .toLocaleLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        );
    },

    searchAddressApi() {
      this.isLoading = true;
      //console.log("search: ", this.searchData.query),
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/place/cities`, {
          //.get(`https://addresses.taxximo.com/places/geo`, {
          params: this.searchData,
        })
        .then((res) => {
          //console.log("searchAddresssApi - response", res);
          if (res.data.length > 0) {
            this.items = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },

    clearEntries() {
      this.items = [];
      //set historyItems to temporaryAddress
      //this.items.push(Object.assign({}, this.historyItems));
    },
  },

  beforeDestroy() {
    clearInterval(this.debounce);
  },
};
</script>
