<template>
  <div>
    <div id="map" ref="map"></div>
    <!-- switch trafic information trafficButton-->
    <input
      id="trafficButton"
      ref="trafficButton"
      type="checkbox"
      class="hidden"
      title="Show traffic information"
    />
    <!--<div id="switchDarkMapButton" ref="switchDarkMapButton">
      <v-card class="pa-2 ma-2">
        <v-switch
          class="pa-0 ma-0"
          dense
          hide-details
          v-model="switchDarkMapModel"
          label="Dark map"
        ></v-switch>
      </v-card>
    </div>-->
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>
<style scoped>
#map {
  height: 100vh;
}
#trafficButton {
  cursor: pointer;
  margin-bottom: 10px;
  padding: 0px 17px;
  height: 40px;
}
</style>
<style>
/* google zmenil margin pre X v bublinke, treba upravit aby to nebolo take velke */
button.gm-ui-hover-effect {
  width: 28px !important;
  height: 22px !important;
}
button.gm-ui-hover-effect > span {
  margin: 2px !important;
}
</style>
<script>
import axios from "axios";
import * as moment from "moment/moment";
import { StatusColor } from "../enums/OrderEnum";
// Navod na html marker: https://developers.google.com/maps/documentation/javascript/advanced-markers/html-markers#simple_html_marker

export default {
  name: "Map",
  props: {
    passOperationalDataDriversOnMap: {
      type: Array,
      default: [],
    },
    passOperationalDataOrdersOnMap: {
      type: Array,
      default: [],
    },
    passSelectedBoardAddressOnMap: {
      type: Object,
      default: [],
    },
    passSelectedDestinationAddressOnMap: {
      type: Object,
      default: [],
    },
    passSelectedTransit1AddressOnMap: {
      type: Object,
      default: [],
    },
    passSelectedTransit2AddressOnMap: {
      type: Object,
      default: [],
    },
    passSelectedTransit3AddressOnMap: {
      type: Object,
      default: [],
    },
    passSelectedTransit4AddressOnMap: {
      type: Object,
      default: [],
    },
    passFindDriverOnMap: {
      type: Number,
      default: null,
    },
    //workaround na opatovne zobrazenie polohy vodica
    passFindDriverOnMapTrigger: {
      type: Number,
      default: 0,
    },
    passFindPositionOnMap: {
      type: Array,
      default: [],
    },
    switchDarkMapModel: { type: Boolean, default: false },
    pathDriverSetting: { type: String, default: "INPROGRESS05MIN" },
    iconDriverSetting: { type: String, default: "PIN" }, //PIN, SIMPLECAR, SVGCAR
    iconDriverLabelSetting: { type: String, default: "VEHICLEINTERNAL" },
    orderMarkerSetting: { type: String, default: "PIN" }, //NEVER, PIN, PINLINE, PINLINERED
    driverSpeedSetting: { type: Boolean, default: false },
  },
  data() {
    return {
      map: null,
      mapCenter: { lat: 48.148, lng: 18.127 },

      StatusColor,

      markerAddress: [],
      markerDriver: "",
      pathDriver: "",
      orderLines: [],
      markerImage: [],
      boundsToAllDrivers: [],
      infoWindows: [],
      activeInfoWindow: null,
      //markery s polohou vyzdvihnutia
      orderMarkers: [],
      //marker ktory sa pouziva na rozne ucely, napr. na indikaciu, kde je waypoint na mape
      temporaryMarker: null,
      //Kontrola ci je stlacena klavesa CRTL
      ctrlPressed: false,
      /*    markerImage2:
        [ ONLINE, OFFLINE, ON_BREAK, DELAY, WARNING, BUSY, ACCEPTED, WAITING, IN_PROGRESS, FINISHED ],
        */
      //parametre pre bublinku nad markerom vodica
      /*driverWindowLabelData: {
        driverId: null,
        orderId: null,
      },*/
      mapTheme: null,
      darkMap: [
        { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
        {
          elementType: "labels.text.stroke",
          stylers: [{ color: "#242f3e" }],
        },
        { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#263c3f" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ color: "#6b9a76" }],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#38414e" }],
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [{ color: "#212a37" }],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9ca5b3" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ color: "#746855" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [{ color: "#1f2835" }],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [{ color: "#f3d19c" }],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [{ color: "#2f3948" }],
        },
        {
          featureType: "transit.station",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#17263c" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [{ color: "#515c6d" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.stroke",
          stylers: [{ color: "#17263c" }],
        },
      ],
      //Snackbar
      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 3000,
      },
    };
  },
  watch: {
    switchDarkMapModel: function (newValue, oldValue) {
      if (newValue == true) {
        this.mapTheme = this.darkMap;
      } else {
        this.mapTheme = null;
      }
      this.map.setOptions({ styles: this.mapTheme });
    },
    passOperationalDataDriversOnMap: function () {
      //console.log(this.passOperationalDataDriversOnMap);
      var tempData = this.passOperationalDataDriversOnMap;
      this.manageDriverMarker(tempData);
    },
    passOperationalDataOrdersOnMap: function () {
      //console.log(this.passOperationalDataOrdersOnMap);
      var tempData = this.passOperationalDataOrdersOnMap;
      this.manageOrderMarker(tempData);
      this.manageOrderLines(tempData);
      this.allOrderData = this.passOperationalDataOrdersOnMap;
    },
    passSelectedBoardAddressOnMap: function () {
      //console.log(this.passSelectedBoardAddressOnMap.displayName, this.passSelectedBoardAddressOnMap.lat,  this.passSelectedBoardAddressOnMap.lng)
      const addressType = "BOARD";
      var tempPosition = this.passSelectedBoardAddressOnMap;
      this.manageAddressMarker(addressType, tempPosition);
    },
    passSelectedDestinationAddressOnMap: function () {
      //console.log(this.passSelectedBoardAddressOnMap.displayName, this.passSelectedBoardAddressOnMap.lat,  this.passSelectedBoardAddressOnMap.lng)
      const addressType = "DESTINATION";
      var tempPosition = this.passSelectedDestinationAddressOnMap;
      this.manageAddressMarker(addressType, tempPosition);
    },
    passSelectedTransit1AddressOnMap: function () {
      const addressType = "TRANSIT1";
      var tempPosition = this.passSelectedTransit1AddressOnMap;
      this.manageAddressMarker(addressType, tempPosition);
    },
    passSelectedTransit2AddressOnMap: function () {
      const addressType = "TRANSIT2";
      var tempPosition = this.passSelectedTransit2AddressOnMap;
      this.manageAddressMarker(addressType, tempPosition);
    },
    passSelectedTransit3AddressOnMap: function () {
      const addressType = "TRANSIT3";
      var tempPosition = this.passSelectedTransit3AddressOnMap;
      this.manageAddressMarker(addressType, tempPosition);
    },
    passSelectedTransit4AddressOnMap: function () {
      const addressType = "TRANSIT4";
      var tempPosition = this.passSelectedTransit4AddressOnMap;
      this.manageAddressMarker(addressType, tempPosition);
    },
    /*passFindDriverOnMap: function () {
      var tempDriverId = this.passFindDriverOnMap;
      this.findDriverOnMap(tempDriverId);
      console.log("passFindDriverOnMap", tempDriverId);
    },*/
    passFindDriverOnMapTrigger: function (newValue, oldValue) {
      var tempDriverId = this.passFindDriverOnMap;
      this.findDriverOnMap(tempDriverId);
      //console.log("passFindDriverOnMapTrigger", tempDriverId);
    },
    passFindPositionOnMap: function () {
      var tempData = this.passFindPositionOnMap;
      this.findPositionOnMap(tempData);
    },
  },
  computed: {},
  mounted() {
    //Mostik medzi plain javascript funkciou a vue metodov
    window.showOrderDetail = this.showOrderDetail;
    window.setDriverToDetailFromMap = this.setDriverToDetailFromMap;
    window.setDriverToMessageFromMap = this.setDriverToMessageFromMap;
    window.setOrderToMessageFromMap = this.setOrderToMessageFromMap;
    window.setBestDriver = this.setBestDriver;
    window.showPositionOnMap = this.showPositionOnMap;
    /*const theme = localStorage.getItem("dark_theme");
    if (theme) {
      if (theme == "true") {
        this.mapTheme = this.darkMap;
      } else {
        this.mapTheme = null;
      }
    }*/
    this.generateMarkers();
    this.initMap();
    //this.createTemporaryMarker();
    //this.rightClickListerner();
    //this.setMarker(this.mapCenter, "A");
  },
  methods: {
    initMap() {
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: this.mapCenter, //the center of the map
        zoom: 7,
        maxZoom: 17, //17
        minZoom: 7,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP, // typ mapy s cestami
        disableDefaultUI: true,
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.BOTTOM_CENTER, //umiestnenie prepínača typu mapy - dole v strede
          mapTypeIds: [
            google.maps.MapTypeId.ROADMAP,
            google.maps.MapTypeId.HYBRID,
          ],
        },
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.BOTTOM_CENTER, //BOTTOM_LEFT
        },
        scaleControl: false,
        styles: this.mapTheme,
        gestureHandling: "greedy", //'greedy' - pohyb jednym prstom na mobilnom zariadeni bude scrollovanie mapy, nie zoomovanie
      });
      var mapa = this.map;
      //position traffic information button to bottom_center
      var trafficButton = document.getElementById("trafficButton");
      mapa.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(
        trafficButton
      );
      var trafficLayer = new google.maps.TrafficLayer();
      trafficButton.addEventListener("click", function () {
        if (trafficLayer.getMap()) {
          trafficLayer.setMap(null);
        } else {
          trafficLayer.setMap(mapa);
        }
      });

      //treba dokoncit, posielat Bounds do pola s vyhladavanim adresy, aby boli vysledky presnejsie
      /* google.maps.event.addListener(mapa, "idle", function () {
        var mapBounds;
        mapBounds = mapa.getBounds();
        console.log("actualMapBounds", mapBounds);
        //this.$emit("markerSettedOnMapToNew", markerSettedOnMap);
        this.$emit("actualMapBounds", mapBounds);
      });*/
      mapa.addListener("idle", (event) => {
        var mapBounds;
        //console.log("previous actualMapBounds", mapBounds);
        mapBounds = mapa.getBounds();
        //console.log("actualMapBounds", mapBounds);
        this.$emit("actualMapBounds", mapBounds);
      });
      // zobrazenie prepinaca tmavej temy mapy - na mape dole
      /* var themeButton = document.getElementById("switchDarkMapButton");
      this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(
        themeButton
      );*/
      //Kontrola stlačenia CTRL tlačidla
      /*  window.onkeydown = function (e) {
        if (e.keyIdentifier == "Control" || e.ctrlKey == true) {
          this.ctrlPressed = true;
          console.log("change CTRL value", this.ctrlPressed);
        } else {
          this.ctrlPressed = false;
          console.log("change CTRL value", this.ctrlPressed);
        }
      };
        mapa.onkeydown = function (e) {
          if (e.keyIdentifier == "Control" || e.ctrlKey == true) {
            ctrlPressed = true;
            console.log("change CTRL value t: ", ctrlPressed);
          }
        };
        window.onkeyup = function (e) {
          if (e.keyIdentifier != "Control" || e.ctrlKey != true) {
            this.ctrlPressed = false;
            console.log("change CTRL value f: ", this.ctrlPressed);
        }
        };*/
      mapa.addListener("rightclick", (event) => {
        var markerSettedOnMap = {};
        var ctrlPressed = false;
        markerSettedOnMap.latitude = String(event.latLng.lat());
        markerSettedOnMap.longitude = String(event.latLng.lng());
        markerSettedOnMap.type = "BOARD";
        markerSettedOnMap.displayName = "MAP";
        this.$emit("markerSettedOnMapToNew", markerSettedOnMap);
        //console.log(ctrlPressed);
        //CRTL check zatial nefunguje, s nim by sa mala davat ikona cieľa
        /* if (ctrlPressed) {
          this.manageAddressMarker("DESTINATION", markerSettedOnMap);
        } else {
          this.manageAddressMarker("BOARD", markerSettedOnMap);
        }*/
      });
      //vygenerovat Oznacovaci docasny marker, ktory sa bude presuvat podla potreby
      this.createTemporaryMarker();
      //Nacitanie stanovišť
      setTimeout(() => {
        this.getStands();
      }, 3000);
    },
    setMarker(Points, Label) {
      //console.log("setMarker - POINTS: ", Points);
      //console.log(Label);
      var markerPoint;
      markerPoint = new google.maps.Marker({
        position: Points,
        map: this.map,
        label: { text: Label, color: "#FFF" },
      });
    },
    /* removeMarker(Points, Label) {
      //locations[key].marker.setMap(null);
    },
    positionMarker(Points, Label) {
      //locations[key].marker.setPosition(latlng);
    },*/
    manageDriverMarker(positionsObject) {
      //console.log(positionsObject[0].actualPosition.latitude)
      //console.log(positionsObject)
      var initialBound = false;
      var markerPosition;
      var tempMarkerDriver = this.markerDriver || [];
      var tempPathDriver = this.pathDriver || [];
      var tempMap = this.map;
      var markerImageSet = this.markerImage;
      var markerImageType;
      var markerColor;
      var delayedMarker;
      var markerLabel;
      var markerLabelText;
      var infoWindowText;
      var activeOrderLabel;
      var silenceLabel;
      var worklogRequestLabel;
      var durationLabel;
      var streetOrderLabel;
      var durationMinutes;
      var noOrderTimeLabel;
      var markerTitle;
      var bearing = 0;
      var speed = 0;
      var speedLabel = "";
      var addressesForTitle;
      var colorLabel = "#000";
      var fontSizeLabel = 140;
      var fontSizeLabelDefault = 140;
      var infoWindow = this.infoWindows || [];
      var activeInfoWindow = this.activeInfoWindow;
      var pathDriverSetting = this.pathDriverSetting;
      var iconDriverSetting = this.iconDriverSetting;
      var iconDriverLabelSetting = this.iconDriverLabelSetting;
      var driverSpeedSetting = this.driverSpeedSetting || false;
      var allOrderData = this.allOrderData || [];
      var timeWarning = false;
      var timeWarningLabel = "";
      var preferredDriverInconsistencyActual = false;
      var preferredDriverInconsistencyOther = false;
      var preferredDriverInconsistencyActualLabel = "";
      var preferredDriverInconsistencyOtherLabel = "";
      //console.log("iconDriverSetting", iconDriverSetting);
      //console.log("tempMarkerDriver", tempMarkerDriver);
      //console.log("positionsObject", positionsObject);
      //Pri prvom zobrazeni okna, kedy este neboli zobrazene ziadne markery, tak urobit "Bounds", Hodnotu positionObject je potrebne dat na "0"
      if (tempMarkerDriver.length == 0 && positionsObject.length > 1) {
        initialBound = true;
      }
      //Resetovat Bounds na vsetkych vodicov - zobrazenie vsektych na mape
      var boundsToAllDriversTemp = new google.maps.LatLngBounds();
      //ODSTRANENIE markerov, ktore uz nedosli v aktualizacii zoznamu vodicov
      tempMarkerDriver.forEach(function (item, index) {
        bearing = 0;
        speed = 0;
        //index je vlastne ID vodica zaznamena pri vytvarani markera
        var removeCheck = true; //preddefinovat odstranenie
        //console.log("Check if remove markerDriver: ", index, " item: ", item);
        //skotrolovat vsetkych vodicov, ktorych poloha prisla zo servera
        positionsObject.forEach(function (item) {
          /*console.log(
            "Compare positionsObject: ",
            item.id,
            "vs. markerDriver: ",
            index
          );*/
          //Ak sa ID tohto vodica zhoduje s ID existujuceho markera, tak nemazat
          if (item.id == index) {
            removeCheck = false;
            return;
          }
        });
        //Vymazat MARKERY, ak je to potrebne a dany marker existuje
        if (removeCheck == true) {
          if (tempMarkerDriver[index]) {
            tempMarkerDriver[index].setMap(null);
            //console.log("Removed markerDriver: ", index, " item: ", item);
          }
          delete tempMarkerDriver[index];
        }
        //Vymazat TRASY objednavky ak je to potrebne a dana trasa existuje
        if (removeCheck == true) {
          if (tempPathDriver[index]) {
            tempPathDriver[index].setMap(null);
            //console.log("Removed tempPathDriver: ", index, " item: ", item);
          }
          delete tempPathDriver[index];
        }
      });
      //AKTUALIZACIA MARKEROV a TRASY
      positionsObject.forEach((item, index) => {
        fontSizeLabel = fontSizeLabelDefault;
        //Postupne prejst zoznam vsetkych vodicov prichadzajuci zo servera
        var driverIdFromPositionObject = item.id;
        var drivePathCoordinates = [];
        var lineColor = "#FFFFFF";
        /*console.log(
          "Attempt to update or create markerDriver - index: ",
          index,
          " driverId: ",
          driverIdFromPositionObject,
          " - item: ",
          item
        );*/
        //this.testFunction(driverIdFromPositionObject);
        //ak zoznam pre daneho vodica obsahuje platnu polohu, urobit z nej google maps position object + poznacit polohu vodica pre zaciatok TRASY
        markerPosition = null;
        //Title pre marker
        markerTitle = item.firstName + " " + item.lastName;
        delayedMarker = false;
        if (item.actualPosition != null) {
          if (
            item.actualPosition.latitude != null &&
            item.actualPosition.longitude != null
          ) {
            markerPosition = new google.maps.LatLng(
              item.actualPosition.latitude,
              item.actualPosition.longitude
            );
            drivePathCoordinates.push({
              lat: item.actualPosition.latitude,
              lng: item.actualPosition.longitude,
            });
          }
          //count bearing (direction) of driver from previous position to actualPosition
          if (
            (iconDriverSetting == "SIMPLECAR" ||
              iconDriverSetting == "SVGCAR" ||
              iconDriverSetting == "CIRCLE") &&
            tempMarkerDriver[driverIdFromPositionObject] != null
          ) {
            //rychlost vozidla v km/h
            if (item.actualPosition) {
              if (item.actualPosition.speed) {
                speed = Math.round(item.actualPosition.speed * 3.6);
              } else {
                speed = 0;
              }
            } else {
              speed = 0;
            }
            //zistenie povodneho otocenia markera
            bearing =
              tempMarkerDriver[driverIdFromPositionObject].icon.rotation;
            var previousPosition =
              tempMarkerDriver[driverIdFromPositionObject].position;
            var previousPositionLat =
              Math.round(previousPosition.lat() * 1000000) / 1000000;
            var previousPositionLng =
              Math.round(previousPosition.lng() * 1000000) / 1000000;
            //console.log("lastLat: ",previousPositionLat," lastLng: ",previousPositionLng);
            var nextPositionLat =
              Math.round(item.actualPosition.latitude * 1000000) / 1000000;
            var nextPositionLng =
              Math.round(item.actualPosition.longitude * 1000000) / 1000000;
            //console.log("nextLat: ",nextPositionLat," nextLng: ",nextPositionLng);
            var dLon = nextPositionLng - previousPositionLng;
            var y =
              Math.sin(dLon * (Math.PI / 180)) *
              Math.cos(nextPositionLat * (Math.PI / 180));
            var x =
              Math.cos(previousPositionLat * (Math.PI / 180)) *
                Math.sin(nextPositionLat * (Math.PI / 180)) -
              Math.sin(previousPositionLat * (Math.PI / 180)) *
                Math.cos(nextPositionLat * (Math.PI / 180)) *
                Math.cos(dLon * (Math.PI / 180));
            bearing = Math.atan2(y, x);
            // Converts from radians to degrees.
            bearing = bearing * (180 / Math.PI);
            //console.log("bearing plain: ", bearing);
            bearing = bearing + 360;
            bearing = Math.round(bearing % 360);
            if (bearing == 0) {
              bearing = Math.round(item.actualPosition.bearing);
            }

            //console.log(" Driver ID:" + driverIdFromPositionObject + " - bearing: " + bearing + " | LAT: " + previousPositionLat + " > " + nextPositionLat + " | LNG: " + previousPositionLng + " > " + nextPositionLng);
          }

          //compute heading from point A to point B

          markerLabelText = "";
          colorLabel = "#000";
          //nastavenie Label v markeri. Ak je Interny nazov vozidla, tak ho doplnit, ak nie, tak prezyvku vodica alebo jeho inicialy
          if (
            item.vehicle != null &&
            iconDriverLabelSetting == "VEHICLEINTERNAL"
          ) {
            markerLabelText = item.vehicle.internalName;
            /* } else if (item.nickname != null) {
            markerLabelText = item.nickname.substring(0, 2); */
          } else if (
            item.internalName != null &&
            iconDriverLabelSetting == "DRIVERINTERNAL"
          ) {
            markerLabelText = item.internalName.substring(0, 2);
          } else if (
            item.firstName != null &&
            item.lastName != null &&
            iconDriverLabelSetting == "DRIVERNAME"
          ) {
            markerLabelText =
              item.firstName.charAt(0) + item.lastName.charAt(0);
          } else if (item.vehicle != null) {
            markerLabelText = item.vehicle.internalName;
          }
          //ak je markerLabelText dlhsi ako 2 znaky, tak nastavit fontSizeLabel na 100%
          if (markerLabelText.length > 2) {
            fontSizeLabel = fontSizeLabel - 20;
          }
          //Indikator poctu jazd. Cim viac jazd ako 1, tym viac vykricnikov
          activeOrderLabel = "";
          timeWarningLabel = "";
          timeWarning = false;
          preferredDriverInconsistencyActual = false;
          preferredDriverInconsistencyOther = false;
          preferredDriverInconsistencyActualLabel = "";
          preferredDriverInconsistencyOtherLabel = "";
          if (item.countActiveOrders > 0 && item.orderActual != null) {
            //Ak sa v passOperationalDataOrdersOnMap nachadza pre daneho vodica objednavka v stave "ACCEPTED" okrem aktualnej objednavky, ktora je typu "TIME", tak vratit parameter "timeWarning = true"
            allOrderData.forEach((itemOrder, index) => {
              if (itemOrder.driver) {
                if (
                  itemOrder.driver.id == driverIdFromPositionObject &&
                  itemOrder.status == "ACCEPTED" &&
                  itemOrder.type == "TIME" &&
                  itemOrder.id != item.orderActual.id &&
                  item.countActiveOrders > 1
                ) {
                  timeWarning = true;
                }
                if (
                  itemOrder.driver.id == driverIdFromPositionObject &&
                  itemOrder.preferredDriver != null &&
                  itemOrder.id == item.orderActual.id &&
                  itemOrder.preferredDriver.id != driverIdFromPositionObject
                ) {
                  preferredDriverInconsistencyActual = true;
                }
                if (
                  itemOrder.driver.id == driverIdFromPositionObject &&
                  itemOrder.status == "ACCEPTED" &&
                  itemOrder.preferredDriver != null &&
                  itemOrder.id != item.orderActual.id &&
                  itemOrder.preferredDriver.id != driverIdFromPositionObject
                ) {
                  preferredDriverInconsistencyOther = true;
                }
              }
            });
          }
          if (timeWarning == true) {
            timeWarningLabel = "°";
            fontSizeLabel = fontSizeLabel - 5;
            //Doplnenie Title pre marker
            markerTitle =
              markerTitle +
              "\n ° " +
              this.$t("ConsoleMap.markerTitle_timeTypeWarning"); // "2 aktívne objednávky"
            colorLabel = "#9C27B0";
          }
          if (preferredDriverInconsistencyActual) {
            preferredDriverInconsistencyActualLabel = "¿";
            fontSizeLabel = fontSizeLabel - 5;
            //Doplnenie Title pre marker
            markerTitle =
              markerTitle +
              "\n ¿ " +
              this.$t(
                "ConsoleMap.markerTitle_preferredDriverInconsistencyActual"
              ); // "2 aktívne objednávky"
            colorLabel = "#1565C0";
          }
          if (preferredDriverInconsistencyOther) {
            preferredDriverInconsistencyOtherLabel = "¿";
            fontSizeLabel = fontSizeLabel - 5;
            //Doplnenie Title pre marker
            markerTitle =
              markerTitle +
              "\n ¿ " +
              this.$t(
                "ConsoleMap.markerTitle_preferredDriverInconsistencyOther"
              ); // "2 aktívne objednávky"
            colorLabel = "#0D47A1";
          }
          if (item.countActiveOrders == 2) {
            activeOrderLabel = "!";
            fontSizeLabel = fontSizeLabel - 5;
            //Doplnenie Title pre marker
            markerTitle =
              markerTitle + "\n ! " + this.$t("ConsoleMap.markerTitle_2Active"); // "2 aktívne objednávky"
          } else if (item.countActiveOrders == 3) {
            activeOrderLabel = "!!";
            fontSizeLabel = fontSizeLabel - 10;
            //Doplnenie Title pre marker
            markerTitle =
              markerTitle +
              "\n !! " +
              this.$t("ConsoleMap.markerTitle_3Active"); // "3 aktívne objednávky"
          } else if (item.countActiveOrders > 3) {
            activeOrderLabel = "!!!";
            fontSizeLabel = fontSizeLabel - 15;
            //Doplnenie Title pre marker
            markerTitle =
              markerTitle +
              "\n !!! " +
              this.$t("ConsoleMap.markerTitle_4Active"); // "4 aktívne objednávky"
          }

          markerLabel = {
            text: markerLabelText + activeOrderLabel,
            color: colorLabel,
          };
          //Indikator Ticheho rezimu vodica
          silenceLabel = "";
          /*if (item.silence == 1) {
            silenceLabel = "X"; //V tichom rezime
            fontSizeLabel = fontSizeLabel - 10;
            //Doplnenie Title pre marker
            markerTitle = markerTitle + "\n" + "Tichý režim zapnutý";
          } else if (item.silence == 2) {
            silenceLabel = "B"; //Blokovany dispecerom
            fontSizeLabel = fontSizeLabel - 10;
          }*/
          if (item.silence == true) {
            silenceLabel = "x"; //V tichom rezime
            fontSizeLabel = fontSizeLabel - 15;
            //Doplnenie Title pre marker
            markerTitle =
              markerTitle +
              "\n x " +
              this.$t("ConsoleMap.markerTitle_silenceEnabled"); // "Tichý režim zapnutý"
          }

          //ak ma request na ukončenie pracovnej zmeny alebo prestavku
          worklogRequestLabel = "";
          if (
            item.worklogRequestType == "ON_BREAK" &&
            item.worklogRequestStatus == "NEW"
          ) {
            worklogRequestLabel = "?";
            fontSizeLabel = fontSizeLabel - 10;
            colorLabel = "#8E24AA";
            //Doplnenie Title pre marker
            markerTitle =
              markerTitle +
              "\n ? " +
              this.$t("ConsoleMap.markerTitle_worklogRequestOnbreakNew");
          } else if (
            item.worklogRequestType == "ON_BREAK" &&
            item.worklogRequestStatus == "CONFIRMED"
          ) {
            worklogRequestLabel = "ß";
            fontSizeLabel = fontSizeLabel - 10;
            colorLabel = "#4A148C";
            //Doplnenie Title pre marker
            markerTitle =
              markerTitle +
              "\n ß " +
              this.$t("ConsoleMap.markerTitle_worklogRequestOnbreakConfirmed");
          } else if (
            item.worklogRequestType == "OFFLINE" &&
            item.worklogRequestStatus == "NEW"
          ) {
            worklogRequestLabel = "?";
            fontSizeLabel = fontSizeLabel - 10;
            colorLabel = "#D32F2F";
            //Doplnenie Title pre marker
            markerTitle =
              markerTitle +
              "\n ? " +
              this.$t("ConsoleMap.markerTitle_worklogRequestOfflineNew");
          } else if (
            item.worklogRequestType == "OFFLINE" &&
            item.worklogRequestStatus == "CONFIRMED"
          ) {
            worklogRequestLabel = "§";
            fontSizeLabel = fontSizeLabel - 10;
            colorLabel = "#B71C1C";
            //Doplnenie Title pre marker
            markerTitle =
              markerTitle +
              "\n § " +
              this.$t("ConsoleMap.markerTitle_worklogRequestOfflineConfirmed");
          }

          //doplnit Bounds o polohu dalsieho vodica
          boundsToAllDriversTemp.extend(markerPosition);
          //ak je stara informacia o polohe, tak zobrazovat iny marker (sedy)
          var d1 = new Date();
          var d2 = new Date(item.actualPosition.updatedAt);
          //console.log("timeDelay: ", d1.getTime() - d2.getTime());
          if (d1.getTime() - d2.getTime() > 300000) {
            //ak je stara informacia o polohe, tak zobrazovat iny marker (sedy)
            //VYNIMKA PRE ZMENU NA ZIMNY A LETNY CAS
            //last 7 days in october and march -  vtedy sa meni letny cas na zimny, takze sa mozu zobrazovat sede markery aj v noci
            if (
              (d1.getMonth() == 9 && d1.getDate() > 24) ||
              (d1.getMonth() == 2 && d1.getDate() > 24)
            ) {
              //ak je sobota alebo nedela, tak nekontrolovat
              if (d1.getDay() == 0 && d1.getDay() == 6) {
                delayedMarker = false;
              } else {
                delayedMarker = true;
              }
            } else {
              delayedMarker = true;
            }

            //Doplnenie Title pre marker
            //ak je viac ako 99min, tak zobraz hodiny a minuty
            var minutesNoData = Math.trunc(
              (d1.getTime() - d2.getTime()) / 60000
            );
            if (minutesNoData > 99) {
              var hoursNoData = Math.floor(minutesNoData / 60);
              var minutesNoData = minutesNoData % 60;
              markerTitle +=
                "\n" +
                this.$t("ConsoleMap.markerTitle_outdatedPosition") + // ""Dlhší čas nemáme aktuálnu polohu" +
                ": " +
                hoursNoData +
                "h " +
                minutesNoData +
                "min";
            } else {
              markerTitle +=
                "\n" +
                this.$t("ConsoleMap.markerTitle_outdatedPosition") + // ""Dlhší čas nemáme aktuálnu polohu" +
                ": " +
                minutesNoData +
                "min.";
            }
          }
        }
        //dopln do markerTitle informaciu o rychlosti
        if (item.actualPosition) {
          if (item.actualPosition.speed > 0) {
            markerTitle +=
              "\n" +
              this.$t("ConsoleMap.markerTitle_driverSpeed") +
              ": " +
              Math.round(item.actualPosition.speed * 3.6) +
              " km/h";
          }
        }

        ///Vypis adries aktualnej objednavky pre Title markera (HOVER) + trvanie zvysku jazdy. Zaroven pridat Koordinaty pre TRASU vodica
        durationLabel = "";
        streetOrderLabel = "";
        durationMinutes = -1;
        addressesForTitle = "";
        if (item.orderActual != null) {
          if (item.orderActual.waypointList != null) {
            item.orderActual.waypointList.forEach(function (
              itemWaypoint,
              indexWaypoint
            ) {
              addressesForTitle =
                addressesForTitle +
                "\n" +
                (indexWaypoint + 1) +
                ": " +
                itemWaypoint.address;
              if (
                item.orderActual.status == "ACCEPTED" &&
                itemWaypoint.status == "ACTIVE"
              ) {
                drivePathCoordinates.push({
                  lat: itemWaypoint.latitude,
                  lng: itemWaypoint.longitude,
                });
              }
              //BOARD zobrazovat iba pri ACCEPTED objednavkach
              if (
                (item.orderActual.status == "WAITING" ||
                  item.orderActual.status == "IN_PROGRESS") &&
                itemWaypoint.status == "ACTIVE" &&
                itemWaypoint.type != "BOARD"
              ) {
                drivePathCoordinates.push({
                  lat: itemWaypoint.latitude,
                  lng: itemWaypoint.longitude,
                });
              }
            });
            if (item.orderActual != null) {
              if (
                item.orderActual.duration != null &&
                item.orderActual.duration != 0
              ) {
                //round to integer - DURATION prichazda za appky vodica v minutach
                durationMinutes = Math.round(item.orderActual.duration);
                //durationMinutes = Math.round(item.orderActual.duration / 60);
              } else {
                durationMinutes = -1;
              }
              if (item.orderActual.status == "IN_PROGRESS") {
                if (durationMinutes == 0) {
                  durationLabel = "|1";
                  fontSizeLabel = fontSizeLabel - 20;
                } else if (durationMinutes < 10 && durationMinutes > 0) {
                  durationLabel = "|" + Math.round(durationMinutes);
                  fontSizeLabel = fontSizeLabel - 20;
                } /*else if (durationMinutes >= 10) {
                  durationLabel = "|#";
                  fontSizeLabel = fontSizeLabel - 25;
                }*/ else {
                  durationLabel = "";
                }

                //Doplnenie Title pre marker
                markerTitle =
                  markerTitle +
                  "\n" +
                  this.$t("ConsoleMap.markerTitle_timeToDestination") + //"Vyloženie zákazníka za " +
                  ": " +
                  Math.max(durationMinutes, 0) +
                  "min.";
              }
              streetOrderLabel = "";
              if (item.orderActual.type == "STREET") {
                streetOrderLabel = "~";
                markerTitle =
                  markerTitle +
                  "\n ~ " +
                  this.$t("ConsoleMap.markerTitle_streetOrder");
              }
            }
          }
        }
        markerTitle = markerTitle + addressesForTitle;
        //Vyber spravneho markera
        markerColor = "#00FF00";
        markerImageType = "OFFLINE";
        if (item.workStatus == "OFFLINE") {
          markerImageType = "OFFLINE";
          markerColor = "#666666";
        } else if (item.workStatus == "ON_BREAK") {
          markerImageType = "ON_BREAK";
          markerColor = "#d27bd2";
        } else if (item.workStatus == "ONLINE") {
          if (delayedMarker == true) {
            markerImageType = "DELAY";
            markerColor = "#CCCCCC";
          } else if (item.orderActual != null) {
            if (item.orderActual.status == "ACCEPTED") {
              markerImageType = "ACCEPTED";
              markerColor = "#FFFF00";
              lineColor = "#FFFF00"; //"yellow accent-2";
            } else if (item.orderActual.status == "WAITING") {
              markerImageType = "WAITING";
              markerColor = "#FFAB40";
              lineColor = "#FFAB40"; //"orange accent-2";
            } else if (item.orderActual.status == "IN_PROGRESS") {
              markerImageType = "IN_PROGRESS";
              markerColor = "#40C4FF";
              lineColor = "#40C4FF"; //"light-blue accent-2";
            } else if (item.orderActual.status == "FINISHED") {
              markerImageType = "FINISHED";
              markerColor = "#00B0FF";
              lineColor = "#00B0FF"; //"light-blue accent-3";
            }
          } else {
            markerImageType = "ONLINE";
            markerColor = "#00FF00";
          }
        }
        //Vytvorenie bublinky pre marker vodica
        infoWindowText = this.driverInfoWindowText(markerImageType, item);

        //Vytvorenie spravnej ikony pre marker vodica
        if (iconDriverSetting == "CIRCLE") {
          var updateMarker = {
            //path: "M348 5c-112,20 -197,103 -216,214 -4,23 -5,5 -5,115l0 139c-64,22 -111,57 -126,127 -11,56 72,33 126,33l1 345c1,342 1,346 8,370 27,95 92,160 186,188 47,13 311,13 359,0 96,-28 163,-96 189,-192 5,-18 5,-53 6,-366l1 -345 131 0 -2 -16c-11,-81 -56,-119 -129,-144l0 -136c0,-79 -2,-94 -3,-109 -14,-103 -87,-186 -191,-217 -41,-13 -284,-14 -335,-6zm446 328c1,1 -56,172 -64,195 -139,-23 -317,-23 -456,0 -8,-23 -65,-194 -64,-195 74,-75 496,-89 584,0zm-430 907c90,8 236,6 327,-5 14,-2 28,-3 32,-3 3,0 70,30 71,32 2,6 -51,30 -87,41 -67,19 -125,27 -205,27 -80,0 -138,-8 -205,-27 -36,-11 -89,-35 -87,-41 1,-2 53,-19 64,-33l20 2c10,1 42,4 70,7z",
            //path: "M271 4c-88,15 -154,81 -169,167 -3,17 -4,3 -4,89l0 109c-50,17 -86,44 -98,99 -9,44 56,25 98,25l1 269c1,266 1,270 6,288 21,74 72,125 146,147 37,11 242,11 279,0 75,-22 127,-75 147,-150 4,-14 4,-41 5,-285l1 -269 102 0 -1 -12c-9,-63 -44,-93 -100,-112l0 -106c0,-61 -1,-74 -3,-85 -11,-81 -67,-145 -148,-169 -32,-10 -221,-11 -261,-5zm162 1027c80,0 149,2 180,5 -14,-7 -47,-22 -49,-22 -3,0 -14,1 -25,3 -71,9 -185,10 -255,4 -22,-2 -47,-5 -55,-6l-15 -2c-9,11 -49,25 -50,26 -2,4 39,23 67,31 53,15 98,21 160,21 53,0 93,-4 137,-15 -22,1 -47,1 -73,1 -96,0 -173,-3 -173,-8 0,-4 78,-8 173,-8 44,0 85,1 116,2 19,-7 38,-16 45,-21 -30,3 -101,5 -183,5 -110,0 -199,-4 -199,-9 0,-5 89,-9 199,-9zm36 -667c43,0 83,1 117,2l7 -22c-38,2 -87,2 -140,2 -120,0 -218,-5 -218,-10 0,-6 98,-10 218,-10 56,0 107,1 145,3l8 -24c-40,3 -103,4 -173,4 -120,0 -218,-5 -218,-10 0,-6 98,-10 218,-10 73,0 138,2 177,4 4,-12 7,-22 9,-28 -29,4 -111,7 -207,7 -120,0 -218,-5 -218,-10 0,-6 98,-10 218,-10 95,0 176,3 206,7 -74,-67 -396,-55 -453,2 -1,1 44,134 50,152 109,-18 247,-18 355,0l9 -28c-33,1 -71,1 -111,1 -120,0 -218,-5 -218,-10 0,-6 97,-10 218,-10zm-251 -295c-27,29 -48,56 -45,60 2,4 26,-17 54,-46l5 -5c-14,41 -47,74 -89,87 3,-58 43,-107 96,-124 0,3 0,5 -1,8 -6,6 -13,13 -20,20zm348 0c27,29 48,56 45,60 -2,4 -26,-17 -54,-46l-2 -3c15,38 47,68 87,81 -4,-58 -43,-107 -96,-124 0,4 1,8 1,12 6,6 12,12 19,20z",
            path: "M476 213c170,39 297,191 297,373 0,212 -172,383 -383,383 -211,0 -383,-172 -383,-383 0,-182 127,-334 297,-373l86 -197 86 197z",
            fillColor: markerColor,
            fillOpacity: 0.9,
            strokeColor: "#222",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            size: new google.maps.Size(31, 44),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(440, 540),
            labelOrigin: new google.maps.Point(420, 600),
            scale: 0.04,
            //scale: 0.4,
            rotation: bearing,
          };
        } else if (
          iconDriverSetting == "SIMPLECAR" ||
          iconDriverSetting == "SVGCAR"
        ) {
          var updateMarker = {
            //path: "M348 5c-112,20 -197,103 -216,214 -4,23 -5,5 -5,115l0 139c-64,22 -111,57 -126,127 -11,56 72,33 126,33l1 345c1,342 1,346 8,370 27,95 92,160 186,188 47,13 311,13 359,0 96,-28 163,-96 189,-192 5,-18 5,-53 6,-366l1 -345 131 0 -2 -16c-11,-81 -56,-119 -129,-144l0 -136c0,-79 -2,-94 -3,-109 -14,-103 -87,-186 -191,-217 -41,-13 -284,-14 -335,-6zm446 328c1,1 -56,172 -64,195 -139,-23 -317,-23 -456,0 -8,-23 -65,-194 -64,-195 74,-75 496,-89 584,0zm-430 907c90,8 236,6 327,-5 14,-2 28,-3 32,-3 3,0 70,30 71,32 2,6 -51,30 -87,41 -67,19 -125,27 -205,27 -80,0 -138,-8 -205,-27 -36,-11 -89,-35 -87,-41 1,-2 53,-19 64,-33l20 2c10,1 42,4 70,7z",
            path: "M271 4c-88,15 -154,81 -169,167 -3,17 -4,3 -4,89l0 109c-50,17 -86,44 -98,99 -9,44 56,25 98,25l1 269c1,266 1,270 6,288 21,74 72,125 146,147 37,11 242,11 279,0 75,-22 127,-75 147,-150 4,-14 4,-41 5,-285l1 -269 102 0 -1 -12c-9,-63 -44,-93 -100,-112l0 -106c0,-61 -1,-74 -3,-85 -11,-81 -67,-145 -148,-169 -32,-10 -221,-11 -261,-5zm162 1027c80,0 149,2 180,5 -14,-7 -47,-22 -49,-22 -3,0 -14,1 -25,3 -71,9 -185,10 -255,4 -22,-2 -47,-5 -55,-6l-15 -2c-9,11 -49,25 -50,26 -2,4 39,23 67,31 53,15 98,21 160,21 53,0 93,-4 137,-15 -22,1 -47,1 -73,1 -96,0 -173,-3 -173,-8 0,-4 78,-8 173,-8 44,0 85,1 116,2 19,-7 38,-16 45,-21 -30,3 -101,5 -183,5 -110,0 -199,-4 -199,-9 0,-5 89,-9 199,-9zm36 -667c43,0 83,1 117,2l7 -22c-38,2 -87,2 -140,2 -120,0 -218,-5 -218,-10 0,-6 98,-10 218,-10 56,0 107,1 145,3l8 -24c-40,3 -103,4 -173,4 -120,0 -218,-5 -218,-10 0,-6 98,-10 218,-10 73,0 138,2 177,4 4,-12 7,-22 9,-28 -29,4 -111,7 -207,7 -120,0 -218,-5 -218,-10 0,-6 98,-10 218,-10 95,0 176,3 206,7 -74,-67 -396,-55 -453,2 -1,1 44,134 50,152 109,-18 247,-18 355,0l9 -28c-33,1 -71,1 -111,1 -120,0 -218,-5 -218,-10 0,-6 97,-10 218,-10zm-251 -295c-27,29 -48,56 -45,60 2,4 26,-17 54,-46l5 -5c-14,41 -47,74 -89,87 3,-58 43,-107 96,-124 0,3 0,5 -1,8 -6,6 -13,13 -20,20zm348 0c27,29 48,56 45,60 -2,4 -26,-17 -54,-46l-2 -3c15,38 47,68 87,81 -4,-58 -43,-107 -96,-124 0,4 1,8 1,12 6,6 12,12 19,20z",
            fillColor: markerColor,
            fillOpacity: 0.9,
            strokeColor: "#222",
            strokeOpacity: 0.6,
            strokeWeight: 2,
            size: new google.maps.Size(31, 44),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(440, 540),
            labelOrigin: new google.maps.Point(420, 640),
            scale: 0.04,
            //scale: 0.4,
            rotation: bearing,
          };
        } else if (iconDriverSetting == "PIN") {
          var updateMarker = {
            url: markerImageSet[markerImageType],
            size: new google.maps.Size(31, 44),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(16, 44),
            labelOrigin: new google.maps.Point(15, 15),
          };
        }
        //skontrolovat ako rychlo ide a pridat text speedLabel: /rychlost/10, do 10km/h text ""
        if (item.actualPosition && driverSpeedSetting) {
          if (item.actualPosition.speed && driverSpeedSetting) {
            speed = Math.round(item.actualPosition.speed * 3.6);
            if (speed > 10) {
              fontSizeLabel = fontSizeLabel - 15;
              speedLabel = "/" + Math.round(speed / 10);
              if (speed > 91) {
                colorLabel = "#FF0000";
              } else if (speed > 71) {
                colorLabel = "#EF6C00";
              } else if (speed > 51) {
                colorLabel = "#FBC02D";
              } else if (speed > 31) {
                colorLabel = "#4CAF50";
              }
            } else {
              speedLabel = "";
            }
          }
        }
        //Skontrolovať ako dlho nema objednavku a za kazdych 10 min oznacit u volneho vodica (ONLINE) jednu cislicu
        noOrderTimeLabel = "";
        //console.log("noOrderTimeLabel", noOrderTimeLabel);
        //console.log("item.lastDriveDate", item.lastDriveDate);
        if (
          item.lastDriveDate &&
          markerImageType == "ONLINE" // || markerImageType == "DELAY"
        ) {
          if (
            item.lastDriveDate != undefined &&
            item.lastDriveDate != "null" &&
            item.lastDriveDate != "" &&
            item.lastDriveDate != null
          ) {
            var d1 = new Date();
            var d2 = new Date(item.lastDriveDate);
            var timeWithoutOrder = d1.getTime() - d2.getTime();
            //console.log("timeWithoutOrder", timeWithoutOrder);
            if (timeWithoutOrder > 5940000) {
              //Za lomitkom je hodnota v desiatkach minut, aby to nezaberalo veľa miesta
              //a zobrazuje to az od 10 minut po 10 minutach (napr. 3 znamena 30 minut)
              //Nad 90 minut sa stale zobrazuje #, aby to nezaberalo vela miesta
              noOrderTimeLabel = noOrderTimeLabel + "|#";
              fontSizeLabel = fontSizeLabel - 20;
            } else if (timeWithoutOrder > 600000) {
              //600000ms = 10min
              noOrderTimeLabel =
                noOrderTimeLabel + "|" + Math.trunc(timeWithoutOrder / 600000);
              fontSizeLabel = fontSizeLabel - 20;
            }
            //Doplnenie Title pre marker (po prvej minute)
            if (timeWithoutOrder > 18000000) {
              markerTitle =
                markerTitle +
                "\n" +
                this.$t("ConsoleMap.markerTitle_timeFromLastOrder") + //"Čas od ukončenia poslednej jazdy "
                ": " +
                Math.trunc(timeWithoutOrder / 3600000) +
                "hod.";
            } else if (timeWithoutOrder > 60000) {
              markerTitle =
                markerTitle +
                "\n" +
                this.$t("ConsoleMap.markerTitle_timeFromLastOrder") + //"Čas od ukončenia poslednej jazdy "
                ": " +
                Math.trunc(timeWithoutOrder / 60000) +
                "min.";
            }
          }
          //console.log("noOrderTimeLabel", noOrderTimeLabel);
        }
        //Nastavenie LABEL MARKERA
        //console.log("fontSizeLabel", fontSizeLabel);
        markerLabel = {
          fontWeight: "normal",
          text:
            preferredDriverInconsistencyActualLabel +
            preferredDriverInconsistencyOtherLabel +
            markerLabelText +
            silenceLabel +
            worklogRequestLabel +
            activeOrderLabel +
            timeWarningLabel +
            streetOrderLabel +
            durationLabel +
            noOrderTimeLabel +
            speedLabel,
          color: colorLabel,
          fontSize: fontSizeLabel + "%",
        };
        if (
          tempMarkerDriver[driverIdFromPositionObject] == null &&
          markerPosition != null
        ) {
          //VYTVORIT NOVY MARKER, ak neexistuje
          //console.log("manageDriverMarker - index2 ", index);
          var infoWindowOffset = 0;
          if (
            iconDriverSetting == "SIMPLECAR" ||
            iconDriverSetting == "SVGCAR" ||
            iconDriverSetting == "CIRCLE"
          ) {
            var coordArray = [1, 1, 31, 1, 31, 66, 1, 66];
            infoWindowOffset = 8;
          } else if (iconDriverSetting == "PIN" || iconDriverSetting == "TAG") {
            var coordArray = [1, 15, 5, 5, 16, 1, 24, 5, 31, 15, 16, 44];
          }
          //Vyber spravneho markera
          if (item) {
            tempMarkerDriver[driverIdFromPositionObject] =
              new google.maps.Marker({
                position: markerPosition,
                map: tempMap,
                label: markerLabel,
                icon: updateMarker,
                title: markerTitle,
                shape: {
                  coords: coordArray,
                  type: "poly",
                },
              });
          }
          //Definovanie bublinky nad markerom
          infoWindow[driverIdFromPositionObject] = new google.maps.InfoWindow({
            content: "" + infoWindowText,
            disableAutoPan: true,
            pixelOffset: new google.maps.Size(0, infoWindowOffset),
          });
          //infoWindow.setContent("test");
          //infoWindow.open(map, tempMarkerDriver[driverIdFromPositionObject]);

          tempMarkerDriver[driverIdFromPositionObject].addListener(
            "click",
            () => {
              if (activeInfoWindow != null) {
                activeInfoWindow.close();
              }
              infoWindow[driverIdFromPositionObject].open({
                anchor: tempMarkerDriver[driverIdFromPositionObject],
                map,
                sholdFocus: false,
              });
              activeInfoWindow = infoWindow[driverIdFromPositionObject];
            }
          );
          /*google.maps.event.addListener(
            tempMarkerDriver[driverIdFromPositionObject],
            "click",
            (function (driverIdFromPositionObject) {
              console.log("markerLabelText: ", markerLabelText);
              return function () {
                if (tempMarkerDriver[driverIdFromPositionObject]) {
                  console.log("infoWindow click: ", driverIdFromPositionObject);
                  //this.buildMarkerContent(driverIdFromPositionObject);
                  infoWindow[driverIdFromPositionObject].setContent(
                    "test: "
                  );
                  //infoWindow[driverIdFromPositionObject].setContent("test: " + this.buildMarkerContent(driverIdFromPositionObject));
                  infoWindow[driverIdFromPositionObject].open(
                    map,
                    tempMarkerDriver[driverIdFromPositionObject]
                  );
                  // activeInfoWindow = infowindow;
                }
              };
            })(driverIdFromPositionObject)
          );*/
          //right click calls function this.setDriverToDetailFromMap(driverIdFromPositionObject);
          tempMarkerDriver[driverIdFromPositionObject].addListener(
            "rightclick",
            () => {
              if (driverIdFromPositionObject) {
                this.setDriverToDetailFromMap(driverIdFromPositionObject);
              }
            }
          );
        } else if (
          tempMarkerDriver[driverIdFromPositionObject] != null &&
          markerPosition != null
        ) {
          //UPRAVIT MARKER (polohu na mape, ikonu a info), ak existuje
          tempMarkerDriver[driverIdFromPositionObject].setIcon(updateMarker);
          tempMarkerDriver[driverIdFromPositionObject].setLabel(markerLabel);
          tempMarkerDriver[driverIdFromPositionObject].setTitle(markerTitle);
          infoWindow[driverIdFromPositionObject].setContent(
            "" + infoWindowText
          );
          //simulovat plynuly pohyb markera
          var t = 25.0;
          var current = tempMarkerDriver[driverIdFromPositionObject].position;
          var latlng;
          var lat = current.lat();
          var lng = current.lng();
          var deltalat = (markerPosition.lat() - current.lat()) / 40;
          var deltalng = (markerPosition.lng() - current.lng()) / 40;
          var delay = 10 * t;
          for (var i = 0; i < 40; i++) {
            (function (ind) {
              setTimeout(function () {
                lat =
                  tempMarkerDriver[driverIdFromPositionObject].position.lat();
                lng =
                  tempMarkerDriver[driverIdFromPositionObject].position.lng();
                lat += deltalat;
                lng += deltalng;
                latlng = new google.maps.LatLng(lat, lng);
                tempMarkerDriver[driverIdFromPositionObject].setPosition(
                  latlng
                );
              }, delay * ind);
            })(i);
          }
        }
        if (item.orderActual != null) {
          //Overenie ci existuje aktualna objednavka s waypointami pre daneho vodica, ak nie, tak nizsie vymazat TRASU
          if (item.orderActual.waypointList != null) {
            //ZOBRAZIT TRASU PODLA NASTAVENI, ak neexistuje a iba v stavoch objednavky, kedy prepravuje zakaznika
            //Nastavenie, kedy sa ma vytvarat trasa
            var displayPath = false;
            //console.log("pathDriverSetting", pathDriverSetting);
            if (pathDriverSetting == "NEVER") {
              displayPath = false;
            } else if (pathDriverSetting == "ALWAYS") {
              displayPath = true;
            } else if (
              pathDriverSetting == "INPROGRESS" &&
              item.orderActual.status == "IN_PROGRESS"
            ) {
              displayPath = true;
            } else if (
              pathDriverSetting == "WAITING" &&
              (item.orderActual.status == "IN_PROGRESS" ||
                item.orderActual.status == "WAITING")
            ) {
              displayPath = true;
            } else if (
              pathDriverSetting == "INPROGRESS05MIN" &&
              item.orderActual.status == "IN_PROGRESS" &&
              durationMinutes <= 5 &&
              durationMinutes > -1
            ) {
              displayPath = true;
            } else if (
              pathDriverSetting == "INPROGRESS10MIN" &&
              item.orderActual.status == "IN_PROGRESS" &&
              durationMinutes <= 10 &&
              durationMinutes > -1
            ) {
              displayPath = true;
            }

            if (
              tempPathDriver[driverIdFromPositionObject] == null &&
              drivePathCoordinates != null && //drivePathCoordinates.lenght > 1 &&
              (item.orderActual.status == "ACCEPTED" ||
                item.orderActual.status == "WAITING" ||
                item.orderActual.status == "IN_PROGRESS")
            ) {
              //VYTVORIT NOVU TRASU a zobrazit, ak k trase existuje marker
              if (displayPath == true) {
                tempPathDriver[driverIdFromPositionObject] =
                  new google.maps.Polyline({
                    path: drivePathCoordinates,
                    map: tempMap,
                    geodesic: true,
                    strokeColor: lineColor,
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                  });
              }
            } else if (
              tempPathDriver[driverIdFromPositionObject] != null &&
              drivePathCoordinates != null &&
              displayPath == true
            ) {
              //UPRAVIT exitujucu TRASU (farba a trasa) a iba v stavoch objednavky, kedy prepravuje zakaznika
              tempPathDriver[driverIdFromPositionObject].setPath(
                drivePathCoordinates
              );
              tempPathDriver[driverIdFromPositionObject].setOptions({
                strokeColor: lineColor,
              });
            } else if (
              tempPathDriver[driverIdFromPositionObject] != null &&
              displayPath == false
            ) {
              //VYMAZAT TRASU, ak ju netreba zobrazovat podla stavu
              tempPathDriver[driverIdFromPositionObject].setMap(null);
              delete tempPathDriver[driverIdFromPositionObject];
            }
          }
        } else if (tempPathDriver[driverIdFromPositionObject] != null) {
          //VYMAZAT TRASU, ak vodic nema ziadnu objednavku
          tempPathDriver[driverIdFromPositionObject].setMap(null);
          delete tempPathDriver[driverIdFromPositionObject];
        }
      });
      this.markerDriver = tempMarkerDriver;
      this.pathDriver = tempPathDriver;
      this.infoWindows = infoWindow;
      this.activeInfoWindow = activeInfoWindow;
      this.boundsToAllDrivers = boundsToAllDriversTemp;
      if (initialBound == true) {
        this.fitDriverBoundsOnMap();
      }
    },

    fitDriverBoundsOnMap() {
      this.map.fitBounds(this.boundsToAllDrivers);
    },
    manageAddressMarker(addressType, addressObject) {
      var markerPosition;
      var iconImage;
      var markerTitle;
      if (addressObject != null) {
        markerPosition = new google.maps.LatLng(
          addressObject.latitude,
          addressObject.longitude
        );
      }
      //console.log("addressObject: ", addressObject);
      var markerOperation;
      if (
        addressObject == null &&
        !addressObject &&
        this.markerAddress[addressType] &&
        this.markerAddress[addressType] != null
      ) {
        //Odstranit marker ak uz neexistuje marker ale marker predtym existoval
        markerOperation = "REMOVE";
        //console.log("REMOVE1");
      } else if (
        addressObject.latitude == null &&
        addressObject.longitude == null &&
        this.markerAddress[addressType]
      ) {
        //Odstranit marker ak neexistuje poloha ale marker predtym existoval
        markerOperation = "REMOVE";
        //console.log("REMOVE2");
      } else if (
        addressObject != null &&
        addressObject &&
        addressObject.latitude != null &&
        addressObject.longitude != null &&
        this.markerAddress[addressType] &&
        this.markerAddress[addressType] != null
      ) {
        //presunut marker ak existuje poloha a existuje marker
        markerOperation = "CHANGE";
        //console.log("CHANGE");
      } else if (
        addressObject != null &&
        addressObject &&
        addressObject.latitude != null &&
        addressObject.longitude != null &&
        (!this.markerAddress[addressType] ||
          this.markerAddress[addressType] == null)
      ) {
        //nadefinovat novy marker ak neexistuje
        markerOperation = "NEW";
        //console.log("NEW", addressObject, this.markerAddress[addressType]);
      }
      switch (addressType) {
        case "BOARD":
          //console.log("The BOARD!!");
          iconImage = require("@/assets/markers/marker_boarding.png");
          markerTitle = this.$t("ConsoleMap.markerTitlePlace_board"); //"Miesto vyzdvihnutia zákazníka";
          break;
        case "DESTINATION":
          //console.log("The DESTINATION!!");
          iconImage = require("@/assets/markers/marker_destination.png");
          markerTitle = this.$t("ConsoleMap.markerTitlePlace_destination"); //"Cieľ cesty zákazníka";
          break;
        case "TRANSIT1":
          //console.log("TRANSIT1!!");
          iconImage = require("@/assets/markers/marker_transit1.png");
          markerTitle = this.$t("ConsoleMap.markerTitlePlace_transit1"); //"1. prejazdový bod";
          break;
        case "TRANSIT2":
          //console.log("TRANSIT2!!");
          iconImage = require("@/assets/markers/marker_transit2.png");
          markerTitle = this.$t("ConsoleMap.markerTitlePlace_transit2"); //"2. prejazdový bod";
          break;
        case "TRANSIT3":
          //console.log("The TRANSIT3!!");
          iconImage = require("@/assets/markers/marker_transit3.png");
          markerTitle = this.$t("ConsoleMap.markerTitlePlace_transit3"); //"3. prejazdový bod";
          break;
        case "TRANSIT4":
          //console.log("The TRANSIT4!!");
          iconImage = require("@/assets/markers/marker_transit4.png");
          markerTitle = this.$t("ConsoleMap.markerTitlePlace_transit4"); //"4. prejazdový bod";
          break;
        default:
        //console.log("Any!!");
      }
      if (markerOperation == "REMOVE") {
        //Odstranit marker ak neexistuje poloha ale marker predtym existoval
        this.markerAddress[addressType].setMap(null);
        this.markerAddress[addressType] = null;
      } else if (markerOperation == "CHANGE") {
        //presunut marker ak existuje poloha a existuje marker
        this.markerAddress[addressType].setPosition(markerPosition);
        this.map.setCenter(markerPosition);
      } else if (markerOperation == "NEW") {
        //nadefinovat novy marker ak neexistuje
        this.markerAddress[addressType] = new google.maps.Marker({
          position: markerPosition,
          map: this.map,
          animation: google.maps.Animation.DROP,
          title: markerTitle,
          icon: {
            url: iconImage,
            size: new google.maps.Size(33, 47),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(16, 47),
          },
          shape: {
            coords: [
              0, 13, 5, 5, 12, 1, 16, 0, 21, 1, 29, 5, 32, 13, 32, 19, 16, 47,
              0, 19,
            ],
            type: "poly",
          },
        });
        this.map.setCenter(markerPosition);
      }
    },
    driverInfoWindowText(markerImageType, item) {
      var tempText = '<font style="color: black;">';
      tempText =
        tempText +
        "<button  title='" +
        this.$t("ConsoleMap.infoWindow_btnSetDriver") + //Nastaviť tohto vodiča v poli na záložke DETAIL objednávky
        "' onclick='window.setDriverToDetailFromMap(" +
        item.id +
        ")' style='background-color: #E65100; color: white; padding: 0px 1px 0px 2px;margin: 0px 4px 0px 0px; vertical-align: middle;'><b>&nbsp;>&nbsp;</b></button>";
      tempText =
        tempText +
        "<button  title='" +
        this.$t("ConsoleMap.infoWindow_btnMessageDriver") + //Poslať vodičovi správu
        "' onclick='window.setDriverToMessageFromMap(" +
        item.id +
        ")' style='background-color: #0080ff; color: white; padding: 0px 1px 0px 1px;margin: 0px 4px 0px 0px; vertical-align: middle;'><b>&nbsp;&#9993;&nbsp;</b></button>";
      //Meno a priezvisko vodica, stav vodica
      tempText =
        tempText +
        "<b>" +
        item.firstName +
        " " +
        item.lastName +
        "</b> - " +
        this.$t("workStatus." + item.workStatus) +
        // item.workStatus +
        "</font>";
      //Cislo vozidla, znacka, typ a SPZ
      if (item.vehicle != null) {
        tempText =
          tempText +
          '<br/><font style="color: black;">' +
          item.vehicle.internalName +
          " | " +
          item.vehicle.brand +
          " " +
          item.vehicle.model +
          " | " +
          item.vehicle.lpn;
      }
      tempText = tempText + "</font>";
      //localStorage.removeItem(\"pushDriverId\");
      /*tempText =
        tempText +
        "<button  title='Zobraziť vodiča vo výberovom poli detailu objednávky' onclick='javascript:localStorage.setItem(\"pushDriverId\"," + item.id + ");' ><b>&nbsp;i&nbsp;</b></button>";*/
      /*tempText =
        tempText +
        '<v-btn class="ma-2" @click="testFn(' + item.id + ')" color="blue"> x </v-btn>';*/

      //Aktivna objednavka: ID - 8 znakov, Adresy
      if (item.orderActual && item.orderActual != null) {
        tempText =
          tempText +
          "<br/>" +
          "<button  title='" +
          this.$t("ConsoleMap.infoWindow_btnDetail") + //Zobraziť detail objednávky
          "' style='background-color: #00695C; color: white; padding: 0px 3px;margin: 0px 4px 0px 0px;' onclick='window.showOrderDetail(" +
          '"' +
          item.orderActual.id +
          '"' +
          ")' ><b>&nbsp;i&nbsp;</b></button>";
        tempText =
          tempText +
          "<button  title='" +
          this.$t("ConsoleMap.infoWindow_btnMessageDriverClient") + //Poslať správu Zákazníkovi a Vodičovi k objednávke
          "' onclick='window.setOrderToMessageFromMap(" +
          '"' +
          item.orderActual.id +
          '"' +
          ")' style='background-color: #0080ff; color: white; padding: 0px 1px 0px 1px;margin: 0px 4px 0px 0px;'><b>&nbsp;&#9993;&nbsp;</b></button>";
        var statusPropertyData = this.statusProperty(item.orderActual.status);
        var statusColor = statusPropertyData.color;
        tempText =
          tempText +
          '<font style="color: black; background-color:' +
          statusColor +
          ';">' +
          item.orderActual.id.substring(0, 8) +
          " | " +
          this.$t("orderStatus." + item.orderActual.status) +
          // item.orderActual.status +
          "</font>";
        tempText = tempText + "</font>";
        if (item.orderActual.waypointList != null) {
          tempText = tempText + '<font style="color: black;">';
          item.orderActual.waypointList.forEach(function (
            itemWaypoint,
            indexWaypoint
          ) {
            tempText =
              tempText +
              "<br/>" +
              "<button  title='" +
              "Show on map" +
              "' onclick='window.showPositionOnMap(" +
              itemWaypoint.latitude +
              "," +
              itemWaypoint.longitude +
              ")' style='background-color: #666666; color: white; padding: 0px 1px 0px 1px;margin: 0px 4px 0px 0px;'><b>&nbsp;p&nbsp;</b></button>&nbsp;" +
              (indexWaypoint + 1) +
              ": " +
              itemWaypoint.address;
          });
          tempText = tempText + "</font>";
        }
      }
      return tempText;
    },
    findDriverOnMap(driverId) {
      var tempMarkerDriver = this.markerDriver || [];
      //ak pride konkretne ID vodzica, vycentrovat mapu na neho
      if (driverId != null) {
        var item = tempMarkerDriver[driverId];
        var latlng;
        if (item != null) {
          latlng = item.position;
          this.map.setCenter(latlng);
        } else {
          //ak sa nenajde vodic, zobrazit alert
          alert(
            this.$t("ConsoleMap.msgFindDriverOnMap1") +
              " " +
              driverId +
              " " +
              this.$t("ConsoleMap.msgFindDriverOnMap2")
          ); //"Vodič s ID " + driverId + " sa nenachádza na mape!"
        }
      } else {
        //zoom on all drivers
        this.fitDriverBoundsOnMap();
      }
    },
    showOrderDetail(orderId) {
      this.$emit("showOrderDetailByIdFromMap", orderId);
    },
    setDriverToDetailFromMap(driverId) {
      //console.log("setDriverToDetailFromMap", driverId);
      this.$emit("setDriverToDetailFromMap", driverId);
      this.snackbar.show = "true";
      this.snackbar.message = this.$t(
        "ConsoleMap.msgSetDriverToDetailFromMapSuccess"
      ); //"Vodič pridaný do detailu objednávky...";
      this.snackbar.color = "success";
      this.snackbar.timeout = 1500;
    },
    setDriverToMessageFromMap(driverId) {
      var messageObj = {};
      messageObj.messageType = "DISP_DRIVER";
      messageObj.fromDriverId = driverId;
      messageObj.toDriverId = driverId;
      messageObj.orderId = "";
      messageObj.messageText = "";
      this.$emit("showMesssageFromMap", messageObj);
    },
    setOrderToMessageFromMap(orderId) {
      var messageObj = {};
      messageObj.messageType = "ORDER";
      messageObj.fromDriverId = "";
      messageObj.toDriverId = "";
      messageObj.orderId = orderId;
      messageObj.messageText = "";
      this.$emit("showMesssageFromMap", messageObj);
    },
    showPositionOnMap(latitude, longitude) {
      if (latitude && longitude) {
        var positionCoordinates;
        //-0.00002 => umozni zobrazit marker tesne pred inym markerom, aby sa nerusili
        positionCoordinates = new google.maps.LatLng(
          latitude - 0.00002,
          longitude
        );
        this.temporaryMarker.setPosition(positionCoordinates);
        this.temporaryMarker.setAnimation(google.maps.Animation.BOUNCE);
        setTimeout(() => {
          this.temporaryMarker.setAnimation(null);
        }, 2000);
      }
    },
    setBestDriver(latitude, longitude) {
      console.log("setBestDriver latitude", latitude);
      //in range of 1000 meters find best driver, without google maps geometry library
      var positionsObject = this.passOperationalDataDriversOnMap;
      var driverIdFromPositionObject = null;
      var tempBestDriver = null;
      var tempBestDistance = 5000; //Maximalna vzdialenost vodica od miesta vyzdvihnutia objednavky v metroch
      positionsObject.forEach((item, index) => {
        //Postupne prejst zoznam vsetkych vodicov prichadzajuci zo servera
        if (
          item.workStatus == "ONLINE" &&
          item.orderActual == null &&
          item.silence == false
        ) {
          driverIdFromPositionObject = item.id;
          if (item.actualPosition != null) {
            if (
              item.actualPosition.latitude != null &&
              item.actualPosition.longitude != null
            ) {
              var lat1 = item.actualPosition.latitude;
              var lon1 = item.actualPosition.longitude;
              var lat2 = latitude;
              var lon2 = longitude;
              //check if driverActualPosition of driver is within radius of stand
              var R = 6371; // Radius of the earth in km
              var dLat = (lat2 - lat1) * (Math.PI / 180);
              var dLon = (lon2 - lon1) * (Math.PI / 180);
              var a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(lat1 * (Math.PI / 180)) *
                  Math.cos(lat2 * (Math.PI / 180)) *
                  Math.sin(dLon / 2) *
                  Math.sin(dLon / 2);
              var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
              var distance = R * c * 1000; // Distance in m
              //convert distance to number and round to integer
              distance = Number(distance.toFixed(0));
              //console.log("vodic: " +driverIdFromPositionObject +" / distance: " +distance);
              if (distance <= tempBestDistance) {
                if (
                  item.lastDriveDate != undefined &&
                  item.lastDriveDate != "null" &&
                  item.lastDriveDate != "" &&
                  item.lastDriveDate != null
                ) {
                  var d1 = new Date();
                  var d2 = new Date(item.lastDriveDate);
                  var timeWithoutOrder = d1.getTime() - d2.getTime();
                  if (timeWithoutOrder > 600000) {
                    //600000ms = 10min
                    //nad 10min bez objednavky, zvysit vzdialenost o 50metrov za každú minútu
                    distance = Math.max(
                      distance - Math.trunc(timeWithoutOrder / 60000) - 10 * 50,
                      0
                    );
                  }
                }
              }
              //console.log("distance", distance);
              if (distance < tempBestDistance) {
                tempBestDriver = driverIdFromPositionObject;
                tempBestDistance = distance;
              }
            }
          }
        }
      });
      console.log("tempBestDriver", tempBestDriver);
      if (tempBestDriver != null) {
        this.setDriverToDetailFromMap(tempBestDriver);
      } else {
        this.snackbar.show = "true";
        this.snackbar.message = this.$t(
          "ConsoleMap.msgSetDriverToDetailFromMapError"
        ); //"Nenájdený žiadny vhodný voľný blízky vodič...";
        this.snackbar.color = "error";
      }
    },
    findPositionOnMap(positionData) {
      //console.log("positionData", positionData);
      var latlng;
      if (positionData) {
        if (positionData.length > 0) {
          //console.log("positionData[0].latitude", positionData[0].latitude);
          //+0.00002 => umozni zobrazit marker tesne za inym markerom, aby sa nerusili
          latlng = new google.maps.LatLng(
            positionData[0].latitude + 0.00002,
            positionData[0].longitude
          );
          //console.log("latlng", latlng);
          this.map.setCenter(latlng);
          this.temporaryMarker.setPosition(latlng);
        }
      }
    },
    generateMarkers() {
      /*
      Ikonu urcuje "Work status", "Order status" a cas od posledneho zaznamu polohy
      WorkStatus: ONLINE, ON_BREAK, OFFLINE (to sa zrejme nikdy nezobrazi ale keby nahodou)
      OrderStatus: ACCEPTED, WAITING, IN_PROGRESS, FINISHED => alebo BUSY (ked sa pouzije zjednodusene zobrazenie farieb)
      Special status ked sa nieco udeje: DELAY, WARNING
      */
      var iconImage;
      var color1 = "#000000";
      var color2 = "#ffcc00";
      var color3 = "#ffffff";
      var markerName;
      var svg;
      var tempMarkerImage = [];

      for (let i = 0; i < 17; i++) {
        markerName = "";
        //console.log(i, "x");
        switch (i) {
          case 0:
            //return "#CCCCCC";
            markerName = "DELAY";
            color2 = "#CCCCCC";
            break;
          case 1:
            markerName = "ONLINE";
            color2 = "#00FF00";
            break;
          case 2:
            markerName = "OFFLINE";
            color2 = "#666666";
            break;
          case 3:
            markerName = "ON_BREAK";
            color2 = "#d27bd2";
            break;
          case 4:
            markerName = "WARNING";
            color2 = "#990000";
            break;
          case 5:
            markerName = "BUSY";
            color2 = "#FF0000";
            break;
          case 6:
            markerName = "ACCEPTED";
            //color2 = this.StatusColor["ACCEPTED"];
            color2 = "#FFFF00"; //"yellow accent-2";
            break;
          case 7:
            markerName = "WAITING";
            //color2 = this.StatusColor["WAITING"];
            color2 = "#FFAB40"; //"orange accent-2";
            break;
          case 8:
            markerName = "IN_PROGRESS";
            //color2 = this.StatusColor["IN_PROGRESS"];
            color2 = "#40C4FF"; //"light-blue accent-2";
            break;
          case 9:
            markerName = "FINISHED";
            //color2 = this.StatusColor["FINISHED"];
            color2 = "#0091EA"; //"light-blue accent-4"; alebo  "light-blue accent-3", //#00B0FF
            break;
          case 10:
            markerName = "TEMP";
            color2 = "#FFFFFF"; //"white";
            break;
          case 11:
            markerName = "ORDER_WHITE";
            color2 = "#FFFFFF"; //"white";
            break;
          case 12:
            markerName = "ORDER_ORANGE";
            color2 = "#FFAB40"; //"orange accent-2";
            break;
          case 13:
            markerName = "ORDER_RED";
            color2 = "#FF0000"; //"red";
            break;
          case 14:
            markerName = "ORDER_PINK";
            color2 = "#E91E63"; //"pink";
            break;
          case 15:
            markerName = "ORDER_REDDARKEN";
            color2 = "#C62828"; //"red darken-3 ";
            break;
          case 16:
            markerName = "ORDER_PINKDARKEN";
            color2 = "#AD1457"; //"pink darken-3";
            break;
          default:
            markerName = "XX";
            color2 = "#FFFFFF"; //white
            break;
        }
        //console.log("markerName: ", markerName, " - color2: ", color2);
        iconImage = "";
        svg =
          '<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="31px" height="44px" version="1.1" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 502 713" xmlns:xlink="http://www.w3.org/1999/xlink">';
        svg =
          svg +
          '<path fill="' +
          color1 +
          '" d="M251 0c-138,0 -251,112 -251,251 0,189 251,462 251,462 0,0 251,-273 251,-462 0,-138 -112,-251 -251,-251z"/>';
        svg = svg + '<circle fill="' + color2 + '" cx="251" cy="251" r="235"/>';
        svg =
          svg +
          '<path fill="' +
          color3 +
          '" d="M461 357l-420 0c-16,-32 -25,-68 -25,-106 0,-38 9,-73 25,-105l420 0c16,32 25,67 25,105 0,38 -9,74 -25,106z"/>';
        svg = svg + "</svg>";
        iconImage = "data:image/svg+xml;base64," + btoa(svg);
        tempMarkerImage[markerName] = iconImage;
      }
      this.markerImage = tempMarkerImage;
      //console.log(this.markerImage);
    },
    statusProperty(orderStatus) {
      var color = "#ffcc00";
      var markerName = "";
      var description = "default";

      //console.log(i, "x");
      switch (orderStatus) {
        case "DELAY":
          //return "#CCCCCC";
          markerName = "DELAY";
          description = "xx";
          color = "#CCCCCC";
          break;
        case "ONLINE":
          markerName = "ONLINE";
          description = "xx";
          color = "#00FF00";
          break;
        case "OFFLINE":
          markerName = "OFFLINE";
          description = "xx";
          color = "#666666";
          break;
        case "ON_BREAK":
          markerName = "ON_BREAK";
          description = "xx";
          color = "#d27bd2";
          break;
        case "WARNING":
          markerName = "WARNING";
          description = "xx";
          color = "#990000";
          break;
        case "BUSY":
          markerName = "BUSY";
          description = "xx";
          color = "#FF0000";
          break;
        case "ACCEPTED":
          markerName = "ACCEPTED";
          description = "xx";
          color = "#FFFF00"; //"yellow accent-2";
          break;
        case "WAITING":
          markerName = "WAITING";
          description = "xx";
          color = "#FFAB40"; //"orange accent-2";
          break;
        case "IN_PROGRESS":
          markerName = "IN_PROGRESS";
          description = "xx";
          color = "#40C4FF"; //"light-blue accent-2";
          break;
        case "FINISHED":
          markerName = "FINISHED";
          description = "xx";
          color = "#00B0FF"; //"light-blue accent-3";
          break;
        default:
          markerName = "XX";
          description = "xx";
          color = "#FFFFFF"; //white
          break;
      }
      return {
        name: markerName,
        description: description,
        color: color,
      };
    },
    createTemporaryMarker() {
      //Vytvorenie spravnej ikony pre marker vodica
      var tempMap = this.map;
      var markerImageSet = this.markerImage;
      /*latlng = new google.maps.LatLng(
            positionData[0].latitude,
            positionData[0].longitude
          );*/
      var updateMarker = {
        url: markerImageSet["TEMP"],
        scaledSize: new google.maps.Size(19, 29),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(10, 29),
        labelOrigin: new google.maps.Point(10, 10),
      };
      this.temporaryMarker = new google.maps.Marker({
        position: this.mapCenter,
        map: tempMap,
        label: {
          text: "?",
          color: "#999",
          fontWeight: "900",
          fontSize: "100%",
        },
        icon: updateMarker,
        shape: {
          coords: [1, 9, 4, 4, 10, 1, 14, 4, 19, 9, 10, 29],
          //coords: [1, 15, 5, 5, 16, 1, 24, 5, 31, 15, 16, 44],
          type: "poly",
        },
      });
    },

    generateMarkersX() {
      /*
      Ikonu urcuje "Work status", "Order status" a cas od posledneho zaznamu polohy
      WorkStatus: ONLINE, ON_BREAK, OFFLINE (to sa zrejme nikdy nezobrazi ale keby nahodou)
      OrderStatus: ACCEPTED, WAITING, IN_PROGRESS, FINISHED => alebo BUSY (ked sa pouzije zjednodusene zobrazenie farieb)
      Special status ked sa nieco udeje: DELAY, WARNING
      */
      var iconImage;
      var color1 = "#000000";
      var color2 = "#ffcc00";
      var color3 = "#ffffff";
      var markerName;
      var svg;
      var tempMarkerImage = [];

      for (let i = 0; i < 11; i++) {
        markerName = "";
        //console.log(i, "x");
        switch (i) {
          case 0:
            //return "#CCCCCC";
            markerName = "DELAY";
            color2 = "#CCCCCC";
            break;
          case 1:
            markerName = "ONLINE";
            color2 = "#00FF00";
            break;
          case 2:
            markerName = "OFFLINE";
            color2 = "#666666";
            break;
          case 3:
            markerName = "ON_BREAK";
            color2 = "#d27bd2";
            break;
          case 4:
            markerName = "WARNING";
            color2 = "#990000";
            break;
          case 5:
            markerName = "BUSY";
            color2 = "#FF0000";
            break;
          case 6:
            markerName = "ACCEPTED";
            color2 = "#FFFF00"; //"yellow accent-2";
            break;
          case 7:
            markerName = "WAITING";
            color2 = "#FFAB40"; //"orange accent-2";
            break;
          case 8:
            markerName = "IN_PROGRESS";
            color2 = "#40C4FF"; //"light-blue accent-2";
            break;
          case 9:
            markerName = "FINISHED";
            color2 = "#00B0FF"; //"light-blue accent-3";
            break;
          case 10:
            markerName = "TEMP";
            color2 = "#FFFFFF"; //"white";
            break;
          default:
            markerName = "XX";
            color2 = "#FFFFFF"; //white
            break;
        }
        //console.log("markerName: ", markerName, " - color2: ", color2);
        iconImage = "";
        svg =
          '<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="35px" height="67px" version="1.1" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 1.18 2.22" xmlns:xlink="http://www.w3.org/1999/xlink">';
        svg = svg + '<defs><style type="text/css">';
        svg = svg + "<![CDATA[";
        svg =
          svg +
          ".str0 {stroke:#999999;stroke-width:0.03;stroke-miterlimit:22.9256}";
        svg = svg + ".fil1 {fill:black}";
        svg = svg + " .fil3 {fill:#999999}";
        svg = svg + ".fil0 {fill:" + color2 + "}";
        svg = svg + ".fil2 {fill:white}";
        svg = svg + " ]]>";
        svg = svg + "</style>";
        svg = svg + "</defs>";
        svg = svg + '<g id="Layer_x0020_1"  >'; //transform="rotate(-180 35 35)
        svg =
          svg +
          '<path class="fil0 str0" d="M0.09 0.76c0.01,0 0.02,0 0.02,0l0 -0.37c0,-0.2 0.17,-0.37 0.38,-0.37l0.2 0c0.21,0 0.37,0.17 0.37,0.37l0 0.37c0.01,0 0.02,0 0.02,0 0.06,0.03 0.09,0.06 0.08,0.09 -0.01,0.02 -0.06,0.02 -0.11,0 0,0 -0.01,0 -0.01,-0.01 -0.02,0.09 -0.04,0.2 -0.04,0.31 0,0.15 0.03,0.28 0.06,0.39l0 0.43c0,0.13 -0.1,0.24 -0.23,0.24l-0.48 0c-0.13,0 -0.24,-0.11 -0.24,-0.24l0 -0.43c0.04,-0.11 0.06,-0.24 0.06,-0.39 0,-0.11 -0.01,-0.22 -0.03,-0.31 -0.01,0.01 -0.01,0.01 -0.01,0.01 -0.05,0.02 -0.1,0.02 -0.11,0 -0.01,-0.03 0.02,-0.06 0.07,-0.09z"/>';
        svg =
          svg +
          '<path class="fil1" d="M0.59 0.56c0.2,0 0.36,0.1 0.36,0.21 0,0.07 -0.05,0.12 -0.12,0.16 -0.07,-0.01 -0.16,-0.02 -0.24,-0.02 -0.09,0 -0.17,0.01 -0.24,0.02 -0.08,-0.04 -0.12,-0.09 -0.12,-0.16 0,-0.11 0.16,-0.21 0.36,-0.21z"/>';
        svg =
          svg +
          '<path class="fil1" d="M0.88 2.07c-0.08,0.03 -0.18,0.05 -0.29,0.05 -0.11,0 -0.22,-0.02 -0.3,-0.05 0,-0.01 0,-0.02 0,-0.03 0,-0.03 0.02,-0.06 0.05,-0.08 0.07,0.01 0.16,0.02 0.25,0.02 0.09,0 0.17,-0.01 0.25,-0.02 0.03,0.02 0.05,0.05 0.05,0.08 0,0.01 0,0.02 -0.01,0.03z"/>';
        svg =
          svg +
          '<ellipse class="fil1" cx="0.25" cy="1.39" rx="0.03" ry="0.44"/>';
        svg =
          svg +
          '<ellipse class="fil1" cx="0.93" cy="1.39" rx="0.03" ry="0.44"/>';
        svg =
          svg +
          '<path class="fil2" d="M0.87 0.11c0.05,-0.01 0.1,0.05 0.13,0.12 0.02,0.05 0.03,0.1 0.02,0.14 0,-0.03 -0.01,-0.07 -0.03,-0.11 -0.02,-0.07 -0.07,-0.13 -0.12,-0.15z"/>';
        svg =
          svg +
          '<path class="fil2" d="M0.31 0.11c-0.05,-0.01 -0.11,0.05 -0.14,0.12 -0.02,0.05 -0.02,0.1 -0.01,0.14 0,-0.03 0.01,-0.07 0.02,-0.11 0.03,-0.07 0.08,-0.13 0.13,-0.15z"/>';
        svg =
          svg +
          '<path class="fil3" d="M0.82 0.14c0.12,0.2 0.16,0.24 0.16,0.54 -0.02,-0.12 -0.04,-0.27 -0.16,-0.54z"/>';
        svg =
          svg +
          ' <path class="fil3" d="M0.35 0.14c-0.11,0.2 -0.15,0.24 -0.15,0.54 0.02,-0.12 0.04,-0.27 0.15,-0.54z"/>';
        svg = svg + " </g>";

        svg = svg + "</svg>";
        iconImage = "data:image/svg+xml;base64," + btoa(svg);
        tempMarkerImage[markerName] = iconImage;
      }
      this.markerImage = tempMarkerImage;
      //console.log(this.markerImage);
    },
    getStands() {
      var map = this.map;
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/stand`)
        .then((response) => {
          var itemList = response.data;
          //if null or undefined, then return
          if (itemList == null || itemList == undefined) {
            return;
          }
          //Ak pride Object, tak zo treba zmenit na Array, aby sme mohli urobit loop/for
          if (itemList.constructor.name != "Array") {
            var newItemList = [];
            newItemList.push(itemList);
            itemList = newItemList;
          }
          //Najdenie defaultneho stanovista
          var titleText = "Stand";
          var iconFile = "marker_stand.png";

          itemList.forEach((item) => {
            if (item.active == true) {
              if (item.defaultStand == true) {
                var titleText = this.$t("ConsoleMap.defaultStand"); //"Default Stand";
                var iconFile = "marker_stand_default.png";
              } else {
                var titleText = this.$t("ConsoleMap.stand"); //"Stand";
                var iconFile = "marker_stand.png";
              }
              //Marker na mapu
              var markerPosition = new google.maps.LatLng(
                item.latitude,
                item.longitude
              );
              const iconImage = {
                url: require("@/assets/markers/" + iconFile),
                // This marker is 20 pixels wide by 32 pixels high.
                //size: new google.maps.Size(24, 34),
                scaledSize: new google.maps.Size(24, 34),
                // The origin for this image is (0, 0).
                origin: new google.maps.Point(0, 0),
                // The anchor for this image is the base of the flagpole at (0, 32).
                anchor: new google.maps.Point(12, 34),
                //labelOrigin: new google.maps.Point(15, 15),
                //scale: 0.33,
              };
              var standMarker = new google.maps.Marker({
                position: markerPosition,
                map: map,
                // internalName, name, capacity, radius
                title:
                  titleText +
                  " " +
                  item.internalName +
                  " (" +
                  item.name +
                  ")" +
                  "\n" +
                  this.$t("ConsoleMap.capacity") +
                  ": " +
                  item.capacity +
                  "\n" +
                  this.$t("ConsoleMap.radius") +
                  ": " +
                  item.radius +
                  "m",
                icon: iconImage,
                /*icon: {
                  path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z",
                  fillColor: "#444",
                  fillOpacity: 0.85,
                  strokeColor: "#222",
                  strokeWeight: 1,
                  scale: 1.2,
                  anchor: new google.maps.Point(0, 0),
                  labelOrigin: new google.maps.Point(0, -28),
                },
                label: { text: "S", color: "white" },*/
              });
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    manageOrderMarker(tempOrders) {
      var map = this.map;
      var orderMarkers = this.orderMarkers || [];
      var markerImageSet = this.markerImage;

      //if orderMarkerSetting = "HIDE", then remove all markers and exist function
      if (this.orderMarkerSetting == "NEVER") {
        //remove all markers if exist
        if (orderMarkers.length > 0) {
          orderMarkers.forEach((marker) => {
            marker.setMap(null);
          });
          orderMarkers = [];
          this.orderMarkers = [];
        }
        return;
      }

      //var indexCounter = -1;
      //nacitanie textu pre markery
      const markerOrderTitle_durationSinceTryingAssign = this.$t(
        "ConsoleMap.markerOrderTitle_durationSinceTryingAssign"
      );
      const markerOrderTitle_addresses = this.$t(
        "ConsoleMap.markerOrderTitle_addresses"
      );
      const markerOrderTitle_customer = this.$t(
        "ConsoleMap.markerOrderTitle_customer"
      );
      const markerOrderTitle_driver = this.$t(
        "ConsoleMap.markerOrderTitle_driver"
      );
      const markerOrderTitle_pbx = this.$t("ConsoleMap.markerOrderTitle_pbx");
      const markerOrderTitle_preferredDriver = this.$t(
        "ConsoleMap.markerOrderTitle_preferredDriver"
      );
      const markerOrderTitle_dispatch = this.$t(
        "ConsoleMap.markerOrderTitle_dispatch"
      );
      const markerOrderTitle_time = this.$t("ConsoleMap.markerOrderTitle_time");
      const markerOrderTitle_instant = this.$t(
        "ConsoleMap.markerOrderTitle_instant"
      );

      //filter from tempOrders only with statu PENDING
      var orders = tempOrders.filter((order) => {
        return order.status == "PENDING";
      });
      //Ak je orders prazdny, tak vymazeme vsetky markery
      //console.log("orders.length: ", orders.length);
      //console.log("orders", orders);
      //console.log("orderMarkers.length start script: ", orderMarkers.length);
      //console.log("orderMarkers", orderMarkers);
      if (orders.length == 0) {
        //ak je orders prazdny, tak vymazeme vsetky markery
        //ak nie je orderMarkers prazdny, tak vymazeme vsetky markery
        //console.log("orderMarkers.length pri mazani vsetkych: ",orderMarkers.length);
        if (orderMarkers.length > 0) {
          //const keys = Object.keys(orderMarkers);
          //keys.forEach(function (marker, index) {
          orderMarkers.forEach((marker) => {
            marker.setMap(null);
          });
          orderMarkers = [];
          this.orderMarkers = [];
        }
      } else {
        //vymazeme stare markery (aj z orderMarkers), ktore uz nie su v orders
        orderMarkers.forEach((marker) => {
          var found = false;
          orders.forEach((order) => {
            if (marker.orderId == order.id) {
              found = true;
            }
          });
          if (found == false) {
            //odstranim marker orderMarker[markerIndex] z orderMarkers
            var markerIndex = orderMarkers.indexOf(marker);
            if (markerIndex > -1) {
              marker.setMap(null);
              orderMarkers.splice(markerIndex, 1);
            }
          }
        });

        var updateMarkerRedDarken = {
          url: markerImageSet["ORDER_REDDARKEN"],
          scaledSize: new google.maps.Size(23, 33),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(12, 33),
          labelOrigin: new google.maps.Point(12, 11),
        };
        var updateMarkerPinkDarken = {
          url: markerImageSet["ORDER_PINKDARKEN"],
          scaledSize: new google.maps.Size(23, 33),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(12, 33),
          labelOrigin: new google.maps.Point(12, 11),
        };
        var updateMarkerRed = {
          url: markerImageSet["ORDER_RED"],
          scaledSize: new google.maps.Size(23, 33),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(12, 33),
          labelOrigin: new google.maps.Point(12, 11),
        };
        var updateMarkerOrange = {
          url: markerImageSet["ORDER_ORANGE"],
          scaledSize: new google.maps.Size(23, 33),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(12, 33),
          labelOrigin: new google.maps.Point(12, 11),
        };
        var updateMarkerWhite = {
          url: markerImageSet["ORDER_WHITE"],
          scaledSize: new google.maps.Size(23, 33),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(12, 33),
          labelOrigin: new google.maps.Point(12, 11),
        };
        var updateMarkerPink = {
          url: markerImageSet["ORDER_PINK"],
          scaledSize: new google.maps.Size(23, 33),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(12, 33),
          labelOrigin: new google.maps.Point(12, 11),
        };
        var labelText = "";
        var minutes = 0;
        var markerLabel = {
          fontWeight: "normal",
          text: labelText,
          color: "#000",
          fontSize: "90%",
        };
        var orderSource = "";
        var orderSourceText = "";
        var orderSourceLabelText = "";
        var orderTypeText = "";
        var orderTypeLabelText = "";
        var preferredDriverText = "";
        var preferredDriverLabelText = "";

        //orders.forEach((order) => {
        orders.forEach(function (order, index) {
          //ak marker existuje, nevytvarat novy
          //get index of marker
          var markerIndex = orderMarkers.findIndex((marker) => {
            return marker.orderId == order.id;
          });
          if (markerIndex > -1) {
            //marker existuje, nevytvaram novy ale updatujem
            //ak je order.type == "INSTANT", vypočítaj koľko sekúnd je od vytvorenia orderu (createdAt) so zohľadnením lokálneho času (knižnica moment.js)
            labelText = "";
            minutes = 0;
            orderSource = "";
            orderSourceText = "";
            orderSourceLabelText = "";
            orderTypeText = "";
            orderTypeLabelText = "";
            preferredDriverText = "";
            preferredDriverLabelText = "";
            if (
              order.source == "DISPATCH_TAXXIMO" ||
              order.source == "DISPATCH_TAXISERVICE" ||
              order.source == "DISPATCH_BROKER"
            ) {
              orderSource = "DISPATCH";
              orderSourceText = markerOrderTitle_dispatch;
            } else if (
              order.source == "CUSTOMER_ANDROID" ||
              order.source == "CUSTOMER_IOS" ||
              order.source == "BRAND_ANDROID" ||
              order.source == "BRAND_IOS"
            ) {
              orderSource = "CUSTOMER";
              orderSourceText = "*" + markerOrderTitle_customer;
            } else if (order.source == "DRIVER_ANDROID") {
              orderSource = "DRIVER";
              orderSourceText = "~" + markerOrderTitle_driver;
            } else if (order.source == "PBX") {
              orderSource = "PBX";
              orderSourceText = "^" + markerOrderTitle_pbx;
            } else {
              orderSource = order.source;
              orderSourceText = order.source;
            }
            if (orderSource == "CUSTOMER") {
              orderSourceLabelText = "*";
            } else if (orderSource == "DRIVER") {
              orderSourceLabelText = "~";
            } else if (orderSource == "PBX") {
              orderSourceLabelText = "^";
            }
            if (order.preferredDriver) {
              if (order.preferredDriver.id) {
                preferredDriverText =
                  "! " +
                  markerOrderTitle_preferredDriver +
                  ": " +
                  (order.preferredDriver.firstName
                    ? order.preferredDriver.firstName
                    : "") +
                  " " +
                  (order.preferredDriver.lastName
                    ? order.preferredDriver.lastName
                    : "");
                preferredDriverLabelText = "!";
              }
            }
            if (order.type == "INSTANT") {
              orderTypeText = markerOrderTitle_instant;
              //niekedy bol createdAt, ale kvôli tomu, že z appky klienta je rozdiel medzi vytvorením PREREGISTERED
              //a ich odoslaním, tak potrebujeme v appke zákazníka updatovať requsetedTime, aby sme mali správny čas
              //var requestedTime = moment(order.createdAt);
              var requestedTime = moment(order.requestedTime);
              var now = moment();
              var duration = moment.duration(now.diff(requestedTime));
              var seconds = duration.asSeconds();
              //konvertuj seconds na minúty a zaokrúhli na celé číslo
              minutes = Math.round(seconds / 60);
            } else if (order.type == "TIME") {
              orderTypeText = "°" + markerOrderTitle_time;
              orderTypeLabelText = "°";
              //ak je order.type == "TIME", vypočítaj koľko sekúnd je od požadovaného času orderu (requestedTime) minus presendTime (v minútach) so zohľadnením lokálneho času (knižnica moment.js)
              var requestedTime = moment(order.requestedTime);
              var now = moment();
              //presendTime je v minútach, preto ho musíme prekonvertovať na sekundy
              var presendTime = order.presendTime * 60;
              requestedTime = requestedTime.subtract(presendTime, "seconds");
              var duration = moment.duration(now.diff(requestedTime));
              var seconds = duration.asSeconds();
              //konvertuj seconds na minúty a zaokrúhli na celé číslo
              minutes = Math.round(seconds / 60);
            } else {
              minutes = 0;
            }
            //je viac ako 5 minút, tak zmen farbu markeru na cervenu
            if (minutes > 15 && order.type == "INSTANT") {
              orderMarkers[markerIndex].setIcon(updateMarkerRedDarken);
            } else if (minutes > 5 && order.type == "INSTANT") {
              orderMarkers[markerIndex].setIcon(updateMarkerRed);
            } else if (minutes > 2 && order.type == "INSTANT") {
              orderMarkers[markerIndex].setIcon(updateMarkerOrange);
            } else if (minutes > 15 && order.type == "TIME") {
              orderMarkers[markerIndex].setIcon(updateMarkerPinkDarken);
            } else if (minutes > 5 && order.type == "TIME") {
              orderMarkers[markerIndex].setIcon(updateMarkerPink);
            } else if (minutes > 2 && order.type == "TIME") {
              orderMarkers[markerIndex].setIcon(updateMarkerOrange);
            } else {
              orderMarkers[markerIndex].setIcon(updateMarkerWhite);
            }
            //je viac ako 99 minút, tak nastav labelText na 99+
            if (minutes > 99) {
              labelText =
                orderSourceLabelText +
                "99+" +
                preferredDriverLabelText +
                orderTypeLabelText;
            } else if (minutes < -99) {
              labelText =
                orderSourceLabelText +
                "-M" +
                preferredDriverLabelText +
                orderTypeLabelText;
            } else {
              labelText =
                orderSourceLabelText +
                minutes.toString() +
                preferredDriverLabelText +
                orderTypeLabelText;
            }
            markerLabel = {
              fontWeight: "normal",
              text: labelText,
              color: "#000",
              fontSize: "90%",
            };
            //set label
            orderMarkers[markerIndex].setLabel(markerLabel);
            //set title
            //get address from order.waypointList ordered by "ordering" asc, where status == "ACTIVE", and add to variable  markerTitle, one address per line
            var markerTitle = "";
            //prvych 8 znakov z ID orderu a typ orderu
            markerTitle +=
              "ID: " +
              order.id.substring(0, 8) +
              " | " +
              orderTypeText +
              " | " +
              orderSourceText +
              "\n";
            if (preferredDriverText != "") {
              markerTitle += preferredDriverText + "\n";
            }
            //minuty od vytvorenia orderu
            //ak je viac ako 99min, tak zobraz hodiny a minuty
            if (minutes > 99) {
              var hours = Math.floor(minutes / 60);
              var minutes = minutes % 60;
              markerTitle +=
                markerOrderTitle_durationSinceTryingAssign +
                ": " +
                hours +
                "h " +
                minutes +
                "min\n"; // "Dĺžka prideľovania
            } else {
              markerTitle +=
                markerOrderTitle_durationSinceTryingAssign +
                ": " +
                minutes +
                "min\n"; // "Dĺžka prideľovania
            }
            markerTitle += markerOrderTitle_addresses + ":\n"; //Adresy
            var waypointList = order.waypointList;
            waypointList.sort(function (a, b) {
              return a.ordering - b.ordering;
            });
            //at begining of each waypoint add "• "
            waypointList.forEach(function (waypoint) {
              if (waypoint.status == "ACTIVE") {
                markerTitle += "• " + waypoint.address + "\n";
              }
            });
            orderMarkers[markerIndex].setTitle(markerTitle);
          } else {
            //marker neexistuje, vytvaram novy
            labelText = "";
            minutes = 0;
            //indexCounter++;
            //ak je order.type == "INSTANT", vypočítaj koľko sekúnd je od vytvorenia orderu (createdAt) so zohľadnením lokálneho času (knižnica moment.js)
            if (order.type == "INSTANT") {
              var createdAt = moment(order.createdAt);
              var now = moment();
              var duration = moment.duration(now.diff(createdAt));
              var seconds = duration.asSeconds();
              //console.log("seconds: ", seconds);
              //konvertuj seconds na minúty a zaokrúhli na celé číslo
              minutes = Math.round(seconds / 60);
            } else if (order.type == "TIME") {
              //ak je order.type == "TIME", vypočítaj koľko sekúnd je od požadovaného času orderu (requestedTime) minus presendTime (v minútach) so zohľadnením lokálneho času (knižnica moment.js)
              var requestedTime = moment(order.requestedTime);
              var now = moment();
              //presendTime je v minútach, preto ho musíme prekonvertovať na sekundy
              var presendTime = order.presendTime * 60;
              requestedTime = requestedTime.subtract(presendTime, "seconds");
              var duration = moment.duration(now.diff(requestedTime));
              var seconds = duration.asSeconds();
              //konvertuj seconds na minúty a zaokrúhli na celé číslo
              minutes = Math.round(seconds / 60);
            } else {
              minutes = 0;
            }
            //je viac ako 99 minút, tak nastav labelText na 99+
            if (minutes > 99) {
              labelText = "99+";
            } else if (minutes < -99) {
              labelText = "-M";
            } else {
              labelText = minutes.toString();
            }
            //ak je minutes > 5, tak nastav marker na Červený/Ružový
            if (minutes > 15 && order.type == "INSTANT") {
              var updateMarker = updateMarkerRedDarken;
            } else if (minutes > 5 && order.type == "INSTANT") {
              var updateMarker = updateMarkerRed;
            } else if (minutes > 2 && order.type == "INSTANT") {
              var updateMarker = updateMarkerOrange;
            } else if (minutes > 15 && order.type == "TIME") {
              var updateMarker = updateMarkerPinkDarken;
            } else if (minutes > 5 && order.type == "TIME") {
              var updateMarker = updateMarkerPink;
            } else if (minutes > 2 && order.type == "TIME") {
              var updateMarker = updateMarkerOrange;
            } else {
              var updateMarker = updateMarkerWhite;
            }

            //get positon from order.waypointList, where type == "BOARD" and status == "ACTIVE"
            var waypoint = order.waypointList.find((waypoint) => {
              return waypoint.type == "BOARD" && waypoint.status == "ACTIVE";
            });
            var markerPosition = new google.maps.LatLng(
              waypoint.latitude,
              waypoint.longitude
            );
            var markerLabel = {
              fontWeight: "normal",
              text: labelText,
              color: "#000",
              fontSize: "90%",
            };
            var marker = new google.maps.Marker({
              position: markerPosition,
              map: map,
              label: markerLabel,
              icon: updateMarker,
              orderId: order.id,
              shape: {
                coords: [1, 11, 4, 4, 12, 1, 20, 4, 23, 11, 12, 33],
                //coords: [1, 15, 5, 5, 16, 1, 24, 5, 31, 15, 16, 44],
                type: "poly",
              },
            });
            //onClick event zavolať funkciu showOrderDetail(order.id)
            marker.addListener("click", function () {
              showOrderDetail(order.id);
            });
            //right click on marker
            marker.addListener("rightclick", function () {
              //console.log("right click on marker");
              //console.log("markerPosition: ", waypoint.latitude);
              //setDriverToDetailFromMap
              setBestDriver(waypoint.latitude, waypoint.longitude);
            });
            //console.log("novy marker: ", marker);
            orderMarkers.push(marker);
          }
        });
      }
      this.orderMarkers = orderMarkers;
    },
    manageOrderLines(tempOrders) {
      var map = this.map;
      var orderLines = this.orderLines || [];
      //if orderMarkerSetting = "HIDE", then remove all markers and exist function
      if (
        this.orderMarkerSetting == "NEVER" ||
        this.orderMarkerSetting == "PIN"
      ) {
        //remove all lines if exist
        if (orderLines.length > 0) {
          orderLines.forEach((line) => {
            line.setMap(null);
          });
          orderLines = [];
          this.orderLines = [];
        }
        return;
      }
      var strokeColor = "#666";
      var strokeOpacity = 0.1;
      var strokeWeight = 1;
      if (this.orderMarkerSetting == "PINLINE") {
        strokeColor = "#666";
        strokeOpacity = 0.5;
        strokeWeight = 1;
      }
      if (this.orderMarkerSetting == "PINLINERED") {
        strokeColor = "#FF0000";
        strokeOpacity = 0.8;
        strokeWeight = 2;
      }
      //filter from tempOrders only with statu PENDING
      var orders = tempOrders.filter((order) => {
        return order.status == "PENDING";
      });
      //ak je orders prazdny, tak vymazeme vsetky lines
      if (orders.length == 0) {
        //remove all lines if exist
        if (orderLines.length > 0) {
          orderLines.forEach((line) => {
            line.setMap(null);
          });
          orderLines = [];
          this.orderLines = [];
        }
        return;
      } else {
        //vymazeme stare lines (aj z orderLines), ktore uz nie su v orders
        orderLines.forEach((line) => {
          var found = false;
          orders.forEach((order) => {
            if (line.orderId == order.id) {
              found = true;
            }
          });
          if (found == false) {
            //odstranim marker orderLines[lineIndex] z orderLines
            var lineIndex = orderLines.indexOf(line);
            if (lineIndex > -1) {
              line.setMap(null);
              orderLines.splice(lineIndex, 1);
            }
          }
        });
        orders.forEach(function (order, index) {
          //ak line existuje, nevytvarat novy
          //get index of line
          var lineIndex = orderLines.findIndex((line) => {
            return line.orderId == order.id;
          });
          if (lineIndex > -1) {
            //line existuje, nevytvaram novy ale updatujem
            //compare line with order
            var line = orderLines[lineIndex];
            var waypointList = order.waypointList;
            waypointList.sort(function (a, b) {
              return a.ordering - b.ordering;
            });
            //only active waypoints
            waypointList = waypointList.filter((waypoint) => {
              return waypoint.status == "ACTIVE";
            });
            //compare if line positions are same as waypointList
            var lineCoordinates = line.getPath().getArray();
            var lineCoordinatesLength = lineCoordinates.length;
            var waypointListLength = waypointList.length;
            if (lineCoordinatesLength != waypointListLength) {
              //lineCoordinatesLength != waypointListLength, update line
              var lineCoordinates = [];
              waypointList.forEach(function (waypoint) {
                if (waypoint.status == "ACTIVE") {
                  var lineCoordinate = {
                    lat: waypoint.latitude,
                    lng: waypoint.longitude,
                  };
                  lineCoordinates.push(lineCoordinate);
                }
              });
              line.setPath(lineCoordinates);
            } //este treba skontrolovat, ci sa zmenili konkretne body na map
          } else {
            //marker neexistuje, vytvaram novy
            //Add line between all waypointes
            var waypointList = order.waypointList;
            waypointList.sort(function (a, b) {
              return a.ordering - b.ordering;
            });
            var lineCoordinates = [];
            waypointList.forEach(function (waypoint) {
              if (waypoint.status == "ACTIVE") {
                var lineCoordinate = {
                  lat: waypoint.latitude,
                  lng: waypoint.longitude,
                };
                lineCoordinates.push(lineCoordinate);
              }
            });
            var tempLine = new google.maps.Polyline({
              path: lineCoordinates,
              orderId: order.id,
              geodesic: true,
              strokeColor: strokeColor,
              strokeOpacity: strokeOpacity,
              strokeWeight: strokeWeight,
            });
            tempLine.setMap(map);
            orderLines.push(tempLine);
          }
        });
      }
      this.orderLines = orderLines;
    },
  },
};
</script>
