<template>
  <v-card max-width="400" class="mx-auto">
    <v-toolbar color="secondary" dense dark>
      <v-app-bar-nav-icon
        :title="$t('ConsoleOrderList.panelOrderList_title')"
        @click.stop="showPanel = !showPanel"
      >
        <v-icon v-if="showPanel">mdi-menu-down</v-icon>
        <v-icon v-else>mdi-menu-up</v-icon>
      </v-app-bar-nav-icon>
      <!-- Zobrazit nazov panela, iba pri minimalizovani. Ak je otvoreny, je jasne co tam je. Pri malom displeji iba ikonu -->
      <v-toolbar-title
        v-show="!showPanel"
        v-if="$vuetify.breakpoint.smAndDown"
        class="pl-0"
        ><v-icon>mdi-road-variant</v-icon></v-toolbar-title
      >
      <v-toolbar-title v-show="!showPanel" v-else>{{
        $t("ConsoleOrderList.panelOrderList")
      }}</v-toolbar-title>
      <!-- Vyhľadávacie pole je potrebné potvrdzovať Enter -->
      <v-spacer></v-spacer>
      <v-text-field
        class="mr-1"
        solo-inverted
        dense
        hide-details
        :label="$t('ConsoleOrderList.searchField')"
        prepend-inner-icon="mdi-magnify"
        :value="search"
        @change="(v) => (search = v)"
        clearable
        @click:clear="
          clearSearch();
          search = '';
          searchToDetailSwitchId = '';
        "
        v-show="showPanel"
        :title="$t('ConsoleOrderList.searchField_title')"
      ></v-text-field>
      <v-spacer></v-spacer>

      <v-btn
        icon
        @click="showPlannedOrder = !showPlannedOrder"
        v-show="showPanel"
        dense
        :title="
          showPlannedOrder
            ? $t('ConsoleOrderList.hidePlannedOrder_title')
            : $t('ConsoleOrderList.showPlannedOrder_title')
        "
      >
        <v-icon v-if="!showPlannedOrder" color="grey lighten-1"
          >mdi-clock-remove-outline</v-icon
        >
        <v-icon v-if="showPlannedOrder">mdi-clock-check-outline</v-icon>
      </v-btn>

      <v-switch
        v-show="showPanel"
        dense
        :title="$t('ConsoleOrderList.showOnlyActiveOrder_title')"
        hide-details
        v-model="showOnlyActiveOrder"
        @change="switchOnlyActiveOrder()"
      ></v-switch>

      <v-progress-linear
        absolute
        bottom
        :indeterminate="loadingIndeterminate"
        :buffer-value="loading ? 100 : 0"
        :value="loading ? 100 : 0"
      ></v-progress-linear>
      <!--:style="{visibility: loading ? 'visible' : 'hidden'}"-->
    </v-toolbar>
    <!-- <div class="overflow-y-auto" style="max-height: 80vh" v-show="showPanel"> -->
    <div v-show="showPanel">
      <!--   <virtual-list
        :data-key="'id'"
        :data-sources="orderedItems"
        :data-component="item"
        :estimate-size="50"
      />-->
      <!--<template v-for="item in orderedItems">-->
      <v-virtual-scroll
        :items="orderedItems"
        bench="10"
        :item-height="118"
        height="80vh"
        ><template v-slot:default="{ item }">
          <OrderListItem
            :source="item"
            itemHeight="118"
            :key="item.id"
            @showOrderDetailByIdFromItem="showOrderDetailByIdFromItem($event)"
            @passFindPositionCoordinatesToMapFromItem="
              passFindPositionCoordinatesToMapFromItem($event)
            "
          />
        </template>
      </v-virtual-scroll>
      <!--</template>-->
      <!--     <v-list three-line dense>
        <template v-for="(item, index) in orderedItems">
          <v-list-item
            :key="item.id"
            :class="getStatusColor(item.status)"
            dense
          >
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-title
                  ><v-icon
                    v-if="item.preferredDriverId"
                    title="Preferovaný vodič"
                    color="black"
                    >mdi-account-star-outline</v-icon
                  >{{
                    item.id.slice(0, 8) +
                    " | " +
                    $t('source.' + item.source) +
                    " | " +
                    new Date(
                      new Date(item.createdAt) -
                        new Date().getTimezoneOffset() * 60000
                    ).toLocaleString("sk-SK") +
                    " | " +
                    $t('orderType.' + item.type) +
                    " | " +
                    $t('orderStatus.' + item.status)
                  }}</v-list-item-title
                ><v-divider dense></v-divider>
                
                <v-list-item-subtitle
                  class="text--primary"
                  v-if="item.type == 'TIME'"
                  title="Požadovaný čas vyzdvihnutia"
                >
                  <v-icon color="black">mdi-clock-outline</v-icon>
                  {{
                    new Date(
                      new Date(item.requestedTime) -
                        new Date().getTimezoneOffset() * 60000
                    ).toLocaleString("sk-SK") +
                    " | " +
                    item.presendTime
                  }}
                  min.</v-list-item-subtitle
                >
                
                <v-list-item-subtitle v-if="item.vehicle" class="text--primary"
                  ><v-icon color="black">mdi-taxi</v-icon
                  >{{
                    (item.firstName ? item.firstName : "") +
                    (item.lastName ? " " + item.lastName : "") +
                    " | " +
                    (item.vehicle.internalName
                      ? item.vehicle.internalName
                      : "") +
                    (item.vehicle.brand ? " " + item.vehicle.brand : "") +
                    (item.vehicle.model ? " " + item.vehicle.model : "") +
                    (item.color
                      ? " " + $t("vehicleColor_short." + item.color)
                      : "") +
                    (item.vehicle.lpn ? " | " + item.vehicle.lpn : "")
                  }}</v-list-item-subtitle
                >
                
                <v-list-item-subtitle v-if="item.note" class="text--primary"
                  ><v-icon color="black">mdi-text-box-outline</v-icon
                  >{{ item.note }}</v-list-item-subtitle
                >

                
                <v-list-item
                  dense
                  class="black--text pa-0 ma-0"
                  justify="center"
                  style="min-height: 0px"
                  v-for="waypoint in item.waypointList"
                  :key="waypoint.id"
                  >
                  <v-list-item-content class="pa-0 ma-0">
                    <v-list-item-title
                      class="d-inline-block text-truncate black--text"
                      style="max-width: 300px"
                    >
                      <v-icon dense color="black" justify="center">{{
                        waypoint.type == "BOARD"
                          ? "fas fa-street-view"
                          : waypoint.type == "DESTINATION"
                          ? "fas fa-flag-checkered"
                          : waypoint.type == "TRANSIT"
                          ? "mdi-transit-connection-variant"
                          : "mdi-map-marker"
                      }}</v-icon
                      >{{ waypoint.address }}</v-list-item-title
                    ></v-list-item-content
                  >
                </v-list-item>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon
                  color="black"
                  :title="$t('source_tooltip.' + item.source)"
                  class="mx-auto pb-2"
                >
                  {{
                    item.source == "DISPATCH_TAXISERVICE"
                      ? "mdi-face-man-outline"
                      : item.source == "DISPATCH_TAXXIMO"
                      ? "mdi-face-man-outline"
                      : item.source == "DISPATCH_BROKER"
                      ? "mdi-face-man-outline"
                      : item.source == "DRIVER_ANDROID"
                      ? "mdi-account-tie-outline"
                      : item.source == "CUSTOMER_ANDROID"
                      ? "mdi-human-greeting-variant"
                      : item.source == "CUSTOMER_IOS"
                      ? "mdi-human-greeting-variant"
                      : item.source == "BRAND_ANDROID"
                      ? "mdi-human-greeting-variant"
                      : item.source == "BRAND_IOS"
                      ? "mdi-human-greeting-variant"
                      : "mdi-alien-outline"
                  }}</v-icon
                >
                <v-icon
                  color="black"
                  :title="$t('orderType_tooltip.' + item.type)"
                  class="mx-auto pb-1"
                >
                  {{
                    item.type == "INSTANT"
                      ? "mdi-bell-circle-outline"
                      : item.type == "TIME"
                      ? "mdi-clock-outline"
                      : item.type == "STREET"
                      ? "mdi-nature-people"
                      : item.type == "DIRECT"
                      ? "mdi-directions"
                      : item.type == "REPEATED"
                      ? "mdi-calendar-refresh"
                      : item.type == "AIRPORT"
                      ? "mdi-airplane-clock"
                      : "mdi-alien-outline"
                  }}</v-icon
                >

                <v-btn icon @click="showOrderDetail(item.id)">
                  <v-icon color="black">mdi-information</v-icon>
                </v-btn>
              </v-list-item-action>
            </template>
          </v-list-item>

          <v-divider
            v-if="index < orderedItems.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list>-->
    </div>
  </v-card>
</template>

<script>
//import axios from "axios";
import {
  Status,
  StatusColor,
  SourceColor,
  TypeColor,
} from "../enums/OrderEnum";
import * as moment from "moment/moment";

import OrderListItem from "../components/OrderListItem.vue";

export default {
  name: "OrderListPanel",
  props: {
    passOperationalDataOrdersToList: {
      type: Array,
      default: [],
    },
    progressStatus: {
      type: String,
      default: "ok",
    },
  },
  components: {
    OrderListItem,
  },
  data() {
    return {
      //pre virtual scroller
      item: OrderListItem,
      items: [],
      //pre virtual scroller - koniec
      //orderedItems: [],

      moment: moment,
      Status,
      SourceColor,
      StatusColor,
      TypeColor,

      showPanel: true,
      showOnlyActiveOrder: true,
      showPlannedOrder: true,
      /*   getAllItemsData: {
        showOnlyActiveOrder: true,
        limit: 100,
        countDriverActiveOrders: true,
      },
*/
      //passOperationalDataOrdersToList: [],
      posts: [],
      errors: [],
      search: "",
      searchToDetailSwitchId: "",

      refreshTimer: null,
      loading: false,
      loadingIndeterminate: false,
    };
  },

  // Fetches posts when the component is created.
  created() {},

  /* mounted() {
    this.getAllItems();

    this.refreshTimer = setInterval(() => {
      this.getAllItems();
    }, 10000);
  },*/

  watch: {
    progressStatus: function (newValue, oldValue) {
      //console.log("progressStatus ", newValue);
      this.animateProgressBar(newValue);
    },
  },

  computed: {
    orderedItems: function () {
      //pri uvodnom nacitani objektu nie su data, teda nepozit funkciu, kym nebudu data
      if (!this.passOperationalDataOrdersToList) {
        return [];
      }
      if (this.showOnlyActiveOrder) {
        // ak su iba aktivne, tak nezobrazovať, preregistrovane, lebo tie este reálne nie su potvrdene zakaznikom a preto neaktivne
        var passOperationalDataOrdersToList =
          this.passOperationalDataOrdersToList.filter(
            (item) => item.status !== "PREREGISTERED"
          );
      } else {
        var passOperationalDataOrdersToList =
          this.passOperationalDataOrdersToList;
      }
      //console.log("this.passOperationalDataOrdersToList: " + this.passOperationalDataOrdersToList);
      let sortingOrder = [
        //v poradi od zadu, lebo na konci sa musia usporiadavat tie co nemaju nadefinovany status
        "FINISHED",
        "IN_PROGRESS",
        "WAITING",
        "ACCEPTED",
        "PLANNED",
        "PENDING", //Cakajuce sa zobrazia vyssie ako Planovane
        "NEW", //New sú prakticky vždy navrhu, lebo ak sa zobrazia, tak je nastavené, že ich dispečer musí akceptovať, aby
        "TRANSFERED",
        "PREREGISTERED",
      ];
      //jednoduche sortovanie podla pozadovaneho poradia stavov
      /*  function compare(a, b) {
        if (sortingOrder.indexOf(a.status) < sortingOrder.indexOf(b.status))
          return 1;
        if (sortingOrder.indexOf(a.status) > sortingOrder.indexOf(b.status))
          return -1;
        return 0;
      }*/
      // Sortovanie podla pozadovaneho poradia stavov a potom podla datumu
      let sortBy = [
        { prop: "status", direction: -1 },
        //{ prop: "requestedTime", direction: 1 },
        { prop: "requestedTime", direction: 1 },
      ];
      function compare(a, b) {
        let i = 0,
          result = 0;
        while (i < sortBy.length && result === 0) {
          if (i == 0) {
            const propA = a[sortBy[i].prop];
            const propB = b[sortBy[i].prop];

            if (propA !== null && propB !== null) {
              result =
                sortBy[i].direction *
                (sortingOrder.indexOf(propA.toString()) <
                sortingOrder.indexOf(propB.toString())
                  ? -1
                  : sortingOrder.indexOf(propA.toString()) >
                    sortingOrder.indexOf(propB.toString())
                  ? 1
                  : 0);
            } //else {result = 0; }
          } else if (i == 1) {
            const propA = a[sortBy[i].prop];
            const propB = b[sortBy[i].prop];

            if (propA !== null && propB !== null) {
              result =
                sortBy[i].direction *
                (propA.toString() < propB.toString()
                  ? -1
                  : propA.toString() > propB.toString()
                  ? 1
                  : 0);
            }
          } //else {result = 0; }
          i++;
        }
        //console.log("a: ",a.id.slice(0, 8),a.status,a.createdAt," | b: ",b.id.slice(0, 8),b.status,b.createdAt,result);
        return result;
      }
      //Funkcne sortovanie najprv podla stavov a potom podla datumov, ale nezohladnuje pozadovane poradie stavov
      /*
            let sortBy = [ { prop: "status", direction: -1, },
        { prop: "createdAt", direction: 1, }, ];
      function compare(a, b) {
        let i = 0,
          result = 0;
        while (i < sortBy.length && result === 0) {
          result =
            sortBy[i].direction *
            (a[sortBy[i].prop].toString() < b[sortBy[i].prop].toString() ? -1
              : a[sortBy[i].prop].toString() > b[sortBy[i].prop].toString() ? 1
              : 0);
          i++;
        }
        return result;
      }
      */
      //filter data with status==NEW"
      var filteredNewData = passOperationalDataOrdersToList.filter(
        (item) => item.status === "NEW"
      );
      //filteredNewData order by "requestedTime" isodate
      filteredNewData.sort(function (a, b) {
        return new Date(a.requestedTime) - new Date(b.requestedTime);
      });
      //filter data with status==PENDING and type=="TIME"
      var filteredPendingTimeData = passOperationalDataOrdersToList.filter(
        (item) => item.status === "PENDING" && item.type === "TIME"
      );
      //filteredPendingTimeData order by "requestedTime" isodate
      filteredPendingTimeData.sort(function (a, b) {
        return new Date(a.requestedTime) - new Date(b.requestedTime);
      });
      //filter data with status==PENDING and type!="TIME"
      var filteredPendingData = passOperationalDataOrdersToList.filter(
        (item) => item.status === "PENDING" && item.type !== "TIME"
      );
      //filteredPendingData order by "requestedTime" isodate
      filteredPendingData.sort(function (a, b) {
        return new Date(a.requestedTime) - new Date(b.requestedTime);
      });
      if (this.showPlannedOrder) {
        //filteredPlannedData order by "requestedTime" isodate
        var filteredPlannedData = passOperationalDataOrdersToList.filter(
          (item) => item.status === "PLANNED"
        );
        filteredPlannedData.sort(function (a, b) {
          return new Date(a.requestedTime) - new Date(b.requestedTime);
        });
      } else {
        //show only PLANNED orders with status==PLANNED and type=="TIME" when requestedTime minus presendTime (presendTime value is integer in minutes) is maximum 30 min in future
        var filteredPlannedData = passOperationalDataOrdersToList.filter(
          (item) =>
            item.status === "PLANNED" &&
            item.type === "TIME" &&
            new Date(item.requestedTime) -
              new Date(item.presendTime * 60 * 1000) -
              new Date() <
              30 * 60 * 1000
        );
        filteredPlannedData.sort(function (a, b) {
          return new Date(a.requestedTime) - new Date(b.requestedTime);
        });

        //var filteredPlannedData = [];
      }
      //filteredAcceptedData order by "acceptedAt" DESC isodate
      var filteredAcceptedData = passOperationalDataOrdersToList.filter(
        (item) => item.status === "ACCEPTED"
      );
      filteredAcceptedData.sort(function (a, b) {
        return new Date(b.acceptedAt) - new Date(a.acceptedAt);
      });
      //filter data with status!=PENDING or (status=PENDING and type!="TIME")
      var filteredData = passOperationalDataOrdersToList.filter(
        (item) =>
          item.status !== "NEW" &&
          item.status !== "PENDING" &&
          item.status !== "PLANNED" &&
          item.status !== "ACCEPTED"
      );
      filteredData.sort(compare);
      //concat filteredNewData, filteredPendingTimeData, filteredPendingData filteredData
      var sortedData = filteredNewData.concat(
        filteredNewData,
        filteredPendingTimeData,
        filteredPendingData,
        filteredPlannedData,
        filteredAcceptedData,
        filteredData
      );

      //return this.passOperationalDataOrdersToList
      //.sort(compare)
      return sortedData.filter((item) => {
        //clearable u pola nastavuje hodnotu search na null, co v tejto funkcii vyhadzuje chybu, treba mat ""
        if (!this.search) {
          this.search = "";
        }
        this.search = this.removeSpecialCharacter(this.search);
        //check if this.search is UID (for example: 88c71091-6b4d-4c54-a690-3c69ff62919a)
        if (
          this.search.match(
            /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
          ) !== null
        ) {
          //this.searchToDetailSwitchId is used to prevent showOrderDetailByIdFromItem() function to be called multiple times
          if (this.searchToDetailSwitchId !== this.search) {
            this.showOrderDetailByIdFromItem(this.search);
            this.searchToDetailSwitchId = this.search;
          }
        } else {
          this.searchToDetailSwitchId = "";
        }
        var timeSearch = "";
        var timeSearchMinus10 = "";
        if (
          this.search.match(/^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/i) !== null
        ) {
          const localTime = this.search;
          const localDateTime = moment().set({
            hour: parseInt(localTime.split(":")[0]),
            minute: parseInt(localTime.split(":")[1]),
          });
          // Konvertovať lokálny čas na UTC
          const utcDateTime = localDateTime.utc();
          timeSearch = utcDateTime.format("HH:mm");
          timeSearchMinus10 = utcDateTime
            .subtract(10, "minutes")
            .format("HH:mm");
          //chceme aby bol cas tolerantny na jednotky minut
          timeSearch = timeSearch.substring(0, timeSearch.length - 1);
          timeSearchMinus10 = timeSearchMinus10.substring(
            0,
            timeSearchMinus10.length - 1
          );
        }
        //console.log("filter - search: ", this.search);
        //console.log("filter - item.id: ", item.id);
        return (
          item.id.toLowerCase().match(this.search) ||
          (item.note
            ? item.note
                .toLocaleLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  this.search
                    .toLocaleLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )
            : false) ||
          (item.waypointList[0]
            ? item.waypointList[0].address
                .toLocaleLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  this.search
                    .toLocaleLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )
            : false) ||
          (item.waypointList[1]
            ? item.waypointList[1].address
                .toLocaleLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  this.search
                    .toLocaleLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )
            : false) ||
          (item.waypointList[2]
            ? item.waypointList[2].address
                .toLocaleLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  this.search
                    .toLocaleLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )
            : false) ||
          (item.waypointList[3]
            ? item.waypointList[3].address
                .toLocaleLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  this.search
                    .toLocaleLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )
            : false) ||
          (item.waypointList[4]
            ? item.waypointList[4].address
                .toLocaleLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  this.search
                    .toLocaleLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )
            : false) ||
          (item.driver
            ? item.driver.firstName
                .toLocaleLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  this.search
                    .toLocaleLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )
            : false) ||
          (item.driver
            ? item.driver.lastName
                .toLocaleLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  this.search
                    .toLocaleLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )
            : false) ||
          (item.requestedTime && timeSearch
            ? item.requestedTime
                .toLocaleLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  timeSearch
                    .toLocaleLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )
            : false) ||
          (item.requestedTime && timeSearchMinus10
            ? item.requestedTime
                .toLocaleLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  timeSearchMinus10
                    .toLocaleLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )
            : false)
        );
      });
    },
  },

  methods: {
    showPanelToggle() {
      if (this.showPanel == true) {
        this.showPanel = false;
      } else {
        this.showPanel = true;
      }
    },
    removeSpecialCharacter(retezec) {
      // Definujte regulárny výraz, ktorý identifikuje znaky !@#$%^&*()_+
      const regex = /[!@#$%^&*()_+]/g;
      //const regex = /[-/\\^$*+?.()|[\]{}]/g;
      // Nahraďte identifikované znaky prázdny reťazec
      const cistyRetezec = retezec.replace(regex, "");

      return cistyRetezec;
    },
    /*   getAllItems() {
      this.animateProgressBar("start"),
        axios
          .get(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/operationalData`,
            {
              params: this.getAllItemsData,
            }
          )
          .then((response) => {
            this.animateProgressBar("ok");
            this.passOperationalDataOrdersToList = response.data.orderList;
            this.$emit("redistributeOperationalData", response.data);
            this.items = response.data.orderList;
          })
          .catch((e) => {
            this.animateProgressBar("error"), this.errors.push(e);
          });
    },*/
    animateProgressBar(progressStatus) {
      if (progressStatus == "start") {
        this.loading = true;
        this.loadingIndeterminate = false;
      } else if (progressStatus == "ok") {
        this.loading = false;
        this.loadingIndeterminate = false;
      } else if (progressStatus == "error") {
        //v pripade chyby sa prepne do rezimu animovaneho loadingu, kym sa neuskotocni uspesny pokus o ziskanie udajov
        this.loadingIndeterminate = true;
      }
    },

    showOrderDetailByIdFromItem(orderId) {
      this.$emit("showOrderDetailById", orderId);
      //this.$store.state.consoleOrderId = orderId;
    },
    passFindPositionCoordinatesToMapFromItem(positionCoordinates) {
      this.$emit("findPositionCoordinatesToMap", positionCoordinates);
      //this.$store.state.consoleOrderId = orderId;
    },
    switchOnlyActiveOrder() {
      this.$emit("switchOnlyActiveOrder", this.showOnlyActiveOrder);
      //this.$store.state.consoleOrderId = orderId;
    },
    copyText(textToCopy) {
      const el = document.createElement("textarea");
      el.value = textToCopy;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
    clearSearch() {
      this.search = "";
      //console.log("clearSearch function: ", this.search);
      this.searchToDetailSwitchId = "";
    },
    //preusporiadanie prejazdovych bodov aby tranzitne boli v strede
    orderedWaypoints(tempList) {
      //console.log("templist: " + JSON.stringify(tempList))
      //console.log("tempList.length: "+ tempList.length)
      if (tempList.length < 3) {
        return 0;
      }
      let sortingOrder = [
        //v poradi odzadu
        "DESTINATION",
        "TRANSIT",
        "BOARD",
      ];
      let sortBy = [
        { prop: "type", direction: -1 },
        { prop: "ordering", direction: 1 },
      ];
      function compareWaypoints(a, b) {
        let i = 0,
          result = 0;
        while (i < sortBy.length && result === 0) {
          if (i == 0) {
            result =
              sortBy[i].direction *
              (sortingOrder.indexOf(a[sortBy[i].prop].toString()) <
              sortingOrder.indexOf(b[sortBy[i].prop].toString())
                ? -1
                : sortingOrder.indexOf(a[sortBy[i].prop].toString()) >
                  sortingOrder.indexOf(b[sortBy[i].prop].toString())
                ? 1
                : 0);
          } else if (i == 1) {
            result =
              sortBy[i].direction *
              (a[sortBy[i].prop].toString() < b[sortBy[i].prop].toString()
                ? -1
                : a[sortBy[i].prop].toString() > b[sortBy[i].prop].toString()
                ? 1
                : 0);
          }
          i++;
        }
        //console.log("a: ", a.id.slice(0, 8), a.status, a.createdAt," | b: ",b.id.slice(0, 8),b.status,b.createdAt,result);
        return result;
      }
      return tempList.sort(compareWaypoints);
    },
    getStatusColor(status) {
      return this.StatusColor[status];
    },
  },

  beforeDestroy() {
    clearInterval(this.refreshTimer);
  },
};
</script>
