<template>
  <div>
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>{{
        $t("VehicleMaintenanceList.page_title")
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>mdi-car-connected</v-icon>
    </v-toolbar>
    <v-row class="my-1" dense align="center">
      <v-col cols="12" sm="3">
        <v-select
          v-model="getAllStatus"
          :label="$t('VehicleMaintenanceList.status')"
          dense
          hide-details
          clearable
          :items="$t('MaintenanceStatusArray')"
        ></v-select>
      </v-col>
      <v-col cols="12" md="3">
        <v-btn small color="info" @click="getAll()">
          <v-icon left dark small> fa-redo-alt </v-icon>
          {{ $t("btnUpdate") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :loading="loadingData"
      :headers="gridHeaders"
      :items="gridItems"
      class="elevation-1"
      sort-by="vehicleInternalName"
      :mobile-breakpoint="mobileBreakpointTable"
      dense
      :items-per-page="50"
      :footer-props="{
        itemsPerPageOptions: [15, 25, 50, 100, -1],
        showFirstLastPage: true,
      }"
      ><!-- Filtrovanie tabulky -->
      <template
        v-slot:[`body.prepend`]
        v-if="$vuetify.breakpoint.width > mobileBreakpointTable"
      >
        <tr>
          <td>
            <v-icon class="px-3">mdi-filter</v-icon>
          </td>
          <td>
            <v-text-field
              v-model="filter.vehicleInternalName"
              type="text"
              clearable
              :label="
                $t('VehicleMaintenanceList.filterLabel.vehicleInternalName')
              "
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.vehicleLpn"
              type="text"
              clearable
              :label="$t('VehicleMaintenanceList.filterLabel.vehicleLpn')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.vehicleStatus"
              type="text"
              clearable
              :label="$t('VehicleMaintenanceList.filterLabel.vehicleStatus')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.vehicleBrand"
              type="text"
              clearable
              :label="$t('VehicleMaintenanceList.filterLabel.vehicleBrand')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.vehicleModel"
              type="text"
              clearable
              :label="$t('VehicleMaintenanceList.filterLabel.vehicleModel')"
            ></v-text-field>
          </td>
          <!-- <td>
            <v-text-field
              v-model="filter.vehicleActive"
              type="text"
              clearable
              :label="$t('VehicleMaintenanceList.filterLabel.vehicleActive')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.vehicleMileages"
              type="text"
              clearable
              :label="$t('VehicleMaintenanceList.filterLabel.vehicleMileages')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.driverLastName"
              type="text"
              clearable
              :label="$t('VehicleMaintenanceList.filterLabel.type')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.status"
              type="text"
              clearable
              :label="$t('VehicleMaintenanceList.filterLabel.status')"
            ></v-text-field>
          </td> -->
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </template>
      <template v-slot:[`body.prepend`] v-else>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.vehicleInternalName"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="
                $t('VehicleMaintenanceList.filterLabel.vehicleInternalName')
              "
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.vehicleLpn"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('VehicleMaintenanceList.filterLabel.vehicleLpn')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.vehicleStatus"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('VehicleMaintenanceList.filterLabel.vehicleStatus')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.vehicleBrand"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('VehicleMaintenanceList.filterLabel.vehicleBrand')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.vehicleModel"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('VehicleMaintenanceList.filterLabel.vehicleModel')"
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <!-- <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.vehicleActive"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('VehicleMaintenanceList.filterLabel.vehicleActive')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.vehicleMileages"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('VehicleMaintenanceList.filterLabel.vehicleMileages')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.type"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('VehicleMaintenanceList.filterLabel.type')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.status"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('VehicleMaintenanceList.filterLabel.status')"
              dense
              hide-details
            ></v-text-field>
          </v-col> -->
        </v-row>
      </template>
      <template v-slot:[`item.plannedAt`]="{ item }">
        <span>{{ $options.filters.formatDateShort(item.plannedAt) }}</span>
      </template>
      <template v-slot:[`item.startedAt`]="{ item }">
        <span>{{ $options.filters.formatDateShort(item.startedAt) }}</span>
      </template>
      <template v-slot:[`item.resolvedAt`]="{ item }">
        <span>{{ $options.filters.formatDateShort(item.resolvedAt) }}</span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          small
          :color="getMaintenanceStatusColor(item.status)"
          class="px-1"
          dark
          label
        >
          {{ $t("MaintenanceStatus." + item.status) }}
        </v-chip>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <v-chip
          small
          :color="getMaintenanceTypeColor(item.type)"
          class="px-1"
          dark
          label
        >
          {{ $t("MaintenanceType." + item.type) }}
        </v-chip>
      </template>

      <template v-slot:[`item.vehicleActive`]="{ item }">
        <v-simple-checkbox
          v-model="item.vehicleActive"
          disabled
        ></v-simple-checkbox>
      </template>
      <template v-slot:[`item.oilChange`]="{ item }">
        <v-simple-checkbox
          v-model="item.oilChange"
          disabled
        ></v-simple-checkbox>
      </template>

      <template v-slot:no-data>
        <v-btn color="error" @click="getAll()"> {{ $t("btnReload") }}</v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small @click="showDetail(item.id)"
          >mdi-square-edit-outline</v-icon
        >
      </template>
    </v-data-table>

    <!-- Dialog na detail -->
    <v-dialog v-model="showVehicleMaintenanceDetailDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t("VehicleMaintenanceList.dialogDetailTitle")
          }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" v-show="displayOnlyForAdmin">
                <v-text-field
                  v-model="selectedItem.vehicleId"
                  type="text"
                  :label="$t('VehicleMaintenanceList.vehicleId')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItem.vehicleBrand"
                  type="text"
                  :label="$t('VehicleMaintenanceList.brand')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItem.vehicleModel"
                  type="text"
                  :label="$t('VehicleMaintenanceList.model')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItem.vehicleLpn"
                  type="text"
                  :label="$t('VehicleMaintenanceList.lpn')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItem.vehicleMileages"
                  type="text"
                  :label="$t('VehicleMaintenanceList.mileages')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <my-datepicker
                  v-model="selectedItem.vehicleLastMaintenanceAt"
                  :label="$t('VehicleMaintenanceList.lastMaintenanceAt')"
                  dense
                  disabled
                  hide-details
                >
                </my-datepicker
              ></v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItem.vehicleLastMaintenanceMileage"
                  type="text"
                  :label="$t('VehicleMaintenanceList.lastMaintenanceMileage')"
                  readonly
                  dense
                  hide-details
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItem.vehicleMaintenanceIntervalDay"
                  type="text"
                  :label="$t('VehicleMaintenanceList.maintenanceIntervalDay')"
                  readonly
                  dense
                  hide-details
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItem.vehicleMaintenanceIntervalMileage"
                  type="text"
                  :label="
                    $t('VehicleMaintenanceList.maintenanceIntervalMileage')
                  "
                  readonly
                  dense
                  hide-details
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItem.vehicleMaintenanceNotificationDay"
                  type="text"
                  :label="
                    $t('VehicleMaintenanceList.maintenanceNotificationDay')
                  "
                  readonly
                  dense
                  hide-details
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItem.vehicleMaintenanceNotificationMileage"
                  type="text"
                  :label="
                    $t('VehicleMaintenanceList.maintenanceNotificationMileage')
                  "
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-divider class="pt-4 pb-2 mt-4 mb-2"></v-divider>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="selectedItem.title"
                  type="text"
                  :label="$t('VehicleMaintenanceList.title')"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="selectedItem.type"
                  :label="$t('VehicleMaintenanceList.type')"
                  dense
                  hide-details
                  :items="$t('MaintenanceTypeArray')"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="selectedItem.status"
                  :label="$t('VehicleMaintenanceList.status')"
                  dense
                  hide-details
                  :items="$t('MaintenanceStatusArray')"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItem.price"
                  type="number"
                  :label="$t('VehicleMaintenanceList.price')"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <my-datepicker
                  v-model="selectedItem.plannedAt"
                  :label="$t('VehicleMaintenanceList.plannedAt')"
                  dense
                  :hideDetails="true"
                >
                </my-datepicker>
              </v-col>
              <v-col cols="12" sm="6">
                <my-datepicker
                  v-model="selectedItem.startedAt"
                  :label="$t('VehicleMaintenanceList.startedAt')"
                  dense
                  :hideDetails="true"
                >
                </my-datepicker>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItem.startedMileage"
                  type="number"
                  :label="$t('VehicleMaintenanceList.startedMileage')"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <my-datepicker
                  v-model="selectedItem.resolvedAt"
                  :label="$t('VehicleMaintenanceList.resolvedAt')"
                  dense
                  :hideDetails="true"
                >
                </my-datepicker>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItem.resolvedMileage"
                  type="number"
                  :label="$t('VehicleMaintenanceList.resolvedMileage')"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  @click="setUpdateVehicleMaintenanceDataMileage()"
                  ><v-icon left> mdi-car-arrow-right </v-icon>
                  {{
                    $t(
                      "VehicleMaintenanceList.btnSetUpdateMaintenanceMileageAtFromVehicle"
                    )
                  }}
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  @click="setUpdateVehicleMaintenanceDataDatesNow()"
                  ><v-icon left> mdi-clock-time-eight-outline </v-icon>
                  {{
                    $t(
                      "VehicleMaintenanceList.btnSetUpdateMaintenanceDatesAtToNow"
                    )
                  }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="selectedItem.note"
                  type="text"
                  :label="$t('VehicleMaintenanceList.note')"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="selectedItem.fluidCheck"
                  :label="$t('VehicleMaintenanceList.fluidCheck')"
                  dense
                  hide-details
                  prepend-icon="mdi-beaker-check"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="selectedItem.oilTopUp"
                  :label="$t('VehicleMaintenanceList.oilTopUp')"
                  dense
                  hide-details
                  prepend-icon="mdi-oil-level"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="selectedItem.oilChange"
                  :label="$t('VehicleMaintenanceList.oilChange')"
                  dense
                  hide-details
                  prepend-icon="mdi-oil"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="selectedItem.coolantChange"
                  :label="$t('VehicleMaintenanceList.coolantChange')"
                  dense
                  hide-details
                  prepend-icon="mdi-coolant-temperature"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="selectedItem.brakeFluidChange"
                  :label="$t('VehicleMaintenanceList.brakeFluidChange')"
                  dense
                  hide-details
                  prepend-icon="mdi-car-brake-parking"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="selectedItem.oilFilterReplacement"
                  :label="$t('VehicleMaintenanceList.oilFilterReplacement')"
                  dense
                  hide-details
                  prepend-icon="mdi-barrel-outline"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="selectedItem.airFilterReplacement"
                  :label="$t('VehicleMaintenanceList.airFilterReplacement')"
                  dense
                  hide-details
                  prepend-icon="mdi-air-filter"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="selectedItem.airConFilterReplacement"
                  :label="$t('VehicleMaintenanceList.airConFilterReplacement')"
                  dense
                  hide-details
                  prepend-icon="mdi-air-conditioner"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="selectedItem.sparkOrGlowPlugReplacement"
                  :label="
                    $t('VehicleMaintenanceList.sparkOrGlowPlugReplacement')
                  "
                  dense
                  hide-details
                  prepend-icon="mdi-white-balance-incandescent"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="selectedItem.tireReplacement"
                  :label="$t('VehicleMaintenanceList.tireReplacement')"
                  dense
                  hide-details
                  prepend-icon="mdi-car-tire-alert"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="selectedItem.drivetrainCheck"
                  :label="$t('VehicleMaintenanceList.drivetrainCheck')"
                  dense
                  hide-details
                  prepend-icon="mdi-source-branch-check"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="selectedItem.serpentineBeltReplacement"
                  :label="
                    $t('VehicleMaintenanceList.serpentineBeltReplacement')
                  "
                  dense
                  hide-details
                  prepend-icon="mdi-ferris-wheel"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="selectedItem.otherFilterReplacement"
                  :label="$t('VehicleMaintenanceList.otherFilterReplacement')"
                  dense
                  hide-details
                  prepend-icon="mdi-image-filter-black-white"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="selectedItem.otherFluidTopUp"
                  :label="$t('VehicleMaintenanceList.otherFluidTopUp')"
                  dense
                  hide-details
                  prepend-icon="mdi-water"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="selectedItem.lightRepair"
                  :label="$t('VehicleMaintenanceList.lightRepair')"
                  dense
                  hide-details
                  prepend-icon="mdi-lightbulb-on-outline"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="selectedItem.brakeRepair"
                  :label="$t('VehicleMaintenanceList.brakeRepair')"
                  dense
                  hide-details
                  prepend-icon="mdi-car-brake-parking"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="selectedItem.bodyRepair"
                  :label="$t('VehicleMaintenanceList.bodyRepair')"
                  dense
                  hide-details
                  prepend-icon="mdi-car-hatchback"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="selectedItem.engineRepair"
                  :label="$t('VehicleMaintenanceList.engineRepair')"
                  dense
                  hide-details
                  prepend-icon="mdi-engine"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="selectedItem.electronicsRepair"
                  :label="$t('VehicleMaintenanceList.electronicsRepair')"
                  dense
                  hide-details
                  prepend-icon="mdi-electric-switch"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="selectedItem.drivetrainRepair"
                  :label="$t('VehicleMaintenanceList.drivetrainRepair')"
                  dense
                  hide-details
                  prepend-icon="mdi-source-branch-remove"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="selectedItem.batteryReplacement"
                  :label="$t('VehicleMaintenanceList.batteryReplacement')"
                  dense
                  hide-details
                  prepend-icon="mdi-battery-charging-90"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="selectedItem.otherRepair"
                  :label="$t('VehicleMaintenanceList.otherRepair')"
                  dense
                  hide-details
                  prepend-icon="mdi-wrench"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="selectedItem.cleaning"
                  :label="$t('VehicleMaintenanceList.cleaning')"
                  dense
                  hide-details
                  prepend-icon="mdi-car-wash"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="showNewVehicleMaintenanceDialog = false"
          >
            {{ $t("btnClose") }}
          </v-btn>
          <v-btn
            color="success"
            text
            @click.stop="submitUpdateVehicleMaintenanceDialog()"
            >{{ $t("btnUpdate") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog na aktualizaciu maintenance casu a kilometrov vozidla -->
    <v-dialog v-model="updateLastVehicleMaintenanceDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t("VehicleDriverList.dialogUpdateLastMaintenanceTitle")
          }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" v-show="displayOnlyForAdmin">
                <v-text-field
                  v-model="updateMaintenanceData.id"
                  type="text"
                  :label="$t('VehicleDriverList.vehicleMaintenance.vehicleId')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItem.brand"
                  type="text"
                  :label="$t('VehicleDriverList.vehicleMaintenance.brand')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItem.model"
                  type="text"
                  :label="$t('VehicleDriverList.vehicleMaintenance.model')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItem.lpn"
                  type="text"
                  :label="$t('VehicleDriverList.vehicleMaintenance.lpn')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItem.mileages"
                  type="text"
                  :label="$t('VehicleDriverList.vehicleMaintenance.mileages')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-divider class="pt-4 pb-2 mt-4 mb-2"></v-divider>
            <v-row>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  @click="setLastMaintenanceAtLast(updateMaintenanceData.id)"
                >
                  {{
                    $t(
                      "VehicleDriverList.vehicleMaintenance.btnSetLastMaintenanceAtFromVehicle"
                    )
                  }}
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  @click="setLastMaintenanceAtNow(updateMaintenanceData.id)"
                >
                  {{
                    $t(
                      "VehicleDriverList.vehicleMaintenance.btnSetLastMaintenanceAtToNow"
                    )
                  }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <my-datepicker
                  v-model="updateMaintenanceData.lastMaintenanceAt"
                  :label="
                    $t('VehicleDriverList.vehicleMaintenance.lastMaintenanceAt')
                  "
                  dense
                  :hideDetails="true"
                >
                </my-datepicker>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="updateMaintenanceData.lastMaintenanceMileage"
                  type="number"
                  :label="
                    $t(
                      'VehicleDriverList.vehicleMaintenance.lastMaintenanceMileage'
                    )
                  "
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="updateLastVehicleMaintenanceDialog = false"
          >
            {{ $t("btnClose") }}
          </v-btn>
          <v-btn
            color="success"
            text
            @click.stop="submitUpdateLastVehicleMaintenanceDialog()"
            >{{ $t("btnUpdate") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";

export default {
  data() {
    return {
      displayOnlyForAdmin: false,
      moment: moment,
      //Prepinanie zobrazenia tabulky pri malom rozliseni z dat v riadkoch na standardnu stlpcovu tabulku
      mobileBreakpointSwitch: false,
      mobileBreakpointTable: 600,

      gridHeaders: [
        {
          text: this.$t("VehicleMaintenanceList.tableHeaders.actions"),
          value: "actions",
          sortable: false,
        },
        {
          text: this.$t(
            "VehicleMaintenanceList.tableHeaders.vehicleInternalName"
          ),
          value: "vehicleInternalName",
          sortable: true,
          filter: (value) => {
            if (!this.filter.vehicleInternalName) {
              return value === "" || value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.vehicleInternalName.toLowerCase());
            }
          },
        },
        {
          text: this.$t("VehicleMaintenanceList.tableHeaders.vehicleLpn"),
          value: "vehicleLpn",
          sortable: true,
          filter: (value) => {
            if (!this.filter.vehicleLpn) {
              return value === "" || value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.vehicleLpn.toLowerCase());
            }
          },
        },
        {
          text: this.$t("VehicleMaintenanceList.tableHeaders.vehicleStatus"),
          value: "vehicleStatus",
          sortable: true,
          filter: (value) => {
            if (!this.filter.vehicleStatus) {
              return value === "" || value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.vehicleStatus.toLowerCase());
            }
          },
        },
        {
          text: this.$t("VehicleMaintenanceList.tableHeaders.vehicleBrand"),
          value: "vehicleBrand",
          sortable: true,
          filter: (value) => {
            if (!this.filter.vehicleBrand) {
              return value === "" || value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.vehicleBrand.toLowerCase());
            }
          },
        },
        {
          text: this.$t("VehicleMaintenanceList.tableHeaders.vehicleModel"),
          value: "vehicleModel",
          sortable: true,
          filter: (value) => {
            if (!this.filter.vehicleModel) {
              return value === "" || value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.vehicleModel.toLowerCase());
            }
          },
        },
        {
          text: this.$t("VehicleMaintenanceList.tableHeaders.vehicleActive"),
          value: "vehicleActive",
          sortable: true,
        },
        {
          text: this.$t("VehicleMaintenanceList.tableHeaders.vehicleMileages"),
          value: "vehicleMileages",
          sortable: true,
        },
        {
          text: this.$t("VehicleMaintenanceList.tableHeaders.type"),
          value: "type",
          sortable: true,
          filter: (value) => {
            if (!this.filter.type) {
              return value === "" || value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.type.toLowerCase());
            }
          },
        },
        {
          text: this.$t("VehicleMaintenanceList.tableHeaders.status"),
          value: "status",
          sortable: true,
          filter: (value) => {
            if (!this.filter.status) {
              return value === "" || value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.status.toLowerCase());
            }
          },
        },
        {
          text: this.$t("VehicleMaintenanceList.tableHeaders.plannedAt"),
          value: "plannedAt",
          sortable: true,
        },
        {
          text: this.$t("VehicleMaintenanceList.tableHeaders.resolvedAt"),
          value: "resolvedAt",
          sortable: true,
        },
        {
          text: this.$t("VehicleMaintenanceList.tableHeaders.oilChange"),
          value: "oilChange",
        },
        {
          text: this.$t("VehicleMaintenanceList.tableHeaders.price"),
          value: "price",
        },
      ],
      gridItems: [],

      loadingData: false,

      //Zoznam vozidiel
      vehicleList: [],
      search: null,
      getAllStatus: null,

      filter: {
        search: "",
        vehicleBrand: "",
        vehicleModel: "",
        vehicleInternalName: "",
        vehicleLpn: "",
        vehicleStatus: "",
        vehicleActive: "",
        vehicleMileages: "",
        type: "",
        status: "",
        plannedAt: "",
        resolvedAt: "",
        oilChange: "",
        price: "",
      },

      showVehicleMaintenanceDetailDialog: false,
      selectedItem: {
        vehicleBrand: "",
        vehicleModel: "",
        vehicleInternalName: "",
        vehicleLpn: "",
        vehicleStatus: "",
        vehicleActive: "",
        vehicleMileages: "",
        type: "",
        status: "",
        plannedAt: "",
        resolvedAt: "",
        oilChange: "",
        price: "",
      },

      updateLastVehicleMaintenanceDialog: false,
      updateMaintenanceData: {
        id: null,
        lastMaintenanceAt: null,
        lastMaintenanceMileage: null,
      },

      //Snackbar
      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 4000,
      },
    };
  },

  mounted() {
    //Nacitanie role podla ktorej sa potom zobrazuju niektore extra polia alebo tlacidla
    this.getUserRole();
    this.getAll();
  },
  watch: {
    mobileBreakpointSwitch: function () {
      //ak sa zmeni mobileBreakpointSwitch, nadefinovat hodnotu pre mobileBreakpoint
      if (this.mobileBreakpointSwitch === true) {
        this.mobileBreakpointTable = 0;
      } else {
        this.mobileBreakpointTable = 600;
      }
      //console.log("mobileBreakpointTable", this.mobileBreakpointTable);
      this.setCookie("mobileBreakpointSwitch", this.mobileBreakpointSwitch, 30);
    },
  },

  methods: {
    getUserRole() {
      let enabledRoles = ["ADMIN", "SUPPORT"];
      let userRoles = this.$store.state.user.roleList;
      this.displayOnlyForAdmin =
        userRoles.some((a) => (enabledRoles.includes(a) ? true : false)) ===
        true;
    },
    getAll() {
      this.loadingData = true;
      var status = "";
      if (this.getAllStatus) {
        status = "?statuses=" + this.getAllStatus;
      }
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/vehicle-maintenance` +
            status
        )

        .then((response) => {
          this.loadingData = false;
          var gridItems = response.data;
          this.gridItems = gridItems;
        })
        .catch((e) => {
          this.loadingData = false;
          this.snackbar.show = true;
          this.snackbar.message = this.$t("msgGetDataError");
          this.snackbar.color = "error";
        });
    },
    /* detail(id) {
      this.$router.push({ name: "pricelist-detail", params: { id: id } });
    },*/

    showDetail(id) {
      var selectedItem;
      //gridItems = this.gridItems;
      selectedItem = Object.assign(
        {},
        this.gridItems.filter(function (elem) {
          if (elem.id == id) return elem;
        })[0]
      );
      if (selectedItem) {
        //alert("selectedItem: " + JSON.stringify(selectedItem));
        this.selectedItem = selectedItem;
        this.showVehicleMaintenanceDetailDialog = true;
      }
    },
    submitUpdateVehicleMaintenanceDialog() {
      if (this.selectedItem.status == "PLANNED") {
        if (
          this.selectedItem.plannedAt == null ||
          this.selectedItem.plannedAt == 0
        ) {
          alert("Zadajte čas plánovanej údržby!");
          return;
        }
      } else if (this.selectedItem.status == "ONGOING") {
        if (
          this.selectedItem.startedMileage == null ||
          this.selectedItem.startedMileage == 0
        ) {
          alert("Zadajte hodnotu pre stav kilometrov na začiatku!");
          return;
        }
        if (
          this.selectedItem.startedAt == null ||
          this.selectedItem.startedAt == 0
        ) {
          alert("Zadajte čas začatia údržby!");
          return;
        }
      } else if (this.selectedItem.status == "COMPLETED") {
        if (
          this.selectedItem.resolvedMileage == null ||
          this.selectedItem.resolvedMileage == 0
        ) {
          alert("Zadajte hodnotu pre stav kilometrov!");
          return;
        }
        if (
          this.selectedItem.resolvedAt == null ||
          this.selectedItem.resolvedAt == 0
        ) {
          alert("Zadajte čas ukončenia údržby!");
          return;
        }
      }
      this.showVehicleMaintenanceDetailDialog = false;
      axios
        .put(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/vehicle-maintenance`,
          this.selectedItem
        )
        .then((response) => {
          this.snackbar.show = true;
          this.snackbar.message = this.$t("msgUpdateSuccess");
          this.snackbar.color = "success";
          if (this.selectedItem.status == "COMPLETED") {
            //check if this.gridItems contains item with later resolvedAt than this.selectedItem.resolvedAt if lpn is the same
            let laterResolvedAt = this.gridItems.some((item) => {
              if (
                item.vehicleLpn === this.selectedItem.vehicleLpn &&
                item.resolvedAt > this.selectedItem.resolvedAt
              ) {
                return true;
              }
            });

            if (!laterResolvedAt) {
              //check if new data are different from last maintenance data
              if (
                this.selectedItem.resolvedAt !==
                  this.selectedItem.lastMaintenanceAt ||
                this.selectedItem.resolvedMileage !==
                  this.selectedItem.lastMaintenanceMileage
              ) {
                this.updateMaintenanceData.id = this.selectedItem.vehicleId;
                this.updateMaintenanceData.lastMaintenanceAt =
                  this.selectedItem.resolvedAt;
                this.updateMaintenanceData.lastMaintenanceMileage =
                  this.selectedItem.resolvedMileage;
                this.updateLastVehicleMaintenanceDialog = true;
              }
            } else {
              this.getAll();
            }
          } else {
            this.getAll();
          }
        })
        .catch((e) => {
          this.snackbar.show = true;
          this.snackbar.message = this.$t("msgUpdateError");
          this.snackbar.color = "error";
        });
    },

    submitUpdateLastVehicleMaintenanceDialog() {
      axios
        .put(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/vehicle/maintenance`,
          this.updateMaintenanceData
        )
        .then((response) => {
          this.updateLastVehicleMaintenanceDialog = false;
          this.getAll();
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgUpdateError");
          this.snackbar.color = "error";
        });
    },
    setLastMaintenanceAtLast(id) {
      var selectedItem;
      selectedItem = Object.assign(
        {},
        this.gridItems.filter(function (elem) {
          if (elem.id == id) return elem;
        })[0]
      );
      if (selectedItem) {
        this.updateMaintenanceData.lastMaintenanceAt =
          selectedItem.lastMaintenanceAt;
        this.updateMaintenanceData.lastMaintenanceMileage =
          selectedItem.lastMaintenanceMileage;
      }
    },
    setLastMaintenanceAtNow(id) {
      var selectedItem;
      selectedItem = Object.assign(
        {},
        this.gridItems.filter(function (elem) {
          if (elem.id == id) return elem;
        })[0]
      );
      if (selectedItem) {
        this.updateMaintenanceData.lastMaintenanceAt = new Date();
        this.updateMaintenanceData.lastMaintenanceAt.setSeconds(0);
        this.updateMaintenanceData.lastMaintenanceAt.setMilliseconds(0);
        this.updateMaintenanceData.lastMaintenanceMileage =
          selectedItem.mileages;
      }
    },
    setUpdateVehicleMaintenanceDataMileage() {
      if (this.selectedItem) {
        if (this.selectedItem.status == "PLANNED") {
          this.selectedItem.startedMileage = this.selectedItem.vehicleMileages;
        } else if (this.selectedItem.status == "ONGOING") {
          this.selectedItem.startedMileage = this.selectedItem.vehicleMileages;
        } else if (this.selectedItem.status == "COMPLETED") {
          if (
            this.selectedItem.startedMileage == null ||
            this.selectedItem.startedMileage == 0
          ) {
            this.selectedItem.startedMileage =
              this.selectedItem.vehicleMileages;
          }
          this.selectedItem.resolvedMileage = this.selectedItem.vehicleMileages;
        } else {
          alert("Pre tento stav doplňte údaje ručne.");
        }
      }
    },
    setUpdateVehicleMaintenanceDataDatesNow() {
      if (this.selectedItem.status == "PLANNED") {
        this.selectedItem.plannedAt = new Date();
        this.selectedItem.plannedAt.setSeconds(0);
        this.selectedItem.plannedAt.setMilliseconds(0);
      } else if (this.selectedItem.status == "ONGOING") {
        this.selectedItem.startedAt = new Date();
        this.selectedItem.startedAt.setSeconds(0);
        this.selectedItem.startedAt.setMilliseconds(0);
      } else if (this.selectedItem.status == "COMPLETED") {
        this.selectedItem.resolvedAt = new Date();
        this.selectedItem.resolvedAt.setSeconds(0);
        this.selectedItem.resolvedAt.setMilliseconds(0);
      } else {
        alert("Pre tento stav doplňte údaje ručne.");
      }
    },

    getVehicleStatusColor(status) {
      switch (status) {
        case "ACTIVE":
          return "green";
        case "DISABLED":
          return "red";
        default:
          return "black";
      }
    },
    getStatusColor(status) {
      switch (status) {
        case "ENABLED":
          return "green";
        case "DISABLED":
          return "red";
        default:
          return "black";
      }
    },
    getActiveColor(status) {
      switch (status) {
        case "ACTIVE":
          return "green";
        case "DISABLED":
          return "red";
        default:
          return "black";
      }
    },
    getMaintenanceStatusColor(status) {
      switch (status) {
        case "PLANNED":
          return "purple";
        case "DELAYED":
          return "red";
        case "ONGOING":
          return "orange";
        case "PAUSED":
          return "orange";
        case "COMPLETED":
          return "green";
        case "CANCELLED":
          return "grey";
        case "UNFINISHED":
          return "red";
        default:
          return "black";
      }
    },
    getMaintenanceTypeColor(type) {
      switch (type) {
        case "REPAIR":
          return "red";
        case "MAINTENANCE":
          return "green";
        case "CHECKUP":
          return "orange";
        case "SHORT_TERM_INSPECTION":
          return "purple";
        case "LONG_TERM_INSPECTION":
          return "purple";
        case "SEASONAL_INSPECTION":
          return "purple";
        case "CLEANING":
          return "blue";
        default:
          return "black";
      }
    },
  },
};
</script>
